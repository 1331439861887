import React, { memo } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// constants
import { exchangeOptions } from "constants";
// api
import { endpoints } from "services/endpoints";
import { useMutate } from "hooks/query";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";
import ControlledSelect from "components/Select/ControlledSelect";
// icons
import IconSave from "assets/images/svg/icon-save.svg";

const ModalAddPaymentArrival = memo(({ close, refetch, courses }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const rowId = searchParams.get("id");
  const type = searchParams.get("type");

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      return: false,
    },
  });

  var prevDay = new Date();
  prevDay.setDate(prevDay.getDate() - 1);

  function removeId() {
    reset();
    searchParams.delete("type");
    setSearchParams(searchParams);
  }

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: () => {
      refetch();
      reset();
      clearAndClose();
      notifySuccess(t("notification.succesApply"));
    },
  });

  const onSubmit = (values) => {
    const body = {
      payment_type: type,
      num: 0,
      currency: values.currency,
      base_amount: null,
      currency_amount: null,
      course: courses[values.currency]?.amount || 0.0,
      percent: 100,
      note: "",
    };

    create.mutate({
      link: endpoints.invoices.invoicePaymentCreate,
      params: { invoice_id: rowId },
      method: "post",
      body,
    });
  };

  return (
    <div className="w-[300px]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2">
            <ControlledSelect
              label={t("pages.manInvoice.form.type")}
              autofocus={true}
              name="currency"
              errors={errors}
              height="50px"
              isRequired={true}
              placeholder={t("pages.manInvoice.form.typePlaceholder")}
              className="w-full  placeholder-darkGreyLight rounded-lg focus:border-purple-0 focus:bg-white focus:border-2 font-[16px] max-w-[100%]"
              options={exchangeOptions}
              onChange={(e) => {
                setValue("currency", e.value);
              }}
            />
          </div>

          <div className="col-span-2 flex justify-between">
            <BaseButton
              label={t("button.cancel")}
              className={`p-2 btn-secondary dark:btn-secondary-dark`}
              onClick={clearAndClose}
            />

            <BaseButton
              icon={IconSave}
              label={t("button.save")}
              type="submit"
              className="col-span-1 bg-mainGreen text-white font-semibold"
              isLoading={create.isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
});

export default ModalAddPaymentArrival;
