import BaseTd from "components/Table/BaseTd";

const Tbody = ({ data }) => {
  return (
    <>
      {data.map((item) => (
        <tr key={item.id} className="hover:bg-GreyLight dark:hover:bg-inputBgDark">
          <BaseTd>{item.name}</BaseTd>
          <BaseTd>{item.description ? item.description : "-"}</BaseTd>
        </tr>
      ))}
    </>
  );
};

export default Tbody;
