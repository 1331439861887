// i18n
import { useTranslation } from "react-i18next";

export const useTranslatedTableConfig = () => {
  const { t } = useTranslation();

  const productsHeaders = [
    {
      key: "number",
      title: "№",
      width: "w-[3%]",
    },

    {
      key: "name",
      title: t("form.title"),
      width: "w-[35%]",
    },
    {
      key: "quantity",
      title: t("pages.admInvoice.table.quantity"),
      width: "w-[8%]",
    },
    {
      key: "price",
      title: t("pages.admInvoice.table.price"),
      width: "w-[8%]",
    },
    {
      key: "pf",
      title: t("pages.admInvoice.table.pf"),
      width: "w-[5%]",
    },
    {
      key: "sum",
      title: t("pages.admInvoice.table.sum"),
      width: "w-[8%]",
    },
    {
      key: "pfs",
      title: t("pages.admInvoice.table.pfs"),
      width: "w-[5%]",
    },
    {
      key: "warehouse",
      title: t("pages.admInvoice.table.warehouse"),
      width: "w-[8%]",
    },
    {
      key: "comment",
      title: t("pages.admInvoice.table.comment"),
      width: "w-[5%]",
    },
    {
      key: "rp",
      title: t("pages.admInvoice.table.rp"),
      width: "w-[8%]",
    },
    {
      key: "b2b",
      title: t("pages.admInvoice.table.b2b"),
      width: "w-[8%]",
    },
    {
      key: "b2c",
      title: t("pages.admInvoice.table.b2c"),
      width: "w-[8%]",
    },
    {
      key: "actions",

      width: "w-[2%]",
    },
  ];

  const paymentsHeaders = [
    {
      key: "number",
      title: "№",
      width: "w-[12%]",
    },
    {
      key: "type",
      title: t("pages.admInvoice.table.type"),
      width: "w-[15%]",
    },

    {
      key: "sum",
      title: t("pages.admInvoice.table.sum"),
      width: "w-[15%]",
    },
    {
      key: "percentage",
      title: "%",
      width: "w-[13%]",
    },
    {
      key: "exchange",
      title: t("pages.admInvoice.table.exchange"),
      width: "w-[15%]",
    },
    {
      key: "usd",
      title: "$",
      width: "w-[16%]",
    },
    {
      key: "comment",
      title: t("pages.admInvoice.table.comment"),
      width: "w-[10%]",
    },

    {
      key: "actions",
      width: "w-[5%]",
    },
  ];

  return { productsHeaders, paymentsHeaders };
};
