import React, { useState } from "react";
// hooks
import useMenuIcons from "hooks/useMenuIcons/useMenuIcons";
// store
import { useCoreStore } from "store/core";
// components
import MenuItem from "./MenuItem";
// icons
import Minus from "assets/images/svg/icon-minus.svg";
import MinusDark from "assets/images/svg/icon-minus-dark.svg";
import Plus from "assets/images/svg/icon-plus.svg";
import PlusDark from "assets/images/svg/icon-plus-dark.svg";

const MenuList = () => {
  const { isOpenSidebar, theme } = useCoreStore();
  const { orgId } = useCoreStore((state) => state);
  const menus = useMenuIcons(orgId);

  const [activeMenus, setActiveMenus] = useState({});

  function menuToggler(label) {
    setActiveMenus((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
    sessionStorage.setItem("submenu", JSON.stringify({ ...activeMenus, [label]: !activeMenus[label] }));
  }
  const menuCollapseIcon = (label) => (activeMenus[label] ? (theme ? MinusDark : Minus) : theme ? PlusDark : Plus);

  return (
    <ul className="menu-list">
      {menus.map((menu) => (
        <React.Fragment key={menu.label}>
          {menu.subMenu?.length > 0 ? (
            <>
              <div onClick={() => menuToggler(menu.label)} className="menu-list--submenu flex items-center">
                <MenuItem
                  isMenu={false}
                  extraClass={`pl-6 ${menu.status === "done" ? "bg-success" : ""}`}
                  menu={{ ...menu, to: "#" }}
                />
                {!isOpenSidebar && (
                  <div className="menu-list--collapse-icon mr-4 w-5 h-5 rounded flex items-center justify-center p-1 cursor-pointer bg-white dark:bg-[#36343E]">
                    <img alt="toggle-icon" src={menuCollapseIcon(menu.label)} />
                  </div>
                )}
              </div>
              {activeMenus[menu.label] &&
                menu.subMenu.map((el, index) => (
                  <MenuItem
                    key={el.to}
                    menu={el}
                    extraClass={`pl-12 sub-menu-item dark ${menu.subMenu.length - 1 === index ? "last-sub-item" : ""}`}
                    child={true}
                  />
                ))}
            </>
          ) : (
            <MenuItem menu={menu} extraClass="pl-6" />
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default MenuList;
