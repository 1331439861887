import { useEffect } from "react";
// store
import { useCoreStore } from "store/core";
import { DarkModeSwitch } from "react-toggle-dark-mode";

const ThemeToggler = () => {
  const { theme, toggleTheme } = useCoreStore((state) => ({
    theme: state.theme,
    toggleTheme: state.toggleTheme,
  }));

  const toggleDarkMode = () => {
    toggleTheme();
  };

  useEffect(() => {
    if (theme) {
      document.body.setAttribute("data-theme", "dark");
    } else {
      document.body.setAttribute("data-theme", "light");
    }
  }, [theme]);

  return <DarkModeSwitch checked={theme} onChange={toggleDarkMode} size={24} />;
};

export default ThemeToggler;
