import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
// helpers
import { debounce } from "services/helpers";
// api
import { useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
// store
import shallow from "zustand/shallow";
import { useCoreStore } from "store/core";
// constants
import { exchangeOptions } from "constants";
// i18n
import { useTranslation } from "react-i18next";
// components
import BaseButton from "components/Button/BaseButton";
import ControlledInput from "components/Input/ControlledInput";
import Checkbox from "components/Checkbox/Checkbox";
import Select from "components/Select/Select";
import Datepicker from "react-tailwindcss-datepicker";
import AdminAddInvoiceModal from "pages/Invoices/AdminInvoices/Modal/ModalAddInvoice";
import Modal from "components/Modal/Modal";
// icons
import AddSquareIcon from "assets/images/svg/add_square-icon.svg";
import ExcelIcon from "assets/images/svg/exel-icon.svg";
import RestoreIcon from "assets/images/svg/restore-icon.svg";
import SearchIcon from "assets/images/svg/search-white.svg";
import LogoutIcon from "assets/images/svg/logout-icon.svg";

const NavigatorAdminInvoices = () => {
  const {
    reset,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [key, setKey] = useState(Math.random());

  const { isOpenSidebar, toggleSidebar, orgId, setAdminInvoiceFilters, theme } = useCoreStore(
    (state) => ({
      isOpenSidebar: state.isOpenSidebar,
      toggleSidebar: state.toggleSidebar,
      setOrgId: state.setOrgId,
      theme: state.theme,
      setNavigatorExtra: state.setNavigatorExtra,
      setAdminInvoiceFilters: state.setAdminInvoiceFilters,
      orgId: state.orgId,
    }),
    shallow,
  );
  const addInvoice = useRef();

  const handleTemplatesAddInvoice = (value) => {
    addInvoice.current.openModal(value);
  };

  const [currentDate, setDate] = useState({
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
  });
  const [currentDate1, setDate1] = useState({
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
  });

  // customers
  const [customerSearch, setCustomerSearch] = useState("");
  const [customerOptions, setCustomerOptions] = useState([]);

  useGet({
    link: endpoints.customers.search,
    params: { org_id: orgId, name: customerSearch || undefined },
    enabled: customerSearch.length > 2,
    onSuccess: (data) => {
      const options = data?.map((el) => ({ value: el.id, label: el.name }));
      setCustomerOptions(options);
    },
  });

  // products
  const [productSearch, setProductSearch] = useState("");
  const [productOptions, setProductOptions] = useState([]);

  useGet({
    link: endpoints.product.search,
    params: {
      org_id: orgId,
      name: productSearch || undefined,
    },
    enabled: productSearch.length > 2,
    onSuccess: (data) => {
      const options = data?.map((el) => ({ value: el.id, label: el.name }));
      setProductOptions(options);
    },
  });

  // managers

  const [managerSearch, setManagerSearch] = useState("");
  const [managerOptions, setManagerOptions] = useState([]);

  useGet({
    link: endpoints.users.list,
    params: {
      org_id: orgId,
      permission: "org-manager",
      q: managerSearch || undefined,
    },
    enabled: managerSearch.length > 2,
    onSuccess: (data) => {
      const options = data?.map((el) => ({ value: el.id, label: el.full_name }));
      setManagerOptions(options);
    },
  });

  const [tagOptions, setTagOptions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  useGet({
    link: endpoints.tags.list,
    params: { org_id: orgId },
    onSuccess: (data) => {
      const options = data?.map((el) => ({ value: el.tag_name, label: el.tag_name }));
      setTagOptions(options);
    },
  });

  const onTagSelect = (selected) => {
    setSelectedTags(selected);
  };

  const customer = watch("customer");
  const product = watch("product");
  const tags = watch("tags");
  const payment_type = watch("payment_type");
  const currency = watch("currency");
  const subject = watch("subject");

  const manager = watch("manager");
  const is_empty = watch("is_empty");
  const min_price = watch("min_price");
  const max_price = watch("max_price");

  const sendFilters = () => {
    const values = getValues();

    // create a string as "tag1,tag2,tag3"
    const selectedTags = values.tags?.map((el) => el.value).join(",");

    const finalObj = {
      // date in format "yyyy-mm-dd"
      from_date: currentDate.endDate.split("/").reverse().join("-"),
      to_date: currentDate1.endDate.split("/").reverse().join("-"),
      customer_id: customer?.value,
      product_id: product?.value,
      manager_id: manager?.value,
      tags: selectedTags,
      payment_type: payment_type?.value,
      currency: currency?.value,
      subject: subject?.value,
      min_price: min_price,
      max_price: max_price,
      is_empty: is_empty,
      timestamp: new Date().getTime(),
    };

    setAdminInvoiceFilters(finalObj);
  };

  useEffect(() => {
    if (product) {
      setValue("subject", {
        value: "product",
        label: "Товар",
      });
    } else {
      setValue("subject", "");
    }
  }, [product, setValue]);

  useEffect(() => {
    if (currency) {
      setValue("subject", {
        value: "payment",
        label: "Оплата",
      });
    } else {
      setValue("subject", "");
    }
  }, [currency, setValue]);

  const resetFilters = () => {
    reset();
    setDate({ startDate: new Date(), endDate: new Date() });
    setDate1({ startDate: new Date(), endDate: new Date() });
    setCustomerSearch("");
    setProductSearch("");
    setManagerSearch("");
    setSelectedTags([]);
    setAdminInvoiceFilters({});
    setKey(Math.random());
  };

  const selectStyles = {
    border: theme ? "1px solid #2d2d2d!important" : "1px solid #9B9DA3!important",
    borderRadius: "4px!important",
    fontSize: "14px!important",
    height: "36px!important",
    minHeight: "36px!important",
    padding: "0 5px!important",
    marginTop: "4px!important",
    placeholder: {
      opacity: "0.1!important",
    },
  };

  return (
    <div className={`p-[20px] flex flex-col h-full ${isOpenSidebar && "p-[12px]"}`}>
      <div className={`flex justify-between items-center ${isOpenSidebar && "flex-col"}`}>
        <div
          className={`flex justify-start items-center hover:cursor-pointer  ${isOpenSidebar && "mb-[40px]"}`}
          onClick={() => navigate("/home")}
        >
          <img src={LogoutIcon} alt="LogoutIcon" className="w-5" />
          {!isOpenSidebar && (
            <p className="text-[#535456] ml-3 text-[18px] dark:text-[#8B8999] hover:text-[#FF705C] group-hover:text-[#FF705C]">
              {t("button.logout")}
            </p>
          )}
        </div>
        <div className="flex flex-col gap-1 pl-1 -ml-[5px] items-end cursor-pointer" onClick={toggleSidebar}>
          <span className="w-5 h-[3px] rounded-sm bg-[#535456]"></span>
          <span className="w-4 h-[3px] rounded-sm bg-[#535456]"></span>
          <span className="w-5 h-[3px] rounded-sm bg-[#535456]"></span>
        </div>
      </div>

      <div className={`mt-[35px] ${isOpenSidebar && "hidden"}`}>
        <form>
          <div className="grid grid-cols-2 gap-y-[8px] gap-x-2">
            <div className="flex flex-col col-span-1">
              <label className="block text-stone-800 dark:text-white font-semibold text-[14px]">{t("info.top")}</label>
              <Datepicker
                key={key}
                asSingle
                useRange={false}
                placeholder={currentDate.startDate}
                displayFormat={"DD.MM.YYYY"}
                startFrom={currentDate}
                value={currentDate}
                onChange={setDate}
                primaryColor={"violet"}
                containerClassName="relative calendar-container calendar-container-small"
                inputClassName="w-full py-[4px] px-[6px] rounded-[4px] focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey text-[14px] border-1 border-[#9B9DA3] dark:border-[#2d2d2d] h-[38px] dark:text-white"
                //place calendar icon to the left
                calendarIcon={<img src="/static/media/calendar-icon.8b5b8b9e.svg" alt="calendar-icon" />}
              />
            </div>
            <div className="flex flex-col col-span-1">
              <label className="block text-stone-800 dark:text-white font-semibold text-[14px]">{t("info.to")}</label>
              <Datepicker
                key={key + 1}
                asSingle
                useRange={false}
                placeholder={currentDate1.startDate}
                displayFormat={"DD.MM.YYYY"}
                startFrom={currentDate1}
                minDate={currentDate.endDate}
                value={currentDate1}
                onChange={setDate1}
                primaryColor={"violet"}
                containerClassName="relative calendar-container calendar-container-small"
                inputClassName="w-full py-[4px] px-[6px] rounded-[4px] focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey text-[14px] border-1 border-[#9B9DA3] dark:border-[#2d2d2d] h-[38px] dark:text-white"
              />
            </div>
            <div className="flex flex-col col-span-2">
              <Controller
                name="customer"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    fieldName="customer"
                    name="customer"
                    styles={selectStyles}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    errors={errors}
                    required
                    label={t("pages.manInvoice.form.customer")}
                    placeholder={t("pages.manInvoice.form.customer")}
                    onInputChange={(value) => {
                      debounce(() => {
                        setCustomerSearch(value);
                      }, 500)();
                    }}
                    // handleChange={}
                    options={customerOptions}
                  />
                )}
              />
            </div>
            <div className="flex flex-col col-span-2">
              <Controller
                name="product"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    fieldName="product"
                    name="product"
                    styles={selectStyles}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    errors={errors}
                    required
                    label={t("pages.betweenWarehouse.product")}
                    placeholder={t("pages.betweenWarehouse.product")}
                    onInputChange={(value) => {
                      debounce(() => {
                        setProductSearch(value);
                      }, 500)();
                    }}
                    // handleChange={}
                    options={productOptions}
                  />
                )}
              />
            </div>
            <div className="flex flex-col col-span-2">
              {/* <Select
                styles={selectStyles}
                placeholderStyles={placeholderStyles}
                label="Тег"
                placeholder="Тег"
                isMulti={false}
                closeMenuOnSelect={false}
                options={options}
                onChange={() => console.log("Log")}
                errors={{}}
              /> */}
              <Controller
                name="tags"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    fieldName="tags"
                    name="tags"
                    styles={selectStyles}
                    isMulti={true}
                    shortMulti={true}
                    closeMenuOnSelect={false}
                    errors={errors}
                    required
                    label={t("pages.products.table.tags")}
                    placeholder={t("pages.products.table.tags")}
                    // handleChange={}
                    options={tagOptions}
                  />
                )}
              />
              {/* <ControlledSelect
                isMulti={true}
                value={selectedTags}
                closeMenuOnSelect={false}
                width={"417px"}
                className="relative !z-[100]"
                options={tagOptions}
                onChange={onTagSelect}
                // hasError={isExistSelectedTag}
              /> */}
            </div>
            <div className="flex flex-col col-span-1">
              <Controller
                name="payment_type"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    fieldName="payment_type"
                    name="payment_type"
                    styles={selectStyles}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    errors={errors}
                    required
                    label={t("info.operation")}
                    placeholder={t("info.operation")}
                    options={[
                      {
                        value: null,
                        label: t("info.all"),
                      },
                      {
                        value: "INCOME",
                        label: t("pages.betweenWarehouse.income"),
                      },
                      {
                        value: "OUTGO",
                        label: t("pages.betweenWarehouse.expense"),
                      },
                    ]}
                  />
                )}
              />
            </div>
            <div className="flex flex-col col-span-1">
              <Controller
                name="currency"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    fieldName="currency"
                    name="currency"
                    styles={selectStyles}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    errors={errors}
                    required
                    label={t("pages.kurs.table.currency")}
                    placeholder={t("pages.kurs.table.currency")}
                    options={[
                      {
                        value: null,
                        label: t("info.all"),
                      },
                      ...exchangeOptions,
                    ]}
                  />
                )}
              />
            </div>
            <div className="flex flex-col col-span-2">
              <Controller
                name="manager"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    fieldName="manager"
                    name="manager"
                    styles={selectStyles}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    errors={errors}
                    required
                    label={t("pages.customers.table.manager")}
                    placeholder={t("pages.customers.table.manager")}
                    onInputChange={(value) => {
                      debounce(() => {
                        setManagerSearch(value);
                      }, 500)();
                    }}
                    options={managerOptions}
                  />
                )}
              />
            </div>
            <div className="flex flex-col col-span-2">
              <Controller
                name="subject"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    fieldName="subject"
                    name="subject"
                    styles={selectStyles}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    errors={errors}
                    required
                    label={t("info.subject")}
                    placeholder={t("info.subject")}
                    // handleChange={}
                    options={[
                      {
                        value: null,
                        label: t("info.all"),
                      },
                      {
                        value: "product",
                        label: t("pages.betweenWarehouse.product"),
                      },
                      {
                        value: "payment",
                        label: t("pages.manInvoice.payment"),
                      },
                    ]}
                  />
                )}
              />
            </div>
            {/* <div className="flex flex-col col-span-2 opacity-[0.2]">
              <Select
                styles={selectStyles}
                placeholderStyles={placeholderStyles}
                label="Проблемные товары"
                placeholder="Проблемные товары"
                isMulti={false}
                closeMenuOnSelect={false}
                options={options}
                onChange={() => console.log("Log")}
                errors={{}}
              />
            </div> */}
            <div className="flex flex-col col-span-1">
              <Controller
                key={key}
                name="min_price"
                control={control}
                render={({ field }) => (
                  <ControlledInput
                    {...field}
                    label={t("pages.sale.min")}
                    name="min_price"
                    fieldName="min_price"
                    className="w-full h-[38px] outline-none border-1 border-[#9B9DA3] dark:border-[#2d2d2d] rounded-[4px] mt-[4px] focus:border-[#9B9DA3] focus:border-[1px]"
                    placeholder="0"
                  />
                )}
              />
            </div>
            <div className="flex flex-col col-span-1">
              <Controller
                key={key}
                name="max_price"
                control={control}
                render={({ field }) => (
                  <ControlledInput
                    {...field}
                    label={t("pages.sale.max")}
                    name="max_price"
                    fieldName="max_price"
                    className="w-full h-[38px] outline-none border-1 border-[#9B9DA3] dark:border-[#2d2d2d] rounded-[4px] mt-[4px] focus:border-[#9B9DA3] focus:border-[1px]"
                    placeholder="0"
                  />
                )}
              />
            </div>
            {/* <div className="flex flex-col col-span-2">
              <Controller
                name="is_empty"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    label="Подозрительный товар"
                    {...field}
                    labelClassName="text-[14px]"
                    classNameExtra="text-[14px] text-[#535456] dark:text-[#8B8999] font-semibold mt-[10px]"
                  />
                )}
              />
            </div> */}
            <div className="flex flex-col col-span-2">
              <Controller
                name="is_empty"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    label={t("pages.admInvoice.emptyInvoice")}
                    {...field}
                    labelClassName="text-[14px]"
                    classNameExtra="text-[14px] text-[#535456] dark:text-[#8B8999] font-semibold"
                  />
                )}
              />
            </div>
          </div>
        </form>
      </div>

      <div
        className={`btns flex flex-row justify-center space-x-[8px] mt-auto ${
          isOpenSidebar && "flex-col space-x-[0] space-y-[8px]"
        }`}
      >
        <BaseButton
          type="submit"
          icon={RestoreIcon}
          className={`w-full max-w-[52px] p-[16px] rounded-[6px] bg-[#FFCD29] text-textBlack hover:opacity-[0.8] transition duration-200
            ${isOpenSidebar && "px-0 py-[10px]"}`}
          iconClassName="w-[20px]"
          onClick={resetFilters}
        />
        <BaseButton
          type="submit"
          icon={AddSquareIcon}
          className={`w-full max-w-[52px] p-[16px] rounded-[6px] bg-[#2196F3] text-textBlack hover:opacity-[0.8] transition duration-200
            ${isOpenSidebar && "px-0 py-[10px]"}
            `}
          onClick={() => handleTemplatesAddInvoice(true)}
          iconClassName="w-[20px]"
        />
        <BaseButton
          type="submit"
          icon={ExcelIcon}
          className={`w-full max-w-[52px] p-[16px] rounded-[6px] bg-[#7B61FF] text-textBlack hover:opacity-[0.8] transition duration-200"
             ${isOpenSidebar && "px-0 py-[10px]"}`}
          iconClassName="w-[20px]"
        />
        <BaseButton
          type="submit"
          icon={SearchIcon}
          className={`w-full max-w-[52px] p-[16px] rounded-[6px] bg-[#87CB19] text-textBlack hover:opacity-[0.8] transition duration-200
            ${isOpenSidebar && "px-0 py-[10px]"}
            `}
          onClick={sendFilters}
          iconClassName="w-[20px]"
        />
      </div>

      <Modal ref={addInvoice} title={t("pages.manInvoice.addInvoice")}>
        <AdminAddInvoiceModal close={handleTemplatesAddInvoice} />
      </Modal>
    </div>
  );
};

export default NavigatorAdminInvoices;
