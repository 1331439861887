import React, { forwardRef } from "react";
import cls from "classnames";

const Checkbox = forwardRef((props, ref) => {
  const { name, label, value = true, classNameExtra, labelClassName, ...rest } = props;

  return (
    <div className={`checkbox flex flex-row gap-1 ${classNameExtra} `}>
      <>
        <input
          {...rest}
          ref={ref}
          type="checkbox"
          id={name}
          defaultChecked={value}
          className={cls({ checked: value })}
        />
        <label
          className={cls("checkbox-label after:bg-white", {
            "bg-purple-1": value === true,
            "bg-gray-500": value === false,
          })}
          htmlFor={name}
        ></label>
      </>
      {label && <label className={cls("block text-stone-800 dark:text-textDark font-semibold ", labelClassName)}>{label}</label>}
    </div>
  );
});

export default Checkbox;
