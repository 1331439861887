import cls from "classnames";
import CircleLoader from "components/Loader/CircleLoader";
import { useCoreStore } from "store/core";

function Input(props) {
  const { theme } = useCoreStore();
  const {
    type = "text",
    fieldName,
    register,
    label,
    className,
    errors,
    placeholder,
    isRequired,
    validations,
    defaultValue,
    isLoading = false,
    refData,
    leftElement,
    rightElement,
    autoFocus,
    disabled = false,
    value,
    inputType,
    formatFunction,
  } = props;

  const { ref, ...rest } = {
    ...register(fieldName, {
      required: {
        value: isRequired,
        // message: "This is required",
      },
      ...validations,
    }),
  };

  const handleKeyDown = (event) => {
    // Allow Tab and Backspace and . keys
    if (
      type === "number" &&
      event.key !== "Tab" &&
      event.key !== "Backspace" &&
      !/^\d+$/.test(event.key || "") &&
      event.key !== "."
    ) {
      event.preventDefault();
    }
    // prefix can be only 2 letters and uppercase (auto uppercase), also don't allow to write numbers
    if (inputType === "letterPrefix") {
      if (event.key !== "Tab" && event.key !== "Backspace" && !/^[A-Za-z]+$/.test(event.key || "")) {
        event.preventDefault();
      }
      if (event.target.value.length >= 2 && event.key !== "Backspace") {
        event.preventDefault();
      }
    }

    if (inputType === "numberPrefix") {
      // maximum value 15, not allow 16 and more
      if (event.target.value.length >= 2 && event.key !== "Backspace") {
        event.preventDefault();
      }
      if (event.target.value.length === 1 && event.key !== "Backspace" && event.key !== "Tab" && event.key !== "0") {
        event.preventDefault();
      }
    }
  };

  // const handleInputChange = (event) => {
  //   const upperValue = event.target.value.toUpperCase().slice(0, 2);

  //   if (/^[A-Za-z]*$/.test(upperValue)) {
  //     event.target.value = upperValue;
  //   }
  // };

  return (
    <div className="mb-2">
      {label && (
        <label
          className={cls("block text-stone-800 dark:text-white font-semibold text-[14px]", {
            "required-field": isRequired,
          })}
          htmlFor={fieldName}
        >
          {label}
        </label>
      )}
      <div className="relative">
        {leftElement && <div className="absolute left-3 top-1/2 -translate-y-1/2">{leftElement}</div>}
        <input
          type={type}
          defaultValue={defaultValue}
          placeholder={placeholder}
          autoFocus={autoFocus}
          // onKeyDown={handleKeyDown}
          // onChange={handleInputChange}
          disabled={disabled}
          value={value || null}
          step="any"
          {...rest}
          className={cls(
            `input bg-GreyInput placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 
            focus:bg-white dark:focus:border-purple-2 focus:border-2 dark:text-midGrey dark:border-blue-3 dark:bg-inputBgDark `,
            className,
            theme ? "custominput-dark" : "custominput-light",
            {
              "pl-9": leftElement,
              "pr-9": rightElement,
            },
          )}
          {...rest}
          ref={(e) => {
            ref(e);
            if (refData) refData.current = e;
          }}
        />
        {isLoading ? (
          <div className="absolute right-3 top-1/2 -translate-y-1/2">
            <CircleLoader theme="primary" />
          </div>
        ) : null}
        {rightElement && <div className="absolute right-3 top-1/2 -translate-y-1/2">{rightElement}</div>}
      </div>
      {errors[fieldName] && <p className="error-message mt-1 text-red-500 text-[14px]">{errors[fieldName].message}</p>}
    </div>
  );
}
export default Input;
