import BaseTbody from "./BaseTbody";

const BaseTable = ({ thead, tbody, extraClassNames = "" }) => {
  return (
    <div className={`overflow-y-auto flex-1 ${extraClassNames}`}>
      <table className="w-full border-collapse border-none">
        {/*<thead className="bg-GreyInput opacity-70">{thead}</thead>*/}
        <thead
          style={{
            zIndex: "60",
            position: "relative",
          }}
        >
          {thead}
        </thead>
        <BaseTbody>{tbody}</BaseTbody>
      </table>
    </div>
  );
};

export default BaseTable;


// import BaseTbody from "./BaseTbody";

// const BaseTable = ({ thead, tbody, extraClassNames = "" }) => {
//   return (
//     <div className={`max-h-[76%] overflow-y-auto 2xl:max-h-[75%] ${extraClassNames}`}>
//       <table className="w-full border-collapse border-none">
//         {/*<thead className="bg-GreyInput opacity-70">{thead}</thead>*/}
//         <thead
//           style={{
//             zIndex: "60",
//             position: "relative",
//           }}
//         >
//           {thead}
//         </thead>
//         <BaseTbody>{tbody}</BaseTbody>
//       </table>
//     </div>
//   );
// };

// export default BaseTable;
