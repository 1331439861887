import uploader from "services/uploader";
// i18n
import { useTranslation } from "react-i18next";
// icons
import iconAddphoto from "assets/images/svg/icon-addphoto.svg";
import iconDeletephoto from "assets/images/svg/delete.svg";

const ImageUploader = ({ url = [], setImagesUrl, label, isMulti = false, name = "", descElement, imgIcon }) => {
  const { t } = useTranslation();

  const requestImageUpload = (event) => {
    uploader(event).then((resp) => {
      let img = resp();
      if (img.includes("localhost")) {
        img = process.env.REACT_APP_API_PROXY_URL + img.slice(21, img.length);
      }

      if (name) {
        setImagesUrl((prev) => {
          return {
            ...prev,
            [name]: [img],
          };
        });
      } else {
        setImagesUrl((prev) => [...prev, img]);
      }
    });
  };

  const deleteImg = (img, name) =>
    setImagesUrl((prevImg) => {
      if (Array.isArray(prevImg)) {
        return prevImg.filter((pht) => pht !== img);
      } else {
        return prevImg[name].filter((pht) => pht !== img);
      }
    });

  return (
    <div>
      {label && <label className="block text-stone-800 dark:text-white font-semibold text-[14px] mb-1">{label}</label>}
      <div className="image__uploader-imgs">
        {url &&
          url.map((image) => (
            <div className="image__uploader-content group cursor-pointer " key={image}>
              <img src={image} alt="document" className="image__uploader-img group-hover:opacity-60" />
              <img
                src={iconDeletephoto}
                alt="delete-icon"
                className="image__uploader-delete absolute opacity-0 group-hover:opacity-100 transition-all duration-300"
                onClick={() => deleteImg(image, name)}
              />
            </div>
          ))}

        {(isMulti || url.length === 0) && (
          <label htmlFor={name || "requestPhoto"} className="image__uploader-img create dark:bg-[#1F1E25]">
            <input type="file" id={name || "requestPhoto"} onChange={requestImageUpload} className="w-[120px]" />
            <img src={imgIcon || iconAddphoto} alt="addphoto" />
          </label>
        )}
        {descElement ? (
          descElement
        ) : (
          <div>
            <h2 className="textBlack font-semibold text-[15px]">{t("info.press")}</h2>
            <p className="text-[#9B9DA3] font-normal text-[13px]">{t("info.minimum")} 256px x 256px</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUploader;
