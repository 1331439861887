import React, { useContext, useMemo, useRef } from "react";
// api
import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// context
import { ProductGroupsContext } from "../ProductGroupsLayout";
// helpers
import { useTranslatedTableConfig } from "../Helpers";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import DndTable from "components/Table/DndTable";
import ActionButton from "components/Button/ActionButton";

const Table = ({ data = [], seperator, handleSearch }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const { header } = useTranslatedTableConfig();
  const { parentIds, setParentIds } = useContext(ProductGroupsContext);
  const bindPropertyId = useRef();

  const bindPropertyMutate = useMutate({
    onSuccess: () => {
      notifySuccess(t("notification.parameterSuccessLinked"));
      setParentIds((prevState) => ({ ...prevState, propertyId: bindPropertyId.current }));
    },
  });

  const handleBindProperty = (id) => {
    bindPropertyId.current = id;
    bindPropertyMutate.mutate({
      link: `${endpoints.productGroup.bindProperty}?id=${parentIds.parentId}`,
      method: "patch",
      body: { property_id: id },
    });
  };
  const handleBindPropertySeperator = (id, value_id) => {
    let newId = id;
    if (newId === parentIds.propertyId) {
      let timestamp = new Date().getTime();
      newId += timestamp;
    }
    bindPropertyId.current = newId;
    bindPropertyMutate.mutate({
      link: `${endpoints.productGroup.bindProperty}?id=${parentIds.parentId}`,
      method: "patch",
      body: { property_id: id, default_value_id: value_id },
    });
  };

  const reset = () => {
    bindPropertyId.current = null;
  };

  const sortCategory = useMutate({
    onSuccess: () => {
      notifySuccess(t("notification.parameterSuccessReorder"));
      reset();
    },
  });

  const onListChange = (data) => {
    sortCategory.mutate({
      link: `${endpoints.parameter.sort}`,
      method: "patch",
      body: data,
    });
  };

  const tableData = useMemo(() => {
    return data.map((el, index) => {
      return {
        index,
        id: el.id,
        row: [
          {
            title: <div className="hover:text-blue-400 cursor-pointer">{el.name[lang]}</div>,
          },
          {
            title: (
              <div className="flex gap-4 items-center justify-end">
                <ActionButton type="move" handleClick={() => handleBindProperty(el.id)} />
              </div>
            ),
          },
        ],
      };
    });
  }, [data]);

  return (
    <div className="h-full relative">
      <DndTable
        header={header}
        body={tableData}
        addable={false}
        seperator={seperator}
        isSearchable={true}
        handleSearch={handleSearch}
        searchPlaceholder={`${t("pages.productGroups.searchParam")}...`}
        orderChange={onListChange}
        handleBindPropertySeperator={handleBindPropertySeperator}
      />
    </div>
  );
};

export default Table;
