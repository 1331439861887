import { useState } from "react";
import cls from "classnames";

// const postionTypes = {
//   top: "-top-8",
//   bottom: "-bottom-8",
// };

const arrowPostions = {
  top: "-top-1",
  bottom: "-bottom-1",
};

export const Tooltip = ({ children, content, position = "", arrowPostion = "top", className = "" }) => {
  const [show, setShow] = useState(false);

  const onMouseEnter = () => {
    setShow(true);
  };

  const onMouseLeave = () => {
    setShow(false);
  };

  return (
    <>
      <div className="relative">
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {children}
        </div>
        <div
          className={cls(
            "absolute flex flex-col items-center max-w-[320px] !z-[420]",
            className,
            // postionTypes[position],
            position,
            {
              hidden: !show,
            },
          )}
        >
          <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className="relative z-20 p-2 bg-[#3C3C3C] shadow-lg rounded-[3px] w-full"
          >
            {content}
            <div className={cls("w-2 h-2 absolute left-[45%] rotate-45 bg-[#3C3C3C]", arrowPostions[arrowPostion])} />
          </div>
        </div>
      </div>
    </>
  );
};
