import React, { useState, useRef } from "react";
import cls from "classnames";
import { useNavigate } from "react-router-dom";
// hooks
import { useOutsideAlerter } from "hooks/outSideClick";
// helpers
import { logOut } from "services/helpers";
// store
import { useCoreStore } from "store/core";
// i18n
import { useTranslation } from "react-i18next";
// components
import Avatar from "components/Avatar";
// icons
import Settings from "assets/images/svg/settings.svg";
import Logout from "assets/images/svg/log-out.svg";

const PersonBar = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const wrapperRef = useRef(null);
  const { userData } = useCoreStore((state) => state);

  const toggler = () => {
    setIsActive(!isActive);
    navigate("/profile");
  };

  const close = () => {
    setIsActive(false);
  };
  const logOutHandler = () => {
    logOut();
    toggler();
    navigate("/login");
  };

  useOutsideAlerter(wrapperRef, close);

  return (
    <div ref={wrapperRef}>
      <div
        className="flex items-center gap-2 cursor-pointer bg-GreyInput dark:bg-[transparent] rounded-[8px]"
        onClick={toggler}
      >
        <Avatar img={userData?.photo} />
      </div>
      <div
        className={cls(
          "profile--box block w-44 py-2 px-2 absolute top-16 right-10 bg-white z-10 dark:border-0 rounded-[6px] dark:bg-mainGreyDark",
          { hidden: !isActive },
        )}
      >
        <div
          onClick={toggler}
          className="flex gap-2 px-2 py-1 cursor-pointer hover:bg-GreyInput dark:hover:bg-[#393c41]"
        >
          <img src={Settings} alt="settings" />
          <span className="dark:text-white"> {t("drawer.setting")} </span>
        </div>
        <div
          onClick={logOutHandler}
          className="flex gap-2 px-2 py-1 cursor-pointer hover:bg-GreyInput dark:hover:bg-[#393c41]"
        >
          <img src={Logout} alt="logout" />
          <span className="dark:text-white"> {t("button.logout")} </span>
        </div>
      </div>
    </div>
  );
};

export default PersonBar;
