import * as Yup from "yup";

export const validationSchema = Yup.object({
  name: Yup.string().required("name kiriting"),
});

export const header = [
  {
    key: "name",
    title: "Name",
  },
  {
    key: "actions",
    title: "",
  },
];

export const fields = [
  {
    type: "text",
    name: "name",
    showError: true,
    control: "input",
    placeholder: "enter your name",
    label: "name",
    className: "w-full",
  },
];
