import { useState, useEffect, useContext } from "react";
import { Tooltip } from "react-tooltip";
// context
import { SaleContext } from "../Sale";
// helpers
import { formatPrice } from "services/helpers";
// api
import { endpoints } from "services/endpoints";
import { useMutate } from "hooks/query";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";

const ProductItem = ({ item, currentLayout, setProductSaleInfo, course }) => {
  const { singleInvoice, setSingleInvoice, type } = useContext(SaleContext);

  const { t } = useTranslation();
  const [contains, setContains] = useState(false);

  const update = useMutate({
    onSuccess: (res) => {
      if (contains) {
        // increase amount
        if (type === "return") {
          setSingleInvoice({
            ...singleInvoice,
            income_products: singleInvoice?.income_products.map((el) =>
              el.product_id === item.id ? { ...el, amount: el.amount + 1 } : el,
            ),
          });
        } else {
          setSingleInvoice({
            ...singleInvoice,
            outgo_products: singleInvoice?.outgo_products.map((el) =>
              el.product_id === item.id ? { ...el, amount: el.amount + 1 } : el,
            ),
          });
        }
      } else {
        // add new item
        if (type === "return") {
          // add to invoice
          setSingleInvoice({
            ...singleInvoice,
            income_products: [
              ...singleInvoice?.income_products,
              {
                ...item,
                id: res?.id,
                product_id: item.id,
                product_name: item.name,
                amount: 1,
              },
            ],
          });
          // add to product sale info
          setProductSaleInfo((prev) => {
            const incomeProducts = prev?.income_products;

            const newProduct = {
              id: res?.id,
              paymentType: "uzs",
              paymentUzs: null,
              paymentUsd: null,
              paymentSumUzs: null,
              paymentSumUsd: null,
              amount: 1,
            };
            return { ...prev, income_products: [...incomeProducts, newProduct] };
          });
        } else {
          // add to invoice
          setSingleInvoice({
            ...singleInvoice,
            outgo_products: [
              ...singleInvoice?.outgo_products,
              {
                ...item,
                id: res?.id,
                product_id: item.id,
                product_name: item.name,
                amount: 1,
              },
            ],
          });
          // add to product sale info
          setProductSaleInfo((prev) => {
            const outgoProducts = prev?.outgo_products;

            const newProduct = {
              id: res.id,
              paymentType: "uzs",
              paymentUzs: null,
              paymentUsd: null,
              paymentSumUzs: null,
              paymentSumUsd: null,
              amount: 1,
            };
            return { ...prev, outgo_products: [...outgoProducts, newProduct] };
          });
        }
      }
      notifySuccess(t("notification.productSuccessAdd"));
    },
  });

  const onClick = () => {
    if (contains) {
      if (type === "return") {
        // find id if product id is equal to item id
        const id = singleInvoice?.income_products?.find((el) => el.product_id === item.id)?.id;

        update.mutate({
          link: `${endpoints.invoices.updateIncomeProduct}?id=${id}`,
          method: "patch",
          body: {
            amount: singleInvoice?.income_products?.find((el) => el.product_id === item.id)?.amount + 1,
          },
        });
      } else {
        const id = singleInvoice?.outgo_products?.find((el) => el.product_id === item.id)?.id;
        update.mutate({
          link: `${endpoints.invoices.updateOutgoProduct}?id=${id}`,
          method: "patch",
          body: {
            amount: singleInvoice?.outgo_products?.find((el) => el.product_id === item.id)?.amount + 1,
          },
        });
      }
    } else {
      if (type === "return") {
        update.mutate({
          link: `${endpoints.invoices.incomeProductCreate}?invoice_id=${singleInvoice?.id}`,
          method: "post",
          body: {
            product_id: item.id,
            amount: 1,
          },
        });
      } else {
        update.mutate({
          link: `${endpoints.invoices.outgoProductCreate}?invoice_id=${singleInvoice?.id}`,
          method: "post",
          body: {
            product_id: item.id,
            amount: 1,
          },
        });
      }
    }
  };

  //create function to cut the string by max symbols
  const cutString = (str, max) => {
    if (str.length <= max) return str;
    return str.slice(0, max) + "...";
  };
  // function to check if the product is already in the invoice
  function isContains(arr, obj) {
    return arr?.some((el) => el.product_id === obj.id);
  }
  // useEffect to check if the product is already in the invoice
  useEffect(() => {
    if (type === "return") setContains(isContains(singleInvoice?.income_products, item));
    else setContains(isContains(singleInvoice?.outgo_products, item));
  }, [singleInvoice, item, type]);

  return (
    <>
      {currentLayout === "list" ? (
        <div
          className={`${
            contains && "bg-[#7B61FF33]"
          } grid grid-cols-10 items-center border-1 border-transparent mb-[18px] px-[16px]
          hover:border-solid hover:border-[#D1D1D1] dark:hover:border-[#2d2d2d] transition duration-100 ease-in-out
          rounded-lg hover:cursor-pointer
      `}
          onClick={onClick}
        >
          <img
            alt="product"
            src={item?.images[0]}
            className={`
           w-[40px] h-[40px] rounded-lg mr-[16px] col-span-1`}
          />
          <span className="mr-[4px] col-span-4 dark:text-[#fff]">
            {/* {cutString(item?.name, 35)} */}

            {item.name.length > 35 ? (
              <>
                <a data-tooltip-id={`product-name-tooltip-${item.id}`} data-tooltip-place="bottom">
                  {cutString(item.name, 35)}
                </a>
                <Tooltip
                  id={`product-name-tooltip-${item.id}`}
                  style={{ maxWidth: "320px", width: "fit-content", textAlign: "center", zIndex: "500" }}
                >
                  <div class="flex flex-row flex-wrap text-white">{item.name}</div>
                </Tooltip>
              </>
            ) : (
              <>{cutString(item?.name, 35)}</>
            )}
          </span>

          <div className="col-span-2">
            <div className="bg-[#F2F2F2] dark:bg-[#1F1E25] rounded-lg py-[3px] w-[fit-content] px-[6px]">
              <span className="text-[#9B9DA3] font-semibold">{item?.code}</span>
            </div>
          </div>
          <span className="col-span-3 text-right pr-[16px] dark:text-[#fff]">
            {formatPrice(item?.b2c_price * course)} {t("info.sum")} (${formatPrice(item?.b2c_price)})
          </span>
        </div>
      ) : (
        <div
          className={`${
            contains && "!bg-[#7B61FF33]"
          } w-auto flex flex-col border-solid border-1 p-[4px] rounded-lg border-[#F2F2F2] dark:border-[#2d2d2d] dark:bg-[#1F1E25] hover:cursor-pointer`}
          onClick={onClick}
        >
          <div className="relative p-[4px]">
            <img alt="product" src={item?.images[0]} className="w-full rounded-lg h-[100px] object-cover" />
            <div className="absolute bottom-[8px] left-[8px] bg-[#F2F2F2] rounded-[4px] py-[3px] w-[fit-content] px-[6px] text-[11px]">
              <span className="text-[#9B9DA3] font-semibold">{item?.code}</span>
            </div>
          </div>
          {item.name.length > 17 ? (
            <>
              <span
                data-tooltip-id={`product-name-tooltip-${item.id}`}
                data-tooltip-place="bottom"
                className="dark:text-[#fff]"
              >
                {cutString(item.name, 17)}
              </span>
              <Tooltip
                id={`product-name-tooltip-${item.id}`}
                style={{ maxWidth: "320px", width: "fit-content", textAlign: "center", zIndex: "500" }}
              >
                <div class="flex flex-row flex-wrap text-white">{item.name}</div>
              </Tooltip>
            </>
          ) : (
            <span className="ml-[4px] font-semibold dark:text-white">{cutString(item?.name, 17)}</span>
          )}

          <span className="ml-[4px] dark:text-white">
            {formatPrice(item?.b2c_price * course)} {t("info.sum")} (${formatPrice(item?.b2c_price)})
          </span>
        </div>
      )}
    </>
  );
};

// px-[16px] w-full items-center pb-[18px]

export default ProductItem;
