// i18n
import { useTranslation } from "react-i18next";

export const useTranslatedTableConfig = () => {
  const { t } = useTranslation();

  const header = [
    {
      key: "name",
      title: t("pages.mainOrganizations.table.name"),
      width: "w-[20%]",
    },
    {
      key: "phone",
      title: t("pages.mainOrganizations.table.phone"),
      width: "w-[20%]",
    },
    {
      key: "tin",
      title: t("pages.mainOrganizations.table.tin"),
      width: "w-[10%]",
    },
    {
      key: "users",
      title: t("pages.mainOrganizations.table.users"),
      width: "w-[10%]",
      className: "text-center",
    },
    {
      key: "empty",
      title: "",
      width: "w-[40%]",
    },
  ];

  return { header };
};
