import { useState } from "react";
import { useGet } from "hooks/query";
import { endpoints } from "services/endpoints";

const useOrganization = (parent_id) => {
  const [orgOptions, setOrgOptions] = useState([]);
  const [subOrgOptions, setSubOrgOptions] = useState([]);
  const [warehouseOptions, setWarehouseOptions] = useState([]);

  useGet({
    // link: endpoints.organizations.get,
    link: endpoints.organizations.list,
    onSuccess: (res) => {
      const optionTransformation = res?.map((el) => ({
        label: el.name,
        value: el.id,
      }));
      setOrgOptions(optionTransformation);
    },
  });

  useGet({
    link: endpoints.organizations.list,
    params: { parent_id },
    enabled: !!parent_id,
    onSuccess: (res) => {
      const optionTransformation = res?.map((el) => ({
        label: el.name,
        value: el.id,
      }));
      setSubOrgOptions(optionTransformation);
    },
  });

  useGet({
    link: endpoints.warehouse.list,
    params: { org_id: parent_id },
    onSuccess: (res) => {
      const optionTransformation = res?.map((el) => ({
        label: el.name,
        value: el.id,
      }));
      setWarehouseOptions(optionTransformation);
    },
  });

  return { orgOptions, subOrgOptions, warehouseOptions };
};

export default useOrganization;
