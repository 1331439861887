import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
// store
import { useCoreStore } from "store/core";
// api
import { useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import ControlledInput from "components/Input/ControlledInput";
import ModalFormComments from "./Modal/ModalFormComments";
import ModalAddProduct from "./Modal/ModalAddProduct";
import ModalAddInvoice from "./Modal/ModalAddInvoice";
import ModalSaveInvoice from "./Modal/ModalSaveInvoice";
import ModalAddPayment from "./Modal/ModalAddPayment";
import ModalBoxCount from "./Modal/ModalBoxCount";
import Modal from "components/Modal/Modal";
import SingleInvoice from "./components/SingleInvoice";
// icons
import Search from "assets/images/svg/search.svg";
import SearchWhite from "assets/images/svg/search-white.svg";
import Plus from "assets/images/svg/icon-add.svg";
import PlusWhite from "assets/images/svg/icon-add-theme.svg";
import InvoiceList from "./components/InvoiceList";

const ClientInvoices = () => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const theme = useCoreStore((state) => state.theme);
  const [currentTable, setCurrentTable] = useState("consumption");
  const [invoices, setInvoices] = useState([]);
  const [singleInvoice, setSingleInvoice] = useState(null);
  const [archived, setArchived] = useState(false);
  const [filter, setFilter] = useState("");

  const rowId = searchParams.get("id");
  const { orgId } = useCoreStore((state) => state);

  const { data: courseUsdApi } = useGet({
    link: endpoints.exchangeRate.last,
    params: { org_id: orgId, base_currency: "USD", currency: "USD" },
  });

  const { data: courseUzsApi } = useGet({
    link: endpoints.exchangeRate.last,
    params: { org_id: orgId, base_currency: "USD", currency: "UZS" },
  });

  const { data: coursePerApi } = useGet({
    link: endpoints.exchangeRate.last,
    params: { org_id: orgId, base_currency: "USD", currency: "PER" },
  });

  const { data: courseTerApi } = useGet({
    link: endpoints.exchangeRate.last,
    params: { org_id: orgId, base_currency: "USD", currency: "TER" },
  });

  const { data: invoicesApi, refetch } = useGet({
    link: endpoints.invoices.list,
    params: { org_id: orgId, state: "CREATED_DONE", q: filter },
  });

  // const {
  //   data: invoicesExt,
  //   isLoading: invoicesExtLoading,
  //   isError: invoicesExtError,
  //   refetchExt,
  // } = useGet({
  //   link: endpoints.invoices.listDetailed,
  //   params: { org_id: orgId, state: "CREATED_DONE"},
  // });

  const { data: archivedInvoices, refetch: archivedInvoicesRefetch } = useGet({
    link: endpoints.invoices.list,
    params: { org_id: orgId, state: "ARCHIVED" },
  });

  const { data: singleInvoiceApi, refetch: singleInvoiceRefetch } = useGet({
    link: endpoints.invoices.oneDetailed,
    params: { id: rowId },
    enabled: !!rowId,
  });

  useEffect(() => {
    if (invoicesApi) {
      setInvoices(invoicesApi);
    }
  }, [invoicesApi]);

  useEffect(() => {
    if (singleInvoiceApi) {
      setSingleInvoice(singleInvoiceApi);
    }
  }, [singleInvoiceApi]);

  const updateSingleInvoice = (updatedFields) => {
    setSingleInvoice((prevInvoice) => ({
      ...prevInvoice,
      ...updatedFields,
    }));
  };

  const modalAddProduct = useRef();
  const modalAddInvoice = useRef();
  const modalSaveInvoice = useRef();
  const modalComments = useRef();
  const modalBoxCount = useRef();
  const addPayment = useRef();

  const handleTemplatesModalAddProduct = (value) => {
    modalAddProduct.current.openModal(value);
  };
  const handleTemplatesModalComments = (value) => {
    modalComments.current.openModal(value);
  };
  const handleTemplatesModalSaveInvoice = (value) => {
    modalSaveInvoice.current.openModal(value);
  };
  const handleTemplatesModalAddInvoice = (value) => {
    modalAddInvoice.current.openModal(value);
  };
  const handleTemplatesModalAddPayment = (value) => {
    addPayment.current.openModal(value);
  };
  const handleTemplatesModalBoxCount = (value) => {
    modalBoxCount.current.openModal(value);
  };

  useEffect(() => {
    if (invoices && invoices?.length > 0) {
      setSearchParams({ ...searchParams, id: invoices[0]?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices.length]);

  useEffect(() => {
    if (archived) {
      if (archivedInvoices && archivedInvoices?.length > 0) {
        setSearchParams({ ...searchParams, id: "" });
      } else {
        setSearchParams({ ...searchParams, id: "" });
      }
    } else {
      if (invoices && invoices?.length > 0) {
        setSearchParams({ ...searchParams, id: "" });
      } else {
        setSearchParams({ ...searchParams, id: "" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archived]);

  useEffect(() => {
    setCurrentTable("consumption");
  }, [rowId]);

  return (
    <div className="grid grid-cols-10 gap-4 h-full">
      <div className="col-span-2 border-2 border-[#F2F2F2] dark:border-[#2D2D2D] border-solid rounded-lg p-[16px] grid relative h-[calc(100vh-215px)]">
        <div className="flex flex-col">
          <div className="border-2 border-solid border-[#D1D1D1] dark:border-[#2D2D2D] p-1 rounded-lg grid grid-cols-2 gap-1 bg-[#F2F2F2] dark:bg-[#1F1E25]">
            <div
              className={`border-1 border-solid border-transparent rounded-[8px] items-center col-span-1 justify-center flex text-[#9B9DA3] cursor-pointer ${
                !archived
                  ? "bg-white dark:bg-[#191A22] border-[#D1D1D1] dark:border-[#2D2D2D] text-[#000]  dark:text-white"
                  : "bg-[#F2F2F2] dark:bg-[transparent]"
              }`}
              onClick={() => setArchived(false)}
            >
              <span>
                {t("pages.manInvoice.sale")} ({invoices?.length})
              </span>
            </div>
            <div
              className={`border-1 py-[8px] border-solid border-transparent rounded-[8px] items-center col-span-1 justify-center flex text-[#9B9DA3] cursor-pointer ${
                archived
                  ? "bg-white dark:bg-[#191A22] border-[#D1D1D1] dark:border-[#2D2D2D] text-[#000] dark:text-white"
                  : "bg-[#F2F2F2] dark:bg-[transparent]"
              }`}
              onClick={() => setArchived(true)}
            >
              <span>
                {t("pages.manInvoice.archive")} ({archivedInvoices?.length})
              </span>
            </div>
          </div>
          <div className="flex flex-row my-[8px] justify-between w-full">
            <div className="mr-[8px] w-full">
              <ControlledInput
                placeholder={`${t("pages.manInvoice.search")}...`}
                className="flex-1 h-[44px] w-full mr-[10px]"
                onChange={(e) => setFilter(e.target.value)}
                leftElement={<img src={theme ? SearchWhite : Search} alt="CloseIcon" className="cursor-pointer" />}
              />
            </div>
            <div
              className="w-[44px] min-w-[44px] h-[44px] border-solid border-2 border-[#D1D1D1] dark:border-[#2d2d2d] rounded-lg flex items-center justify-center cursor-pointer"
              onClick={() => handleTemplatesModalAddInvoice(true)}
            >
              <img src={theme ? PlusWhite : Plus} alt="Add" />
            </div>
          </div>
          <InvoiceList invoices={archived ? archivedInvoices : invoices} />
        </div>
      </div>

      <div className="col-span-8 border-2 border-[#F2F2F2] dark:border-[#2D2D2D] border-solid rounded-lg p-[16px] flex flex-col h-[calc(100vh-215px)] overflow-hidden">
        {rowId && singleInvoice && (
          <SingleInvoice
            singleInvoice={singleInvoice}
            archived={archived}
            updateSingleInvoice={updateSingleInvoice}
            currentTable={currentTable}
            setCurrentTable={setCurrentTable}
            refetch={refetch}
            singleInvoiceRefetch={singleInvoiceRefetch}
            handleTemplatesModalAddProduct={handleTemplatesModalAddProduct}
            handleTemplatesModalComments={handleTemplatesModalComments}
            handleTemplatesModalAddPayment={handleTemplatesModalAddPayment}
            handleTemplatesModalSaveInvoice={handleTemplatesModalSaveInvoice}
            handleTemplatesModalBoxCount={handleTemplatesModalBoxCount}
          />
        )}
      </div>

      <Modal ref={modalAddProduct} title={t("pages.manInvoice.addProduct")}>
        <ModalAddProduct close={handleTemplatesModalAddProduct} refetch={singleInvoiceRefetch} />
      </Modal>
      <Modal ref={modalComments} title={t("info.comment")}>
        <ModalFormComments
          archived={archived}
          close={handleTemplatesModalComments}
          singleInvoice={singleInvoice}
          updateSingleInvoice={updateSingleInvoice}
        />
      </Modal>
      <Modal ref={modalAddInvoice} title={t("pages.manInvoice.addInvoice")}>
        <ModalAddInvoice setArchived={setArchived} close={handleTemplatesModalAddInvoice} refetch={refetch} />
      </Modal>
      <Modal classNameExtra="!overflow-visible" ref={modalSaveInvoice} title={t("pages.manInvoice.saveInvoice")}>
        <ModalSaveInvoice
          close={handleTemplatesModalSaveInvoice}
          refetch={refetch}
          archivedInvoicesRefetch={archivedInvoicesRefetch}
        />
      </Modal>
      <Modal ref={modalBoxCount} title={t("pages.manInvoice.boxCount")}>
        <ModalBoxCount
          archived={archived}
          close={handleTemplatesModalBoxCount}
          refetchSingleInvoice={singleInvoiceRefetch}
          setInvoices={setInvoices}
          setSingleInvoice={setSingleInvoice}
        />
      </Modal>
      <Modal ref={addPayment} title={t("pages.manInvoice.addPayment")}>
        <ModalAddPayment
          close={handleTemplatesModalAddPayment}
          refetch={singleInvoiceRefetch}
          courses={{
            usd: courseUsdApi,
            uzs: courseUzsApi,
            per: coursePerApi,
            ter: courseTerApi,
          }}
        />
      </Modal>
    </div>
  );
};

export default ClientInvoices;
