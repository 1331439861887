export const useTranslatedTableConfig = () => {

  const header = [
    {
      key: "name",
      title: "Name",
    },
    {
      key: "actions",
      title: "",
    },
  ];
  return { header };
}
