import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Tbody from "./Tbody";
import BaseTable from "components/Table/BaseTable";
import BaseThead from "components/Table/BaseThead";
import BaseButton from "components/Button/BaseButton";
import GoBackButton from "components/Button/GoBackButton";
import { notifySuccess } from "components/Toast";
import ControlledInput from "components/Input/ControlledInput";
import Pagination from "components/Pagination/Pagination";
import DeleteConfirmation from "container/DeleteConfirmation";
// i18n
import { useTranslation } from "react-i18next";
import { usePaginateGoBack } from "hooks/usePaginateGoBack/usePaginateGoback";
import { useCoreStore } from "store/core";
import { useGet, useMutate } from "hooks/query";
import { checkHasAccessToAction, userInfo } from "services/helpers";
import { endpoints } from "services/endpoints";
import { useTranslatedTableConfig } from "../Helpers";
// icons
import Search from "assets/images/svg/search.svg";
import AddIcon from "assets/images/svg/add-to-photos.svg";
import TrashIcon from "assets/images/svg/trash-white.svg";
import SearchWhite from "assets/images/svg/search-white.svg";

const Table = ({ refetch, data = [], modalToggler, handleSearch, paginationConfig = {}, setPage }) => {
  const { t } = useTranslation();
  const { header } = useTranslatedTableConfig();
  const [isSelectedAllRows, setIsSelectedAllRows] = useState(false);
  const [checkboxes, setCheckboxes] = useState({});
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const { id } = useParams();
  const theme = useCoreStore((state) => state.theme);

  const closeModal = useRef();
  const closeModalMulti = useRef();
  const deleteId = useRef();
  const hasAccessToCreate = checkHasAccessToAction("create-warehouse");
  const hasOrganizationMenu = userInfo()?.resource_access?.[userInfo()?.azp]?.roles.includes("organizations-menu");
  const paginateGoBack = usePaginateGoBack({
    setPage,
    total: paginationConfig.total,
    pageCount: paginationConfig.pageCount,
  });

  const { data: singleData } = useGet({
    link: endpoints.organizations.one,
    params: { id },
    enabled: !!id,
  });

  const handleChangeCheckbox = (e) => {
    setIsSelectedAllRows(e.target.checked);
  };

  const closeDeleteModal = (value, id) => {
    deleteId.current = id;
    closeModal.current.openModal(value);
  };

  const closeDeleteModalMulti = (value) => {
    closeModalMulti.current.openModal(value);
  };

  const deleteOrganization = useMutate({
    onSuccess: () => {
      refetch();
      closeDeleteModal(false);
      notifySuccess(t("notification.successDelete"));
      paginateGoBack();
    },
  });

  const confirmDelete = () => {
    deleteOrganization.mutate({
      link: `${endpoints.warehouse.delete}?id=${deleteId.current}`,
      method: "delete",
    });
  };

  const deleteOrganizationMulti = useMutate({
    onSuccess: () => {
      refetch();
      setSelectedRowIds([]);
      closeDeleteModalMulti(false);
      notifySuccess(t("notification.successDelete"));
      paginateGoBack();
    },
  });

  const confirmDeleteMulti = () => {
    deleteOrganizationMulti.mutate({
      link: endpoints.warehouse.delete,
      body: { ids: selectedRowIds },
      method: "patch",
    });
  };

  useEffect(() => {
    const filterByTrue = Object.values(checkboxes).filter((el) => el);
    const filteredKeys = Object.keys(checkboxes).filter((key) => checkboxes[key]);
    setSelectedRowIds(filteredKeys);

    if (filterByTrue.length !== data.length) setIsSelectedAllRows(false);
  }, [checkboxes]);

  return (
    <div className="h-full px-2 flex flex-col">
      {hasOrganizationMenu && (
        <Link to={"/organizations"} className="w-8 h-8 mr-4">
          <GoBackButton />
        </Link>
      )}
      <h2 className="text-bold text-2xl flex text-black dark:text-textDark">{`${t("pages.warehouses.title")}: ${
        singleData?.name
      }`}</h2>
      <div className="w-full flex justify-between items-center my-4">
        <div className="w-[260px]">
          <ControlledInput
            className="w-full"
            onChange={handleSearch}
            placeholder={`${t("pages.warehouses.search")}...`}
            leftElement={<img src={theme ? SearchWhite : Search} alt="CloseIcon" className="cursor-pointer" />}
          />
        </div>
        <div className="flex gap-3">
          {selectedRowIds.length > 0 && (
            <BaseButton
              label={`${t("button.deleteSelected")} (${selectedRowIds.length})`}
              icon={TrashIcon}
              className="btn-danger"
              onClick={() => closeDeleteModalMulti(true)}
            />
          )}
          {hasAccessToCreate && (
            <BaseButton
              label={t("pages.warehouses.add")}
              icon={AddIcon}
              className="btn-primary"
              onClick={() => modalToggler(true)}
            />
          )}
        </div>
      </div>
      <BaseTable
        thead={
          <BaseThead
            header={header}
            withCheckbox={true}
            isSelectedAllRows={isSelectedAllRows}
            handleChangeCheckbox={handleChangeCheckbox}
          />
        }
        tbody={
          <Tbody
            data={data}
            isChecked={isSelectedAllRows}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            closeDeleteModal={closeDeleteModal}
          />
        }
      />
      <div className="w-full mt-auto">
        <Pagination paginationConfig={paginationConfig} />
      </div>
      <DeleteConfirmation
        ref={closeModal}
        close={closeDeleteModal}
        cb={confirmDelete}
        description={t("info.sureDelete")}
        title={t("info.delete")}
      />

      <DeleteConfirmation
        ref={closeModalMulti}
        close={closeDeleteModalMulti}
        cb={confirmDeleteMulti}
        description={t("info.sureDelete")}
        title={t("info.delete")}
      />
    </div>
  );
};

export default Table;
