// i18n
import { useTranslation } from "react-i18next";
// icons
import TableActionIcon from "assets/images/svg/icon-table-actions.svg";

export const useTranslatedTableConfig = () => {
  const { t } = useTranslation();

  const header = [
    {
      key: "username",
      title: t("pages.mainUsers.table.username"),
      width: "w-[15%]",
    },
    {
      key: "name",
      title: t("pages.mainUsers.table.name"),
      width: "w-[15%]",
    },
    {
      key: "phone_number",
      title: t("pages.mainUsers.table.phoneNumber"),
      width: "w-[15%]",
    },
    {
      key: "state",
      title: t("pages.mainUsers.table.state"),
      width: "w-[15%]",
    },
    {
      key: "role",
      title: t("pages.mainUsers.table.role"),
      width: "w-[15%]",
    },
    {
      key: "empty",
      // image: TableActionIcon,
      width: "w-[10%]",
    },
  ];

  const fields = [
    {
      type: "text",
      name: "full_name",
      showError: true,
      control: "input",
      placeholder: "enter your full name",
      label: "Full name",
      className: "w-full",
    },
    {
      type: "text",
      name: "username",
      showError: true,
      control: "input",
      placeholder: "enter your username",
      label: "Username",
      className: "w-full",
    },
    {
      type: "text",
      name: "phone_number",
      showError: true,
      control: "input",
      label: "Phone",
      className: "w-full",
      placeholder: "+(998) 90 000-00-00",
    },
  ];

  const fields2 = [
    {
      name: "state",
      control: "checkbox",
      placeholder: "enter your role",
      label: "Active",
      className: "w-full",
    },
    {
      name: "role",
      control: "checkbox",
      placeholder: "enter your role",
      label: "Active",
      className: "w-full",
    },
  ];

  return { header, fields, fields2 };
};
