import React, { useState, memo, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// helpers
import { dataToOptions, dataToValues, sortByAlphabetically } from "services/helpers";
// store
import { useCoreStore } from "store/core";
// api
import { useMutate, useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import { FormCheckbox } from "components/Checkbox/FormCheckbox";
import Input from "components/Input/Input";
import ControlledInput from "components/Input/ControlledInput";
import BaseButton from "components/Button/BaseButton";
// icons
import IconSave from "assets/images/svg/icon-save.svg";
import Search from "assets/images/svg/search.svg";
import SearchWhite from "assets/images/svg/search-white.svg";

const ModalForm = memo(({ close, refetch, row }) => {
  const { t } = useTranslation();

  const [searchPermission, setSearchPermission] = useState("");
  const [permissionOptions, setPermissionOptions] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState([]);

  let [searchParams, setSearchParams] = useSearchParams();
  const rowId = searchParams.get("row_id");
  const theme = useCoreStore((state) => state.theme);

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  function removeId() {
    reset();
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }

  useGet({
    link: endpoints.permissions.get,
    onSuccess: (res) => {
      setPermissionOptions(sortByAlphabetically(dataToOptions(res)));
    },
  });

  useEffect(() => {
    if (rowId) {
      setValue("name", row.name);
      setValue("description", row.description);
      setValue("composites", dataToValues(row.composites));
      setSelectedPermission(dataToValues(row.composites));
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  const handleSearchPermission = (e) => {
    setSearchPermission(e.target.value);
  };

  const filterPermissionOptions = useMemo(
    () =>
      permissionOptions.filter((permission) => permission.label.toLowerCase().includes(searchPermission.toLowerCase())),
    [searchPermission, permissionOptions],
  );

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: () => {
      refetch();
      reset();
      clearAndClose();
      notifySuccess(t("notification.roleSuccessCreate"));
    },
  });

  const update = useMutate({
    onSuccess: () => {
      refetch();
      reset();
      clearAndClose();
      notifySuccess(t("notification.roleSuccessUpdate"));
    },
  });

  const onSubmit = (values) => {
    // values.composites = getOptionValue(values.composites);

    if (row.id) {
      update.mutate({
        link: `${endpoints.roles.update}?id=${row.id}`,
        method: "put",
        body: values,
      });
    } else {
      create.mutate({
        link: endpoints.roles.create,
        method: "post",
        body: values,
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          autoFocus
          label={t("pages.roles.form.name")}
          fieldName="name"
          register={register}
          errors={errors}
          isRequired={true}
          className="w-full"
          placeholder={t("pages.roles.form.namePlaceholder")}
        />
        <Input
          label={t("pages.roles.form.description")}
          fieldName="description"
          register={register}
          errors={errors}
          isRequired={true}
          className="w-full"
          placeholder={t("pages.roles.form.descriptionPlaceholder")}
        />

        <ControlledInput
          className="w-full"
          placeholder={`${t("pages.roles.form.search")}...`}
          label={t("pages.roles.form.permissions")}
          onChange={handleSearchPermission}
          leftElement={<img src={theme ? SearchWhite : Search} alt="CloseIcon" className="cursor-pointer" />}
        />
        {/* <div className="grid grid-cols-2 gap-y-2 gap-x-4 mt-3 max-h-[230px] overflow-y-scroll"> */}
        <div className="grid grid-cols-2 gap-y-2 mt-3 max-h-[230px] overflow-y-scroll">
          {filterPermissionOptions.map((permission) => (
            <div>
              <FormCheckbox
                key={permission.value}
                value={permission.value}
                hasTooltip={permission.label.length > 21}
                label={permission.label}
                errors={errors}
                hasDots={true}
                checked={selectedPermission.includes(permission.value)}
                // onChange={(e) => {
                //   if (e.target.checked) {
                //     setSelectedPermission((prev) => [...prev, permission.value]);
                //   } else {
                //     setSelectedPermission((prev) => prev.filter((el) => el !== permission.value));
                //   }
                // }}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedPermission([...selectedPermission, permission.value]);
                    setValue("composites", [...selectedPermission, permission.value]);
                  } else {
                    setSelectedPermission(selectedPermission.filter((item) => item !== permission.value));
                    setValue(
                      "composites",
                      selectedPermission.filter((item) => item !== permission.value),
                    );
                  }
                }}
                // {...register("composites", { required: true })}
              />
            </div>
          ))}
        </div>
        {errors.composites && <p className="error-message mb-2 mt-1 text-red-500">Permission is required</p>}

        <div className="flex gap-6 mt-8">
          <BaseButton
            label={t("button.cancel")}
            onClick={clearAndClose}
            className="flex-1 p-2 btn-secondary dark:btn-secondary-dark"
          />
          <BaseButton
            type="submit"
            className="flex-1 p-2 btn-success"
            label={row.id ? t("button.update") : t("button.save")}
            icon={IconSave}
            isLoading={create.isLoading || update.isLoading}
          />
        </div>
      </form>
    </div>
  );
});

export default ModalForm;
