import { useEffect, useState } from "react";
// i18n
import { useTranslation } from "react-i18next";
import useMenuIcons from "hooks/useMenuIcons/useMenuIcons";

const BreadCrumbs = ({ path }) => {

  const { t } = useTranslation();

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const menus = useMenuIcons();

  useEffect(() => {
    const newBreadcrumbs = buildBreadcrumbs(menus, path, []);
    // Compare the newBreadcrumbs with the current breadcrumbs state
    if (JSON.stringify(newBreadcrumbs) !== JSON.stringify(breadcrumbs)) {
      setBreadcrumbs(newBreadcrumbs);
    }
  }, [menus, path, breadcrumbs]); // Include breadcrumbs in the dependency array

  const buildBreadcrumbs = (menuList, currentPath, crumbs) => {
    if (currentPath === "/home") {
      return [];
    }

    crumbs.push(t("drawer.home"));

    for (let i = 0; i < menuList.length; i++) {
      const menu = menuList[i];
      if (menu.subMenu) {
        const currentSubMenu = menu.subMenu.find((subMenu) => subMenu.to === currentPath);
        if (currentSubMenu) {
          return [...crumbs, menu.label, currentSubMenu.label];
        } else {
          const newCrumbs = buildBreadcrumbs(menu.subMenu, currentPath, [...crumbs, menu.label]);
          if (newCrumbs.length > 0) {
            return newCrumbs;
          }
        }
      } else if (menu.to === currentPath) {
        return [...crumbs, menu.label];
      }
    }

    return [];
  };

  return (
    <div>
      <ul className="flex gap-2">
        {breadcrumbs.map((item, index) => (
          <li key={index} className="flex gap-2">
            <span className={` ${index === breadcrumbs.length - 1 ? "text-[#535456]" : "text-[#9B9DA3]"}`}>{item}</span>
            {index !== breadcrumbs.length - 1 && <span className="w-[16px] h-[1px] bg-[#9B9DA3] my-auto"></span>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BreadCrumbs;
