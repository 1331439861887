import { useState, useEffect, createContext } from "react";
import { useSearchParams } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// components
import ProductGroupsFirst from "./ProductGroupsFirst/ProductGroupsFirst";
import ProductGroupsSecond from "./ProductGroupsSecond/ProductGroupsSecond";
import ProductGroupsThird from "./ProductGroupsThird/ProductGroupsThird";
import Empty from "../Category/Empty";

const layoutWrapper = "grid grid-cols-3 gap-10 overflow-y-hidden h-full rounded-lg";

export const ProductGroupsContext = createContext(null);

const ProductGroupsLayout = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [parentIds, setParentIds] = useState({
    parentId: "",
    propertyId: "",
    propertiesIdList: [],
  });

  useEffect(
    () => {
      // clear row_id1 from url
      if (searchParams.get("row_id1")) {
        setSearchParams({});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <div className={layoutWrapper}>
        <ProductGroupsContext.Provider value={{ parentIds, setParentIds }}>
          <div>
            <h1 className="text-bold text-2xl flex text-black dark:text-textDark mb-4">
              {t("pages.productGroups.title")}{" "}
            </h1>
            <div className="dark:bg-mainGreyDark border dark:border-[#2D2D2D] rounded-md p-3 h-[calc(100vh-262px)]">
              <ProductGroupsFirst />
            </div>
          </div>
          <div>
            <h1 className="text-bold text-2xl flex text-black dark:text-textDark mb-4">
              {t("pages.productGroups.selected")}{" "}
            </h1>
            <div className="dark:bg-mainGreyDark border dark:border-[#2D2D2D] rounded-md p-3 h-[calc(100vh-262px)]">
              {parentIds.parentId ? <ProductGroupsSecond /> : <Empty title={t("pages.productGroups.selected")} />}
            </div>
          </div>
          <div>
            <h1 className="text-bold text-2xl flex text-black dark:text-textDark mb-4">
              {t("pages.parameters.title")}{" "}
            </h1>
            <div className="dark:bg-mainGreyDark border dark:border-[#2D2D2D] rounded-md p-3 h-[calc(100vh-262px)]">
              {parentIds.parentId ? <ProductGroupsThird /> : <Empty title={t("pages.productGroups.selectPar")} />}
            </div>
          </div>
        </ProductGroupsContext.Provider>
      </div>
    </>
  );
};

export default ProductGroupsLayout;
