import React, { memo, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// api
import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import { ParameterContext } from "../ParametrLayout";
import Input from "components/Input/Input";
import BaseButton from "components/Button/BaseButton";
// icons
import IconSave from "assets/images/svg/icon-save.svg";

const ModalForm = memo(({ close, refetch, row, orgId }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const rowId = searchParams.get("row_id2");
  const { parentIds } = useContext(ParameterContext);

  const {
    reset,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: {
        // num: null,
        uz: "",
        ru: "",
      },
    },
  });

  function removeId() {
    reset();
    searchParams.delete("row_id2");
    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (rowId) {
      setValue("name.uz", row.name?.uz);
      setValue("name.ru", row.name?.ru);
    } else {
      reset();
    }
  }, [row]);

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: () => {
      refetch();
      clearAndClose();
      notifySuccess(t("notification.valueSuccessCreate"));
    },
  });

  const update = useMutate({
    onSuccess: () => {
      refetch();
      clearAndClose();
      notifySuccess(t("notification.valueSuccessUpdate"));
    },
  });

  const onSubmit = (values) => {
    const body = {
      ...values,
    };
    if (row.id) {
      update.mutate({
        link: `${endpoints.parameterValue.update}?id=${row.id}`,
        method: "put",
        body: body,
      });
    } else {
      create.mutate({
        link: `${endpoints.parameterValue.create}?property_id=${parentIds.parentId}`,
        method: "post",
        body: body,
      });
    }
  };

  return (
    <div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5">
          {/*<Input*/}
          {/*  label="Raqam"*/}
          {/*  fieldName="name.num"*/}
          {/*  type="number"*/}
          {/*  register={register}*/}
          {/*  errors={errors}*/}
          {/*  isRequired={true}*/}
          {/*  className="w-full"*/}
          {/*/>*/}
          <Input
            autoFocus
            label={t("form.titleUz")}
            placeholder={t("form.title")}
            fieldName="name.uz"
            register={register}
            errors={errors}
            isRequired={true}
            className="w-full"
          />
          <Input
            label={t("form.titleRu")}
            placeholder={t("form.title")}
            fieldName="name.ru"
            register={register}
            errors={errors}
            isRequired={true}
            className="w-full"
          />
        </div>
        <div className="flex gap-6 mt-8">
          <BaseButton
            label={t("button.cancel")}
            onClick={clearAndClose}
            className="flex-1 p-2 btn-secondary dark:btn-secondary-dark"
          />
          <BaseButton
            type="submit"
            className="flex-1 p-2 btn-success"
            icon={IconSave}
            label={row.id ? t("button.update") : t("button.save")}
          />
        </div>
      </form>
    </div>
  );
});

export default ModalForm;
