import React, { memo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
// store
import { useCoreStore } from "store/core";
// helpers
import { debounce } from "services/helpers";
// api
import { useGet, useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";
import Select from "components/Select/Select";
// icons
import IconSave from "assets/images/svg/icon-save.svg";

const ModalForm = memo(({ reverse, close, setOneInvoice, type }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const invoiceId = searchParams.get("invoice_id");

  // products
  const [productSearch, setProductSearch] = useState("");
  const [productOptions, setProductOptions] = useState([]);

  const { orgId } = useCoreStore((state) => state);

  useGet({
    link: endpoints.product.search,
    params: {
      org_id: orgId,
      name: productSearch || undefined,
    },
    enabled: productSearch.length > 2,
    onSuccess: (data) => {
      const options = data?.map((el) => ({ value: el.id, label: el.name }));
      setProductOptions(options);
    },
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function removeId() {
    reset();
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: (e) => {
      setOneInvoice((prev) => {
        // Determine which array to update based on type and reverse
        const isIncome = type === "in" ? !reverse : reverse;

        return {
          ...prev,
          income_products: isIncome ? [...prev.income_products, e] : prev.income_products,
          outgo_products: !isIncome ? [...prev.outgo_products, e] : prev.outgo_products,
        };
      });

      clearAndClose();
      notifySuccess(t("notification.productSuccessAdd"));
    },
  });

  const onSubmit = (values) => {
    const body = {
      product_id: values.product.value,
      amount: null,
    };

    create.mutate({
      link: ((type, reverse) => {
        if (type === "in") {
          return reverse
            ? endpoints.warehouseInvoice.outgoProductCreate
            : endpoints.warehouseInvoice.incomeProductCreate;
        } else {
          return reverse
            ? endpoints.warehouseInvoice.incomeProductCreate
            : endpoints.warehouseInvoice.outgoProductCreate;
        }
      })(type, reverse),
      method: "post",
      params: {
        warehouse_invoice_id: invoiceId,
      },
      body,
    });
  };

  return (
    <div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5">
          <Controller
            name="product"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                fieldName="product"
                name="product"
                isMulti={false}
                autoFocus={true}
                closeMenuOnSelect={true}
                errors={errors}
                required
                label={t("pages.betweenWarehouse.product")}
                placeholder={t("pages.betweenWarehouse.product")}
                onInputChange={(value) => {
                  debounce(() => {
                    setProductSearch(value);
                  }, 500)();
                }}
                // handleChange={}
                options={productOptions}
              />
            )}
          />
        </div>
        <div className="flex gap-6 mt-8">
          <BaseButton
            label={t("button.cancel")}
            onClick={clearAndClose}
            className="flex-1 p-2 btn-secondary dark:btn-secondary-dark"
          />
          <BaseButton
            type="submit"
            className="flex-1 p-2 btn-success"
            icon={IconSave}
            label={t("button.save")}
            isLoading={create.isLoading}
          />
        </div>
      </form>
    </div>
  );
});

export default ModalForm;
