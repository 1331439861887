import { useDropzone } from "react-dropzone";
// store
import { useCoreStore } from "store/core";
// utils
import uploader from "services/uploader";
// i18n
import { useTranslation } from "react-i18next";
// icons
import iconAddphoto from "assets/images/svg/icon-addphoto.svg";
import TableAction from "components/Table/TableAction";

const ImageUploaderLarge = ({
  url = [],
  setImagesUrl,
  label,
  isMulti = false,
  name = "",
  descElement,
  imgIcon,
  labelClass,
}) => {
  const { t } = useTranslation();

  const theme = useCoreStore((state) => state.theme);

  const requestImageUpload = (event) => {
    uploader(event).then((resp) => {
      let img = resp();
      if (img.includes("localhost")) {
        img = process.env.REACT_APP_API_PROXY_URL + img.slice(21, img.length);
      }

      if (name) {
        setImagesUrl((prev) => {
          return {
            ...prev,
            [name]: [img],
          };
        });
      } else {
        setImagesUrl((prev) => [...prev, img]);
      }
    });
  };

  const requestImageUploadDrag = (file) => {
    uploader(false, false, file).then((resp) => {
      let img = resp();
      if (img.includes("localhost")) {
        img = process.env.REACT_APP_API_PROXY_URL + img.slice(21, img.length);
      }

      if (name) {
        setImagesUrl((prev) => {
          return {
            ...prev,
            [name]: [img],
          };
        });
      } else {
        setImagesUrl((prev) => [...prev, img]);
      }
    });
  };

  const deleteImg = (img, name) =>
    setImagesUrl((prevImg) => {
      if (Array.isArray(prevImg)) {
        return prevImg.filter((pht) => pht !== img);
      } else {
        return prevImg[name].filter((pht) => pht !== img);
      }
    });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (file) => {
      requestImageUploadDrag(file[0]);
    },
  });
  return (
    <div>
      {label && <label className="block text-stone-800 dark:text-white font-semibold text-[14px] mb-1">{label}</label>}
      <div className={`image__uploader_large-imgs dark:!border-1 dark:!border-[#2d2d2d]`}>
        {url &&
          url.map((image) => (
            <div className="image__uploader_large-content w-full" key={image}>
              <img src={image} alt="document" className="image__uploader_large-img w-full object-cover" />
              <div className="absolute right-[10px] top-[10px]">
                <TableAction hasBackground boxClass="w-44 z-[1000]" parentClass="!h-[20px] px-[2px] opacity-[0.8]">
                  <div
                    className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                    onClick={() => {
                      deleteImg(image, name);
                    }}
                  >
                    <p className="text-[11px]">{t("button.replace")}</p>
                  </div>

                  <div
                    className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                    onClick={() => deleteImg(image, name)}
                  >
                    <p className="text-[#FF705D] text-[11px]">{t("button.delete")}</p>
                  </div>
                </TableAction>
              </div>
            </div>
          ))}

        {(isMulti || url.length === 0) && (
          <label
            {...getRootProps()}
            htmlFor={name || "requestPhoto"}
            className={`image__uploader_large-img image__uploader_large-img-large dark:!bg-[#1F1E25] dark:!border-[transparent] cursor-pointer create relative ${labelClass}`}
          >
            <div className={`flex flex-col items-center transition-[0.5s] ${isDragActive && "opacity-0"}`}>
              <input
                type="file"
                id={name || "requestPhoto"}
                onChange={requestImageUpload}
                className="w-[120px]"
                {...getInputProps()}
              />
              <img src={imgIcon || iconAddphoto} alt="addphoto" />
              <h2 className="textBlack dark:text-[#9B9DA3] font-semibold text-[15px]">{t("info.pressOrDrag")}</h2>
              <p className="text-[#9B9DA3] font-normal text-[13px]">{t("info.minimumSize")} 256px</p>
            </div>

            {isDragActive && (
              <div className="absolute w-full h-full bg-[#7B61FF0D] border-2 border-[#7B61FF] border-dashed rounded-[4px] flex flex-col items-center justify-center">
                <p className="text-[#7B61FF] font-semibold text-[14px] mb-1">Перетащите файл(ы) сюда</p>
              </div>
            )}
          </label>
        )}

        {descElement ? descElement : <div></div>}
      </div>
    </div>
  );
};

export default ImageUploaderLarge;
