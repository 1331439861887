import { useState } from "react";
import { useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
import { useCoreStore } from "store/core";


export const useGetManager = () => {
  const [managersOptions, setManagersOptions] = useState([]);

  const orgId = useCoreStore((state) => state.orgId);

  useGet({
    link: endpoints.users.list,
    params: {permission: "org-manager", org_id: orgId},
    onSuccess: (res) => {
      const optionTransformation = res?.map((el) => ({
        label: el.full_name,
        value: el.id,
      }));

      setManagersOptions(optionTransformation);
    },
  });

  return managersOptions;
};
