import { useEffect } from "react";
// store
import { useCoreStore } from "store/core";
// api
import { useSearchParams } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// components
import BaseButton from "components/Button/BaseButton";
import Table from "./Table/Table";
// icons
import CommentIcon from "assets/images/svg/comment-icon.svg";
import CommentIconDark from "assets/images/svg/comment-icon-dark.svg";
import AddSquareIcon from "assets/images/svg/add_square-icon.svg";
import ExelIcon from "assets/images/svg/exel-icon.svg";

const TableWrapper = ({
  oneInvoice,
  reverse,
  setReverse,
  setOneInvoice,
  setComment,
  setCommentType,
  type,
  setType,
  handleTemplatesModalProduct,
  handleTemplatesModalComments,
}) => {
  const { t } = useTranslation();
  const formatTime = (timestamp) => {
    // 12.06.2023

    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day < 10 ? `0${day}` : day}.${month < 10 ? `0${month}` : month}.${year}`;
  };

  const { userData } = useCoreStore((state) => state);

  const userWarehouseId = userData.warehouse_id;

  useEffect(() => {
    setReverse(oneInvoice?.owner_warehouse_id === userWarehouseId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneInvoice]);

  let [searchParams, setSearchParams] = useSearchParams();

  if (!oneInvoice) {
    return (
      <div className="w-full h-full flex flex-col justify-center items-center text-[#BDBDBD]">
        <p>{t("pages.betweenWarehouse.selectInvoice")}</p>
      </div>
    );
  }

  return (
    <div className="w-[79%] h-full border-1 border-GreyInput dark:border-[#2D2D2D] rounded-[8px] p-[16px]">
      <div className="header mb-[16px] flex flex-row justify-between items-center">
        <div className="date">
          <p className="text-textBlack text-[14px] px-[16px] py-[15px] bg-GreyInput dark:bg-[#1F1E25] dark:text-[#fff] rounded-[8px] w-auto font-medium">
            {t("info.date")}: {formatTime(oneInvoice?.created_at)}
          </p>
        </div>

        <div className="btns flex flex-row space-x-[8px]">
          <BaseButton
            type="submit"
            icon={oneInvoice?.note ? CommentIcon : CommentIconDark}
            className={`w-full p-[16px] rounded-[6px] bg-[#FFCD29] text-textBlack hover:opacity-[0.8] transition duration-200 ${
              oneInvoice?.note ? "bg-[#FFCD29]" : "bg-[#F2f2f2]"
            }`}
            iconClassName="w-[20px]"
            onClick={() => {
              // delete item_id from url
              searchParams.delete("item_id");
              setSearchParams(searchParams);
              setCommentType("invoice");
              setComment(oneInvoice?.note);
              handleTemplatesModalComments(true);
            }}
          />
          <BaseButton
            type="submit"
            icon={AddSquareIcon}
            className={`w-full p-[16px] rounded-[6px] text-textBlack hover:opacity-[0.8] transition duration-200 ${
              type === "out" ? "bg-[#FF705D]" : "bg-[#2196F3]"
            }`}
            iconClassName="w-[20px]"
            onClick={() => handleTemplatesModalProduct(true)}
          />
        </div>
      </div>

      <div className="tabs flex flex-row border-1 rounded-[8px] p-[4px] mb-[20px]  dark:border-[#2D2D2D]">
        <BaseButton
          className={`w-[50%] p-[8px] rounded-[6px] hover:opacity-[0.8] transition duration-200 text-medium ${
            type === "out" ? "bg-[#FF705D] text-[#fff]" : "text-[#FF705D]"
          }`}
          label={`${t("pages.betweenWarehouse.expense")} (${
            reverse ? oneInvoice.income_products.length : oneInvoice.outgo_products.length
          })`}
          onClick={() => setType("out")}
        />
        <BaseButton
          className={`w-[50%] p-[8px] rounded-[6px] hover:opacity-[0.8] transition duration-200 text-medium  ${
            type === "in" ? "bg-[#2196F3] text-[#fff]" : " text-[#2196F3]"
          }`}
          label={`${t("pages.betweenWarehouse.income")} (${
            reverse ? oneInvoice.outgo_products.length : oneInvoice.income_products.length
          })`}
          onClick={() => setType("in")}
        />
      </div>
      <div className="flex flex-col h-[calc(100vh-440px)]">
        <Table
          type={type}
          reverse={reverse}
          setComment={setComment}
          setCommentType={setCommentType}
          handleTemplatesModalComments={handleTemplatesModalComments}
          setOneInvoice={setOneInvoice}
          oneInvoice={oneInvoice}
        />
      </div>

      <BaseButton
        type="submit"
        icon={ExelIcon}
        className="w-auto px-[10px] py-[6px] rounded-[6px] bg-[#7B61FF] text-textBlack hover:opacity-[0.8] transition duration-200 ml-auto  mt-[10px]"
        iconClassName="w-[16px]"
      />
    </div>
  );
};

export default TableWrapper;
