import { useQuery, useMutation } from "@tanstack/react-query";

import instance from "services/httpService";
import { notifyError } from "components/Toast";

export const useGet = ({ link, params, queryKey = "", ...props }) =>
  useQuery(
    [`use-get-${link}-${queryKey}`, link, params],
    () => instance.get("/api" + link, { params }),
    {
      ...props,
      retry: 1,
      keepPreviousData: true,
      onError: (error) => {
        if (error.error?.includes("invalid_grant"))
          window.location.replace("login");

        notifyError(error.response?.data?.error);
      },
    },
  );

export const useMutate = ({ ...props }) =>
  useMutation(
    ({ method = "post", link, body = null, params }) =>
      instance[method]("/api" + link, body, { params }),
    {
      ...props,
      retry: 1,
      onError: (error) => {
        notifyError(error.message);
        props.onError(error);
      },
    },
  );
