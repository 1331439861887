import { useSearchParams } from "react-router-dom";
// helpers
import cls from "classnames";
import { cutString, formatTimestamp } from "services/helpers";
// store
import { useCoreStore } from "store/core";
// icons
import IconPerson from "assets/images/svg/icon-person.svg";
import IconPersonDark from "assets/images/svg/icon-person-dark.svg";
import Clock from "assets/images/svg/clock-icon.svg";
import Box from "assets/images/svg/box.svg";

const InvoiceItem = ({ invoice }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { theme } = useCoreStore((state) => state);

  const currentInvoice = searchParams.get("id");

  return (
    <div
      key={invoice.id}
      className={cls(
        "bg-[#FAFAFA] dark:bg-[#1F1E25] border-1 border-transparent rounded-lg p-[8px] flex flex-col w-full mt-[8px] cursor-pointer",
        {
          "bg-[#F2F2F2] !border-[#D1D1D1]": currentInvoice === invoice.id,
        },
      )}
      onClick={() => {
        setSearchParams({ ...searchParams, id: invoice.id });
      }}
    >
      <div className="flex flex-row w-full justify-start">
        <span className="dark:text-[#9B9DA3]">№{invoice.num}</span>
        <img src={theme ? IconPersonDark : IconPerson} className="mx-[4px] w-[18px]" alt="Person"></img>
        <span className="dark:text-[#9B9DA3]">{cutString(invoice.customer_name, 19)}</span>
      </div>
      <div className="flex flex-row text-[#9B9DA3] w-full relative justify-between">
        <div className="flex">
          <img src={Clock} className="mr-[4px]" alt={Clock}></img>
          <span className="whitespace-nowrap">{formatTimestamp(invoice.dynamic_date)}</span>
        </div>
        <div className="flex">
          <img src={Box} alt="Box"></img>
          <span className="ml-[4px]">{invoice.box_count}</span>
        </div>
      </div>
    </div>
  );
};

export default InvoiceItem;
