import React, { memo, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useCoreStore } from "store/core";
// heplers
import { debounce } from "services/helpers";
// api
import { useMutate, useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";
import Chip from "components/Chip/Chip";
import { FormCheckbox } from "components/Checkbox/FormCheckbox";
import CustomSelect from "components/Select/CustomSelect";
import ControlledInput from "components/Input/ControlledInput";
// icons
import IconSave from "assets/images/svg/icon-save.svg";
import TrashIcon from "assets/images/svg/trash-red.svg";
import Search from "assets/images/svg/search.svg";
import SearchWhite from "assets/images/svg/search-white.svg";

const ModalAddProduct = memo(({ close, refetch }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const rowId = searchParams.get("id");
  const type = searchParams.get("type");
  const { orgId } = useCoreStore((state) => state);
  const [productName, setProductName] = useState("");
  const [productOptions, setProductOptions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);

  const [currentMode, setCurrentMode] = useState("simple");

  const theme = useCoreStore((state) => state.theme);

  const { reset, pattern, handleSubmit } = useForm();

  const { data: tags } = useGet({
    link: `${endpoints.tags.list}?org_id=${orgId}`,
  });

  useGet({
    link: endpoints.product.get,
    params: {
      org_id: orgId,
      size: 100,
      page: 1,
      ...(productName && { name: productName }),
      ...(selectedTags.length > 0 && { tag_names: selectedTags.map((tag) => tag.label).join(",") }),
    },
    enabled: productName?.length >= 3 || selectedTags?.length > 0,
    onSuccess: (res) => {
      const optionTransformation = res.data.map((el) => ({
        label: el.name,
        value: el.id,
      }));
      setProductOptions(optionTransformation);
    },
  });

  const handleChangeSimple = (value) => {
    let products = [];

    if (selectedProducts) {
      if (selectedProducts.find((el) => el.value === value.value)) {
        products = selectedProducts.filter((el) => el.value !== value.value);
      } else {
        products = [...selectedProducts, value];
      }
    } else {
      products = [value];
    }

    setSelectedProducts(products);
    // setProductName("");
    // setProductOptions([]);
  };
  const handleDelete = (id) => {
    setSelectedProducts((prev) => prev.filter((el) => el.value !== id));
  };

  const changeMode = (mode) => {
    setCurrentMode(mode);
    setSelectedProducts(null);
    setProductName("");
    setProductOptions([]);
    setSelectedTags([]);
  };
  const debouncedHandleInputChange = debounce((value) => {
    setProductName(value);
  }, 500);

  function removeId() {
    reset();
    searchParams.delete("type");
    setSearchParams(searchParams);
  }

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: () => {
      refetch();
      reset();
      clearAndClose();
      notifySuccess(t("pages.manInvoice.productSuccessAdd"));
    },
  });

  const onSubmit = (values) => {
    const body = {
      product_id: selectedProducts[0].value,
      product_ids: selectedProducts.map((el) => el.value),
    };

    create.mutate({
      link:
        type === "INCOME"
          ? endpoints.invoices.incomeProductCreateMultiple
          : endpoints.invoices.outgoProductCreateMultiple,
      params: { invoice_id: rowId },
      method: "post",
      body,
    });
  };

  return (
    <div className="w-[600px]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 bg-[#FAFAFA] dark:bg-[#1F1E25] dark:border-[#2D2D2D] border-solid border-[2px] border-[#F2F2F2] rounded-lg w-full h-[44px] p-[2px]">
          <div
            className={`${
              currentMode === "simple"
                ? "border-solid border-2 border-[#F2F2F2] bg-white dark:bg-[#191A22] dark:!text-white dark:border-[transparent]"
                : ""
            } col-span-1 rounded-lg flex items-center justify-center cursor-pointer dark:text-[#9B9DA3]`}
            onClick={() => changeMode("simple")}
          >
            <span>{t("pages.manInvoice.simple")}</span>
          </div>
          <div
            className={`${
              currentMode === "advanced"
                ? "border-solid border-2 border-[#F2F2F2] bg-white dark:bg-[#191A22] dark:!text-white dark:border-[transparent]"
                : ""
            } col-span-1 rounded-lg flex items-center justify-center cursor-pointer dark:text-[#9B9DA3]`}
            onClick={() => changeMode("advanced")}
          >
            <span>{t("pages.manInvoice.extended")}</span>
          </div>
        </div>
        <div className="mt-[16px]">
          {currentMode === "simple" && (
            <div>
              <CustomSelect
                name="pattern"
                placeholder={t("button.select")}
                options={productOptions}
                autofocus={true}
                onChangeFunc={({ option }) => handleChangeSimple(option)}
                value={null}
                hasError={pattern?.error}
                onInputChange={(value) => debouncedHandleInputChange(value)}
              />
            </div>
          )}

          {currentMode === "advanced" && (
            <div className="grid grid-cols-2 gap-6">
              <div className="col-span-1">
                <ControlledInput
                  label={t("form.title")}
                  placeholder={t("form.titlePlaceholder")}
                  className="w-full h-[44px]"
                  onChange={(e) => {
                    setProductName(e.target.value);
                  }}
                  autoFocus={true}
                  value={productName}
                />
              </div>
              <div className="col-span-1">
                <CustomSelect
                  // isMulti={true}
                  title={t("pages.tags.title")}
                  height="44px"
                  padding="0px 5px"
                  name="tags"
                  search={true}
                  closeMenuOnSelect={false}
                  width={"100%"}
                  placeholder={`${t("pages.products.selectTags")}...`}
                  leftElement={<img src={theme ? SearchWhite : Search} alt="CloseIcon" className="cursor-pointer" />}
                  options={tags?.map((item) => ({ value: item.id, label: item.tag_name })) ?? []}
                  // empty value always should be
                  value={[]}
                  checkbox={true}
                  selectedTags={selectedTags}
                  onChangeFunc={(value) => {
                    // check if already selected
                    if (selectedTags?.find((tag) => tag.value === value.option.value)) {
                      // delete from selected
                      setSelectedTags(selectedTags.filter((tag) => tag.value !== value.option.value));
                    } else setSelectedTags([...selectedTags, value.option]);

                    // clear select
                    return;
                  }}
                />

                <div className="mt-[6px] flex w-full flex-wrap gap-[4px]">
                  {/* list of selected tags here */}
                  {selectedTags &&
                    selectedTags.map((tag) => (
                      <Chip
                        key={tag.value}
                        text={tag.label}
                        onDelete={() => {
                          setSelectedTags(selectedTags.filter((item) => item.value !== tag.value));
                        }}
                      />
                    ))}
                </div>
              </div>
            </div>
          )}

          {currentMode === "advanced" && (
            <div className="flex flex-col flex-wrap w-full mt-[15px]">
              {/* show here products */}
              {productOptions?.length > 0 && (
                <div className="flex flex-col flex-wrap w-full mt-[15px]">
                  <h3 className="mb-[5px] text-[14px] font-semibold dark:text-white">
                    {t("info.searchRes")} ({productOptions?.length})
                  </h3>
                  <div className="w-full flex flex-col max-h-[140px] overflow-y-auto border-1 border-[#D1D1D1] dark:border-[#2D2D2D] rounded-[6px] p-[6px]">
                    {productOptions?.map((item, i) => (
                      <div
                        key={i}
                        className="flex flex-row mb-[5px] items-center w-full h-[40px] bg-[#fff] dark:bg-[#1F1E25] dark:text-[#9B9DA3] rounded-lg p-[10px] hover:bg-[#F2F2F2] cursor-pointer"
                        onClick={() => handleChangeSimple(item)}
                      >
                        <FormCheckbox
                          name={item.value}
                          checked={selectedProducts?.find((el) => el.value === item.value)}
                          className="mr-2"
                          errors={{}}
                        />
                        <span>{item.label}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {selectedProducts?.length > 0 && (
            <div className="flex flex-wrap w-full mt-[15px]">
              <h3 className="mb-[5px] text-[14px] font-semibold dark:text-white">
                {t("pages.manInvoice.form.selectedProducts")} ({selectedProducts?.length})
              </h3>
              <div className="w-full max-h-[150px] overflow-y-auto border-1 border-[#D1D1D1] dark:border-[#2d2d2d] rounded-[6px] p-[6px]">
                {selectedProducts?.map((item, i) => (
                  <div
                    key={i}
                    className="flex flex-row mb-[5px] items-center justify-between w-full h-[40px] bg-[#fff] dark:bg-[#1F1E25] dark:text-[#9B9DA3] rounded-lg p-[10px] hover:bg-[#F2F2F2] cursor-pointer"
                  >
                    <span>{item.label}</span>

                    <span className="text-[#FF4D4F]" onClick={() => handleDelete(item.value)}>
                      <img src={TrashIcon} alt="TrashIcon" className="w-[20px] h-[20px]" />
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex gap-6 mt-8 justify-between">
          <BaseButton
            label={t("button.cancel")}
            className={`p-2 btn-secondary dark:btn-secondary-dark`}
            onClick={clearAndClose}
          />
          <BaseButton
            type="submit"
            className="p-2 btn-success"
            icon={IconSave}
            label={t("button.save")}
            isLoading={create.isLoading}
          />
        </div>
      </form>
    </div>
  );
});

export default ModalAddProduct;
