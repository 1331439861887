import { useState, useRef, useEffect, useLayoutEffect } from "react";
// constants
import { invoiceMainStates } from "constants";
// api
import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// search params
import { useSearchParams } from "react-router-dom";
// helpers
import { formatTimestamp } from "services/helpers";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import DeleteConfirmation from "container/DeleteConfirmation";
import ProductsTable from "../Table/ProductsTable";
import PaymentsTable from "../Table/PaymentsTable";
import InvoiceActions from "./InvoiceActions";
import Modal from "components/Modal/Modal";
import ModalEditInvoice from "../Modal/ModalEditInvoice";
import ModalAddProductInvoice from "../Modal/ModalAddProductInvoice";
import ModalAddProductExpense from "../Modal/ModalAddProductExpense";
import ModalAddPayment from "../Modal/ModalAddPayment";
import ModalEditProductExpence from "../Modal/ModalEditProductExpence";
import ModalEditProductIncome from "../Modal/ModalEditProductIncome";
import ModalEditPayment from "../Modal/ModalEditPayment";
import ModalChronology from "../Modal/ModalChronology";
// icons
import ArrowTop from "assets/images/svg/arrow-top.svg";

const Invoice = ({ invoice, refetch, setInvoices, open, pfShow, pfsShow }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const [maxHeight, setMaxHeight] = useState(4000);
  const [firstRender, setFirstRender] = useState(true);
  const invoiceRef = useRef();

  const editInvoice = useRef();
  const addProductIncome = useRef();
  const addProductExpense = useRef();
  const addPaymentArrival = useRef();
  const addPaymentExpense = useRef();

  const editProductExpence = useRef();
  const editProductIncome = useRef();
  const editPayment = useRef();
  const chronology = useRef();

  const closeModal = useRef();
  const deleteId = useRef();

  const closeDeleteModal = (value, id) => {
    deleteId.current = id;
    closeModal.current.openModal(value);
  };

  // useEffect(() => {
  //   if (isOpen && invoiceRef.current) {
  //     // Set maxHeight to the scrollHeight of the element plus some extra space
  //     setMaxHeight(`${invoiceRef.current.scrollHeight}px`);
  //   } else {
  //     setMaxHeight(0); // Set maxHeight to 0 to close the element
  //   }
  // }, [isOpen, invoice]); // Depend on isOpen and invoice to recalculate when they change
  useEffect(() => {
    if (isOpen && invoiceRef.current) {
      const timeoutDuration = firstRender ? 500 : 0; // 1 секунда для первого рендера, 0 для последующих
      const timeoutId = setTimeout(() => {
        setMaxHeight(`${invoiceRef.current.scrollHeight}px`);
        if (firstRender) {
          setFirstRender(false); // Установите firstRender в false после первого рендера
        }
      }, timeoutDuration);
      return () => clearTimeout(timeoutId); // Очистите таймер, если компонент будет размонтирован
    } else {
      setMaxHeight(0);
    }
  }, [isOpen, invoice, firstRender]);

  const deleteInvoice = useMutate({
    onSuccess: (res) => {
      // delete item from single invoice
      setInvoices((prev) => prev.filter((item) => item.id !== deleteId.current));
      closeDeleteModal(false);
      notifySuccess(t("notification.successDelete"));
    },
  });

  const confirmDelete = () => {
    deleteInvoice.mutate({
      link: `${endpoints.invoices.delete}?id=${deleteId.current}`,
      method: "delete",
    });
  };

  const handleTemplatesEditInvoice = (value) => {
    editInvoice.current.openModal(value);
  };
  const handleTemplatesAddInvoice = (value) => {
    addProductIncome.current.openModal(value);
  };
  const handleTemplatesAddProductExpense = (value) => {
    addProductExpense.current.openModal(value);
  };
  const handleTemplatesAddPaymentArrival = (value) => {
    addPaymentArrival.current.openModal(value);
  };
  const handleTemplatesAddPaymentExpense = (value) => {
    addPaymentExpense.current.openModal(value);
  };
  const handleTemplatesEditProductExpence = (value) => {
    editProductExpence.current.openModal(value);
  };
  const handleTemplatesEditProductIncome = (value) => {
    editProductIncome.current.openModal(value);
  };
  const handleTemplatesEditPayment = (value) => {
    editPayment.current.openModal(value);
  };
  const handleTemplatesChronology = (value) => {
    chronology.current.openModal(value);
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <div className="flex flex-col mb-[8px]">
      <div
        className="
    px-[16px] py-[6px] flex items-center bg-[#F2F2F2] dark:bg-[#1F1E25]  mt-[10px] justify-between cursor-pointer rounded-t-lg"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex">
          <img
            src={ArrowTop}
            alt="arrow-top"
            className={`${isOpen ? "transform rotate-0" : "rotate-180"} mr-4 transition-all duration-100`}
          ></img>
          <div>
            <span className="text-[#535456] dark:text-[#9B9DA3]">{t("pages.admInvoice.invoice")}: </span>
            <span className="text-extrabold dark:text-[#fff]">
              №{invoice.num} - {invoice.customer_name}
            </span>
          </div>
        </div>
        <div>
          <span className="text-[#535456] dark:text-[#9B9DA3]">{t("info.date")}: </span>
          <span className="dark:text-[#fff]">{formatTimestamp(invoice.dynamic_date, false)}</span>
        </div>
        <div className="flex">
          <div className="mr-[16px]">
            <span className="text-[#535456] dark:text-[#9B9DA3]">{t("info.status")}: </span>
            <span className="dark:text-[#fff]">
              {invoiceMainStates.find((state) => state.value === invoice.state).label}
            </span>
          </div>
          <div>
            <span className="text-[#535456] dark:text-[#9B9DA3]">{t("pages.customers.table.manager")}: </span>
            <span className="dark:text-[#fff]">{invoice.manager_full_name}</span>
          </div>
          <div className="flex items-center ml-[16px] gap-4" onClick={(e) => e.stopPropagation()}>
            <InvoiceActions
              invoice={invoice}
              closeDeleteModal={closeDeleteModal}
              handleTemplatesEditInvoice={handleTemplatesEditInvoice}
              handleTemplatesAddInvoice={handleTemplatesAddInvoice}
              handleTemplatesAddProductExpense={handleTemplatesAddProductExpense}
              handleTemplatesAddPaymentArrival={handleTemplatesAddPaymentArrival}
              handleTemplatesAddPaymentExpense={handleTemplatesAddPaymentExpense}
              handleTemplatesChronology={handleTemplatesChronology}
            />
          </div>
        </div>
      </div>
      <div
        ref={invoiceRef}
        style={{ maxHeight: maxHeight }} // Apply the dynamic maxHeight here
        className={`transition-all duration-[350ms] overflow-hidden text-sm text-center flex flex-col`}
      >
        <div
          // opacity with delay
          className={`flex flex-col transition-all duration-200`}
          ref={invoiceRef}
        >
          {invoice.outgo_products.length > 0 && (
            <ProductsTable
              products={invoice.outgo_products}
              pfShow={pfShow}
              pfsShow={pfsShow}
              invoiceId={invoice.id}
              warehouse={invoice.warehouse_name}
              setInvoices={setInvoices}
              badge={t("pages.admInvoice.issued")}
              type="expense"
              colors={{
                darkColor: "#FFE4E0",
                lightColor: "#FFF1EF",
                badgeColor: "#FFBEB5",
              }}
              handleTemplatesEditProductExpence={handleTemplatesEditProductExpence}
            />
          )}

          {invoice.income_products.length > 0 && (
            <ProductsTable
              products={invoice.income_products}
              pfShow={pfShow}
              pfsShow={pfsShow}
              invoiceId={invoice.id}
              warehouse={invoice.warehouse_name}
              setInvoices={setInvoices}
              badge={t("pages.admInvoice.recieved")}
              type="income"
              colors={{
                darkColor: "#D5EBFD",
                lightColor: "#E9F4FE",
                badgeColor: "#99CEFA",
              }}
              handleTemplatesEditProductIncome={handleTemplatesEditProductIncome}
            />
          )}

          {invoice.outgo_payments.length > 0 && (
            <PaymentsTable
              payments={invoice.outgo_payments}
              invoiceId={invoice.id}
              setInvoices={setInvoices}
              badge={t("pages.admInvoice.issued")}
              colors={{
                darkColor: "#FFF5D7",
                lightColor: "#FFFAEA",
                badgeColor: "#FFE89D",
              }}
              handleTemplatesEditPayment={handleTemplatesEditPayment}
              type="EXPENSE"
            />
          )}

          {invoice.income_payments.length > 0 && (
            <PaymentsTable
              payments={invoice.income_payments}
              invoiceId={invoice.id}
              setInvoices={setInvoices}
              badge={t("pages.admInvoice.recieved")}
              colors={{
                darkColor: "#E8F5D3",
                lightColor: "#F3FAE8",
                badgeColor: "#C8E795",
              }}
              handleTemplatesEditPayment={handleTemplatesEditPayment}
              type="INCOME"
            />
          )}
        </div>
      </div>
      <div className="bg-[#F2F2F2] dark:bg-[#1F1E25] flex item-center mt-[10px] rounded-b-lg p-[6px] justify-center">
        <p className="dark:text-white">
          {t("pages.sale.table.total")}:{" "}
          <span className="font-semibold">
            {invoice.outgo_payments.length +
              invoice.income_payments.length +
              invoice.outgo_products.length +
              invoice.income_products.length}
          </span>
        </p>
      </div>

      <Modal ref={editInvoice} title={t("pages.manInvoice.editInvoice")} classNameExtra="!overflow-visible">
        <ModalEditInvoice close={handleTemplatesEditInvoice} refetch={refetch} />
      </Modal>

      <Modal
        ref={addProductIncome}
        title={{
          start: `${t("pages.admInvoice.modal.addProduct")} `,
          end: t("pages.admInvoice.modal.incomeSm"),
          color: "#2196F3",
        }}
      >
        <ModalAddProductInvoice close={handleTemplatesAddInvoice} refetch={refetch} />
      </Modal>

      <Modal
        ref={addProductExpense}
        title={{
          start: `${t("pages.admInvoice.modal.addProduct")} `,
          end: t("pages.admInvoice.modal.expenseSm"),
          color: "#FF705D",
        }}
      >
        <ModalAddProductExpense close={handleTemplatesAddProductExpense} refetch={refetch} />
      </Modal>

      <Modal
        ref={addPaymentArrival}
        title={{
          start: `${t("pages.admInvoice.modal.addPayment")} `,
          end: t("pages.admInvoice.modal.incomeSm"),
          color: "#87CB19",
        }}
        classNameExtra="!overflow-visible"
      >
        <ModalAddPayment close={handleTemplatesAddPaymentArrival} refetch={refetch} />
      </Modal>

      <Modal
        ref={addPaymentExpense}
        title={{
          start: `${t("pages.admInvoice.modal.addPayment")} `,
          end: t("pages.admInvoice.modal.expenseSm"),
          color: "#FFCD29",
        }}
        classNameExtra="!overflow-visible"
      >
        <ModalAddPayment close={handleTemplatesAddPaymentExpense} refetch={refetch} />
      </Modal>

      <Modal
        ref={editProductExpence}
        title={{
          start: `${t("pages.admInvoice.modal.editProduct")} `,
          end: t("pages.admInvoice.modal.expenseSm"),
          color: "#FF705D",
        }}
      >
        <ModalEditProductExpence close={handleTemplatesEditProductExpence} refetch={refetch} />
      </Modal>

      <Modal
        ref={editProductIncome}
        title={{
          start: `${t("pages.admInvoice.modal.editProduct")} `,
          end: t("pages.admInvoice.modal.incomeSm"),
          color: "#2196F3",
        }}
      >
        <ModalEditProductIncome close={handleTemplatesEditProductIncome} refetch={refetch} />
      </Modal>

      <Modal
        ref={editPayment}
        title={{
          start: `${t("pages.admInvoice.modal.editPayment")} `,
          end:
            searchParams.get("type") === "INCOME"
              ? t("pages.admInvoice.modal.incomeSm")
              : t("pages.admInvoice.modal.expenseSm"),
          color: searchParams.get("type") === "INCOME" ? "#87CB19" : "#FFCD29",
        }}
      >
        <ModalEditPayment close={handleTemplatesEditPayment} refetch={refetch} />
      </Modal>

      <Modal ref={chronology} title={t("pages.admInvoice.actions.history")}>
        <ModalChronology close={handleTemplatesChronology} />
      </Modal>
      <DeleteConfirmation ref={closeModal} close={closeDeleteModal} cb={confirmDelete} />
    </div>
  );
};

export default Invoice;
