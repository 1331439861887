import { PatternFormat } from "react-number-format";
import cls from "classnames";
import { useCoreStore } from "store/core";

function NumericField(props) {
  const { label, errors, name, className = "", onChange, leftElement, rightElement, ...rest } = props;

  const { theme } = useCoreStore();

  return (
    <div className="mb-2">
      {label && (
        <label className={cls("block text-stone-800 dark:text-white font-semibold text-[14px]")}>{label}</label>
      )}
      <div className="relative">
        {leftElement && <div className="absolute left-3 top-1/2 -translate-y-1/2">{leftElement}</div>}
        <PatternFormat
          autoComplete="off"
          valueIsNumericString
          name={name}
          className={cls(
            `input bg-GreyInput w-full placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 
          focus:bg-white dark:focus:border-purple-2 focus:border-2 dark:text-midGrey dark:border-blue-3 dark:bg-inputBgDark`,
            className,
            theme ? "custominput-dark" : "custominput-light",
            {
              "pl-9": leftElement,
              "pr-9": rightElement,
            },
          )}
          onValueChange={(value) => onChange(value.value)}
          {...rest}
        />
        {rightElement && <div className="absolute right-3 top-1/2 -translate-y-1/2">{rightElement}</div>}
      </div>
      {errors[name] && <p className="error-message mb-4 mt-1 text-red-500">{label} ni tanlang</p>}
    </div>
  );
}

export default NumericField;
