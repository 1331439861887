import React, { memo, useEffect, useState, useImperativeHandle, forwardRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
import { FormCheckbox } from "components/Checkbox/FormCheckbox";
// i18n
import { useTranslation } from "react-i18next";
import Input from "components/Input/Input";
import { notifySuccess } from "components/Toast";
import { Tooltip } from "components/Tooltip/Tooltip";
import BaseButton from "components/Button/BaseButton";
import ImageUploader from "components/ImageUploader";
import ImageUploaderLarge from "components/ImageUploaderLarge";
// import Checkbox from "components/Checkbox/Checkbox";

import IconSave from "assets/images/svg/icon-save.svg";
import IconSun from "assets/images/svg/icon-sun.svg";
import IconMoon from "assets/images/svg/icon-moon.svg";
import QuestionHelp from "assets/images/svg/icon-gray-question.svg";

const ModalForm = memo(
  forwardRef(({ close, refetch, row, setCategories, orgId }, ref) => {
    const { t } = useTranslation();
    let [searchParams, setSearchParams] = useSearchParams();
    const rowId = searchParams.get("row_id1");

    const [catalog, setCatalog] = useState(false);

    const [firstImages1, setFirstImages1] = useState({
      mainImage1: [],
      lightIcon1: [],
      darkIcon1: [],
    });

    const {
      reset,
      setValue,
      register,
      handleSubmit,
      control,
      formState: { errors },
    } = useForm({
      defaultValues: {
        // is_main_page: true,
        name: {
          uz: "",
          ru: "",
        },
      },
    });

    useImperativeHandle(ref, () => ({
      removeImages: setImagesInitial,
    }));

    function removeId() {
      reset();
      searchParams.delete("row_id1");
      setSearchParams(searchParams);
    }

    useEffect(() => {
      if (rowId) {
        setValue("name.uz", row.name?.uz);
        setValue("name.ru", row.name?.ru);
        setFirstImages1(() => {
          return {
            mainImage1: row.main_image ? [row.main_image] : [],
            lightIcon1: row.icon_light ? [row.icon_light] : [],
            darkIcon1: row.icon_dark ? [row.icon_dark] : [],
          };
        });
      } else {
        reset();
      }
    }, [row]);

    function setImagesInitial() {
      setFirstImages1({
        mainImage1: [],
        lightIcon1: [],
        darkIcon1: [],
      });
    }

    function clearAndClose() {
      close(false);
      removeId();
      setImagesInitial();
    }

    const create = useMutate({
      onSuccess: (res) => {
        setCategories((prev) => [...prev, res]);
        clearAndClose();
        notifySuccess(t("notification.categorySuccessCreate"));
      },
    });

    const update = useMutate({
      onSuccess: (res) => {
        setCategories((prev) => prev.map((el) => (el.id === res.id ? res : el)));
        clearAndClose();
        notifySuccess(t("notification.categorySuccessUpdate"));
      },
    });

    const onSubmit = (values) => {
      const body = {
        ...values,
        main_image: firstImages1.mainImage1[0],
        icon_light: firstImages1.lightIcon1[0],
        icon_dark: firstImages1.darkIcon1[0],
        parent_id: null,
      };
      if (row.id) {
        update.mutate({
          link: `${endpoints.category.update}?id=${row.id}`,
          method: "put",
          body: body,
        });
      } else {
        create.mutate({
          link: `${endpoints.category.create}?org_id=${orgId}`,
          method: "post",
          body: body,
        });
      }
    };

    return (
      <div className="">
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-3">
            <ImageUploaderLarge
              url={firstImages1.mainImage1}
              label={t("pages.categories.image")}
              setImagesUrl={setFirstImages1}
              name="mainImage1"
            />
          </div>
          <div className="flex flex-col gap-5">
            <Input
              autoFocus
              label={t("form.titleUz")}
              placeholder={t("form.title")}
              fieldName="name.uz"
              register={register}
              errors={errors}
              isRequired={true}
              className="w-full"
            />
            <Input
              label={t("form.titleRu")}
              placeholder={t("form.title")}
              fieldName="name.ru"
              register={register}
              errors={errors}
              isRequired={true}
              className="w-full"
            />
            {/* <Controller
            name="is_main_page"
            control={control}
            render={({ field }) => (
              <Checkbox label="is main page category" {...field} />
            )}
          /> */}
          </div>
          <div className="flex mt-5">
            <p className="text-stone-800 dark:text-white font-semibold text-[14px] mr-[10px] required-field">
              {t("info.icons")}
            </p>
            <Tooltip
              position="-top-14"
              arrowPostion="bottom"
              className="left-[-58px] z-50"
              content={<div className="text-textLight text-[14px]">{t("info.icons")}</div>}
            >
              <img src={QuestionHelp} alt="Question" />
            </Tooltip>
          </div>
          <div className="flex mt-1 gap-8">
            <ImageUploader
              url={firstImages1.lightIcon1}
              setImagesUrl={setFirstImages1}
              name="lightIcon1"
              descElement={<h2 className="textBlack font-semibold text-[15px] dark:text-white">{t("info.light")}</h2>}
              imgIcon={IconSun}
            />
            <ImageUploader
              url={firstImages1.darkIcon1}
              setImagesUrl={setFirstImages1}
              name="darkIcon1"
              descElement={<h2 className="textBlack font-semibold text-[15px] dark:text-white">{t("info.dark")}</h2>}
              imgIcon={IconMoon}
            />
          </div>
          <div className="mt-[26px] flex">
            <FormCheckbox
              label={t("pages.categories.catalog")}
              checked={catalog}
              errors={{}}
              onChange={(e) => {
                setCatalog(e.target.checked);
              }}
            />
            <div className="ml-[10px]">
              <Tooltip
                position="-top-14"
                arrowPostion="bottom"
                className="left-[-58px] w-36 z-50"
                content={<div className="text-textLight text-[14px]">{t("info.accessDenied")}</div>}
              >
                <img src={QuestionHelp} alt="question" />
              </Tooltip>
            </div>
          </div>
          <div className="flex gap-6 mt-8">
            <BaseButton
              label={t("button.cancel")}
              onClick={clearAndClose}
              className="flex-1 p-2 btn-secondary dark:btn-secondary-dark"
            />
            <BaseButton
              type="submit"
              className="flex-1 p-2 btn-success"
              icon={IconSave}
              label={row.id ? t("button.update") : t("button.save")}
            />
          </div>
        </form>
      </div>
    );
  }),
);

export default ModalForm;
