import React from "react";

import DefaultAvatar from "assets/images/avatar.jpg";

const Avatar = ({ img }) => {
  return (
      <img src={img ?? DefaultAvatar} alt="avatar" className="w-[36px] h-[36px] rounded-[8px]" />
  );
};

export default Avatar;
