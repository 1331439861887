import React from "react";
import Layer from "./AuthLayer";
// i18n
import { useTranslation } from "react-i18next";
// icons
import LoginBgIcon from "assets/images/svg/icon-login-bg.svg";

const AuthContainer = ({ children }) => {
  const { t } = useTranslation();

  const year = new Date().getFullYear();

  return (
    <div className="flex h-screen relative">
      <div className="flex basis-2/4 relative">
        {children}
        <img src={LoginBgIcon} alt="loginicon" className="absolute bottom-0" />
        <div className="absolute bottom-6 text-darkGreyLight left-1/2 -translate-x-1/2">
          <p>© {year} — NT Systems LTD. {t("footer.copyright")}.</p>
        </div>
      </div>
      <div className="basis-2/4">
        <Layer />
      </div>
    </div>
  );
};

export default AuthContainer;
