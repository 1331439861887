import { useSearchParams } from "react-router-dom";
// components
import TableAction from "components/Table/TableAction";
// i18n
import { useTranslation } from "react-i18next";
// icons
import EditIcon from "assets/images/svg/icon-edit.svg";
import InfoIcon from "assets/images/svg/icon-info.svg";
import PrintIcon from "assets/images/svg/icon-print.svg";
import SendIcon from "assets/images/svg/icon-send-grey.svg";
import HistoryIcon from "assets/images/svg/icon-history.svg";
import ProductPlusIcon from "assets/images/svg/icon-product-plus.svg";
import ProductMinusIcon from "assets/images/svg/icon-product-minus.svg";
import PaymentPlusIcon from "assets/images/svg/icon-payment-plus.svg";
import PaymentMinusIcon from "assets/images/svg/icon-payment-minus.svg";
import IconTrash from "assets/images/svg/trash-red.svg";

const InvoiceActions = ({
  invoice,
  closeDeleteModal,
  handleTemplatesEditInvoice,
  handleTemplatesAddInvoice,
  handleTemplatesAddProductExpense,
  handleTemplatesAddPaymentArrival,
  handleTemplatesAddPaymentExpense,
  // handleTemplatesChronology,
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleEditClick = () => {
    setSearchParams({ ...searchParams, row_id: invoice.id });
    handleTemplatesEditInvoice(true);
  };

  const tabClass =
    "flex gap-2 px-[8px] py-[6px] rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack flex items-center cursor-pointer";
  const imgClass = "w-[20px] h-[20px]";
  return (
    <TableAction boxClass="z-[500]">
      <div className={tabClass} onClick={handleEditClick}>
        <img src={EditIcon} alt="delete" className={imgClass} />
        <p>{t("pages.admInvoice.actions.edit")}</p>
      </div>

      <div className={tabClass} onClick={() => console.log("INACTIVE")}>
        <img src={InfoIcon} alt="delete" className={imgClass} />
        <p>{t("pages.admInvoice.actions.properties")}</p>
      </div>

      <div className={tabClass} onClick={() => console.log("INACTIVE")}>
        <img src={PrintIcon} alt="delete" className={imgClass} />
        <p>{t("pages.admInvoice.actions.print")}</p>
      </div>
      <div className={tabClass} onClick={() => console.log("INACTIVE")}>
        <img src={SendIcon} alt="delete" className={imgClass} />
        <p>{t("pages.admInvoice.actions.bot")}</p>
      </div>
      <div
        className={tabClass + " opacity-50"}
        onClick={() => {
          // handleTemplatesChronology(true);
          return console.log("INACTIVE");
        }}
      >
        <img src={HistoryIcon} alt="delete" className={imgClass} />
        <p>{t("pages.admInvoice.actions.history")}</p>
      </div>
      <div
        className={tabClass}
        onClick={() => {
          setSearchParams({ ...searchParams, invoice_id: invoice.id });
          handleTemplatesAddInvoice(true);
        }}
      >
        <img src={ProductPlusIcon} alt="delete" className={imgClass} />
        <p>{t("pages.admInvoice.actions.prodIncome")}</p>
      </div>
      <div
        className={tabClass}
        onClick={() => {
          setSearchParams({ ...searchParams, invoice_id: invoice.id });
          handleTemplatesAddProductExpense(true);
        }}
      >
        <img src={ProductMinusIcon} alt="delete" className={imgClass} />
        <p>{t("pages.admInvoice.actions.prodExpense")}</p>
      </div>
      <div
        className={tabClass}
        onClick={() => {
          setSearchParams({ ...searchParams, invoice_id: invoice.id, type: "OUTGO" });
          handleTemplatesAddPaymentExpense(true);
        }}
      >
        <img src={PaymentMinusIcon} alt="delete" className={imgClass} />
        <p>{t("pages.admInvoice.actions.payExpense")}</p>
      </div>
      <div
        className={tabClass}
        onClick={() => {
          setSearchParams({ ...searchParams, invoice_id: invoice.id, type: "INCOME" });
          handleTemplatesAddPaymentArrival(true);
        }}
      >
        <img src={PaymentPlusIcon} alt="delete" className={imgClass} />
        <p>{t("pages.admInvoice.actions.payIncome")}</p>
      </div>
      <div className={tabClass} onClick={() => closeDeleteModal(true, invoice?.id)}>
        <img src={IconTrash} alt="delete" className={imgClass} />
        <p>{t("button.delete")}</p>
      </div>
    </TableAction>
  );
};

export default InvoiceActions;
