import React from "react";
import cls from "classnames";
// components
import BaseTableData from "./BaseTableData";
import { FormCheckbox } from "components/Checkbox/FormCheckbox";
// icons
import IconOrder from "assets/images/svg/icon-order.svg";

const DragHandler = ({ onMouseDown }) => (
  <div onMouseDown={onMouseDown}>
    <img src={IconOrder} className="w-7" alt="icon-order" />
  </div>
);

const DndTableItem = ({
  item,
  showSelected = false,
  dragHandleProps,
  commonProps,
  className,
  handleShow = () => {},
}) => {
  const { onMouseDown } = dragHandleProps;

  let isRowActive = commonProps.activeRowId === item.id;

  return (
    <tr
      className={cls(`w-full pl-4 flex select-none rounded hover:bg-GreyLight dark:hover:bg-inputBgDark`, {
        "bg-[#F2F2F2] dark:bg-blue-3": isRowActive,
      })}
    >
      {showSelected && (
        <FormCheckbox
          label=""
          errors={{}}
          onChange={(e) => handleShow(item.id, item.property_id, e.target.checked)}
          checked={item.is_show}
        />
      )}
      {/* {item.property_separator && <div className="ml-[24px]"></div>} */}
      <BaseTableData className="flex items-center cursor-move">
        <DragHandler {...dragHandleProps} />
      </BaseTableData>
      <td className="flex justify-between items-center w-full group">
        {item.row.map((el, ind) => (
          <>
            <BaseTableData
              key={`${el.title}${ind}`}
              onClick={() => (el.action ? el.action : null)}
              tag="div"
              className={className}
            >
              <div className={cls("flex items-center gap-2 cursor-move w-full")}>{el.title}</div>
            </BaseTableData>
          </>
        ))}
      </td>
    </tr>
  );
};

export default DndTableItem;
