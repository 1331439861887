import { useEffect, useRef, useState } from "react";
import cls from "classnames";
// i18n
import { useTranslation } from "react-i18next";

import BaseTable from "components/Table/BaseTable";
import BaseThead from "components/Table/BaseThead";
import BaseButton from "components/Button/BaseButton";
import Pagination from "components/Pagination/Pagination";
import { notifySuccess } from "components/Toast";
import ControlledInput from "components/Input/ControlledInput";
import ControlledSelect from "components/Select/ControlledSelect";
import ControlledCheckbox from "components/Checkbox/ControlledCheckbox";

import { useMutate } from "hooks/query";
import DeleteConfirmation from "container/DeleteConfirmation";
import { useCoreStore } from "store/core";
import { endpoints } from "services/endpoints";
import { checkHasAccessToAction } from "services/helpers";

import Tbody from "./Tbody";
import { useTranslatedTableConfig } from "../Helpers";
import useOrganization from "../../hooks/useOrganization";
import useRoles from "../../hooks/useRoles";
import { usePaginateGoBack } from "hooks/usePaginateGoBack/usePaginateGoback";

import Search from "assets/images/svg/search.svg";
import SearchWhite from "assets/images/svg/search-white.svg";
import AddIcon from "assets/images/svg/add-to-photos.svg";
import TrashIcon from "assets/images/svg/trash-white.svg";
import ArrowTop from "assets/images/svg/arrow-top.svg";

const Table = ({ refetch, data = [], modalToggler, handleSearch, paginationConfig, filters, setFilters, setPage }) => {
  const { t } = useTranslation();

  const { header } = useTranslatedTableConfig();

  const [isSelectedAllRows, setIsSelectedAllRows] = useState(false);
  const [hasFilter, setHasFilter] = useState(false);
  const [checkboxes, setCheckboxes] = useState({});
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const closeModal = useRef();
  const deleteId = useRef();
  const { theme, orgId } = useCoreStore();
  const { orgOptions } = useOrganization(orgId, false);
  const { subOrgOptions } = useOrganization(filters?.org_id?.value, false);

  const hasAccessToCreate = checkHasAccessToAction("create-user");
  const paginateGoBack = usePaginateGoBack({
    setPage,
    total: paginationConfig.total,
    pageCount: paginationConfig.pageCount,
  });

  const handleChangeCheckbox = (e) => {
    setIsSelectedAllRows(e.target.checked);
  };

  const closeDeleteModal = (value, id) => {
    deleteId.current = id;
    closeModal.current.openModal(value);
  };

  const deleteUser = useMutate({
    onSuccess: () => {
      refetch();
      closeDeleteModal(false);
      notifySuccess(t("notification.successDelete"));
      paginateGoBack();
    },
  });

  const confirmDelete = () => {
    deleteUser.mutate({
      link: `${endpoints.users.delete}?id=${deleteId.current}`,
      method: "delete",
    });
  };

  const deleteMultiUser = useMutate({
    onSuccess: (res) => {
      refetch();
      setCheckboxes({});
      setSelectedRowIds([]);
      notifySuccess(t("notification.successDelete"));
      paginateGoBack(res.length);
    },
  });

  const deleteSelectedRows = () => {
    deleteMultiUser.mutate({
      link: `${endpoints.users.delete}`,
      method: "patch",
      body: { ids: selectedRowIds },
    });
  };

  const toggleUser = useMutate({
    onSuccess: () => {
      refetch();
    },
  });

  const userToggleState = (id, state) => {
    toggleUser.mutate({
      link: `${endpoints.users.update_state}?id=${id}`,
      method: "patch",
      body: { state: state == "ACTIVE" ? "INACTIVE" : "ACTIVE" },
    });
  };

  const checkActiveUsers = (value) => {
    setFilters((prev) => ({ ...prev, is_active: value }));
  };

  const filterChange = (option, name) => {
    setFilters((prev) => ({ ...prev, [name]: option }));
  };

  useEffect(() => {
    // Determine if the condition to reset sub_org_id is met
    const shouldResetSubOrg = !filters?.org_id?.value;

    if (shouldResetSubOrg) {
      setFilters((prev) => ({ ...prev, sub_org_id: null }));
    }

    // If you need to prevent continuous updates, you could use a ref to track
    // the previous value and only update if the value has changed.
  }, [filters?.org_id?.value]);

  const setPermissionFilter = (option) => {
    if (option.label === "All") {
      setFilters((prev) => ({ ...prev, permission: null }));
      return;
    }

    setFilters((prev) => ({ ...prev, permission: option.label }));
  };

  useEffect(() => {
    const filterByTrue = Object.values(checkboxes).filter((el) => el);
    const filteredKeys = Object.keys(checkboxes).filter((key) => checkboxes[key]);
    setSelectedRowIds(filteredKeys);

    if (filterByTrue.length !== data.length) setIsSelectedAllRows(false);
  }, [checkboxes]);

  return (
    <div className="h-full px-2 flex flex-col">
      <h2 className="text-bold text-2xl flex text-black dark:text-textDark">{t("pages.mainUsers.title")}</h2>
      <div className="w-full flex justify-between items-center my-4">
        <div className="w-[260px]">
          <ControlledInput
            className="w-full"
            placeholder={t("pages.mainUsers.searchUsers") + "..."}
            onChange={handleSearch}
            leftElement={<img src={theme ? SearchWhite : Search} alt="CloseIcon" className="cursor-pointer" />}
          />
        </div>
        <div className="flex gap-3 items-center">
          {selectedRowIds.length > 0 && (
            <BaseButton
              label={`${t("button.deleteSelected")} (${selectedRowIds.length})`}
              icon={TrashIcon}
              className="btn-danger"
              onClick={deleteSelectedRows}
            />
          )}
          {hasAccessToCreate && (
            <BaseButton
              label={t("button.addUser")}
              icon={AddIcon}
              className="btn-primary"
              onClick={() => modalToggler(true)}
            />
          )}
          <div
            className={cls(
              "flex items-center gap-1 cursor-pointer",
              "bg-GreyInput h-[32px] py-[1px] px-2 rounded-[6px] dark:bg-midBlack",
            )}
            onClick={() => setHasFilter((prev) => !prev)}
          >
            <span className="w-[4px] h-[4px] rounded-full bg-black dark:bg-white"></span>
            <span className="w-[4px] h-[4px] rounded-full bg-black dark:bg-white"></span>
            <span className="w-[4px] h-[4px] rounded-full bg-black dark:bg-white"></span>
          </div>
        </div>
      </div>
      {hasFilter && (
        <div className="flex justify-between mb-4 pr-1">
          <div className="flex gap-3">
            <ControlledSelect
              name="organizations"
              value={filters.org_id}
              placeholder={t("pages.mainUsers.selectOrg")}
              options={[{ label: "Все", value: null }, ...orgOptions]}
              className="relative !z-[70]"
              width={"280px"}
              height={"44px"}
              onChange={(option) => filterChange(option, "org_id")}
            />
            <ControlledSelect
              name="branches"
              value={filters.sub_org_id}
              placeholder={t("pages.mainUsers.selectBranch")}
              options={[{ label: "Все", value: null }, ...subOrgOptions]}
              className="relative !z-[70]"
              width={"280px"}
              height={"44px"}
              onChange={(option) => filterChange(option, "sub_org_id")}
            />
            {/* <ControlledSelect
              name="roles"
              value={filters.role_id}
              placeholder="Выберите роль"
              options={[{ label: "All", value: null }, ...rolesOptions]}
              className="relative !z-[70]"
              width={"180px"}
              height={"44px"}
              onChange={(option) => setPermissionFilter(option)}
            /> */}
          </div>
          <ControlledCheckbox
            checked={filters.is_active}
            name="checkbox"
            label={t("pages.mainUsers.showActive")}
            onChange={(e) => checkActiveUsers(e.target.checked)}
          />
        </div>
      )}
      <BaseTable
        // extraClassNames="h-[71%]"
        thead={
          <BaseThead
            header={header}
            withCheckbox={true}
            isSelectedAllRows={isSelectedAllRows}
            handleChangeCheckbox={handleChangeCheckbox}
          />
        }
        tbody={
          <Tbody
            data={data}
            isChecked={isSelectedAllRows}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            userToggleState={userToggleState}
            closeDeleteModal={closeDeleteModal}
          />
        }
      />
      <Pagination paginationConfig={paginationConfig} />
      <DeleteConfirmation ref={closeModal} close={closeDeleteModal} cb={confirmDelete} />
    </div>
  );
};

export default Table;
