import React, { memo, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import { useForm, Controller } from "react-hook-form";
// constants
import { exchangeOptions, baseCurrency } from "constants";
// api
import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// store
import { useCoreStore } from "../../store/core";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import Input from "components/Input/Input";
import CustomSelect from "components/Select/Select";
import BaseButton from "../../components/Button/BaseButton";

import IconSave from "assets/images/svg/icon-save.svg";

var prevDay = new Date();
prevDay.setDate(prevDay.getDate() - 1);

const ModalForm = memo(({ close, refetch, row }) => {
  const { t } = useTranslation();

  let [searchParams, setSearchParams] = useSearchParams();
  const rowId = searchParams.get("row_id");
  const { orgId } = useCoreStore((state) => state);
  const [currentDate, setDate] = useState({
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
  });

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  function removeId() {
    reset();
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (rowId) {
      setValue("currency_amount", row.currency_amount);
      setValue("currency", { label: row.currency.toUpperCase(), value: row.currency });
      setValue("base_currency", { label: row.base_currency.toUpperCase(), value: row.base_currency });
      setDate({
        startDate: new Date(row.currency_date),
        endDate: new Date(row.currency_date),
      });
    } else {
      reset();
    }
  }, [row, rowId, setValue, reset]);

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: () => {
      refetch();
      reset();
      clearAndClose();
      notifySuccess(t("notification.kursSuccessCreate"));
    },
  });

  const update = useMutate({
    onSuccess: () => {
      refetch();
      reset();
      clearAndClose();
      notifySuccess(t("notification.kursSuccessUpdate"));
    },
  });

  const onSubmit = (values) => {
    const { base_currency, currency, currency_amount } = values;
    const body = {
      currency_date: currentDate.endDate,
      currency: currency.value.toLowerCase(),
      currency_amount: Number(currency_amount),
      base_currency: base_currency.value.toLowerCase(),
    };
    if (row.id) {
      update.mutate({
        link: `${endpoints.exchangeRate.update}?id=${row.id}`,
        method: "put",
        body: body,
      });
    } else {
      create.mutate({
        link: `${endpoints.exchangeRate.create}?org_id=${orgId}`,
        method: "post",
        body: body,
      });
    }
  };

  return (
    <div className="w-96 ">
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6">
          <Datepicker
            asSingle
            useRange={false}
            placeholder={currentDate.startDate}
            displayFormat={"DD.MM.YYYY"}
            startFrom={currentDate}
            value={currentDate}
            onChange={setDate}
            minDate={prevDay}
            primaryColor={"violet"}
            containerClassName="relative calendar-container"
            inputClassName="w-full py-3 px-4 rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey dark:text-white"
          />
        </div>
        <div className="w-full mb-6">
          <Controller
            ref={null}
            name="base_currency"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomSelect
                {...field}
                isRequired
                autoFocus={true}
                errors={errors}
                label={t("pages.kurs.form.baseCurrency")}
                options={baseCurrency}
                placeholder={t("pages.kurs.form.baseCurrencyPlaceholder")}
              />
            )}
          />
        </div>
        <div className="w-full mb-6">
          <Controller
            ref={null}
            name="currency"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomSelect
                {...field}
                isRequired
                errors={errors}
                label={t("pages.kurs.form.currency")}
                options={exchangeOptions}
                placeholder={t("pages.kurs.form.baseCurrencyPlaceholder")}
              />
            )}
          />
        </div>
        <div className="mb-2">
          <Input
            label={t("pages.kurs.form.rate")}
            placeholder={t("pages.kurs.form.ratePlaceholder")}
            fieldName="currency_amount"
            register={register}
            errors={errors}
            type="number"
            isRequired={true}
            className="w-full"
          />
        </div>
        <div className="flex gap-6 mt-8">
          <BaseButton
            label={t("button.cancel")}
            onClick={clearAndClose}
            className="flex-1 p-2 btn-secondary dark:btn-secondary-dark"
          />
          <BaseButton
            type="submit"
            className="flex-1 p-2 btn-success"
            icon={IconSave}
            label={row.id ? t("button.edit") : t("button.save")}
            isLoading={create.isLoading || update.isLoading}
          />
        </div>
      </form>
    </div>
  );
});

export default ModalForm;
