import React, { memo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useCoreStore } from "store/core";
import { useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
// api
import { useMutate, useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
// helpers
import { debounce } from "services/helpers";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";
import ControlledSelect from "components/Select/ControlledSelect";
import TextArea from "components/Textarea/TextArea";
import ControlledInput from "components/Input/ControlledInput";
// icons
import IconSave from "assets/images/svg/icon-save.svg";
import Checkbox from "components/Checkbox/Checkbox";

const ModalAddInvoice = memo(({ close, refetch }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const invoiceId = searchParams.get("invoice_id");
  const [productName, setProductName] = useState("");
  const [productOptions, setProductOptions] = useState([]);
  const { orgId } = useCoreStore((state) => state);

  const [returnInvoice, setReturnInvoice] = useState(false);

  const {
    reset,
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      return: false,
    },
  });

  useGet({
    link: endpoints.product.get,
    params: {
      org_id: orgId,
      size: 100,
      page: 1,
      ...(productName && { name: productName }),
    },
    enabled: productName?.length >= 3,
    onSuccess: (res) => {
      const optionTransformation = res.data.map((el) => ({
        label: el.name,
        value: el.id,
      }));
      setProductOptions(optionTransformation);
    },
  });

  const debouncedHandleInputChange = debounce((value) => {
    setProductName(value);
  }, 500);

  var prevDay = new Date();
  prevDay.setDate(prevDay.getDate() - 1);

  function removeId() {
    reset();
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: () => {
      refetch();
      reset();
      clearAndClose();
      notifySuccess(t("notification.successProductAdd"));
    },
  });

  const quantity = watch("quantity");
  const price = watch("price");
  const sum = watch("sum");

  const handleQuantityChange = (value) => {
    setValue("quantity", value);

    if (value && price) {
      setValue("sum", value * price);
    }
  };

  const handlePriceChange = (value) => {
    setValue("price", value);

    if (value && quantity) {
      setValue("sum", value * quantity);
    }
  };

  const handleSumChange = (value) => {
    setValue("sum", value);

    if (value && quantity) {
      setValue("price", value / quantity);
    }
  };

  const onSubmit = (values) => {
    const body = {
      product_id: values.product,
      num: Number(values.quantity),
      amount: Number(values.quantity),
      price: Number(values.price),
      total: Number(values.sum),
      note: values.comment,
      refund: returnInvoice,
      real_price: Number(values.real_price),
      risk: Number(values.risk),
    };

    create.mutate({
      link: endpoints.invoices.incomeProductCreate,
      params: { invoice_id: invoiceId },
      method: "post",
      body,
    });
  };

  return (
    <div className="w-[600px]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <ControlledSelect
              label={t("pages.betweenWarehouse.product")}
              name="product"
              errors={errors}
              autoFocus={true}
              height="50px"
              isRequired={true}
              placeholder={t("pages.admInvoice.form.productPlaceholder")}
              className="w-full  placeholder-darkGreyLight rounded-lg focus:border-purple-0 focus:bg-white focus:border-2 font-[16px] max-w-[100%]"
              options={productOptions}
              onInputChange={(value) => debouncedHandleInputChange(value)}
              onChange={(e) => {
                setValue("product", e.value);
              }}
            />
          </div>

          <div className="col-span-1">
            <NumericFormat
              value={quantity}
              isRequired={true}
              fieldName="quantity"
              label={t("pages.admInvoice.table.quantity")}
              placeholder={t("pages.admInvoice.form.quantityPlaceholder")}
              name="quantity"
              errors={errors}
              register={register}
              hasError={errors.quantity}
              className="w-full h-[50px] rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey"
              //
              decimalScale={0} // Number of decimal places
              thousandSeparator=""
              fixedDecimalScale={false}
              allowNegative={false} // Set to true if negative values should be allowed
              onValueChange={(values) => {
                handleQuantityChange(values.value);
              }}
              customInput={ControlledInput}
            />
          </div>
          <div className="col-span-1">
            <NumericFormat
              value={price}
              fieldName="price"
              label={t("pages.sale.price")}
              placeholder={t("pages.admInvoice.form.pricePlaceholder")}
              name="price"
              errors={errors}
              register={register}
              hasError={errors.price}
              rules={{ required: true }}
              className="w-full h-[50px] rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey"
              //
              decimalScale={2} // Number of decimal places
              thousandSeparator=" "
              fixedDecimalScale={false}
              allowNegative={false} // Set to true if negative values should be allowed
              onValueChange={(values) => {
                handlePriceChange(values.value);
              }}
              customInput={ControlledInput}
            />
          </div>
          <div className="col-span-1">
            <NumericFormat
              value={sum}
              fieldName="sum"
              label={t("pages.admInvoice.table.sum")}
              placeholder={t("pages.admInvoice.form.sumPlaceholder")}
              name="sum"
              errors={errors}
              register={register}
              hasError={errors.sum}
              className="w-full h-[50px] rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey"
              readOnly={true}
              onFocus={(e) => e.target.blur()}
              decimalScale={2} // Number of decimal places
              thousandSeparator=" "
              fixedDecimalScale={false}
              allowNegative={false} // Set to true if negative values should be allowed
              onValueChange={(values) => {
                handleSumChange(values.value);
              }}
              customInput={ControlledInput}
            />
          </div>

          <div className="col-span-1">
            <NumericFormat
              isRequired={true}
              fieldName="real_price"
              label={t("pages.admInvoice.form.realPrice")}
              placeholder={t("pages.admInvoice.form.pricePlaceholder")}
              name="real_price"
              errors={errors}
              register={register}
              hasError={errors.real_price}
              className="w-full h-[50px] rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey"
              //
              decimalScale={0} // Number of decimal places
              thousandSeparator=" "
              fixedDecimalScale={false}
              allowNegative={false} // Set to true if negative values should be allowed
              onValueChange={(values) => {
                setValue("real_price", values.value);
              }}
              customInput={ControlledInput}
            />
          </div>
          <div className="col-span-1">
            <NumericFormat
              isRequired={true}
              fieldName="risk"
              label={t("pages.admInvoice.form.risk")}
              placeholder={t("pages.admInvoice.form.riskPlaceholder")}
              name="places"
              errors={errors}
              register={register}
              hasError={errors.places}
              className="w-full h-[50px] rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey"
              //
              decimalScale={0} // Number of decimal places
              thousandSeparator=" "
              fixedDecimalScale={false}
              allowNegative={false} // Set to true if negative values should be allowed
              onValueChange={(values) => {
                setValue("risk", values.value);
              }}
              customInput={ControlledInput}
            />
          </div>

          <div className="col-span-2">
            <TextArea
              fieldName="comment"
              label={t("info.comment")}
              placeholder={t("pages.admInvoice.form.commentPlaceholder")}
              name="comment"
              errors={{}}
              register={register}
              className="w-full py-3 px-4 rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey h-[50px]"
            />
          </div>

          <div className="col-span-2">
            <Checkbox
              label={t("pages.sale.return")}
              labelClassName="text-[14px] ml-[14px]"
              name="return"
              className="w-full h-[50px] rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey"
              value={returnInvoice}
              onChange={(e) => {
                setReturnInvoice(e.target.checked);
              }}
            />
          </div>

          <div className="col-span-2 flex justify-between">
            <BaseButton
              label={t("button.cancel")}
              onClick={clearAndClose}
              className="p-2 btn-secondary dark:btn-secondary-dark"
            />

            <BaseButton
              icon={IconSave}
              label={t("button.save")}
              type="submit"
              className="col-span-1 bg-mainGreen text-white font-semibold"
              isLoading={create.isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
});

export default ModalAddInvoice;
