import React, { useState, useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useCoreStore } from "store/core";
// api
import { useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import WareHouseList from "./components/WareHouseList";
import TableWrapper from "./components/TableWrapper";
import Modal from "components/Modal/Modal";
import ModalForm from "./components/ModalForm";
import ModalFormProduct from "./components/ModalFormProduct";
import ModalFormComment from "./components/ModalFormComment";

const BetweenWarehouse = () => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();

  const [type, setType] = useState("out");
  const [comment, setComment] = useState("");
  const [commentType, setCommentType] = useState("");
  const [oneInvoice, setOneInvoice] = useState(null);
  const [reverse, setReverse] = useState(false);
  const [warehouseInvoiceList, setWarehouseInvoiceList] = useState([]);

  const modal = useRef();
  const modalProduct = useRef();
  const modalComments = useRef();

  const invoiceId = searchParams.get("invoice_id");

  const { orgId, userData } = useCoreStore((state) => state);

  const { data: warehouseInvoiceListApi } = useGet({
    link: endpoints.warehouseInvoice.list,
    params: { org_id: orgId, owner_warehouse_id: userData.warehouse_id },
  });

  const { data: oneInvoiceApi } = useGet({
    link: endpoints.warehouseInvoice.oneDetailed,
    params: { id: invoiceId },
    enabled: !!invoiceId,
  });

  useEffect(() => {
    if (warehouseInvoiceListApi?.length) setWarehouseInvoiceList(warehouseInvoiceListApi);
  }, [warehouseInvoiceListApi]);

  useEffect(() => {
    if (warehouseInvoiceList?.length) setSearchParams({ invoice_id: warehouseInvoiceList?.[0]?.id });
  }, [warehouseInvoiceList]);

  useEffect(() => {
    setOneInvoice(oneInvoiceApi);
  }, [oneInvoiceApi]);

  const handleTemplatesModal = (value) => {
    modal.current.openModal(value);
  };

  const handleTemplatesModalProduct = (value) => {
    modalProduct.current.openModal(value);
  };

  const handleTemplatesModalComments = (value) => {
    modalComments.current.openModal(value);
  };

  return (
    <div className="flex flex-row w-full h-full justify-between">
      <WareHouseList
        handleTemplatesModal={handleTemplatesModal}
        warehouseInvoiceList={warehouseInvoiceList}
        setWarehouseInvoiceList={setWarehouseInvoiceList}
      />
      <TableWrapper
        handleTemplatesModalProduct={handleTemplatesModalProduct}
        handleTemplatesModalComments={handleTemplatesModalComments}
        reverse={reverse}
        setReverse={setReverse}
        setComment={setComment}
        setCommentType={setCommentType}
        type={type}
        setType={setType}
        oneInvoice={oneInvoice}
        setOneInvoice={setOneInvoice}
      />

      <Modal ref={modal} title={t("pages.betweenWarehouse.title")}>
        <ModalForm
          close={handleTemplatesModal}
          setWarehouseInvoiceList={setWarehouseInvoiceList}
          warehouseInvoiceList={warehouseInvoiceList}
        />
      </Modal>

      <Modal ref={modalProduct} title={t("pages.betweenWarehouse.addProduct")}>
        <ModalFormProduct
          reverse={reverse}
          close={handleTemplatesModalProduct}
          setOneInvoice={setOneInvoice}
          type={type}
        />
      </Modal>

      <Modal ref={modalComments} title={t("info.comment")}>
        <ModalFormComment
          reverse={reverse}
          close={handleTemplatesModalComments}
          oneInvoice={oneInvoice}
          setOneInvoice={setOneInvoice}
          comment={comment}
          commentType={commentType}
        />
      </Modal>
    </div>
  );
};

export default BetweenWarehouse;
