import cls from "classnames";
import CircleLoader from "components/Loader/CircleLoader";
import { useCoreStore } from "store/core";

function TextArea(props) {
  const { theme } = useCoreStore();
  const {
    fieldName,
    register,
    label,
    className,
    errors,
    placeholder,
    isRequired,
    validations,
    defaultValue,
    isLoading = false,
    refData,
    leftElement,
    rightElement,
    autoFocus,
    disabled = false,
  } = props;

  const { ref, ...rest } = {
    ...register(fieldName, {
      required: {
        value: isRequired,
        message: "This is required",
      },
      ...validations,
    }),
  };

  return (
    <div className="mb-2">
      {label && (
        <label
          className={cls("block text-stone-800 dark:text-white font-semibold text-[14px]", {
            "required-field": isRequired,
          })}
          htmlFor={fieldName}
        >
          {label}
        </label>
      )}
      <div className="relative">
        {leftElement && <div className="absolute left-3 top-1/2 -translate-y-1/2">{leftElement}</div>}
        <textarea
          defaultValue={defaultValue}
          placeholder={placeholder}
          autoFocus={autoFocus}
          disabled={disabled}
          className={cls(
            `input bg-GreyInput placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 
            focus:bg-white dark:focus:border-purple-2 focus:border-2 dark:text-midGrey dark:border-blue-3 dark:bg-inputBgDark overflow-hidden min-h-[55px] max-h-[200px] overflow-y-auto`,
            className,
            theme ? "custominput-dark" : "custominput-light",
            {
              "pl-9": leftElement,
              "pr-9": rightElement,
            },
          )}
          {...rest}
          ref={(e) => {
            ref(e);
            if (refData) refData.current = e;
          }}
        />
        {isLoading ? (
          <div className="absolute right-3 top-1/2 -translate-y-1/2">
            <CircleLoader theme="primary" />
          </div>
        ) : null}
        {rightElement && <div className="absolute right-3 top-1/2 -translate-y-1/2">{rightElement}</div>}
      </div>
      {errors[fieldName] && <p className="error-message mt-1 text-red-500 text-[14px]">{errors[fieldName].message}</p>}
    </div>
  );
}

export default TextArea;
