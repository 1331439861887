import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { NumericFormat } from "react-number-format";
// store
import { useCoreStore } from "store/core";
// helpers
import { debounce, formatPrice } from "services/helpers";
// api
import { endpoints } from "services/endpoints";
import { useGet, useMutate } from "hooks/query";
// i18n
import { useTranslation } from "react-i18next";
// components
import { SaleContext } from "../Sale";
import BaseTd from "components/Table/BaseTd";
import { notifySuccess } from "components/Toast";
// icons
import iconTrash from "assets/images/svg/trash-red.svg";

const ProductItem = React.memo(({ item, closeDeleteModal }) => {
  const { type, productSaleInfo, setProductSaleInfo } = useContext(SaleContext);

  const { t } = useTranslation();

  const { orgId } = useCoreStore((state) => state);

  const [course, setCourse] = useState(1);

  const cutString = (str, max) => {
    if (str?.length <= max) return str;
    return str?.slice(0, max) + "...";
  };

  const uzsInput = useRef(null);
  const usdInput = useRef(null);

  const { data: courseApi } = useGet({
    link: endpoints.exchangeRate.last,
    params: { org_id: orgId, base_currency: "USD", currency: "UZS" },
  });

  useEffect(() => {
    if (courseApi) {
      setCourse(Number(courseApi?.amount));
    }
  }, [courseApi]);

  const productPayment =
    type === "return"
      ? productSaleInfo?.income_products?.find((payment) => payment.id === item.id)
      : productSaleInfo?.outgo_products?.find((payment) => payment.id === item.id);

  const handlePaymentType = (paymentType) => {
    if (type === "out") {
      setProductSaleInfo((prev) => {
        const newProductSaleInfo = prev?.outgo_products?.map((payment) =>
          payment.id === item.id ? { ...payment, paymentType } : payment,
        );
        return { ...prev, outgo_products: newProductSaleInfo };
      });
    }
    if (type === "return") {
      setProductSaleInfo((prev) => {
        const newProductSaleInfo = prev?.income_products?.map((payment) =>
          payment.id === item.id ? { ...payment, paymentType } : payment,
        );
        return { ...prev, income_products: newProductSaleInfo };
      });
    }
  };

  const edit = useMutate({
    onSuccess: (res) => {
      notifySuccess(t("notification.successProductUpdate"));
      // update amount and price of product in singleInvoice
      // if (type === "return") {
      //   const newSingleInvoice = { ...singleInvoice };
      //   newSingleInvoice.income_products = newSingleInvoice.income_products.map((item) => {
      //     if (item.id === res.id) {
      //       return {
      //         ...item,
      //         amount: res.amount,
      //         price: res.price,
      //       };
      //     }
      //     return item;
      //   });
      //   updateSingleInvoice(newSingleInvoice);
      // } else {
      //   const newSingleInvoice = { ...singleInvoice };
      //   newSingleInvoice.outgo_products = newSingleInvoice.outgo_products.map((item) => {
      //     if (item.id === res.id) {
      //       return {
      //         ...item,
      //         amount: res.amount,
      //         price: res.price,
      //       };
      //     }
      //     return item;
      //   });
      //   updateSingleInvoice(newSingleInvoice);
      // }
    },
  });

  const updateProductData = (key, value) => {
    // Determine the correct endpoint based on the table
    const endpoint =
      type === "return"
        ? `${endpoints.invoices.updateIncomeProduct}?id=${item.id}`
        : `${endpoints.invoices.updateOutgoProduct}?id=${item.id}`;

    // Call the API with the new data
    edit.mutate({
      link: endpoint,
      method: "patch",
      body: { [key]: Number(value) },
    });
  };
  const debouncedUpdateProductData = useCallback(
    debounce((key, value) => {
      updateProductData(key, value);
    }, 300),
    [], // If updateProductData or any other dependencies don't change, this won't be recreated
  );

  // useEffect(() => {
  //   if (productPayment?.paymentType === "uzs" && uzsInput.current) {
  //     uzsInput.current.focus();
  //   } else if (productPayment?.paymentType === "usd" && usdInput.current) {
  //     usdInput.current.focus();
  //   }
  // }, [productPayment?.paymentType]);

  const handlePaymentUzs = (value) => {
    const newUsdValue = (value / course).toFixed(2);
    if (type === "out") {
      debouncedUpdateProductData("price", value);
      setProductSaleInfo((prev) => {
        const newProductSaleInfo = prev?.outgo_products?.map((payment) =>
          payment.id === item.id
            ? {
                ...payment,
                paymentUzs: value,
                paymentUsd: newUsdValue,
                paymentSumUzs: Number(value).toFixed(0) * item.amount,
                paymentSumUsd: (Number(value).toFixed(2) / course) * item.amount,
              }
            : payment,
        );
        return { ...prev, outgo_products: newProductSaleInfo };
      });
    }
    if (type === "return") {
      debouncedUpdateProductData("price", value);
      setProductSaleInfo((prev) => {
        const newProductSaleInfo = prev?.income_products?.map((payment) =>
          payment.id === item.id
            ? {
                ...payment,
                paymentUzs: value,
                paymentUsd: newUsdValue,
                paymentSumUzs: Number(value).toFixed(0) * item.amount,
                paymentSumUsd: (Number(value).toFixed(2) / course) * item.amount,
              }
            : payment,
        );
        return { ...prev, income_products: newProductSaleInfo };
      });
    }
  };

  const handlePaymentUsd = (value) => {
    const newUzsValue = (value * course).toFixed(0);
    if (type === "out") {
      debouncedUpdateProductData("price", newUzsValue);
      setProductSaleInfo((prev) => {
        const newProductSaleInfo = prev?.outgo_products?.map((payment) =>
          payment.id === item.id
            ? {
                ...payment,
                paymentUsd: value,
                paymentUzs: newUzsValue,
                paymentSumUzs: (value * course).toFixed(0) * item.amount,
                paymentSumUsd: value * item.amount,
              }
            : payment,
        );
        return { ...prev, outgo_products: newProductSaleInfo };
      });
    }
    if (type === "return") {
      debouncedUpdateProductData("price", newUzsValue);
      setProductSaleInfo((prev) => {
        const newProductSaleInfo = prev?.income_products?.map((payment) =>
          payment.id === item.id
            ? {
                ...payment,
                paymentUsd: value,
                paymentUzs: newUzsValue,
                paymentSumUzs: (value * course).toFixed(0) * item.amount,
                paymentSumUsd: value * item.amount,
              }
            : payment,
        );
        return { ...prev, income_products: newProductSaleInfo };
      });
    }
  };

  // handle amount change
  useEffect(() => {
    if (type === "out") {
      setProductSaleInfo((prev) => {
        const newProductSaleInfo = prev?.outgo_products?.map((payment) =>
          payment.id === item.id
            ? {
                ...payment,
                amount: item.amount,
                paymentSumUzs: (payment.paymentUzs * item.amount).toFixed(0),
                paymentSumUsd: (payment.paymentUsd * item.amount).toFixed(2),
              }
            : payment,
        );
        return { ...prev, outgo_products: newProductSaleInfo };
      });
    }
    if (type === "return") {
      setProductSaleInfo((prev) => {
        const newProductSaleInfo = prev?.income_products?.map((payment) =>
          payment.id === item.id
            ? {
                ...payment,
                amount: item.amount,
                paymentSumUzs: (payment.paymentUzs * item.amount).toFixed(0),
                paymentSumUsd: (payment.paymentUsd * item.amount).toFixed(2),
              }
            : payment,
        );
        return { ...prev, income_products: newProductSaleInfo };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.amount]);

  const tdStyles = "!text-[12px] py-[10px]";

  return (
    <tr className="hover:bg-GreyLight dark:hover:bg-inputBgDark">
      <BaseTd extraClass={tdStyles + " flex flex-row items-center"}>
        <span className="mr-[4px] col-span-4 text-[12px]">{cutString(item?.product_name, 18)}</span>
        <div className="col-span-2">
          <div className="bg-[#F2F2F2] dark:bg-[#1F1E25] dark:border-[transparent] rounded-lg py-[3px] w-[fit-content] px-[6px]">
            <span className="text-[#9B9DA3] font-semibold text-[12px]">{item?.code || 111}</span>
          </div>
        </div>
      </BaseTd>
      <BaseTd extraClass={tdStyles}>{item.amount}</BaseTd>
      <BaseTd extraClass={tdStyles}>
        <div className="inline-flex px-[4px] bg-[#FAFAFA] dark:bg-[#1F1E25] dark:border-[transparent] border-solid border-[1px] rounded-lg items-center py-[2px]">
          {/* <ControlledInput value={item.amount} className="w-[84px] h-[20px] font-xs p-0 bg-white"/> */}
          {productPayment?.paymentType === "uzs" ? (
            <>
              <NumericFormat
                defaultValue={productPayment?.paymentUzs}
                className="w-[84px] h-[20px] text-[14px] input p-[2px] mr-[8px] dark:bg-[#27262C] dark:border-[#36343E]"
                decimalScale={0}
                thousandSeparator=" "
                suffix={" сум"}
                fixedDecimalScale={false}
                allowNegative={false}
                ref={uzsInput}
                onValueChange={(values) => handlePaymentUzs(values.value)}
              />
              <span className="w-[55px] text-[14px] mr-[11px]" onClick={() => handlePaymentType("usd")}>
                {!!(productPayment?.paymentUsd && productPayment?.paymentUzs) &&
                  formatPrice(productPayment?.paymentUsd)}
                $
              </span>
            </>
          ) : (
            <>
              <span
                className="w-[84px] text-[14px] mr-[11px] whitespace-nowrap"
                onClick={() => handlePaymentType("uzs")}
              >
                {formatPrice(productPayment?.paymentUzs)} сум
              </span>
              <NumericFormat
                defaultValue={productPayment?.paymentUsd}
                className="w-[55px] h-[20px] text-[14px] input p-[2px] mr-[8px] dark:bg-[#27262C] dark:border-[#36343E]"
                decimalScale={2} // Number of decimal places
                thousandSeparator=" "
                suffix={"$"}
                fixedDecimalScale={false}
                allowNegative={false} // Set to true if negative values should be allowed
                ref={usdInput}
                onValueChange={(values) => handlePaymentUsd(values.value)}
              />
            </>
          )}
        </div>
      </BaseTd>
      <BaseTd extraClass={tdStyles}>
        {productPayment?.paymentType === "uzs"
          ? `${formatPrice(productPayment?.paymentUzs * item.amount)} сум`
          : `${formatPrice(productPayment?.paymentUsd * item.amount)} $`}
      </BaseTd>
      <BaseTd extraClass={tdStyles}>
        <div className="flex items-center gap-4 justify-end">
          <div
            className="flex gap-2 p-2 cursor-pointer rounded-[4px] bg-GreyInput dark:bg-midBlack hover:bg-GreyInput dark:hover:bg-midBlack"
            onClick={() => closeDeleteModal(true, item?.id)}
          >
            <img src={iconTrash} alt="delete" />
          </div>
        </div>
      </BaseTd>
    </tr>
  );
});

const Tbody = ({ data, closeDeleteModal }) => {
  const { type } = useContext(SaleContext);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (type === "return") {
      setProducts(data?.income_products);
    } else {
      setProducts(data?.outgo_products);
    }
  }, [data, type]);

  if (!data) return null;

  return (
    <>
      {products?.map((item) => (
        <ProductItem key={item.id} item={item} closeDeleteModal={closeDeleteModal} />
      ))}
    </>
  );
};

export default Tbody;
