import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";
// store
import { useCoreStore } from "store/core";
// helpers
import { logOut } from "services/helpers";
// i18n
import { useTranslation } from "react-i18next";
// icons
import SidebarLogo from "assets/images/svg/icon-logo-sidebar.svg";
import LogoDark from "assets/images/svg/logo-dark.svg";
import LogoutIcon from "assets/images/svg/logout-icon.svg";
// components
import MenuList from "./MenuList";

const NavigatorBase = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const logoutUser = () => {
    logOut();
    navigate("/login");
    setOrgId("");
  };

  const { isOpenSidebar, toggleSidebar, setOrgId, theme } = useCoreStore(
    (state) => ({
      isOpenSidebar: state.isOpenSidebar,
      toggleSidebar: state.toggleSidebar,
      setOrgId: state.setOrgId,
      theme: state.theme,
    }),
    shallow,
  );

  return (
    <>
      <div className="pt-7 pr-4 flex justify-between items-center pl-6">
        {!isOpenSidebar && <img alt="logo" className="w-40" src={theme ? LogoDark : SidebarLogo} />}
        <div className="flex flex-col gap-1 pl-1 -ml-[5px] items-end cursor-pointer" onClick={toggleSidebar}>
          <span className="w-5 h-[3px] rounded-sm bg-[#535456]"></span>
          <span className="w-4 h-[3px] rounded-sm bg-[#535456]"></span>
          <span className="w-5 h-[3px] rounded-sm bg-[#535456]"></span>
        </div>
      </div>
      <div className="pt-5">
        {" "}
        <MenuList />
      </div>
      <div className="w-full py-2 absolute bottom-1 group bg-[#f2f2f2] dark:bg-mainGreyDark">
        <div className="flex justify-start items-center pl-6 mb-3 hover:cursor-pointer" onClick={logoutUser}>
          <img src={LogoutIcon} alt="LogoutIcon" className="w-5" />
          {!isOpenSidebar && (
            <p className="text-[#535456] ml-3 text-[18px] dark:text-[#8B8999] hover:text-[#FF705C] group-hover:text-[#FF705C]">
              {t("button.logout")}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default NavigatorBase;
