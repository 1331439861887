import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import useSearch from "hooks/useSearch/useSearch";

// api
import useGetSingleData from "hooks/useGetSingleData/useGetSingleData";
import { endpoints } from "services/endpoints";
import { useGet } from "hooks/query";
// store
import { useCoreStore } from "store/core";
// i18n
import { useTranslation } from "react-i18next";
// components
import Modal from "components/Modal/Modal";
import ModalForm from "./ModalForm";
import Table from "./Table";

const CategoryFirst = () => {
  const { t } = useTranslation();
  let [searchParams, _] = useSearchParams();
  let queryId = searchParams.get("row_id1");
  const modal1 = useRef();
  const form1 = useRef();
  const { orgId } = useCoreStore((state) => state);
  const { searchResult, handleSearch } = useSearch();

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [categories, setCategories] = useState([]);

  const {
    data: categoriesApi,
    isLoading,
    isError,
    refetch,
  } = useGet({
    link: endpoints.category.get,
    params: { org_id: orgId, page, size: 11, q: searchResult || undefined },
  });
  const { singleData } = useGetSingleData(queryId, categories);

  useEffect(() => {
    if (categoriesApi) {
      setCategories((prev) => [...prev, ...categoriesApi.data]);
      setHasMore(categoriesApi.data.length > 0);
    }
  }, [categoriesApi]);

  const isScrollAtBottom = (element) => {
    return element.scrollHeight - element.scrollTop === element.clientHeight;
  };

  // Scroll event handler
  const handleScroll = (event) => {
    const { currentTarget: target } = event;

    if (isScrollAtBottom(target) && !isLoading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleTemplatesModal = (value) => {
    modal1.current.openModal(value);
  };

  useEffect(() => {
    if (queryId && modal1.current) {
      handleTemplatesModal(true);
    }
  }, [queryId]);

  const removeImages = () => {
    form1.current.removeImages();
  };

  if (isLoading) return <p>Loading</p>;
  if (isError) return <p>Errror</p>;

  return (
    <>
      <Table
        data={categories}
        refetch={refetch}
        modalToggler={handleTemplatesModal}
        handleChange={handleSearch}
        handleScroll={handleScroll}
      />
      <Modal ref={modal1} title={t("pages.categories.new")} close={removeImages} rowId="row_id1">
        <ModalForm
          ref={form1}
          close={handleTemplatesModal}
          refetch={refetch}
          row={queryId && singleData ? singleData : {}}
          setCategories={setCategories}
          orgId={queryId ?? orgId}
        />
      </Modal>
    </>
  );
};

export default CategoryFirst;
