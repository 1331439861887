import { forwardRef } from "react";
import cls from "classnames";

import { Tooltip } from "components/Tooltip/Tooltip";

export const FormCheckbox = forwardRef((props, ref) => {
  const { label, hasDots = false, hasTooltip = false, errors, name, ...rest } = props;

  return (
    <>
      <div className="flex items-center">
        <input
          ref={ref}
          id={label}
          type="checkbox"
          name={name}
          className="w-4 h-4 text-purple-1 accent-purple-1 hover:accent-purple-1 bg-gray-100 border-gray-300 rounded dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          {...rest}
        />
        {hasTooltip ? (
          <Tooltip
            className="left-[-10%] !max-w-[180px]"
            content={<div className="break-word text-textLight text-[14px]">{label}</div>}
          >
            <label
              htmlFor={label}
              className={cls("ml-2 text-sm font-medium text-gray-900 dark:text-gray-300", {
                "text-ellipsis whitespace-nowrap overflow-hidden w-[95%] inline-block": hasDots,
              })}
            >
              {label}
            </label>
          </Tooltip>
        ) : (
          <label htmlFor={label} className={cls("ml-2 text-sm font-medium text-gray-900 dark:text-gray-300")}>
            {label}
          </label>
        )}
      </div>
      {errors[name] && <p className="error-message mb-2 mt-1 text-red-500 text-[14px]">{errors[name].message}</p>}
    </>
  );
});
