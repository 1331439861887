import { forwardRef, useEffect } from "react";
// i18n
import { useTranslation } from "react-i18next";
// components
import Modal from "components/Modal/Modal";
import BaseButton from "components/Button/BaseButton";

const DeleteConfirmation = ({ cb, close, title, description, isLoading }, ref) => {
  const { t } = useTranslation();

  const submitConfirm = (e) => {
    // only if modal is open
    if (e.key === "Enter" && ref.current && ref.current.isModalOpen()) {
      cb();
    }
  };

  const closeModal = () => {
    close(false);
  };

  const cancelModal = (e) => {
    if (e.key === "Escape" && ref.current) {
      closeModal();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", submitConfirm);
    window.addEventListener("keydown", cancelModal);
    return () => {
      window.removeEventListener("keydown", submitConfirm);
      window.removeEventListener("keydown", cancelModal);
    };
  }, [cb, close, ref]);

  return (
    <Modal ref={ref} title={title || t("info.delete")}>
      <h4 className="dark:text-textDark text-xl text-regular">{description || t("info.sureDelete")}</h4>
      <div className="flex gap-6 mt-8">
        <BaseButton
          label={t("button.no")}
          onClick={() => close(false)}
          className="flex-1 p-2 btn-secondary dark:btn-secondary-dark"
        />
        <BaseButton label={t("button.yes")} className="flex-1 p-2 btn-primary" onClick={cb} isLoading={isLoading} />
      </div>
    </Modal>
  );
};

export default forwardRef(DeleteConfirmation);
