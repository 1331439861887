import React, { useState, memo, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cls from "classnames";

import { useCoreStore } from "store/core";

const MenuItem = memo(({ menu, extraClass, isMenu = true, child = false, categoryIcon = false }) => {
  const [activeMenu, setActiveMenu] = useState(false);
  const location = useLocation();
  const isOpenSidebar = useCoreStore((state) => state.isOpenSidebar);

  useEffect(() => {
    // Update the state when the location changes
    setActiveMenu(location.pathname === menu.to);
  }, [location, menu.to]);

  const linkClass = cls(extraClass, "menu-list--item text-blue-2 dark:text-[#8B8999]", {
    sidebar_opened: isOpenSidebar,
    "activeLink dark:bg-inputBgDark dark:!text-purple-1": activeMenu && isMenu,
  });

  return (
    <NavLink
      to={menu.to}
      className={linkClass}
      onClick={(e) => {
        if (activeMenu) {
          e.preventDefault(); // Prevent the default behavior if the link is disabled
        }
      }}
    >
      <div>{activeMenu && menu?.iconActive && isMenu ? menu.iconActive : menu?.icon}</div>

      {!child && !menu?.icon && <img alt="home" className="w-5 h-5" src={menu.img} />}
      {categoryIcon && (
        <img alt="home" className="w-5 h-5" src="/static/media/menu-products.2ca3f79f95133c0f34151bc7e2d06b3e.svg" />
      )}
      <span
        className={cls("transition-all", {
          "hidden bg-textDark before:bg-textDark !z-[500] text-white dark:bg-inputBgDark dark:before:bg-inputBgDark dark:text-[#8B8999]":
            isOpenSidebar,
          "ml-2": child,
        })}
      >
        {menu.label}
      </span>
    </NavLink>
  );
});

export default MenuItem;
