import { useMemo, useRef, useContext } from "react";
import { useSearchParams } from "react-router-dom";
// hooks
import { checkHasAccessToAction } from "services/helpers";
// store
import { useCoreStore } from "store/core";
// api
import { endpoints } from "services/endpoints";
import { useMutate } from "hooks/query";
// context
import { ProductGroupsContext } from "../ProductGroupsLayout";
// helpers
import { useTranslatedTableConfig } from "../Helpers";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";
import DndTable from "components/Table/DndTable";
import ActionButton from "components/Button/ActionButton";
import DeleteConfirmation from "container/DeleteConfirmation";
// icons
import Plus from "assets/images/svg/icon-add.svg";
import PlusWhite from "assets/images/svg/icon-add-theme.svg";
import iconTrash from "assets/images/svg/trash-red.svg";

const Table = ({ data = [], setProductGroups, modalToggler, handleSearch, handleScroll }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const { header } = useTranslatedTableConfig();
  const { theme } = useCoreStore();
  const closeModal = useRef();
  const deleteId = useRef();
  // eslint-disable-next-line no-unused-vars
  let [_, setSearchParams] = useSearchParams();
  const { parentIds, setParentIds } = useContext(ProductGroupsContext);

  const hasAccessToCreate = checkHasAccessToAction("create-product-group");
  const hasAccessToEdit = checkHasAccessToAction("update-product-group");
  const hasAccessToDelete = checkHasAccessToAction("delete-product-group");

  const closeDeleteModal = (value) => {
    closeModal.current.openModal(value);
  };

  const sortCategory = useMutate({
    onSuccess: () => {
      notifySuccess(t("notification.groupSuccessReorder"));
    },
  });

  const onListChange = (data) => {
    sortCategory.mutate({
      link: `${endpoints.productGroup.sort}`,
      method: "patch",
      body: data,
    });
  };

  const deleteCategory = useMutate({
    onSuccess: () => {
      setProductGroups((prev) => prev.filter((el) => el.id !== deleteId.value));
      closeDeleteModal(false);
      notifySuccess(t("notification.successDelete"));
    },
  });

  const confirmDelete = () => {
    deleteCategory.mutate({
      link: `${endpoints.productGroup.delete}?id=${deleteId.value}`,
      method: "delete",
    });
  };

  const tableData = useMemo(() => {
    return data.map((el, index) => {
      return {
        index,
        id: el.id,
        row: [
          {
            title: (
              <div
                className="hover:text-blue-400 cursor-pointer"
                onClick={() => setParentIds((prev) => ({ ...prev, parentId: el.id }))}
              >
                {el.name[lang]}{" "}
                <span className="ml-[5px] bg-[#F2F2F2] text-[#9B9DA3] rounded-[4px] py-[1px] px-[3px] text-[14px]">
                  {el.property_count || 0}
                </span>
              </div>
            ),
          },
          {
            title: (
              <div className="flex gap-4 items-center justify-end">
                {hasAccessToEdit && (
                  <ActionButton type="edit" handleClick={() => setSearchParams({ row_id1: el.id })} />
                )}
                {hasAccessToDelete && (
                  <img
                    src={iconTrash}
                    alt="delete"
                    className="cursor-pointer"
                    onClick={() => {
                      deleteId.value = el.id;
                      closeDeleteModal(true, el.id);
                    }}
                  />
                )}
              </div>
            ),
          },
        ],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div className="h-full relative flex flex-col">
        <div className="h-[calc(100vh-350px)]">
          <DndTable
            header={header}
            body={tableData}
            add={modalToggler}
            addable={true}
            isSearchable={true}
            searchPlaceholder={`${t("pages.productGroups.search")}...`}
            hasBackground={false}
            handleSearch={handleSearch}
            orderChange={onListChange}
            activeRowId={parentIds.parentId}
            handleScroll={handleScroll}
          />
        </div>

        <div className="w-full px-2 z-10 mt-[10px]">
          {hasAccessToCreate && (
            <BaseButton
              label={t("button.create")}
              onClick={modalToggler}
              icon={theme ? PlusWhite : Plus}
              className="w-full whitespace-nowrap btn-transparent dark:btn-transparent-dark dark:text-white"
            />
          )}
        </div>
      </div>
      <DeleteConfirmation ref={closeModal} close={closeDeleteModal} cb={confirmDelete} />
    </>
  );
};

export default Table;
