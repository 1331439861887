import { useContext } from "react";
import { SaleContext } from "../Sale";
// api
import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// store
import { useCoreStore } from "store/core";
import { useSearchParams } from "react-router-dom";
// components
import InvoiceTab from "./InvoiceTab";
// icons
import IconPlus from "assets/images/svg/icon-plus.svg";
import IconPlusDark from "assets/images/svg/icon-plus-dark.svg";

const InvoiceTabs = ({ tabs, currentInvoice, setCurrentInvoice }) => {
  const { orgId, theme } = useCoreStore((state) => state);
  const [searchParams, setSearchParams] = useSearchParams();
  const { invoices, setInvoices, setSelectedManager } = useContext(SaleContext);

  const create = useMutate({
    onSuccess: (res) => {
      setSelectedManager(res.manager);
      setInvoices((prev) => [res, ...prev]);
      setSearchParams({ invoice_id: res.id });
    },
  });

  const createInvoice = () => {
    create.mutate({
      link: endpoints.invoices.sale,
      params: {
        org_id: orgId,
      },
      method: "post",
    });
  };

  return (
    <>
      <div
        className="bg-[#FAFAFA] dark:bg-[#1F1E25] w-[100%] h-[54px] flex flex-row p-[5px] rounded-lg overflow-x-auto overflow-y-hidden 
      box-shadow-[0px 2px 4px rgba(0, 0, 0, 0.05)]"
      >
        {invoices?.map((invoice) => (
          <InvoiceTab invoice={invoice} key={invoice.id} />
        ))}
        <div
          className="w-[80px] min-w-[80px] h-[27px] border-dashed border-2 border-[#D1D1D1] dark:border-[#9B9DA3] self-center rounded-lg flex items-center justify-center cursor-pointer"
          onClick={createInvoice}
        >
          <img src={theme ? IconPlusDark : IconPlus} alt="Add invoice" className="w-[14px] h-[14px] opacity-50" />
        </div>
      </div>
    </>
  );
};

export default InvoiceTabs;
