import * as Yup from "yup";

// i18n
import { useTranslation } from "react-i18next";

export const useTranslatedTableConfig = () => {
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    name: Yup.string().required("name kiriting"),
    description: Yup.string().required("description kiriting"),
  });

  const header = [
    {
      key: "name",
      title: t("pages.roles.table.name"),
      width: "w-[20%]",
    },
    {
      key: "description",
      title: t("pages.roles.table.description"),
      width: "w-[20%]",
    },
    {
      key: "permissions",
      title: t("pages.roles.table.permissions"),
      width: "w-[45%]",
    },
    {
      key: "empty",
      title: "",
      width: "w-[15%]",
    },
  ];

  return { validationSchema, header };
};
