import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
// helpers
import { checkHasAccessToAction } from "services/helpers";
// components
import BaseButton from "components/Button/BaseButton";
import BaseTd from "components/Table/BaseTd";
import ControlledCheckbox from "components/Checkbox/ControlledCheckbox";
// icons
import iconTrash from "assets/images/svg/trash-red.svg";
import IconEdit from "assets/images/svg/edit-icon.svg";

const Tbody = ({ data, isChecked, checkboxes, setCheckboxes, closeDeleteModal }) => {
  // eslint-disable-next-line no-unused-vars
  let [_, setSearchParams] = useSearchParams();

  const hasAccessToEdit = checkHasAccessToAction("update-course");
  const hasAccessToDelete = checkHasAccessToAction("delete-course");

  const selectTableRow = (rowId) => {
    setCheckboxes({ ...checkboxes, [rowId]: !checkboxes[rowId] });
  };

  useEffect(() => {
    const objState = {};
    if (isChecked) {
      data.forEach((item) => {
        objState[item.id] = true;
      });
      setCheckboxes(objState);
    } else {
      const filterByTrue = Object.values(checkboxes).filter((el) => el);
      if (filterByTrue.length === data.length) setCheckboxes({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  return (
    <>
      {data.map((item) => (
        <tr key={item.id} className="hover:bg-GreyLight dark:hover:bg-inputBgDark">
          <BaseTd>
            <div className="flex items-center gap-2">
              <ControlledCheckbox checked={checkboxes[item.id] || false} onChange={() => selectTableRow(item.id)} />
              <span>{new Date(item.currency_date).toLocaleDateString("ru-RU").replace(/\//g, ".")}</span>
            </div>
          </BaseTd>
          <BaseTd>
            <div className="flex items-center gap-2">
              <span>{item.base_currency.toUpperCase()}</span>
            </div>
          </BaseTd>
          <BaseTd>
            <div className="flex items-center gap-2">
              <span>{item.currency_amount}</span>
            </div>
          </BaseTd>
          <BaseTd>
            <div className="flex items-center gap-4 justify-end">
              {hasAccessToEdit && (
                <BaseButton icon={IconEdit} className="p-[6px]" onClick={() => setSearchParams({ row_id: item.id })} />
              )}
              {hasAccessToDelete && (
                <div
                  className="flex gap-2 p-2 cursor-pointer rounded-[4px] bg-GreyInput dark:bg-midBlack hover:bg-GreyInput dark:hover:bg-midBlack"
                  onClick={() => closeDeleteModal(true, item.id)}
                >
                  <img src={iconTrash} alt="delete" />
                </div>
              )}
            </div>
          </BaseTd>
        </tr>
      ))}
    </>
  );
};

export default Tbody;
