// i18n
import { useTranslation } from "react-i18next";
// icons
import UserBlockGray from "assets/images/svg/icon-block-gray.svg";
import UserUnblock from "assets/images/svg/icon-unblock.svg";

const GetBlockImage = ({ state }) => {
  const { t } = useTranslation();

  return state === "INACTIVE" ? (
    <>
      <img src={UserUnblock} alt="UserUnblock" />
      <p className="text-textBlack dark:text-white text-sm">{t("button.unblock")}</p>
    </>
  ) : (
    <>
      <img src={UserBlockGray} alt="UserUnblock" />
      <p className="text-textBlack dark:text-white text-sm">{t("button.block")}</p>
    </>
  );
};

export default GetBlockImage;
