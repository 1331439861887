import { useTranslatedTableConfig } from "../Helpers";
// components
import Tbody from "./Tbody";
import BaseTable from "components/Table/BaseTable";
import BaseThead from "components/Table/BaseThead";

const Table = ({
  type,
  reverse,
  setComment,
  setCommentType,
  handleTemplatesModalComments,
  setOneInvoice,
  oneInvoice,
}) => {
  const { header } = useTranslatedTableConfig();

  let data;

  if (type === "out") {
    data = reverse ? oneInvoice.income_products : oneInvoice.outgo_products;
  } else {
    data = reverse ? oneInvoice.outgo_products : oneInvoice.income_products;
  }

  return (
    <BaseTable
      thead={<BaseThead header={header} />}
      tbody={
        <Tbody
          data={data}
          reverse={reverse}
          setComment={setComment}
          setCommentType={setCommentType}
          handleTemplatesModalComments={handleTemplatesModalComments}
          setOneInvoice={setOneInvoice}
          type={type}
        />
      }
    />
  );
};

export default Table;
