import React, { memo, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { set, useForm } from "react-hook-form";
// api
import { useGet, useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// hooks
import useDebounce from "hooks/useDebounce/useDebounce";
// i18n
import { useTranslation } from "react-i18next";
// components
import Input from "components/Input/Input";
import TextArea from "components/Textarea/TextArea";
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";

import IconSave from "assets/images/svg/icon-save.svg";

const ModalForm = memo(({ close, refetch, row, orgId }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchResult, setSearchResult] = useState();
  const [firstCode, setFirstCode] = useState();

  const rowId = searchParams.get("row_id");

  const {
    reset,
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  function removeId() {
    reset();
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }

  const code = watch("code");

  useDebounce(() => setSearchResult(code), [code], 500);

  const { data: check } = useGet({
    link: endpoints.warehouse.check,
    params: { org_id: orgId, code: searchResult },
    enabled: searchResult?.length >= 3,
  });

  useEffect(() => {
    if (rowId) {
      setValue("name", row.name);
      setValue("address", row.address);
      setValue("code", row.code);
      setFirstCode(row.code);
    } else {
      reset();
    }
  }, [row]);

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: () => {
      refetch();
      clearAndClose();
      notifySuccess(t("notification.warehouseSuccessCreate"));
    },
  });

  const update = useMutate({
    onSuccess: () => {
      refetch();
      clearAndClose();
      notifySuccess(t("notification.warehouseSuccessUpdate"));
    },
  });

  const onSubmit = (values) => {
    if (check?.contains && code !== firstCode) return;

    if (row.id) {
      update.mutate({
        link: `${endpoints.warehouse.update}?id=${row.id}`,
        method: "put",
        body: values,
      });
    } else {
      create.mutate({
        link: `${endpoints.warehouse.create}?org_id=${orgId}`,
        method: "post",
        body: values,
      });
    }
  };

  return (
    <div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5">
          <div>
            <Input
              label={t("form.code")}
              fieldName="code"
              placeholder={t("form.codePlaceholder")}
              autoFocus={true}
              register={register}
              errors={errors}
              isRequired={true}
              className="w-full"
            />
            {
              // check if code is already taken
              check?.contains && code !== firstCode && (
                <div className="text-red-500 text-[13px] mt-[4px]">
                  {t("form.code")} {check.code} {t("info.taken")}.
                </div>
              )
            }
          </div>

          <Input
            label={t("form.title")}
            fieldName="name"
            register={register}
            errors={errors}
            isRequired={true}
            className="w-full"
            placeholder={t("form.titlePlaceholder")}
          />
          <TextArea
            label={t("form.address")}
            type="text"
            fieldName="address"
            name="description"
            register={register}
            errors={errors}
            placeholder={t("form.addressPlaceholder")}
            className="w-full bg-GreyInput placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 focus:bg-white focus:border-2 h-[88px]"
          />
        </div>
        <div className="flex gap-6 mt-8">
          <BaseButton
            label={t("button.cancel")}
            onClick={clearAndClose}
            className="flex-1 p-2 btn-secondary dark:btn-secondary-dark"
          />
          <BaseButton
            type="submit"
            className="flex-1 p-2 btn-success"
            icon={IconSave}
            label={row.id ? t("button.update") : t("button.save")}
            isLoading={create.isLoading || update.isLoading}
          />
        </div>
      </form>
    </div>
  );
});

export default ModalForm;
