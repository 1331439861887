import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useForm } from "react-hook-form";
// store
import { useCoreStore } from "store/core";
// api
import { useMutate } from "hooks/query";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import Input from "components/Input/Input";
import BaseButton from "components/Button/BaseButton";
import AuthContainer from "./AuthContainer";
// icons
import Logo from "assets/images/svg/icon-login-logo.svg";
import ProfileIcon from "assets/images/svg/icon-input-profile.svg";
import ProfileIconDark from "assets/images/svg/icon-input-profile-dark.svg";
import LockIcon from "assets/images/svg/icon-input-lock.svg";
import LockIconDark from "assets/images/svg/icon-input-lock-dark.svg";
import CloseIcon from "assets/images/svg/icon-input-close.svg";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userNameRef = useRef(null);

  const { theme } = useCoreStore();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (localStorage.getItem("user_token")) {
      navigate("/home");
    }
  }, []);

  const loginRequest = useMutate({
    onSuccess: ({ credentials, ...rest }) => {
      const decoded = jwt_decode(credentials.access_token);
      localStorage.setItem("user_token", credentials.access_token);
      localStorage.setItem("refresh_token", credentials.refresh_token);
      localStorage.setItem("user_info", JSON.stringify(decoded));
      navigate("/");
      notifySuccess(t("notification.welcome"));
    },
    onError: () => {},
  });

  const onSubmit = async (values) => {
    loginRequest.mutate({
      method: "post",
      link: "/auth",
      params: values,
    });
  };

  const resetUsername = () => {
    setValue("username", "");
    if (userNameRef.current) userNameRef.current.focus();
  };

  return (
    <AuthContainer>
      <div className="w-full max-w-md flex flex-col gap-7 m-auto items-center">
        <div className="w-full flex flex-col justify-center items-center">
          <div>
            <h1 className="text-center text-bold text-blue-2 text-3xl">{t("pages.login.title")}</h1>
            <h4 className="text-base text-darkGreyLight text-light">{t("pages.login.typeData")}</h4>
          </div>
        </div>
        <form className="w-[292px]" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4">
            <Input
              refData={userNameRef}
              label={t("form.login")}
              fieldName="username"
              register={register}
              errors={errors}
              isRequired={true}
              placeholder={t("form.typeLogin")}
              className="w-full "
              leftElement={<img src={theme ? ProfileIconDark : ProfileIcon} alt="ProfileIcon" />}
              rightElement={<img src={CloseIcon} alt="CloseIcon" className="cursor-pointer" onClick={resetUsername} />}
            />
            <Input
              label={t("form.password")}
              type="password"
              fieldName="password"
              register={register}
              errors={errors}
              isRequired={true}
              placeholder={t("form.typePassword")}
              className="w-full bg-GreyInput placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 focus:bg-white focus:border-2"
              leftElement={<img src={theme ? LockIconDark : LockIcon} alt="LockIcon" />}
            />
          </div>
          <BaseButton
            label={t("button.enterSystem")}
            type="submit"
            className="w-full mt-6 p-4 btn-primary bg-purple-1 rounded-md"
            isLoading={loginRequest.isLoading}
          />
          <div className="flex justify-between items-center mt-5">
            <div className="text-[#696969]">
              <input type="checkbox" className="mr-1  cursor-pointer" />
              <span className="text-sm"> {t("button.rememberMe")} </span>
            </div>
            <p className="text-[#7B61FF] cursor-pointer hover:text-[#6354a7] text-sm">{t("button.registration")}</p>
          </div>
        </form>
        <div className="mt-12">
          <img src={Logo} alt="logo" />
        </div>
      </div>
    </AuthContainer>
  );
};

export default Login;
