export const exchangeOptions = [
  {
    label: "USD",
    value: "usd",
  },
  {
    label: "UZS",
    value: "uzs",
  },
  {
    label: "PER",
    value: "uzs_per",
  },
  {
    label: "TER",
    value: "uzs_term",
  },
];

export const baseCurrency = [
  {
    label: "USD",
    value: "usd",
  },
  {
    label: "EUR",
    value: "euro",
  },
];

export const invoiceStates = [
  {
    label: "Создано и сделано",
    value: "CREATED_DONE",
  },
  {
    label: "Создано",
    value: "CREATED",
  },
  {
    label: "Сделано",
    value: "DONE",
  },
  {
    label: "Архивировано",
    value: "ARCHIVED",
  },
];

export const invoiceMainStates = [
  {
    label: "Создано",
    value: "CREATED",
  },
  {
    label: "Сделано",
    value: "DONE",
  },
  {
    label: "Архивировано",
    value: "ARCHIVED",
  },
];

export const languages = [
  {
    label: "Русский",
    value: "ru",
  },
  {
    label: "O'zbekcha",
    value: "uz",
  },
];
