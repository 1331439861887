import Home from "pages/Home/Home";
import Organization from "pages/Organization/MainOrganizations/Organization";
import SubOrganization from "pages/Organization/SubOrganizations/SubOrganization";
import Roles from "pages/Security/Roles";
import Users from "pages/Security/Users/MainUsers";
import Permissions from "pages/Security/Permissions";
import WareHouse from "pages/Warehouse/WareHouse";
import BetweenWarehouse from "pages/BetweenWarehouse/BetweenWarehouse";
import SubUsers from "pages/Security/Users/SubUsers/SubUsers";
import CategoryLayout from "pages/Category/CategoryLayout";
import Tag from "pages/Tag/Tag";
import ParametrLayout from "pages/Parameter/ParametrLayout";
import ProductGroupsLayout from "pages/ProductGroups/ProductGroupsLayout";
import ExchangeRate from "pages/ExchangeRate/ExchangeRate";
import Customer from "pages/Customer/Customer";
import Profile from "pages/Profile/Profile";
import Products from "pages/Products/Products";
import Sale from "pages/Sale/Sale";
import AdminInvoices from "pages/Invoices/AdminInvoices/AdminInvoices";
import ClientInvoices from "pages/Invoices/ClientInvoices/ClientInvoices";

const clientRoutes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/organizations",
    component: <Organization />,
  },
];

const adminRoutes = [
  {
    path: "/home",
    component: <Home />,
    permissions: ["all"],
  },
  {
    path: "/organizations",
    component: <Organization />,
    permissions: ["organization-menu"],
  },
  {
    path: "/warehouse/:id",
    component: <WareHouse />,
    permissions: ["warehouse-menu", "app-root"],
  },
  {
    path: "/sub-organizations/:id",
    component: <SubOrganization />,
    permissions: ["sub-organization-menu", "app-root"],
  },
  {
    path: "/permissions",
    component: <Permissions />,
    permissions: ["permission-menu"],
  },
  {
    path: "/roles",
    component: <Roles />,
    permissions: ["roles-menu"],
  },
  {
    path: "/users",
    component: <Users />,
    permissions: ["user-menu"],
  },
  {
    path: "/sub-users/:id",
    component: <SubUsers />,
    permissions: ["sub-user-menu"],
  },
  {
    path: "/category",
    component: <CategoryLayout />,
    permissions: ["all"],
  },
  {
    path: "/tags",
    component: <Tag />,
    permissions: ["all"],
  },
  {
    path: "/parameters",
    component: <ParametrLayout />,
    permissions: ["all"],
  },
  {
    path: "/products",
    component: <Products />,
    permissions: ["all"],
  },
  {
    path: "/product-groups",
    component: <ProductGroupsLayout />,
    permissions: ["all"],
  },
  {
    path: "/kurs",
    component: <ExchangeRate />,
    permissions: ["all"],
  },
  {
    path: "/customers",
    component: <Customer />,
    permissions: ["all"],
  },
  {
    path: "/invoices",
    component: <ClientInvoices />,
    permissions: ["invoice-menu"],
  },
  {
    path: "/sale",
    component: <Sale />,
    permissions: ["all"],
  },
  {
    path: "/between-warehouse",
    component: <BetweenWarehouse />,
    permissions: ["warehouse-invoice-menu"],
  },

  {
    path: "/profile",
    component: <Profile />,
    permissions: ["all"],
  },
  {
    path: "/admin-invoices",
    component: <AdminInvoices />,
    permissions: ["admin-invoice-menu"],
  },
];

export { clientRoutes, adminRoutes };
