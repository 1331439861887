import React, { useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
// hooks
import { checkHasAccessToAction } from "services/helpers";
// store
import { useCoreStore } from "store/core";
// api
import { endpoints } from "services/endpoints";
import { useMutate } from "hooks/query";
// i18n
import { useTranslation } from "react-i18next";
// components
import BaseButton from "components/Button/BaseButton";
import { notifySuccess } from "components/Toast";
import DndTable from "components/Table/DndTable";
import ActionButton from "components/Button/ActionButton";
import DeleteConfirmation from "container/DeleteConfirmation";
// import { header } from "../Helpers";
// icons
import Plus from "assets/images/svg/icon-add.svg";
import PlusWhite from "assets/images/svg/icon-add-theme.svg";

const header = [
  {
    key: "name_uz",
    title: "Name Uz",
  },
  {
    key: "actions",
    title: "",
  },
];

const Table = ({ refetch, data = [], modalToggler, handleSearch }) => {
  const { t } = useTranslation();
  const closeModal = useRef();
  const deleteId = useRef();
  // eslint-disable-next-line no-unused-vars
  let [_, setSearchParams] = useSearchParams();

  const hasAccessToCreate = checkHasAccessToAction("create-property");
  const hasAccessToEdit = checkHasAccessToAction("update-property");
  const hasAccessToDelete = checkHasAccessToAction("delete-property");

  const { theme } = useCoreStore();

  const closeDeleteModal = (value) => {
    closeModal.current.openModal(value);
  };

  const sortCategory = useMutate({
    onSuccess: () => {
      notifySuccess(t("notification.valueSuccessReorder"));
    },
  });

  const onListChange = (data) => {
    sortCategory.mutate({
      link: `${endpoints.parameterValue.sort}`,
      method: "patch",
      body: data,
    });
  };

  const deleteCategory = useMutate({
    onSuccess: () => {
      refetch();
      closeDeleteModal(false);
      notifySuccess(t("notification.successDelete"));
    },
  });

  const confirmDelete = () => {
    deleteCategory.mutate({
      link: `${endpoints.parameterValue.delete}?id=${deleteId.value}`,
      method: "delete",
    });
  };

  const tableData = useMemo(() => {
    return data.map((el, index) => {
      return {
        index,
        id: el.id,
        row: [
          {
            title: el.name.uz + " | " + el.name.ru,
          },
          // {
          //   title: el.name.ru,
          // },
          {
            title: (
              <div className="flex gap-4 justify-end">
                {hasAccessToEdit && (
                  <ActionButton type="edit" handleClick={() => setSearchParams({ row_id2: el.id })} />
                )}
                {hasAccessToDelete && (
                  <ActionButton
                    type="delete-group"
                    handleClick={() => {
                      deleteId.value = el.id;
                      closeDeleteModal(true, el.id);
                    }}
                  />
                )}
              </div>
            ),
          },
        ],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div className="relative flex flex-col flex-1 h-full">
        <div className="flex-1 relative">
          <div className="w-full h-[calc(100vh-335px)] pb-[15px]">
            <DndTable
              header={header}
              body={tableData}
              add={modalToggler}
              addable={true}
              handleSearch={handleSearch}
              isSearchable={true}
              orderChange={onListChange}
            />
          </div>
        </div>

        <div className="w-full px-2">
          {hasAccessToCreate && (
            <BaseButton
              label={t("pages.parameters.newValue")}
              onClick={modalToggler}
              icon={theme ? PlusWhite : Plus}
              className="w-full whitespace-nowrap btn-transparent dark:btn-transparent-dark dark:text-white"
            />
          )}
        </div>
      </div>
      <DeleteConfirmation ref={closeModal} close={closeDeleteModal} cb={confirmDelete} />
    </>
  );
};

export default Table;
