import React, { useState, useEffect, useCallback, memo } from "react";
import { useSearchParams } from "react-router-dom";
import { set, useForm } from "react-hook-form";
// api
import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";
// icons
import IconSave from "assets/images/svg/icon-save.svg";
import Input from "components/Input/Input";

const ModalBoxCount = memo(({ archived, close, setInvoices, setSingleInvoice }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const rowId = searchParams.get("id");
  const num = searchParams.get("num");

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  function removeId() {
    reset();

    searchParams.delete("type");
    searchParams.delete("num");
    setSearchParams(searchParams);
  }

  useEffect(() => {
    setValue("box_count", num);
  }, [num, setValue]);

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: () => {
      const boxCount = getValues("box_count");
      setInvoices((prev) => {
        const newInvoices = prev.map((invoice) => {
          if (invoice.id === rowId) {
            return {
              ...invoice,
              box_count: Number(boxCount),
            };
          }
          return invoice;
        });
        return newInvoices;
      });

      setSingleInvoice((prev) => {
        if (prev.id === rowId) {
          return {
            ...prev,
            box_count: Number(boxCount),
          };
        }
        return prev;
      });

      reset();
      clearAndClose();
      notifySuccess(t("notification.succesApply"));
    },
  });

  const onSubmit = (values) => {
    const body = {
      box_count: Number(values.box_count) || 0,
    };
    create.mutate({
      link: `${endpoints.invoices.updateBoxCount}?id=${rowId}`,
      method: "patch",
      body,
    });
  };

  return (
    <div className="w-[350px]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          autoFocus
          disabled={archived}
          label={t("pages.betweenWarehouse.table.quantity")}
          fieldName="box_count"
          register={register}
          errors={errors}
          isRequired={true}
          className="w-full"
        />

        <div className={`${archived && "justify-end"} flex gap-6 mt-8`}>
          <BaseButton
            label={archived ? t("button.close") : t("button.cancel")}
            onClick={clearAndClose}
            className={`${archived ? "" : "flex-1"} p-2 btn-secondary dark:btn-secondary-dark`}
          />
          {!archived && (
            <BaseButton
              type="submit"
              className="flex-1 p-2 btn-success"
              icon={IconSave}
              label={t("button.save")}
              isLoading={create.isLoading}
            />
          )}
        </div>
      </form>
    </div>
  );
});

export default ModalBoxCount;
