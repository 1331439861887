import React, { memo, useEffect, useContext } from "react";
import { ParameterContext } from "../ParametrLayout";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// api
import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import Input from "components/Input/Input";
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";
import { FormCheckbox } from "components/Checkbox/FormCheckbox";
// icons
import IconSave from "assets/images/svg/icon-save.svg";

const ModalForm = memo(({ close, refetch, row, orgId }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const rowId = searchParams.get("row_id1");

  const { setParentIds } = useContext(ParameterContext);

  const {
    reset,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: {
        uz: "",
        ru: "",
      },
    },
  });

  function removeId() {
    reset();
    searchParams.delete("row_id1");
    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (rowId) {
      setValue("name.uz", row.name?.uz);
      setValue("name.ru", row.name?.ru);
      setValue("is_separator", row.is_separator);
    } else {
      reset();
    }
  }, [row]);

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: (res) => {
      refetch();
      clearAndClose();
      notifySuccess(t("notification.parameterSuccessCreate"));
      setParentIds({ parentId: res.id });
    },
  });

  const update = useMutate({
    onSuccess: () => {
      refetch();
      clearAndClose();
      notifySuccess(t("notification.parameterSuccessUpdate"));
    },
  });

  const onSubmit = (values) => {
    const body = {
      ...values,
      parent_id: null,
    };
    if (row.id) {
      update.mutate({
        link: `${endpoints.parameter.update}?id=${row.id}`,
        method: "put",
        body: body,
      });
    } else {
      create.mutate({
        link: `${endpoints.parameter.create}?org_id=${orgId}`,
        method: "post",
        body: body,
      });
    }
  };

  return (
    <div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5">
          <Input
            autoFocus
            label={t("form.titleUz")}
            placeholder={t("form.title")}
            fieldName="name.uz"
            register={register}
            errors={errors}
            isRequired={true}
            className="w-full"
          />
          <Input
            label={t("form.titleRu")}
            placeholder={t("form.title")}
            fieldName="name.ru"
            register={register}
            errors={errors}
            isRequired={true}
            className="w-full"
          />
          <FormCheckbox label={t("pages.parameters.separator")} errors={errors} {...register("is_separator")} />
        </div>
        <div className="flex gap-6 mt-8">
          <BaseButton
            label={t("button.cancel")}
            onClick={clearAndClose}
            className="flex-1 p-2 btn-secondary dark:btn-secondary-dark"
          />
          <BaseButton
            type="submit"
            className="flex-1 p-2 btn-success"
            icon={IconSave}
            label={row.id ? t("button.update") : t("button.save")}
          />
        </div>
      </form>
    </div>
  );
});

export default ModalForm;
