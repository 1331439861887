import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import cls from "classnames";
import { checkHasAccessToAction } from "services/helpers";
import { useGetManager } from "../hooks/useGetManager";

import BaseButton from "components/Button/BaseButton";
import BaseTd from "components/Table/BaseTd";
import { Tooltip } from "components/Tooltip/Tooltip";
import ControlledCheckbox from "components/Checkbox/ControlledCheckbox";
import Checkbox from "components/Checkbox/Checkbox";

import IconDefault from "assets/images/svg/icon-default.svg";
import IconEdit from "assets/images/svg/edit-icon.svg";
import UserBlock from "assets/images/svg/icon-block.svg";

const Tbody = ({ data, isChecked, checkboxes, setCheckboxes, customerToggleState }) => {
  // eslint-disable-next-line no-unused-vars
  let [_, setSearchParams] = useSearchParams();
  const orgManagerList = useGetManager();

  // const hasAccessToEdit = checkHasAccessToAction("update-customer");
  // const hasAccessToDelete = checkHasAccessToAction("delete-customer");
  const hasAccessToEdit = checkHasAccessToAction("update-customer");
  const hasAccessToDelete = checkHasAccessToAction("delete-customer");

  const selectTableRow = (rowId) => {
    setCheckboxes({ ...checkboxes, [rowId]: !checkboxes[rowId] });
  };

  const findManagerName = (id) => {
    return orgManagerList.find((manager) => manager.value === id)?.label ?? "";
  };

  useEffect(() => {
    const objState = {};
    if (isChecked) {
      data.forEach((item) => {
        objState[item.id] = true;
      });
      setCheckboxes(objState);
    } else {
      const filterByTrue = Object.values(checkboxes).filter((el) => el);
      if (filterByTrue.length === data.length) setCheckboxes({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  return (
    <>
      {data.map((item) => (
        <tr key={item.id} className="hover:bg-GreyLight dark:hover:bg-inputBgDark">
          <BaseTd>
            <div className="flex items-center gap-4">
              <ControlledCheckbox checked={checkboxes[item.id] || false} onChange={() => selectTableRow(item.id)} />
              <img
                src={item.image || IconDefault}
                alt="photos"
                className={cls("table--avatar", { "\rounded-full": !item.image_id })}
              />
              <div className="flex gap-2 items-center">
                <p className="flex items-center gap-2">
                  {item.name} <span className="bg-[#F2F2F2] text-[#9B9DA3] px-1 rounded-sm text-sm"> {item.code} </span>
                </p>
                {item.state === "INACTIVE" ? (
                  <div>
                    <Tooltip
                      position="-top-14"
                      arrowPostion="bottom"
                      className="left-[-63px] w-36 top-"
                      content={<div className="text-textLight text-[14px]">Доступ ограничен</div>}
                    >
                      <img src={UserBlock} alt="user block" />
                    </Tooltip>
                  </div>
                ) : null}
              </div>
            </div>
          </BaseTd>
          <BaseTd>{item.telegram_id || "-"}</BaseTd>
          <BaseTd>{item.balance}</BaseTd>
          <BaseTd>{findManagerName(item.manager_id) || "н/д"}</BaseTd>
          <BaseTd>
            <div className="flex items-center gap-1 justify-end">
              {hasAccessToEdit && !item.is_default && (
                <BaseButton icon={IconEdit} className="py-[6px]" onClick={() => setSearchParams({ row_id: item.id })} />
              )}
              {hasAccessToDelete && !item.is_default && (
                <div className="p-1" onClick={() => customerToggleState(item.id, item.state)}>
                  <Checkbox value={item.state === "ACTIVE"} />
                </div>
              )}
            </div>
          </BaseTd>
        </tr>
      ))}
    </>
  );
};

export default Tbody;
