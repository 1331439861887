import React, { useState, useEffect, useRef, useCallback } from "react";
import { NumericFormat } from "react-number-format";
// api
import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
import { useSearchParams } from "react-router-dom";
// components
import BaseTd from "components/Table/BaseTd";
import BaseButton from "components/Button/BaseButton";
import { notifySuccess } from "components/Toast";
// helpers
import { debounce } from "services/helpers";
// icons
import CommentIcon from "assets/images/svg/comment-icon.svg";
import CommentIconDark from "assets/images/svg/comment-icon-dark.svg";

const Tbody = ({ data, reverse, handleTemplatesModalComments, setComment, setCommentType, setOneInvoice, type }) => {
  const ProductItem = React.memo(({ item, index, arrLength }) => {
    const [amount, setAmount] = useState(data.amount);

    let [searchParams, setSearchParams] = useSearchParams();

    const inputRef = useRef(null);

    useEffect(() => {
      if (index === arrLength - 1 && inputRef.current) {
        inputRef.current.focus();
      }
    }, [index, arrLength]);

    const edit = useMutate({
      onSuccess: () => {
        setOneInvoice((prev) => ({
          ...prev,
          income_products: prev.income_products.map((el) => {
            if (el.id === item.id) {
              return { ...el, amount: Number(amount) };
            }
            return el;
          }),
          outgo_products: prev.outgo_products.map((el) => {
            if (el.id === item.id) {
              return { ...el, amount: Number(amount) };
            }
            return el;
          }),
        }));

        notifySuccess("Товар успешно обновлен");
      },
    });

    const updateProductData = (key, value) => {
      // Determine the correct endpoint based on the table
      const endpoint = (() => {
        if (type === "in") {
          return reverse
            ? `${endpoints.warehouseInvoice.outgoProductUpdate}?id=${item.id}`
            : `${endpoints.warehouseInvoice.incomeProductUpdate}?id=${item.id}`;
        } else {
          return reverse
            ? `${endpoints.warehouseInvoice.incomeProductUpdate}?id=${item.id}`
            : `${endpoints.warehouseInvoice.outgoProductUpdate}?id=${item.id}`;
        }
      })();

      // Call the API with the new data
      edit.mutate({
        link: endpoint,
        method: "patch",
        body: { [key]: Number(value) },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedUpdateProductData = useCallback(
      debounce((key, value) => {
        // delete space from value

        console.log(value);
        updateProductData(key, value);
      }, 1000),
      [],
    );
    const handleAmountChange = (event) => {
      const newAmount = event.target.value;
      const formattedValue = newAmount.replace(/\s/g, "");
      setAmount(formattedValue);
      // Use the debounced function here
      debouncedUpdateProductData("amount", formattedValue);
    };
    return (
      <tr key={item.id} className="hover:bg-GreyLight dark:hover:bg-inputBgDark">
        {/* {header.map((head) => (
        <BaseTd key={head.key} width={head.width}>
          {item[head.key]}
        </BaseTd>
      ))} */}

        <BaseTd>{item.num}</BaseTd>

        <BaseTd>{item.product_name}</BaseTd>
        <BaseTd>
          {/* <input type="number" onChange={handleAmountChange} defaultValue={item.amount} /> */}
          <NumericFormat
            getInputRef={(el) => {
              inputRef.current = el;
            }}
            defaultValue={item.amount}
            // value={amount}
            decimalScale={0}
            thousandSeparator=" "
            suffix=""
            fixedDecimalScale={false}
            allowNegative={false}
            allowEmptyFormatting={true}
            onChange={(e) => {
              handleAmountChange(e);
            }}
            className="w-[50px] h-[30px] rounded-[6px] border-1 border-GreyInput text-textBlack text-[14px] px-[8px] focus:outline-none dark:bg-[#1F1E25] dark:text-[#fff] dark:border-[#2d2d2d]"
          />
        </BaseTd>

        <BaseTd>
          <BaseButton
            type="submit"
            icon={item.note ? CommentIcon : CommentIconDark}
            className={`w-auto px-[8px] py-[6px] rounded-[6px]  text-textBlack hover:opacity-[0.8] transition duration-200
            ${item.note ? "bg-[#FFCD29]" : "bg-[#F2f2f2]"}
            `}
            iconClassName="w-[20px]"
            onClick={() => {
              // add item_id to url
              searchParams.set("item_id", item.id);
              setSearchParams(searchParams);
              handleTemplatesModalComments(true);
              setComment(item.note);
              setCommentType(type);
            }}
          />
        </BaseTd>
      </tr>
    );
  });

  return (
    <>
      {data?.map((item, i) => (
        <ProductItem item={item} index={i} arrLength={data?.length} key={item.id} />
      ))}
    </>
  );
};

export default Tbody;
