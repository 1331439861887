import React, { memo } from "react";
import { useSearchParams } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// components
import BaseButton from "components/Button/BaseButton";

const ModalChronology = memo(({ close }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();

  function removeId() {
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }

  function clearAndClose() {
    close(false);
    removeId();
  }

  const dummy = {
    created: {
      date: 1628582400,
      user: {
        id: 1,
        name: "Администратор",
      },
    },
    done: {
      date: 1628583400,
      user: {
        id: 1,
        name: "Менеджер",
      },
    },
    archived: {
      date: 1628592400,
      user: {
        id: 1,
        name: "Администратор",
      },
    },
  };

  const dummyArray = Object.entries(dummy).map(([key, value]) => ({
    state: key,
    ...value,
  }));

  return (
    <div className="w-[600px]">
      <div>
        <div className="grid grid-cols-3 bg-[#F2F2F2] px-[16px] rounded-[8px] mb-[16px]">
          <div className="col-span-1 text-center py-[12px]">
            <p className="text-[12px] text-[#2D2D34] opacity-[.7]">{t("info.date")}</p>
          </div>
          <div className="col-span-1  text-center py-[12px]">
            <p className="text-[12px] text-[#2D2D34] opacity-[.7]">{t("info.status")}</p>
          </div>
          <div className="col-span-1  text-center py-[12px]">
            <p className="text-[12px] text-[#2D2D34] opacity-[.7]">{t("pages.mainUsers.table.username")}</p>
          </div>
        </div>

        {dummyArray.map((item, index) => (
          <div className="grid grid-cols-3 mb-[16px]">
            <div className="text-center py-[12px]">
              {/* Convert UNIX timestamp to readable date */}
              <p className="text-[14px] text-[#2D2D34]">
                {new Date(item.date * 1000)
                  .toLocaleString("ru-RU", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                  .replace(",", "")}
              </p>
            </div>
            <div className="text-center py-[12px]">
              <span
                className={`w-[fit-content] px-[8px] py-[4px] rounded-[8px] 
                ${item.state === "created" && "bg-[rgba(255,205,41,0.10)] text-[#FFCD29]"}
                ${item.state === "done" && "bg-[#2196F31A] text-[#2196F3]"}
                ${item.state === "archived" && "bg-[rgba(135,203,25,0.10)] text-[#87CB19]"}
                `}
              >
                {item.state === "created" && t("info.created")}
                {item.state === "done" && t("info.done")}
                {item.state === "archived" && t("info.archived")}
              </span>
            </div>
            <div className="text-center py-[12px]">
              <p className="text-[12px] text-[#2D2D34]">{item.user.name}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end gap-6 mt-8">
      <BaseButton
            label={t("button.cancel")}
            onClick={clearAndClose}
            className="p-2 btn-secondary dark:btn-secondary-dark"
          />
      </div>
    </div>
  );
});

export default ModalChronology;
