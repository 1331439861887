import { useState } from "react";
import { useGet } from "hooks/query";
import { endpoints } from "services/endpoints";

const useUsers = (orgId) => {
  const [managerOptions, setManagerOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);


 useGet({
    link: endpoints.users.list,
    params: { org_id: orgId, permission: "org-manager" },
    onSuccess: (res) => {
      // here i got object of objects, transform it to array of objects

      const filtered = Object.keys(res).map((key) => res[key]);

        const optionTransformation = filtered.map((el) => ({
          label: el.full_name,
          value: el.id,
        }));
        setManagerOptions(optionTransformation);
      },
  });

useGet({
    link: endpoints.customers.list,
    params: { org_id: orgId },
    onSuccess: (res) => {
        const optionTransformation = res?.map((el) => ({
          label: el.name,
          value: el.id,
        }));
        // filter if customer has id "000"
        setCustomerOptions(optionTransformation.filter((el) => el.value !== "00000000-0000-0000-0000-000000000000"));
      }
  });


  return { managerOptions, customerOptions };
};

export default useUsers;
