import { useState, useEffect } from "react";
import { useCoreStore } from "store/core";
import { useForm } from "react-hook-form";
// api
import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// constants
import { languages } from "constants";
// components
import { notifySuccess } from "components/Toast";
import ProfileImageUploader from "./components/ProfileImageUploader";
import NumericField from "components/NumericField/NumericField";
import Input from "components/Input/Input";
import Select from "components/Select/Select";
import BaseButton from "components/Button/BaseButton";
// icons
import Save from "assets/images/svg/icon-save.svg";

const Profile = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const { t, i18n } = useTranslation();
  const { userData, refetchUserInfo } = useCoreStore();

  const [mode, setMode] = useState("edit");
  const [imagesUrl, setImagesUrl] = useState([]);

  const name = watch("name");
  const phone = watch("phone");
  const email = watch("email");

  useEffect(() => {
    if (name !== userData.full_name || phone !== userData.phone_number || email !== userData.email) {
      setMode("edit");
    } else {
      setMode("view");
    }
  }, [name, phone, email]);

  useEffect(() => {
    if (imagesUrl[0] !== userData?.photo) {
      setMode("edit");
    }
  }, [imagesUrl]);

  useEffect(() => {
    if (userData) {
      setValue("name", userData.full_name);
      setValue("phone", userData.phone_number);
      setValue("email", userData.email);
      setValue("lang", userData.lang);
      setImagesUrl([userData?.photo]);
    }
  }, [userData]);

  // watch any changes on inputs

  const update = useMutate({
    onSuccess: () => {
      notifySuccess(t("notification.successProfileUpdate"));
      refetchUserInfo();
      setMode("view");
    },
  });

  function onCancel() {
    if (userData) {
      setValue("name", userData.full_name);
      setValue("phone", userData.phone_number);
      setValue("email", userData.email);
      setImagesUrl([userData?.photo]);
    }
    setMode("view");
  }

  function onSubmit(values) {
    const body = {
      photo: imagesUrl[0],
      full_name: values.name,
      phone_number: "+998" + values.phone,
      email: values.email,
      // lang: values.lang,
    };

    update.mutate({
      link: endpoints.users.update_current,
      method: "patch",
      body: body,
    });
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    // set language to local storage
    localStorage.setItem("_lang", lng);
  };

  return (
    <>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className="text-2xl text-bold text-textBlack dark:text-textDark mb-4">{t("pages.profile.title")}</h1>
          <div className="flex flex-col w-[fit-content]">
            <div className="mt-3 mb-[16px]">
              <ProfileImageUploader
                url={imagesUrl}
                setImagesUrl={setImagesUrl}
                label={t("pages.profile.profileImage")}
                username={userData.full_name}
                roles={userData.roles}
              />
            </div>
            <div className="profile_image__uploader-imgs dark:!border-[#2d2d2d]">
              <div className="grid grid-cols-2 gap-4">
                <Input
                  autoFocus
                  className="h-[44px]"
                  label={t("form.fullname")}
                  fieldName="name"
                  register={register}
                  errors={errors}
                />

                <NumericField
                  name="phone_number"
                  placeholder="+998 -- --- -- --"
                  format="+998 ## ### ## ##"
                  errors={errors}
                  label={t("form.phoneNumber")}
                  allowEmptyFormatting={true}
                  isRequired={true}
                  mask="-"
                  className="w-full h-[44px]"
                  onChange={(e) => setValue("phone", e)}
                  value={userData.phone_number?.slice(4)}
                />
                <Input
                  autoFocus
                  label="Email"
                  fieldName="email"
                  className="h-[44px]"
                  placeholder="Email"
                  register={register}
                  errors={errors}
                />
                {/* <Input
                  autoFocus
                  label="Язык"
                  fieldName="lang"
                  className="opacity-60"
                  placeholder="Русский"
                  disabled
                  register={register}
                  errors={errors}
                /> */}
                <Select
                  fieldName="lang"
                  name="lang"
                  isMulti={false}
                  closeMenuOnSelect={true}
                  errors={errors}
                  required
                  label={t("form.language")}
                  placeholder={t("form.language")}
                  isClearable={false}
                  // handleChange={}
                  options={languages}
                  value={languages.find((option) => option.value === i18n.language)}
                  onChange={(e) => {
                    changeLanguage(e.value);
                  }}
                />
              </div>
            </div>

            <div className="flex justify-between mt-[16px]">
              <BaseButton
                label={t("button.cancel")}
                className="whitespace-nowrap w-[130px] h-[44px] border dark:text-[#fff]"
                onClick={onCancel}
              />
              <BaseButton
                label={t("button.save")}
                type="submit"
                labelClass="text-sm/[15px] text-white"
                icon={Save}
                className="whitespace-nowrap w-[130px] h-[44px] bg-mainGreen"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Profile;
