export const endpoints = {
  users: {
    list: "/user/list",
    get: "/user/page",
    one: "/user/one",
    check: "/user/check",
    create: "/user/create",
    delete: "/user/delete",
    update: "/user/update",
    update_state: "/user/update/state",
    update_current: "/user/update_current",
  },
  permissions: {
    get: "/permission/list",
  },
  roles: {
    get: "/role/list",
    one: "/role/one",
    create: "/role/create",
    delete: "/role/delete",
    update: "/role/update",
  },
  organizations: {
    get: "/org/page",
    one: "/org/one",
    list: "/org/list",
    create: "/org/create",
    update: "/org/update",
    delete: "/org/delete",
  },
  warehouse: {
    get: "/warehouse/page",
    one: "/warehouse/one",
    list: "/warehouse/list",
    check: "/warehouse/check",
    create: "/warehouse/create",
    update: "/warehouse/update",
    delete: "/warehouse/delete",
  },
  warehouseInvoice: {
    //get
    list: "/warehouse_invoice/list",
    oneDetailed: "/warehouse_invoice/one_detailed",
    // create
    create: "/warehouse_invoice/create",
    incomeProductCreate: "/warehouse_invoice/income_product/create",
    outgoProductCreate: "/warehouse_invoice/outgo_product/create",
    // update
    update: "/warehouse_invoice/update",
    incomeProductUpdate: "/warehouse_invoice/income_product/update",
    outgoProductUpdate: "/warehouse_invoice/outgo_product/update",
  },
  tags: {
    get: "/org_tag/page",
    one: "/org_tag/one",
    list: "/org_tag/list",
    create: "/org_tag/create",
    update: "/org_tag/update",
    delete: "/org_tag/delete",
  },
  category: {
    get: "/category/page",
    one: "/category/one",
    list: "/category/list",
    sort: "/category/sort",
    create: "/category/create",
    update: "/category/update",
    delete: "/category/delete",
  },
  parameter: {
    get: "/property/page",
    one: "/property/one",
    list: "/property/list",
    sort: "/property/sort",
    create: "/property/create",
    update: "/property/update",
    delete: "/property/delete",
  },
  parameterValue: {
    get: "/property_value/page",
    one: "/property_value/one",
    list: "/property_value/list",
    sort: "/property_value/sort",
    create: "/property_value/create",
    update: "/property_value/update",
    delete: "/property_value/delete",
  },
  product: {
    get: "/product/page",
    one: "/product/one",
    search: "/product/search",
    update: "/product/update",
    generateCode: "/product/generate_code",
    check: "/product/check",
    create: "/product/create",
    update_state: "/product/update/state",
    update_states: "/product/update/states",
    update_tags: "/product/update/add",
  },
  productGroup: {
    get: "/product_group/page",
    one: "/product_group/one",
    list: "/product_group/list",
    sort: "/product_group/sort",
    bindProperty: "/product_group/bind_property",
    unbindProperty: "/product_group/unbind_property",
    showProperty: "/product_group/show_property",
    unshowProperty: "/product_group/unshow_property",
    create: "/product_group/create",
    update: "/product_group/update",
    delete: "/product_group/delete",
  },
  productGroupProperty: {
    list: "/product_group_property/list",
    sort: "/product_group_property/sort",
  },
  exchangeRate: {
    get: "/course/page",
    one: "/course/one",
    last: "/course/last",
    create: "/course/create",
    update: "/course/update",
    delete: "/course/delete",
  },
  customers: {
    get: "/customer/page",
    one: "/customer/one",
    list: "/customer/list",
    search: "/customer/search",
    check: "/customer/check",
    generateCode: "/customer/generate_code",
    create: "/customer/create",
    update: "/customer/update",
    update_state: "/customer/update/state",
    multiple_delete: "/customer/delete",
    delete: "/customer/delete",
  },
  invoices: {
    // create
    create: "/invoice/create",
    sale: "/invoice/sale",
    customerCreate: "/invoice/customer_create",
    incomeProductCreate: "/invoice/income_product/create",
    incomeProductCreateMultiple: "/invoice/income_product/create_multiple",
    outgoProductCreate: "/invoice/outgo_product/create",
    outgoProductCreateMultiple: "/invoice/outgo_product/create_multiple",
    invoicePaymentCreate: "/invoice/invoice_payment/create",
    // read
    one: "/invoice/one",
    oneDetailed: "/invoice/one_detailed",
    list: "/invoice/list",
    listDetailed: "/invoice/list_detailed",
    pageDetailed: "/invoice/page_detailed",
    // update
    update: "/invoice/update",
    archive: "/invoice/archive",
    updateBoxCount: "/invoice/update_box_count",
    updateIncomeProduct: "/invoice/income_product/update",
    updateOutgoProduct: "/invoice/outgo_product/update",
    updateInvoicePayment: "/invoice/invoice_payment/update",
    // delete
    delete: "/invoice/delete",
    deleteIncomeProduct: "/invoice/income_product/delete",
    deleteOutgoProduct: "/invoice/outgo_product/delete",
    deleteInvoicePayment: "/invoice/invoice_payment/delete",
  },
};
