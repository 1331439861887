import { useCoreStore } from "store/core";
// hooks
import { useTranslatedTableConfig } from "../Helpers";
// i18n
import { useTranslation } from "react-i18next";
// components
import Tbody from "./Tbody";
import BaseTable from "components/Table/BaseTable";
import BaseThead from "components/Table/BaseThead";
import ControlledInput from "components/Input/ControlledInput";
// icons
import Search from "assets/images/svg/search.svg";
import SearchWhite from "assets/images/svg/search-white.svg";

const Table = ({ data = [], handleSearch, paginationConfig }) => {
  const { t } = useTranslation();

  const { header } = useTranslatedTableConfig();

  const theme = useCoreStore((state) => state.theme);
  return (
    <div className="h-full px-2 flex flex-col">
      <h2 className="text-bold text-2xl flex text-black dark:text-textDark">{t("pages.permissions.title")}</h2>
      <div className="w-full flex justify-between items-center my-4">
        <div className="w-[260px]">
          <ControlledInput
            className="w-full"
            placeholder={`${t("pages.permissions.search")}...`}
            onChange={handleSearch}
            leftElement={<img src={theme ? SearchWhite : Search} alt="CloseIcon" className="cursor-pointer" />}
          />
        </div>
      </div>
      <BaseTable extraClassNames="h-[71%]" thead={<BaseThead header={header} />} tbody={<Tbody data={data} />} />
      {/* <Pagination paginationConfig={paginationConfig} /> */}
    </div>
  );
};

export default Table;
