import CustomRequests from "./requests/customRequests";

export default async function uploader(event, multi = false, file) {
  let fileUploaded = null;
  if (event) {
    fileUploaded = multi ? event : event.target.files[0];
  } else {
    fileUploaded = file;
  }
  const sizeOfUploadedImage = fileUploaded.size / 1024 / 1024;
  let urlUploadedImg = "";

  if (sizeOfUploadedImage > 10) {
    alert("Iltimos hajmi 10 mb dan kichik bo'lgan rasm kiriting");
  }

  const formData = new FormData();
  formData.append("file", fileUploaded);
  formData.append("category", "test");

  await CustomRequests.mainImageUploader(formData)
    .then((response) => {
      urlUploadedImg = response.fullPath;
    })
    .catch((error) => {
      console.log(error);
    });

  return function () {
    return urlUploadedImg;
  };
}
