import React from "react";
import LeftArrow from "assets/images/svg/left-arrow.svg";

const GoBackButton = () => {
  return (
    <button
      className="w-8 h-8 dark:text-darkGrey rounded border-[1px] border-darkGrey dark:border-darkGrey
                bg-white dark:bg-mainGreyDark flex items-center"
    >
      <img src={LeftArrow} alt="modalclose" className="m-auto" />
    </button>
  );
};

export default GoBackButton;
