import { forwardRef } from "react";
// helpers
import { useTranslatedTableConfig } from "../Helpers";
import { formatPrice } from "services/helpers";
// i18n
import { useTranslation } from "react-i18next";
// components
import Tbody from "./ProductsTbody";
import BaseTable from "components/Table/BaseTable";
import BaseThead from "components/Table/BaseThead";

const Table = forwardRef(
  ({
    products = [],
    pfShow,
    pfsShow,
    invoiceId,
    warehouse,
    setInvoices,
    badge,
    colors,
    type,
    handleTemplatesEditProductExpence,
    handleTemplatesEditProductIncome,
  }) => {
    const { t } = useTranslation();
    const { productsHeaders } = useTranslatedTableConfig();

    function validHeader(array, showPf, showPfs) {
      return array.filter((obj) => {
        if (obj.key === "pf" && !showPf) {
          return false; // If obj.key is 'pf' and showPf is false, filter it out
        }
        if (obj.key === "pfs" && !showPfs) {
          return false; // If obj.key is 'pfs' and showPfs is false, filter it out
        }
        return true; // Keep the object in the array if none of the above conditions are met
      });
    }

    const filteredHeaders = validHeader(productsHeaders, pfShow, pfsShow);

    const { darkColor, lightColor, badgeColor } = colors;

    const extraClassTr = `!bg-[${darkColor}] `;
    const extraClassTh = "!py-[6px] px-[16px] first:rounded-tl-[0] first:rounded-bl-[0]";

    return (
      <>
        <div className="flex flex-row justify-between mt-[16px]"></div>
        <div className="h-[90%] px-2 relative">
          <div
            className="flex flex-row items-stretch"
            style={{
              borderRadius: "8px",
              overflow: "hidden",
              border: `1px solid ${badgeColor}`,
            }}
          >
            <div
              className={`w-[22px] relative flex-auto`}
              style={{
                backgroundColor: `${badgeColor}`,
              }}
            >
              {badge && (
                <span
                  style={{ transform: "rotate(-90deg) translate(-65%, 0%)", transformOrigin: "left" }}
                  className="block text-[#2D2D34] absolute bottom-1/2 left-1/2 text-[12px] font-semibold w-inherit"
                >
                  {badge}
                </span>
              )}
            </div>
            <div className="w-full h-full flex-auto">
              <BaseTable
                thead={
                  <BaseThead
                    header={filteredHeaders}
                    extraClassTh={extraClassTh}
                    extraClassTr={extraClassTr}
                    thColor={darkColor}
                  />
                }
                tbody={
                  <Tbody
                    data={products}
                    pfShow={pfShow}
                    pfsShow={pfsShow}
                    type={type}
                    invoiceId={invoiceId}
                    warehouse={warehouse}
                    setInvoices={setInvoices}
                    darkColor={darkColor}
                    lightColor={lightColor}
                    handleTemplatesEditProductExpence={handleTemplatesEditProductExpence}
                    handleTemplatesEditProductIncome={handleTemplatesEditProductIncome}
                  />
                }
              />
            </div>
          </div>
          <div className="pr-[16px] mt-[8px] flex justify-end items-center space-x-[30px]">
            {type === "expense" && (
              <p className="text-[#FF705D] text-[15px] bg-[#FFF1EF] px-[8px] py-[2px] rounded-[8px]">
                <span className="text-bold">{t("info.piece")}: -1.36</span>
              </p>
            )}

            <p className="text-[#535456] dark:text-white text-[15px]">
              {t("pages.admInvoice.table.quantity")}:{" "}
              <span className="text-bold">
                {products.reduce((acc, curr) => {
                  const quantity = Number(curr.amount);
                  if (!isNaN(quantity)) {
                    return acc + quantity;
                  } else {
                    return acc;
                  }
                }, 0)}{" "}
                {t("info.piece")}.
              </span>
            </p>
            <p className="text-[#535456] dark:text-white text-[15px]">
              {t("pages.admInvoice.table.sum")}:{" "}
              <span className="text-bold">
                {formatPrice(
                  products.reduce((acc, curr) => {
                    const sum = Number(curr.total);
                    if (!isNaN(sum)) {
                      return acc + sum;
                    } else {
                      return acc;
                    }
                  }, 0),
                )}{" "}
              </span>
            </p>
          </div>
        </div>
      </>
    );
  },
);

export default Table;
