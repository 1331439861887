import React, { memo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// api
import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";
import Datepicker from "react-tailwindcss-datepicker";
import Input from "components/Input/Input";
import Checkbox from "components/Checkbox/Checkbox";
// icons
import IconSave from "assets/images/svg/icon-save.svg";

const ModalSaveInvoice = memo(({ close, refetch, archivedInvoicesRefetch = () => {} }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  let [searchParams, setSearchParams] = useSearchParams();
  const rowId = searchParams.get("id") || searchParams.get("invoice_id");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur", // or "onChange"
  });

  const [sendTelegram, setSendTelegram] = useState(false);

  const [currentDate, setDate] = useState({
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
  });

  var prevDay = new Date();

  prevDay.setDate(prevDay.getDate() - 1);

  function removeId() {
    reset();
  }

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: () => {
      refetch();
      archivedInvoicesRefetch();
      reset();
      clearAndClose();
      setSearchParams({});
      notifySuccess(t("notification.invoiceSuccessSave"));
    },
  });

  const onSubmit = (values) => {
    const currentDateFormatted = currentDate.startDate.split(".").reverse().join("-");
    const body = {
      dynamic_date: currentDateFormatted,
      loan_days: Number(values.due),
      send_telegram: sendTelegram,
    };

    create.mutate({
      link: `${endpoints.invoices.archive}?id=${rowId}`,
      method: "patch",
      body: body,
    });
  };

  return (
    <div className="w-[600px] p-[5px] !overflow-visible">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <label className="block text-stone-800 dark:text-white font-semibold text-[14px]">{t("info.date")}</label>
            <Datepicker
              asSingle
              useRange={false}
              isRequired={true}
              // placeholder={"Выберите дату"}
              placeholder={currentDate.startDate}
              displayFormat={"DD.MM.YYYY"}
              startFrom={currentDate}
              value={currentDate}
              onChange={setDate}
              primaryColor={"violet"}
              containerClassName="relative calendar-container "
              inputClassName="w-full py-3 px-4 rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey dark:text-white"
            />
          </div>
          <div className="col-span-1">
            <Input
              autoFocus
              label={t("pages.manInvoice.form.dueDay")}
              fieldName="due"
              placeholder={t("pages.manInvoice.form.dueDayPlaceholder")}
              register={register}
              errors={errors}
              isRequired={true}
              className="w-full"
            />
          </div>

          <div className="col-span-2">
            <Checkbox
              label={t("pages.manInvoice.form.sendTg")}
              labelClassName="text-[14px] ml-[14px]"
              name="telegram"
              className="w-full h-[50px] rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey"
              value={sendTelegram}
              onChange={(e) => {
                setSendTelegram(e.target.checked);
              }}
            />
          </div>

          <div className="col-span-2 flex justify-between">
            <BaseButton
              label={t("button.cancel")}
              className={`p-2 btn-secondary dark:btn-secondary-dark`}
              onClick={clearAndClose}
            />

            <BaseButton
              icon={IconSave}
              label={t("button.save")}
              type="submit"
              className="col-span-1 bg-mainGreen text-white font-semibold"
              isLoading={create.isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
});

export default ModalSaveInvoice;
