import { useState, useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
// store
import { useCoreStore } from "store/core";
// api
import { endpoints } from "services/endpoints";
import { useGet } from "hooks/query";
// helpers
import useSearch from "hooks/useSearch/useSearch";
// i18n
import { useTranslation } from "react-i18next";
// components
import Modal from "components/Modal/Modal";
import ModalForm from "./ModalForm";
import Table from "./Table";

const ProductGroupsFirst = () => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [productGroups, setProductGroups] = useState([]);

  let queryId = searchParams.get("row_id1");
  const modal1 = useRef();
  const { orgId } = useCoreStore((state) => state);
  const { searchResult, handleSearch } = useSearch();

  const { data, isLoading, isError, refetch } = useGet({
    link: endpoints.productGroup.get,

    params: { org_id: orgId, page, size: 12, q: searchResult || undefined },
  });

  const { data: singleData } = useGet({
    link: endpoints.productGroup.one,
    params: { id: queryId },
    queryKey: queryId,
    enabled: !!queryId,
  });

  useEffect(() => {
    if (data) {
      setProductGroups((prev) => [...prev, ...data.data]);
      setHasMore(data.data.length > 0);
    }
  }, [data]);

  const isScrollAtBottom = (element) => {
    return element.scrollHeight - element.scrollTop === element.clientHeight;
  };

  // Scroll event handler
  const handleScroll = (event) => {
    const { currentTarget: target } = event;

    if (isScrollAtBottom(target) && !isLoading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleTemplatesModal = (value) => {
    modal1.current.openModal(value);
  };

  useEffect(() => {
    if (queryId && modal1.current) {
      handleTemplatesModal(true);
    }
  }, [queryId]);

  if (isLoading) return <p>Loading</p>;
  if (isError) return <p>Errror</p>;

  return (
    <>
      <Table
        data={productGroups}
        refetch={refetch}
        setProductGroups={setProductGroups}
        modalToggler={handleTemplatesModal}
        handleSearch={handleSearch}
        handleScroll={handleScroll}
      />
      <Modal ref={modal1} rowId="row_id1" title={queryId ? t("pages.productGroups.update") : t("pages.productGroups.add")}>
        <ModalForm
          close={handleTemplatesModal}
          setProductGroups={setProductGroups}
          row={queryId && singleData ? singleData : {}}
          orgId={queryId ?? orgId}
        />
      </Modal>
    </>
  );
};

export default ProductGroupsFirst;
