// i18n
import { useTranslation } from "react-i18next";

export const useTranslatedTableConfig = () => {
  const { t } = useTranslation();

  const header = [
    {
      key: "number",
      title: "№",
      width: "w-[5%]",
    },
    {
      key: "name",
      title: t("pages.manInvoice.table.name"),
      width: "w-[35%]",
    },
    {
      key: "quantity",
      title: t("pages.manInvoice.table.quantity"),
      width: "w-[10%]",
    },
    {
      key: "price",
      title: t("pages.manInvoice.table.price"),
      width: "w-[10%]",
    },
    {
      key: "amount",
      title: t("pages.manInvoice.table.amount"),
      width: "w-[10%]",
    },
    {
      key: "comment",
      title: t("pages.manInvoice.table.comment"),
      width: "w-[5%]",
    },
    {
      key: "rp",
      title: t("pages.manInvoice.table.rp"),
      width: "w-[5%]",
    },
    {
      key: "b2b",
      title: t("pages.manInvoice.table.b2b"),
      width: "w-[5%]",
    },
    {
      key: "b2c",
      title: t("pages.manInvoice.table.b2c"),
      width: "w-[5%]",
    },
    {
      key: "b2c",
      width: "w-[5%]",
    },
  ];

  const header2 = [
    {
      key: "number",
      title: "№",
      width: "w-[5%]",
    },
    {
      key: "type",
      title: t("pages.manInvoice.table.type"),
      width: "w-[20%]",
    },
    {
      key: "amount",
      title: t("pages.manInvoice.table.amount"),
      width: "w-[20%]",
    },
    {
      key: "percentage",
      title: "%",
      width: "w-[15%]",
    },
    {
      key: "rate",
      title: t("pages.manInvoice.table.rate"),
      width: "w-[15%]",
    },
    {
      key: "dollars",
      title: "$",
      width: "w-[15%]",
    },
    {
      key: "comment",
      title: t("pages.manInvoice.table.comment"),
      width: "w-[15%]",
    },
    {
      key: "comment",
      title: "",
    },
  ];

  return { header, header2 };
};
