import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useTranslatedTableConfig } from "../Helpers";
// store
import { useCoreStore } from "store/core";
// api
import { endpoints } from "services/endpoints";
import { useMutate } from "hooks/query";
// i18n
import { useTranslation } from "react-i18next";
// components
import Tbody from "./Tbody";
import BaseTable from "components/Table/BaseTable";
import BaseThead from "components/Table/BaseThead";
import ControlledInput from "components/Input/ControlledInput";
import Pagination from "components/Pagination/Pagination";
import CustomSelect from "components/Select/CustomSelect";
import Chip from "components/Chip/Chip";
import BaseButton from "components/Button/BaseButton";
import { notifySuccess } from "components/Toast";
import Search from "assets/images/svg/search.svg";
import SearchWhite from "assets/images/svg/search-white.svg";
import AddWhite from "assets/images/svg/icon-add-white.svg";
import TrashIcon from "assets/images/svg/trash-white.svg";
import TagWhite from "assets/images/svg/tag-white.svg";

const Table = forwardRef(
  (
    {
      data = [],
      tagModalToggler,
      modalToggler,
      handleSearch,
      paginationConfig,
      tags,
      categories,
      setPage,
      refetch,
      selectedTags,
      setSelectedTags,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { header } = useTranslatedTableConfig();
    const [isSelectedAllRows, setIsSelectedAllRows] = useState(false);
    const [checkboxes, setCheckboxes] = useState({});
    const [selectedRowIds, setSelectedRowIds] = useState([]);

    const theme = useCoreStore((state) => state.theme);

    const handleChangeCheckbox = (e) => {
      setIsSelectedAllRows(e.target.checked);
    };

    useEffect(() => {
      const filterByTrue = Object.values(checkboxes).filter((el) => el);
      const filteredKeys = Object.keys(checkboxes).filter((key) => checkboxes[key]);
      setSelectedRowIds(filteredKeys);

      if (filterByTrue.length !== data.length) setIsSelectedAllRows(false);
    }, [checkboxes]);

    const stateProduct = useMutate({
      onSuccess: () => {
        refetch();
        setSelectedRowIds([]);
        notifySuccess(t("notification.successApply"));
      },
    });

    const changeState = (id, state) => {
      stateProduct.mutate({
        link: `${endpoints.product.update_state}?id=${id}`,
        method: "patch",
        body: {
          state,
        },
      });
    };

    const changeStateSelected = (state) => {
      stateProduct.mutate({
        link: `${endpoints.product.update_states}`,
        method: "patch",
        body: {
          state,
          ids: selectedRowIds,
        },
      });
    };

    const updateTags = (categories, tags) => {
      stateProduct.mutate({
        link: `${endpoints.product.update_tags}`,
        method: "patch",
        body: {
          ids: selectedRowIds,
          categories,
          tags,
        },
      });
    };

    useImperativeHandle(ref, () => ({
      updateTags,
    }));

    return (
      <div className="h-full flex flex-col relative">
        <div>
          <h1 className="text-2xl text-bold text-textBlack dark:text-textDark mb-4">{t("pages.products.title")}</h1>

          <div className="flex flex-row justify-between">
            <div className="flex flex-row mb-[35px]">
              <ControlledInput
                placeholder={`${t("pages.products.search")}...`}
                onChange={handleSearch}
                className="mr-2 w-[230px] h-[45px]"
                leftElement={<img src={theme ? SearchWhite : Search} alt="CloseIcon" className="cursor-pointer" />}
              />
              <div className="flex flex-col">
                <CustomSelect
                  // isMulti={true}
                  height="45px"
                  padding="0px 5px"
                  name="tags"
                  search={true}
                  closeMenuOnSelect={false}
                  width={"230px"}
                  placeholder={`${t("pages.products.form.selectTags")}...`}
                  leftElement={<img src={theme ? SearchWhite : Search} alt="CloseIcon" className="cursor-pointer" />}
                  options={tags?.map((item) => ({ value: item.id, label: item.tag_name })) ?? []}
                  // empty value always should be
                  value={[]}
                  checkbox={true}
                  selectedTags={selectedTags}
                  onChangeFunc={(value) => {
                    // check if already selected
                    if (selectedTags?.find((tag) => tag.value === value.option.value)) {
                      // delete from selected
                      setSelectedTags(selectedTags.filter((tag) => tag.value !== value.option.value));
                    } else setSelectedTags([...selectedTags, value.option]);

                    // clear select
                    return;
                  }}
                />

                <div className="flex flex-wrap justify-center">
                  {selectedTags?.map((item, i) => (
                    <Chip
                      text={item.label}
                      closeOption={true}
                      key={i}
                      onDelete={() => {
                        setSelectedTags(selectedTags.filter((tag) => tag.value !== item.value));
                        // setFilters({
                        //   ...filters,
                        //   tag_name: selectedTags?.filter((tag) => tag.value !== item.value)?.map((item) => item.value),
                        // });
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-row">
              {selectedRowIds.length > 0 && (
                <BaseButton
                  label={`${t("button.disableSelected")} (${selectedRowIds.length})`}
                  className="whitespace-nowrap h-[45px] border ml-2 btn-danger"
                  icon={TrashIcon}
                  onClick={() => changeStateSelected("INACTIVE")}
                />
              )}

              <BaseButton
                label={t("button.add")}
                icon={AddWhite}
                className="whitespace-nowrap h-[45px] border ml-2 btn-primary dark:border-[transparent]"
                labelClass="text-sm/[15px] text-white"
                onClick={() => modalToggler(true)}
              />

              {selectedRowIds.length > 0 && (
                <BaseButton
                  label={t("pages.products.tagsCategories")}
                  icon={TagWhite}
                  className="whitespace-nowrap h-[45px] border ml-2 bg-mainGreen"
                  labelClass="text-sm/[15px] text-white"
                  onClick={() => tagModalToggler(true)}
                />
              )}
            </div>
          </div>
        </div>

        <BaseTable
          thead={
            <BaseThead
              header={header}
              withCheckbox={true}
              isSelectedAllRows={isSelectedAllRows}
              handleChangeCheckbox={handleChangeCheckbox}
            />
          }
          tbody={
            <Tbody
              data={data}
              isChecked={isSelectedAllRows}
              tags={tags}
              categories={categories}
              checkboxes={checkboxes}
              setCheckboxes={setCheckboxes}
              changeState={changeState}
              modalToggler={modalToggler}
            />
          }
        />
        <div className="w-full mt-auto">
          <Pagination paginationConfig={paginationConfig} />
        </div>
      </div>
    );
  },
);

export default Table;
