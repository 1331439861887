import Close from "assets/images/svg/close.svg";
import CloseDark from "assets/images/svg/close-dark.svg";

import { useCoreStore } from "store/core";

import { forwardRef } from "react";

const Chip = forwardRef((props, ref) => {
  const { text, closeOption = true, onDelete, className } = props;
  const theme = useCoreStore((state) => state.theme);

  return (
    <div
      data-te-chip-init
      data-te-ripple-init
      className={`[word-wrap: break-word] my-[5px] mr-1 flex h-[22px] cursor-pointer items-center justify-between rounded-[4px] border border-[#D1D1D1] bg-[#FAFAFA] bg-[transparent] px-[5px] py-0 text-[13px] font-normal normal-case leading-loose text-[#4f4f4f] shadow-none transition-[opacity] duration-300 ease-linear hover:border-[#9fa6b2] hover:!shadow-none dark:text-neutral-200 whitespace-nowrap ${className}`}
      data-te-ripple-color="dark"
      
    >
      {text}
      {closeOption && (
        <span
          data-te-chip-close
          className={`float-right w-4 cursor-pointer pl-[8px] text-[16px] text-[#afafaf] opacity-[.53] transition-all duration-200 ease-in-out hover:text-[#8b8b8b] dark:text-neutral-400 dark:hover:text-neutral-100`}
          onClick={onDelete}
        >
          <img src={theme ? CloseDark : Close} alt="CloseIcon" className="cursor-pointer" />
        </span>
      )}
    </div>
  );
});

export default Chip;
