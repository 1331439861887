import { useState, useEffect, useRef, useCallback } from "react";
import { NumericFormat } from "react-number-format";
// helpers
import { debounce, formatPrice } from "services/helpers";
// store
import { useCoreStore } from "store/core";
// api
import { endpoints } from "services/endpoints";
import { useMutate } from "hooks/query";
import { useSearchParams } from "react-router-dom";
import { exchangeOptions } from "constants";
// i18n
import { useTranslation } from "react-i18next";
// components
import BaseTd from "components/Table/BaseTd";
import BaseButton from "components/Button/BaseButton";
import ControlledSelect from "components/Select/ControlledSelect";
import DeleteConfirmation from "container/DeleteConfirmation";
import { notifySuccess } from "components/Toast";
// icons
import CommentIcon from "assets/images/svg/comment-icon.svg";
import CommentIconDark from "assets/images/svg/comment-icon-dark.svg";
import iconTrash from "assets/images/svg/trash-red.svg";

function PaymentItem({
  item,
  index,
  arrLength,
  setDeleteId,
  handleTemplatesModalComments,
  archived,
  currentTable,
  singleInvoice,
  updateSingleInvoice,
  closeDeleteModal,
}) {
  const { t } = useTranslation();
  const inputRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const { theme } = useCoreStore();

  const tdStyles = "px-[6px] py-[6px] text-[15px]";

  const [currency, setCurrency] = useState(item.currency);

  const edit = useMutate({
    onSuccess: (res) => {
      notifySuccess(t("notification.paymentSuccessUpdate"));

      const newSingleInvoice = { ...singleInvoice };

      if (currentTable === "outgoPayments") {
        newSingleInvoice.outgo_payments = newSingleInvoice.outgo_payments.map((item) => {
          if (item.id === res.id) {
            return {
              ...item,
              ...res,
            };
          }
          return item;
        });
      } else {
        newSingleInvoice.income_payments = newSingleInvoice.income_payments.map((item) => {
          if (item.id === res.id) {
            return {
              ...item,
              ...res,
            };
          }
          return item;
        });
      }
      // focus on the input

      updateSingleInvoice(newSingleInvoice);
    },
  });

  useEffect(() => {
    if (index === 0 && inputRef.current) {
      inputRef.current.focus();
    }
  }, [index, arrLength]);

  const updatePaymentData = (key, value) => {
    // Call the API with the new data
    edit.mutate({
      link: `${endpoints.invoices.updateInvoicePayment}?id=${item.id}`,
      method: "patch",
      body: { [key]: Number(value) },
    });
  };

  const extraUpdatePaymentData = (body) => {
    // Call the API with the new data
    edit.mutate({
      link: `${endpoints.invoices.updateInvoicePayment}?id=${item.id}`,
      method: "patch",
      body,
    });
  };

  const debouncedUpdateProductData = useCallback(
    debounce((key, value) => {
      updatePaymentData(key, value);
    }, 300),
    [], // If updateProductData or any other dependencies don't change, this won't be recreated
  );

  const extraDebouncedUpdateProductData = useCallback(
    debounce((body) => {
      extraUpdatePaymentData(body);
    }, 300),
    [], // If updateProductData or any other dependencies don't change, this won't be recreated
  );

  const [isHover, setIsHover] = useState(false);

  const [inputKey, setInputKey] = useState(0);

  const [baseAmount, setBaseAmount] = useState(item.base_amount);
  const [percent, setPercent] = useState(item.percent);
  const [course, setCourse] = useState(item.course);
  const [currencyAmount, setCurrencyAmount] = useState(item.currency_amount);

  const handleBaseAmountChange = (value) => {
    const formattedValue = value !== "" ? Number(value.replace(/\s/g, "")) : null;

    if (percent && course) {
      const watchPercent = Number(percent);
      const watchCourse = Number(course);

      const currency = value !== "" ? (formattedValue / watchCourse / 100) * watchPercent : null;

      setBaseAmount(formattedValue);
      setCurrencyAmount(currency);
      extraDebouncedUpdateProductData({
        currency_amount: currency,
        base_amount: formattedValue,
      });
    } else {
      setBaseAmount(formattedValue);
      debouncedUpdateProductData("base_amount", formattedValue);
    }
  };

  const handlePercentChange = (value) => {
    const formattedValue = value !== "" ? Number(value.replace(/\s/g, "")) : null;
    const watchBaseAmount = Number(baseAmount);
    const watchCourse = Number(course);

    if (formattedValue > 100) {
      if (baseAmount && course) {
        const currency = value !== "" ? (watchBaseAmount / watchCourse / 100) * 100 : null;
        setPercent(100);
        setCurrencyAmount(currency);
        setInputKey((prevKey) => prevKey + 1);
        extraDebouncedUpdateProductData({
          currency_amount: currency,
          percent: 100,
        });
      } else {
        setPercent(100);
        debouncedUpdateProductData("percent", 100);
        setInputKey((prevKey) => prevKey + 1); // Increment key to force re-render
      }
    } else {
      if (baseAmount && course) {
        const currency = value !== "" ? (watchBaseAmount / watchCourse / 100) * formattedValue : null;
        setPercent(formattedValue);
        setCurrencyAmount(currency);
        extraDebouncedUpdateProductData({
          currency_amount: currency,
          percent: formattedValue,
        });
      } else {
        setPercent(formattedValue);
        debouncedUpdateProductData("percent", formattedValue);
      }
    }
  };

  const handleCourseChange = (value) => {
    const formattedValue = value !== "" ? Number(value.replace(/\s/g, "")) : null;

    if (baseAmount && percent) {
      const watchBaseAmount = Number(baseAmount);
      const watchPercent = Number(percent);
      const currency = (watchBaseAmount / Number(formattedValue) / 100) * watchPercent;

      setCourse(formattedValue);
      setCurrencyAmount(currency);
      extraDebouncedUpdateProductData({
        currency_amount: currency,
        course: formattedValue,
      });
    } else {
      setCourse(formattedValue);
      debouncedUpdateProductData("course", formattedValue);
    }
  };

  // TODO delete below vode when done refactoring (changing values the course - not baseAmount)

  // const handleCurrencyAmountChange = (value) => {
  //   const formattedValue = value !== "" ? Number(value.replace(/\s/g, "")) : null;

  //   if (percent && course) {
  //     const watchPercent = Number(percent);
  //     const watchCourse = Number(course);
  //     // const baseAmount = ((formattedValue * watchCourse) / watchPercent) * 100;

  //     const baseAmount = value !== "" ? formattedValue * watchCourse * (watchPercent / 100) : null;

  //     setBaseAmount(baseAmount);

  //     extraDebouncedUpdateProductData({
  //       base_amount: baseAmount,
  //       currency_amount: formattedValue,
  //     });
  //   } else {
  //     setCurrencyAmount(formattedValue);
  //     debouncedUpdateProductData("currency_amount", formattedValue);
  //   }
  // };

  const handleCurrencyAmountChange = (value) => {
    const formattedValue = value !== "" ? Number(value.replace(/\s/g, "")) : null;

    if (percent && baseAmount) {
      const watchPercent = Number(percent);
      const watchBaseAmount = Number(baseAmount);
      // const baseAmount = ((formattedValue * watchCourse) / watchPercent) * 100;

      const course = value !== "" ? (watchBaseAmount / formattedValue / watchPercent) * 100 : null;

      setCourse(course);

      extraDebouncedUpdateProductData({
        course: course,
        currency_amount: formattedValue,
      });
    } else {
      setCurrencyAmount(formattedValue);
      debouncedUpdateProductData("currency_amount", formattedValue);
    }
  };

  return (
    <tr
      key={item.id}
      className="hover:bg-GreyLight dark:hover:bg-inputBgDark"
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      <BaseTd extraClass={tdStyles}>{index + 1}</BaseTd>
      <BaseTd extraClass={tdStyles}>
        <ControlledSelect
          // errors={{}}
          height="32px"
          className="w-[130px] placeholder-darkGreyLight rounded-lg focus:border-purple-0 focus:bg-white focus:border-2 font-[16px] max-w-[100%]"
          borderColor={`${theme ? "#2d2d2d!important" : "#D1D1D1!important"}`}
          options={exchangeOptions}
          onClick={(e) => {
            if (archived) {
              e.preventDefault();
            }
          }}
          disabled={archived}
          onChange={(e) => {
            if (!archived) {
              setCurrency(e.value);
              extraDebouncedUpdateProductData({
                currency: e.value,
              });
            }
          }}
          value={exchangeOptions.find((option) => option.value === currency)}
        />
      </BaseTd>
      <BaseTd extraClass={tdStyles}>
        {archived ? (
          <span>{formatPrice(baseAmount)}</span>
        ) : (
          <NumericFormat
            getInputRef={(el) => {
              inputRef.current = el;
            }}
            decimalScale={2}
            thousandSeparator=" "
            suffix=""
            fixedDecimalScale={false}
            allowNegative={false}
            allowEmptyFormatting={true}
            value={baseAmount}
            onChange={(e) => {
              handleBaseAmountChange(e.target.value);
            }}
            className="w-[84px] h-[20px] text-[14px] input p-[2px] mr-[8px] dark:bg-[#1F1E25] dark:border-[#2d2d2d]"
          />
        )}
      </BaseTd>
      <BaseTd extraClass={tdStyles}>
        {archived || currency === "usd" ? (
          <span>{percent}</span>
        ) : (
          <NumericFormat
            key={inputKey} // Use the key to force re-render
            decimalScale={2}
            thousandSeparator=" "
            suffix=""
            fixedDecimalScale={false}
            allowNegative={false}
            allowEmptyFormatting={true}
            value={percent}
            onChange={(e) => {
              handlePercentChange(e.target.value);
            }}
            className="w-[84px] h-[20px] text-[14px] input p-[2px] mr-[8px] dark:bg-[#1F1E25] dark:border-[#2d2d2d]"
          />
        )}
      </BaseTd>
      <BaseTd extraClass={tdStyles}>
        {archived || currency === "usd" ? (
          <span>{formatPrice(course)}</span>
        ) : (
          <NumericFormat
            decimalScale={2}
            thousandSeparator=" "
            suffix=""
            fixedDecimalScale={false}
            allowNegative={false}
            allowEmptyFormatting={true}
            value={course}
            onChange={(e) => {
              handleCourseChange(e.target.value);
            }}
            className="w-[84px] h-[20px] text-[14px] input p-[2px] mr-[8px] dark:bg-[#1F1E25] dark:border-[#2d2d2d]"
          />
        )}
      </BaseTd>
      <BaseTd extraClass={tdStyles}>
        {archived || currency === "usd" ? (
          <span>{formatPrice(currencyAmount)}</span>
        ) : (
          <NumericFormat
            decimalScale={2}
            thousandSeparator=" "
            suffix=""
            fixedDecimalScale={false}
            allowNegative={false}
            allowEmptyFormatting={true}
            value={currencyAmount}
            onChange={(e) => {
              handleCurrencyAmountChange(e.target.value);
            }}
            className="w-[84px] h-[20px] text-[14px] input p-[2px] mr-[8px] dark:bg-[#1F1E25] dark:border-[#2d2d2d]"
          />
        )}
      </BaseTd>
      <BaseTd extraClass={tdStyles + " flex justify-center"}>
        <BaseButton
          type="submit"
          icon={item.note ? CommentIcon : CommentIconDark}
          className={`w-auto px-[8px] py-[6px] rounded-[6px]  text-textBlack hover:opacity-[0.8] transition duration-200
          ${item.note ? "bg-[#FFCD29]" : "bg-[#F2f2f2]"}
          `}
          iconClassName="w-[20px]"
          onClick={() => {
            searchParams.set("item_id", item.id);
            searchParams.set("comment_type", currentTable);
            item.note ? searchParams.set("comment", item.note) : searchParams.set("comment", "");
            setSearchParams(searchParams);
            handleTemplatesModalComments(true);
          }}
        />
      </BaseTd>
      <BaseTd extraClass={tdStyles}>
        <div className="text-center flex justify-center">
          {archived ? (
            <span></span>
          ) : (
            <img
              src={iconTrash}
              alt="delete"
              className={`${isHover ? "opacity-1" : "opacity-0"} cursor-pointer width-[20px] height-[20px] text-center`}
              onClick={() => {
                setDeleteId(item.id); // Set the id to deleteId ref in Tbody component
                closeDeleteModal(true);
              }}
            />
          )}
        </div>
      </BaseTd>
    </tr>
  );
}

const Tbody2 = ({ data, archived, currentTable, singleInvoice, updateSingleInvoice, handleTemplatesModalComments }) => {
  const closeModal = useRef();
  const deleteId = useRef();

  const closeDeleteModal = (value) => {
    closeModal.current.openModal(value);
  };

  const deletePayment = useMutate({
    onSuccess: () => {
      const newSingleInvoice = { ...singleInvoice };

      if (currentTable === "outgoPayments") {
        newSingleInvoice.outgo_payments = newSingleInvoice.outgo_payments.filter(
          (item) => item.id !== deleteId.current,
        );
      } else {
        newSingleInvoice.income_payments = newSingleInvoice.income_payments.filter(
          (item) => item.id !== deleteId.current,
        );
      }

      updateSingleInvoice(newSingleInvoice);

      closeDeleteModal(false);
      notifySuccess("Успешно удалено");
    },
  });

  const confirmDelete = () => {
    deletePayment.mutate({
      // Assuming deleteId.current holds the actual ID after being set by setDeleteId
      link: `${endpoints.invoices.deleteInvoicePayment}?id=${deleteId.current}`,
      method: "delete",
    });
  };

  return (
    <>
      {data.map((item, index) => (
        <PaymentItem
          key={item.id}
          item={item}
          index={index}
          arrLength={data.length}
          setDeleteId={(id) => (deleteId.current = id)}
          handleTemplatesModalComments={handleTemplatesModalComments}
          archived={archived}
          currentTable={currentTable}
          singleInvoice={singleInvoice}
          updateSingleInvoice={updateSingleInvoice}
          closeDeleteModal={closeDeleteModal}
        />
      ))}

      <DeleteConfirmation ref={closeModal} close={closeDeleteModal} cb={confirmDelete} />
    </>
  );
};

export default Tbody2;
