import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import cls from "classnames";

import BaseButton from "components/Button/BaseButton";
import TableAction from "components/Table/TableAction";
import BaseTd from "components/Table/BaseTd";
import ControlledCheckbox from "components/Checkbox/ControlledCheckbox";
import { Tooltip } from "components/Tooltip/Tooltip";
import GetBlockImage from "components/GetBlockImage/GetBlockImage";

import { checkHasAccessToAction } from "services/helpers";
// i18n
import { useTranslation } from "react-i18next";

import iconTrash from "assets/images/svg/trash-red.svg";
import IconDefault from "assets/images/svg/icon-default.svg";
import UserBlock from "assets/images/svg/icon-block.svg";

const Tbody = ({ data, isChecked, checkboxes, setCheckboxes, closeDeleteModal, userToggleState }) => {
  const { t } = useTranslation();

  let [_, setSearchParams] = useSearchParams();

  const hasAccessToEdit = checkHasAccessToAction("update-user");
  const hasAccessToDelete = checkHasAccessToAction("delete-user");

  const selectTableRow = (rowId) => {
    setCheckboxes({ ...checkboxes, [rowId]: !checkboxes[rowId] });
  };

  useEffect(() => {
    const objState = {};
    if (isChecked) {
      data.forEach((item) => {
        objState[item.id] = true;
      });
      setCheckboxes(objState);
    } else {
      const filterByTrue = Object.values(checkboxes).filter((el) => el);
      if (filterByTrue.length === data.length) setCheckboxes({});
    }
  }, [isChecked]);

  return (
    <>
      {data.map((item) => (
        <tr key={item.id} className="hover:bg-GreyLight dark:hover:bg-inputBgDark">
          <BaseTd>
            <div className="flex items-center gap-2">
              <ControlledCheckbox checked={checkboxes[item.id] || false} onChange={() => selectTableRow(item.id)} />
              <img
                src={item.photo || IconDefault}
                alt="avatar"
                className={cls("table--avatar", { "rounded-full": !item.photo })}
              />
              <div className="flex gap-2 items-center">
                <span>{item.full_name}</span>
                {item.state == "INACTIVE" ? (
                  <div className="relative z-[1]">
                    <Tooltip
                      position="-top-14"
                      arrowPostion="bottom"
                      className="left-[-63px] w-36 top-"
                      content={<div className="text-textLight text-[14px]">{t("info.accessDenied")}</div>}
                    >
                      <img src={UserBlock} alt="user block" />
                    </Tooltip>
                  </div>
                ) : null}
              </div>
            </div>
          </BaseTd>
          <BaseTd>{item.username}</BaseTd>
          <BaseTd>{item.phone_number}</BaseTd>
          <BaseTd>{item.org_name ? item.org_name : "-"}</BaseTd>
          <BaseTd>{item.roles.map((role) => role.name).join(", ")}</BaseTd>
          <BaseTd>
            <div className="flex items-center gap-4 justify-end">
              {hasAccessToEdit && item.username !== "root" && (
                <BaseButton
                  label={t("button.edit")}
                  labelClass="text-regular font-light"
                  className="btn-gray hover:bg-GreyInput dark:text-white dark:bg-midBlack dark:border-midBlack py-[6px] text-md h-[32px] text-[15px]"
                  onClick={() => setSearchParams({ row_id: item.id })}
                />
              )}
              {hasAccessToDelete && (
                <TableAction hasBackground boxClass={"w-44"} parentClass="!h-[32px]">
                  <div
                    className="flex gap-2 px-3 py-[5px] rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                    onClick={() => userToggleState(item.id, item.state)}
                  >
                    <GetBlockImage state={item.state} />
                  </div>
                  <div
                    className="flex gap-2 px-3 py-[5px] rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                    onClick={() => closeDeleteModal(true, item.id)}
                  >
                    <img src={iconTrash} alt="delete" />
                    <p className="text-[#FF705D] text-sm">{t("button.delete")}</p>
                  </div>
                </TableAction>
              )}
            </div>
          </BaseTd>
        </tr>
      ))}
    </>
  );
};

export default Tbody;
