import React, { memo } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";

// api
import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// helpers
import useUsers from "pages/Invoices/hooks/useUsers";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";
import ControlledSelect from "components/Select/ControlledSelect";
import { useCoreStore } from "store/core";
// icons
import IconSave from "assets/images/svg/icon-save.svg";

const ModalSaveInvoice = memo(({ setArchived, close, refetch, row }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const { orgId } = useCoreStore((state) => state);

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur", // or "onChange"
  });

  const { customerOptions: customerList } = useUsers(orgId);

  function removeId() {
    reset();
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: () => {
      refetch();
      reset();
      clearAndClose();
      setArchived(false);
      notifySuccess(t("notification.invoiceSuccessCreate"));
    },
  });

  const onSubmit = (values) => {
    const body = {
      customer_id: values.client,
    };

    create.mutate({
      link: endpoints.invoices.customerCreate,
      params: { org_id: orgId },
      method: "post",
      body: body,
    });
  };

  return (
    <div className="w-[300px]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2">
            <ControlledSelect
              label={t("pages.manInvoice.form.customer")}
              name="client"
              errors={errors}
              height="50px"
              isRequired={true}
              placeholder={t("pages.manInvoice.form.customerPlaceholder")}
              className="w-full  placeholder-darkGreyLight rounded-lg focus:border-purple-0 focus:bg-white focus:border-2 font-[16px] max-w-[100%]"
              options={customerList}
              autoFocus={true}
              onChange={(e) => {
                setValue("client", e.value);
              }}
            />
          </div>

          <div className="col-span-2 flex justify-between">
            <BaseButton
              label={t("button.cancel")}
              className={`p-2 btn-secondary dark:btn-secondary-dark`}
              onClick={clearAndClose}
            />

            <BaseButton
              icon={IconSave}
              label={t("button.save")}
              type="submit"
              className="col-span-1 bg-mainGreen text-white font-semibold"
              isLoading={create.isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
});

export default ModalSaveInvoice;
