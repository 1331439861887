import { useDropzone } from "react-dropzone";
// utils
import cls from "classnames";
import uploader from "services/uploader";
// i18n
import { useTranslation } from "react-i18next";
// components
import TableAction from "components/Table/TableAction";
// icons
import iconAddphoto from "assets/images/svg/icon-addphoto.svg";
import AddIcon from "assets/images/svg/icon-plus.svg";

const ImageUploaderMulti = ({
  url = [],
  setImagesUrl,
  label,
  name = "",
  imgIcon,
  labelClass,
  isRequired,
  errorMessages,
}) => {
  const { t } = useTranslation();
  const requestImageUpload = (event) => {
    const images = event.target.files;

    for (let i = 0; i < images.length; i++) {
      // place current image to uploader

      uploader(event.target.files[i], true).then((resp) => {
        let img = resp();
        if (img.includes("localhost")) {
          img = process.env.REACT_APP_API_PROXY_URL + img.slice(21, img.length);
        }

        if (name) {
          setImagesUrl((prev) => {
            return [...prev, img];
          });
        } else {
          setImagesUrl((prev) => [...prev, img]);
        }
      });
    }
  };

  const requestImageUploadDrag = (files) => {
    for (let i = 0; i < files.length; i++) {
      uploader(false, true, files[i]).then((resp) => {
        let img = resp();
        if (img.includes("localhost")) {
          img = process.env.REACT_APP_API_PROXY_URL + img.slice(21, img.length);
        }

        if (name) {
          setImagesUrl((prev) => {
            return [...prev, img];
          });
        } else {
          setImagesUrl((prev) => [...prev, img]);
        }
      });
    }
  };

  const deleteImg = (img) =>
    // delete this image by name (img)
    setImagesUrl((prevImg) => {
      if (Array.isArray(prevImg)) {
        return prevImg.filter((pht) => pht !== img);
      } else {
        return {
          ...prevImg,
          [name]: prevImg[name].filter((pht) => pht !== img),
        };
      }
    });

  const makeMainImg = (img) => {
    // just set it as first element
    setImagesUrl((prevImg) => {
      if (Array.isArray(prevImg)) {
        return [img, ...prevImg.filter((pht) => pht !== img)];
      } else {
        return {
          ...prevImg,
          [name]: [img, ...prevImg[name].filter((pht) => pht !== img)],
        };
      }
    });
  };

  const moveOrderImg = (img, direction) => {
    // move back or forward

    if (direction === "forward") {
      setImagesUrl((prevImg) => {
        if (Array.isArray(prevImg)) {
          const index = prevImg.findIndex((pht) => pht === img);
          if (index === 0) return prevImg;
          const newImg = prevImg.filter((pht) => pht !== img);
          newImg.splice(index - 1, 0, img);
          return newImg;
        } else {
          const index = prevImg[name].findIndex((pht) => pht === img);
          if (index === 0) return prevImg;
          const newImg = prevImg[name].filter((pht) => pht !== img);
          newImg.splice(index - 1, 0, img);
          return {
            ...prevImg,
            [name]: newImg,
          };
        }
      });
    } else if (direction === "back") {
      setImagesUrl((prevImg) => {
        if (Array.isArray(prevImg)) {
          const index = prevImg.findIndex((pht) => pht === img);
          if (index === prevImg.length - 1) return prevImg;
          const newImg = prevImg.filter((pht) => pht !== img);
          newImg.splice(index + 1, 0, img);
          return newImg;
        } else {
          const index = prevImg[name].findIndex((pht) => pht === img);
          if (index === prevImg[name].length - 1) return prevImg;
          const newImg = prevImg[name].filter((pht) => pht !== img);
          newImg.splice(index + 1, 0, img);
          return {
            ...prevImg,
            [name]: newImg,
          };
        }
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (file) => {
      requestImageUploadDrag(file);
    },
  });

  return (
    <div className="">
      {label && (
        <label
          className={cls("block text-stone-800 dark:text-white font-semibold text-[14px] mb-1", {
            "required-field": isRequired,
          })}
        >
          {label}
        </label>
      )}
      <div className={`image__uploader_large-imgs relative`}>
        {/* setted images */}

        {url.length > 0 && (
          <div
            className={`h-[187px] max-h-[187px] flex flex-row border border-[#E5E5E5] dark:border-[#2d2d2d] rounded-[4px] p-[8px] w-full gap-[4px]`}
          >
            <div className="relative w-[50%]" key={url[0]}>
              <img
                src={url[0]}
                alt="document"
                className="rounded-[4px] max-h-[100%] w-full object-cover aspect-square h-full"
              />
              <TableAction
                hasBackground
                parentClass="!h-[20px] px-[2px] opacity-[0.8]"
                className="absolute top-[5px] right-[5px] z-100"
                boxClass="z-[100]"
              >
                <div
                  className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                  onClick={() => deleteImg(url[0])}
                >
                  <p className="text-[#FF705D] text-[12px]">{t("button.delete")}</p>
                </div>
              </TableAction>
            </div>

            <div className="grid gap-[10px] grid-cols-2 w-[50%] flex-wrap max-h-[187px] overflow-auto">
              {url.length > 1 &&
                url.slice(1, url.length).map((image) => (
                  <div className="w-full h-auto aspect-square relative" key={image}>
                    <img
                      src={image}
                      alt="document"
                      className="rounded-[4px] aspect-square w-full h-full object-cover"
                    />
                    <TableAction
                      // parentClass="bg-white/[0.6] rounded-[4px] h-[16px] w-[18px]"
                      hasBackground
                      parentClass="!h-[20px] px-[2px] opacity-[0.8]"
                      className="absolute top-[5px] right-[5px] z-100"
                      boxClass="z-[100]"
                    >
                      <div
                        className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                        onClick={() => deleteImg(image)}
                      >
                        <p className="text-[#FF705D] text-[12px]">{t("button.delete")}</p>
                      </div>
                      <div
                        className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                        onClick={() => moveOrderImg(image, "back")}
                      >
                        <p className="text-textBlack text-[12px]">{t("button.forward")}</p>
                      </div>
                      <div
                        className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                        onClick={() => moveOrderImg(image, "forward")}
                      >
                        <p className="text-textBlack text-[12px]">{t("button.back")}</p>
                      </div>

                      <div
                        className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                        onClick={() => makeMainImg(image)}
                      >
                        <p className="text-textBlack text-[12px]">{t("button.makeMain")}</p>
                      </div>
                    </TableAction>
                    <div className="absolute right-[10px] top-[10px]"></div>
                  </div>
                ))}

              {/* add photo */}

              <label
                className={`w-full aspect-square relative border-1 border-dashed border-[#D1D1D1] rounded-[4px] cursor-pointer ${
                  isDragActive && "!border-0"
                }`}
                htmlFor={name || "requestPhoto"}
                {...getRootProps()}
              >
                <div className={`flex flex-col items-center transition-[0.5s] ${isDragActive && "opacity-0"}`}>
                  <input
                    {...getInputProps()}
                    type="file"
                    id={name || "requestPhoto"}
                    onChange={requestImageUpload}
                    className="w-full h-full absolute top-0 left-0 opacity-0 cursor-pointer"
                    multiple
                  />
                  <img
                    src={AddIcon}
                    alt="addphoto"
                    className=" absolute
                     top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
                      w-[20px] h-[20px] opacity-50
                     "
                  />
                </div>
                {isDragActive && (
                  <div className="absolute top-0 left-0 w-full h-full bg-[#7B61FF0D] border-2 border-[#7B61FF] border-dashed rounded-[4px] flex flex-col items-center justify-center">
                    <p className="text-[#7B61FF] font-semibold text-[8px] mb-1 text-center">{t("info.dragHere")}</p>
                  </div>
                )}
              </label>
            </div>
          </div>
        )}

        {/* preview */}

        {url.length === 0 && (
          <label
            htmlFor={name || "requestPhoto"}
            className={`image__uploader_large-img image__uploader_large-img-large create dark:!bg-[#1F1E25] dark:!border-[#2d2d2d] cursor-pointer ${labelClass}
            ${errorMessages && "!border !border-red-500"}
            `}
            {...getRootProps()}
          >
            <div className={`flex flex-col items-center transition-[0.5s] ${isDragActive && "opacity-0"}`}>
              <input
                {...getInputProps()}
                type="file"
                id={name || "requestPhoto"}
                onChange={requestImageUpload}
                className="w-[120px]"
                multiple
              />

              <img src={imgIcon || iconAddphoto} alt="addphoto" />
              <h2 className="textBlack font-semibold text-[15px] dark:text-[#9B9DA3]">{t("info.pressOrDrag")}</h2>
              <p className="text-[#9B9DA3] font-normal text-[13px]">{t("info.minimumSize")} 256px</p>
            </div>
            {isDragActive && (
              <div className="absolute top-0 left-0 w-full h-full bg-[#7B61FF0D] border-2 border-[#7B61FF] border-dashed rounded-[4px] flex flex-col items-center justify-center">
                <p className="text-[#7B61FF] font-semibold text-[14px] mb-1">{t("info.dragHere")}</p>
              </div>
            )}
          </label>
        )}
      </div>
    </div>
  );
};

export default ImageUploaderMulti;
