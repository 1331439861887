import { useRef, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import useSearch from "hooks/useSearch/useSearch";
// api
import { endpoints } from "services/endpoints";
import { useGet } from "hooks/query";
// i18n
import { useTranslation } from "react-i18next";
// components
import { ParameterContext } from "../ParametrLayout";
import Modal from "components/Modal/Modal";
import ModalForm from "./ModalForm";
import Table from "./Table";

const ParametrSecond = () => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  let queryId = searchParams.get("row_id2");
  const modal2 = useRef();
  // const { orgId } = useCoreStore((state) => state);
  const { parentIds } = useContext(ParameterContext);
  const { searchResult, handleSearch } = useSearch();

  const handleTemplatesModal = (value) => {
    modal2.current.openModal(value);
  };

  const { data, isLoading, isError, refetch } = useGet({
    link: endpoints.parameterValue.list,
    params: { property_id: parentIds.parentId, q: searchResult || undefined },
  });

  const { data: singleData } = useGet({
    link: endpoints.parameterValue.one,
    params: { id: queryId },
    queryKey: queryId,
    enabled: !!queryId,
  });

  // const { singleData } = useGetSingleData(queryId, data);

  useEffect(() => {
    if (queryId && modal2.current) {
      handleTemplatesModal(true);
    }
  }, [queryId]);

  if (isLoading) return <p>Loading</p>;
  if (isError) return <p>Errror</p>;

  return (
    <>
      <Table data={data} refetch={refetch} modalToggler={handleTemplatesModal} handleSearch={handleSearch} />
      <Modal
        ref={modal2}
        rowId="row_id2"
        title={queryId ? t("pages.parameters.editValue") : t("pages.parameters.addValue")}
      >
        <ModalForm
          close={handleTemplatesModal}
          refetch={refetch}
          row={queryId && singleData ? singleData : {}}
          orgId={queryId}
        />
      </Modal>
    </>
  );
};

export default ParametrSecond;
