import React, { useLayoutEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useSearch from "hooks/useSearch/useSearch";
// api
import { useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
// store
import { useCoreStore } from "store/core";
// i18n
import { useTranslation } from "react-i18next";
// components
import CircleLoader from "components/Loader/CircleLoader";
import Modal from "components/Modal/Modal";
import Table from "./Table/Table";
import ModalForm from "./ModalForm";

const ExchangeRate = () => {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  let [searchParams, _] = useSearchParams();
  let queryId = searchParams.get("row_id");
  const { orgId } = useCoreStore((state) => state);
  const { searchResult, handleSearch } = useSearch();

  const modal = useRef();

  const handleTemplatesModal = (value) => {
    modal.current.openModal(value);
  };

  const { data, isLoading, isError, refetch } = useGet({
    link: endpoints.exchangeRate.get,
    params: { org_id: orgId, size: 10, page: page + 1, q: searchResult || undefined },
  });

  const { data: singleData, isFetching } = useGet({
    link: endpoints.exchangeRate.one,
    params: { id: queryId },
    enabled: !!queryId,
  });

  useLayoutEffect(() => {
    if (queryId && modal.current) {
      handleTemplatesModal(true);
    }
  }, [queryId]);

  const paginationConfig = {
    forcePage: page,
    total: data?.total,
    pageCount: Math.ceil((data?.total ?? 10) / 10),
    pageRangeDisplayed: 2,
    onPageChange: ({ selected }) => setPage(selected % data?.total),
  };

  if (isLoading) return <p>Loading</p>;
  if (isError) return <p>Errror</p>;

  return (
    <div className="flex flex-col h-full">
      <Table
        data={data.data}
        refetch={refetch}
        modalToggler={handleTemplatesModal}
        handleSearch={handleSearch}
        paginationConfig={paginationConfig}
        setPage={setPage}
      />
      <Modal
        classNameExtra="!overflow-visible"
        ref={modal}
        title={queryId ? t("pages.kurs.update") : t("pages.kurs.add")}
      >
        {isFetching ? (
          <div className="w-full flex justify-center">
            <CircleLoader />
          </div>
        ) : (
          <ModalForm close={handleTemplatesModal} refetch={refetch} row={queryId && singleData ? singleData : {}} />
        )}
      </Modal>
    </div>
  );
};

export default ExchangeRate;
