// helpers
import { formatTimestamp } from "services/helpers";
import { useSearchParams } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
// components
import BaseButton from "components/Button/BaseButton";
import Table from "../Table/Table";
// icons
import Box from "assets/images/svg/box-black.svg";
import CommentIcon from "assets/images/svg/comment-icon.svg";
import CommentIconDark from "assets/images/svg/comment-icon-dark.svg";
import AddSquareIcon from "assets/images/svg/add_square-icon.svg";

const SingleInvoice = ({
  singleInvoice,
  archived,
  updateSingleInvoice,
  currentTable,
  setCurrentTable,
  refetch,
  singleInvoiceRefetch,
  handleTemplatesModalAddProduct,
  handleTemplatesModalAddPayment,
  handleTemplatesModalComments,
  handleTemplatesModalSaveInvoice,
  handleTemplatesModalBoxCount,
}) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  // no render if no invoice
  //   if (!singleInvoice) {
  //     return null;
  //   }

  return (
    <div className="h-full flex flex-col relative">
      <div className="flex flex-row justify-between h-[44px]">
        <div className="bg-[#F2F2F2] dark:bg-[#1F1E25] dark:text-[#9B9DA3] rounded-lg items-center flex flex-row p-[14px] opacity">
          <div className="mr-[35px]">
            <span>{t("info.date")}: </span>
            <span>{formatTimestamp(singleInvoice.created_at, false)}</span>
          </div>
          <div className="flex flex-row items-center">
            <span className="mr-[6px]">
              {t("pages.customers.table.customer")}: {singleInvoice.customer_name}
            </span>
            <span>{singleInvoice.name}</span>
            <div className="ml-[4px] bg-[#9B9DA3] px-[4px] rounded-lg">
              <span className="text-[#F2F2F2]">{singleInvoice.code}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-row">
          <div
            className="flex rounded-lg bg-[#F2F2F2] items-center justify-center min-w-[44px] h-[44px] mr-[8px] px-[8px] cursor-pointer"
            onClick={() => {
              handleTemplatesModalBoxCount(true);
              searchParams.set("id", singleInvoice.id);
              searchParams.set("num", singleInvoice.box_count || "");
              setSearchParams(searchParams);
            }}
          >
            <img src={Box} alt="Box" className="w-[16px]"></img>
            <span className="ml-[4px] text-[#2D2D34] text-[15px]">{singleInvoice.box_count}</span>
          </div>

          <div className="btns flex flex-row space-x-[8px]">
            <BaseButton
              type="submit"
              icon={singleInvoice.note ? CommentIcon : CommentIconDark}
              className={`w-full p-[16px] rounded-lg bg-[#FFCD29] text-textBlack hover:opacity-[0.8] transition duration-200 ${
                singleInvoice.note ? "bg-[#FFCD29]" : "bg-[#F2f2f2]"
              }`}
              iconClassName="w-[20px]"
              onClick={() => {
                searchParams.set("id", singleInvoice.id);
                singleInvoice.note ? searchParams.set("comment", singleInvoice.note) : searchParams.set("comment", "");
                searchParams.set("comment_type", "invoice");
                searchParams.delete("item_id");
                setSearchParams(searchParams);
                handleTemplatesModalComments(true);
              }}
            />
            {!archived && (
              <>
                {currentTable === "payment" && (
                  <BaseButton
                    type="submit"
                    icon={AddSquareIcon}
                    onClick={() => {
                      handleTemplatesModalAddPayment(true);
                      searchParams.set("type", "OUTGO");
                      setSearchParams(searchParams);
                    }}
                    className="w-full p-[16px] rounded-lg bg-[#FF705D] text-textBlack hover:opacity-[0.8] transition duration-200"
                    iconClassName="w-[20px]"
                  />
                )}

                <BaseButton
                  type="submit"
                  icon={AddSquareIcon}
                  className={`
              ${currentTable === "arrival" && "bg-[#2196F3]"} 
              ${currentTable === "consumption" && "bg-[#FF705D]"}
              ${currentTable === "payment" && "bg-[#2196F3]"}
                w-full p-[16px] rounded-lg text-textBlack hover:opacity-[0.8] transition duration-200"
              iconClassName="w-[20px]`}
                  onClick={() => {
                    if (currentTable === "arrival") {
                      handleTemplatesModalAddProduct(true);
                      searchParams.set("type", "INCOME");
                      setSearchParams(searchParams);
                    } else if (currentTable === "consumption") {
                      handleTemplatesModalAddProduct(true);
                      searchParams.set("type", "OUTGO");
                      setSearchParams(searchParams);
                    } else if (currentTable === "payment") {
                      handleTemplatesModalAddPayment(true);
                      searchParams.set("type", "INCOME");
                      setSearchParams(searchParams);
                    }
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 bg-[#FAFAFA] dark:bg-[#1F1E25] border-solid border-[2px] border-[#F2F2F2] dark:border-[transparent] rounded-lg w-full p-[4px] my-[16px]">
        <div
          onClick={() => setCurrentTable("consumption")}
          className={`${
            currentTable === "consumption" ? "bg-[#FF705D] text-white" : "text-[#FF705D]"
          } col-span-1 rounded-lg flex items-center justify-center cursor-pointer`}
        >
          <span>
            {t("pages.betweenWarehouse.expense")} {`(${singleInvoice.outgo_products.length})`}
          </span>
        </div>
        <div
          onClick={() => setCurrentTable("arrival")}
          className={`${
            currentTable === "arrival" ? "bg-[#2196F3] text-white" : "text-[#2196F3]"
          } col-span-1 rounded-lg flex items-center justify-center cursor-pointer h-[36px]`}
        >
          <span>
            {t("pages.betweenWarehouse.income")} {`(${singleInvoice.income_products.length})`}
          </span>
        </div>
        <div
          onClick={() => setCurrentTable("payment")}
          className={`${
            currentTable === "payment" ? "bg-[#FACD29] text-white" : "text-[#FACD29]"
          } col-span-1 rounded-lg flex items-center justify-center cursor-pointer`}
        >
          <span>
            {t("pages.manInvoice.payment")}{" "}
            {`(${singleInvoice.income_payments.length + singleInvoice.outgo_payments.length})`}
          </span>
        </div>
      </div>
      <Table
        archived={archived}
        outgoProducts={singleInvoice.outgo_products}
        incomeProducts={singleInvoice.income_products}
        incomePayments={singleInvoice.income_payments}
        outgoPayments={singleInvoice.outgo_payments}
        singleInvoice={singleInvoice}
        updateSingleInvoice={updateSingleInvoice}
        currentTable={currentTable}
        refetch={refetch}
        singleInvoiceRefetch={singleInvoiceRefetch}
        handleTemplatesModalSaveInvoice={handleTemplatesModalSaveInvoice}
        handleTemplatesModalComments={handleTemplatesModalComments}
      />
    </div>
  );
};

export default SingleInvoice;
