// i18n
import { useTranslation } from "react-i18next";

export const useTranslatedTableConfig = () => {
  const { t } = useTranslation();

  const header = [
    {
      key: "number",
      title: "№",
      width: "w-[5%]",
    },
    {
      key: "name",
      title: t("pages.betweenWarehouse.table.name"),
      width: "w-[70%]",
    },
    {
      key: "input",
      title: t("pages.betweenWarehouse.table.quantity"),
      width: "w-[15%]",
    },
    {
      key: "comment",
      title: t("info.comment"),
      width: "w-[10%]",
    },
  ];

  return { header };
};
