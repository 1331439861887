import { Suspense } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import cls from "classnames";

import Header from "container/Header/Header";
import Navigator from "container/Navigator/Navigator";
import { Footer } from "components/Footer/Footer";

import { useCoreStore } from "store/core";
import UseUserInfoGetter from "hooks/userInfoInterval/useUserInfoGetter";

const RequireAuth = () => {
  const location = useLocation();
  const isOpenSidebar = useCoreStore((state) => state.isOpenSidebar);
  UseUserInfoGetter();
  const user_token = localStorage.getItem("user_token");

  return user_token ? (
    <div className="h-full flex">
      <div className={cls("transition-all flex-shrink-0", isOpenSidebar ? "w-[4rem]" : "w-80")}>
        <Navigator />
      </div>
      <div className="w-full h-[100vh] flex flex-col overflow-hidden">
        <Header />
        <Suspense fallback={<h1>Loading...</h1>}>
          <div className="flex-1 w-full p-4 pb-[30px] bg-white dark:bg-blue-3 overflow-hidden">
            <Outlet />
          </div>
        </Suspense>
        <Footer />
      </div>
    </div>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
