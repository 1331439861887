import React, { useState, memo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useCoreStore } from "store/core";
// api
import { useGet, useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import ImageUploaderLarge from "components/ImageUploaderLarge";
import Input from "components/Input/Input";
import CustomSelect from "components/Select/Select";
import BaseButton from "components/Button/BaseButton";
import Checkbox from "components/Checkbox/Checkbox";
import NumericField from "components/NumericField/NumericField";
//Custom hooks
import useOrganization from "../hooks/useOrganization";

import { getOptionValue, dataToOptions, isOrgowner } from "services/helpers";

// assets
import IconEyeOpen from "assets/images/svg/icon-eye-open.svg";
import IconEyeOpenWhite from "assets/images/svg/icon-eye-open-white.svg";
import IconSave from "assets/images/svg/icon-save.svg";
import LockIcon from "assets/images/svg/icon-input-lock.svg";
import LockIconDark from "assets/images/svg/icon-input-lock-dark.svg";
import useDebounce from "hooks/useDebounce/useDebounce";

const ModalForm = memo(({ close, refetch, row }) => {
  const { t } = useTranslation();
  const orgIdState = useCoreStore((state) => state.orgId);

  const [passwordEye, setPasswordEye] = useState(false);
  const [orgId, setOrgId] = useState(orgIdState);
  const [roleValue, setRoleValue] = useState("");
  const [imagesUrl, setImagesUrl] = useState([]);
  const [firstLogin, setFirstLogin] = useState("");
  const [usedOrg, setUsedOrg] = useState(false);
  const [selectRules, setSelectRules] = useState({
    organization: {
      isDisabled: false,
      required: true,
    },
    branch: {
      isDisabled: false,
      required: false,
    },
    warehouse: {
      isDisabled: false,
      required: false,
    },
  });
  const [searchResult, setSearchResult] = useState();

  let [searchParams, setSearchParams] = useSearchParams();
  const rowId = searchParams.get("row_id");

  // const orgOwnerState = isOrgowner();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      state: true,
      // roles: {
      //   label: "org-admin",
      //   value: "org-admin",
      // },
    },
  });
  const username = watch("username");
  const theme = useCoreStore((state) => state.theme);

  const { orgOptions, subOrgOptions, warehouseOptions } = useOrganization(orgId);

  useDebounce(() => setSearchResult(username), [username], 500);

  useEffect(() => {
    if (rowId) {
      setValue("username", row.username);
      setValue("full_name", row.full_name);
      setValue("email", row.email);
      setValue("phone_number", row.phone_number.slice(3));
      setValue("state", row.state === "ACTIVE");
      setFirstLogin(row.username);
    } else {
      reset({
        username: "",
        password: "",
        full_name: "",
        // roles: 'org-admin',
        org_id: {},
        sub_org_id: {},
        warehouse_id: {},
        phone_number: "",
        email: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (row.photo) {
      setImagesUrl([row.photo]);
    } else {
      setImagesUrl([]);
    }
  }, [row.photo]);

  useEffect(() => {
    handleChangeOrgId({ value: orgId });
  }, [orgId]);

  useEffect(() => {
    if (row.org_id && !usedOrg) {
      setOrgId(row.org_id);
      const findInitVal = orgOptions.find((el) => el.value === row.org_id);
      setValue("org_id", findInitVal);
    }
  }, [row.org_id, orgOptions]);

  useEffect(() => {
    if (row.org_id) {
      setOrgId(row.org_id);
      const findInitVal = subOrgOptions.find((el) => el.value === row.sub_org_id);
      setValue("sub_org_id", findInitVal);
    }
  }, [row.sub_org_id, subOrgOptions]);

  useEffect(() => {
    if (row.org_id) {
      const findInitVal = warehouseOptions.find((el) => el.value === row.warehouse_id);
      setValue("warehouse_id", findInitVal);
    }
  }, [row.warehouse_id, warehouseOptions]);

  const { data, isFetching } = useGet({
    link: endpoints.users.check,
    params: { username: searchResult },
    enabled: searchResult?.length >= 3 && firstLogin !== searchResult,
  });

  const { data: role } = useGet({
    link: endpoints.roles.get,
    params: { q: "org-manager" },
  });

  function handleChangeRole(val) {
    setRoleValue(val.label);
  }

  const togglePassword = () => {
    setPasswordEye((prev) => !prev);
  };

  function handleChangeOrgId(val) {
    // Reset the values of sub_org_id and warehouse_id
    setValue("sub_org_id", null);
    setValue("warehouse_id", null);

    setOrgId(val.value);

    setUsedOrg(true); // TODO check this
  }

  function generatePassword() {
    const randomPassword = Math.random().toString(36).slice(8) + Math.random().toString(36).slice(8);
    setValue("password", randomPassword);

    if (navigator.clipboard.writeText(randomPassword)) {
      notifySuccess(t("notification.passwordCopy"));
    }
  }

  function removeId() {
    reset({
      username: "",
      password: "",
      full_name: "",
      // roles: {},
      org_id: {},
      sub_org_id: {},
      warehouse_id: {},
      phone_number: "",
      email: "",
    });
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }

  function clearAndClose() {
    removeId();
    close(false);
    setOrgId("");
    setImagesUrl([]);
  }

  const create = useMutate({
    onSuccess: () => {
      refetch();
      reset({
        username: "",
        password: "",
        full_name: "",
        // roles: {},
        org_id: {},
        sub_org_id: {},
        warehouse_id: {},
        phone_number: "",
        email: "",
      });
      clearAndClose();
      notifySuccess(t("notification.userSuccessCreate"));
    },
  });

  const update = useMutate({
    onSuccess: () => {
      refetch();
      reset({
        username: "",
        password: "",
        full_name: "",
        // roles: {},
        sub_org_id: {},
        warehouse_id: {},
        phone_number: "",
        email: "",
      });
      clearAndClose();
      notifySuccess(t("notification.userSuccessUpdate"));
    },
  });

  function onSubmit(values) {
    const body = {
      ...values,
      org_id: orgId,
      photo: imagesUrl[0],
      state: values.state ? "ACTIVE" : "INACTIVE",
      phone_number: `998${values.phone_number}`,
      sub_org_id: values.sub_org_id?.value,
      warehouse_id: values.warehouse_id?.value,
      // roles: [role[0].id],
    };

    if (rowId) {
      const { password, ...rest } = body;
      update.mutate({
        link: `${endpoints.users.update}?id=${rowId}`,
        method: "put",
        body,
      });
    } else {
      create.mutate({
        link: endpoints.users.create,
        method: "post",
        body: body,
      });
    }
  }

  return (
    <div className="w-[610px]">
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <input autoComplete="false" name="hidden" type="text" className="hidden" />
        <div className="flex gap-3">
          <div className="flex-1 w-[250px]">
            <ImageUploaderLarge
              url={imagesUrl}
              label={t("pages.mainUsers.form.profileCover")}
              setImagesUrl={setImagesUrl}
            />
            <Input
              autoFocus
              label={t("pages.mainUsers.form.username")}
              fieldName="username"
              autocomplete="off"
              register={register}
              errors={errors}
              isLoading={isFetching}
              isRequired={true}
              className={`w-full h-[44px] ${data?.has_user && searchResult?.length >= 3 && "border-red-500"}`}
              placeholder={t("pages.mainUsers.form.usernamePlaceholder")}
            />
            {data?.has_user && searchResult?.length >= 3 && searchResult !== firstLogin && (
              <p className="mt-[-6px] text-red-500 text-[13px]">{t("info.loginTaken")}</p>
            )}
            <Input
              type={passwordEye ? "text" : "password"}
              label={t("pages.mainUsers.form.password")}
              placeholder={t("pages.mainUsers.form.passwordPlaceholder")}
              fieldName="password"
              autocomplete="off"
              register={register}
              errors={errors}
              rightElement={
                <div className="flex items-center gap-2">
                  <img
                    src={theme ? LockIconDark : LockIcon}
                    alt="generator"
                    className="cursor-pointer"
                    onClick={generatePassword}
                  />
                  <img
                    src={theme ? IconEyeOpenWhite : IconEyeOpen}
                    alt="CloseIcon"
                    className="cursor-pointer"
                    onClick={togglePassword}
                  />
                </div>
              }
              isRequired={rowId ? false : true}
              className="w-full h-[44px] border-[transparent]"
            />
            <Input
              label={t("pages.mainUsers.form.name")}
              fieldName="full_name"
              register={register}
              errors={errors}
              isRequired={true}
              className="w-full h-[44px]"
              placeholder={t("pages.mainUsers.form.namePlaceholder")}
            />
          </div>
          <div className="flex-1 w-[250px]">
            <div className="mb-2">
              <Controller
                name="sub_org_id"
                control={control}
                rules={{ required: selectRules.branch.required }}
                render={({ field }) => (
                  <CustomSelect
                    {...field}
                    errors={errors}
                    isRequired={true}
                    label={t("pages.mainUsers.form.branch")}
                    isDisabled={selectRules.branch.isDisabled}
                    options={subOrgOptions}
                    placeholder={t("pages.mainUsers.form.branchPlaceholder")}
                  />
                )}
              />
            </div>
            <div className="mb-2">
              <Controller
                name="warehouse_id"
                control={control}
                rules={{ required: selectRules.warehouse.required }}
                render={({ field }) => (
                  <CustomSelect
                    {...field}
                    errors={errors}
                    isRequired={true}
                    label={t("pages.mainUsers.form.warehouse")}
                    isDisabled={selectRules.warehouse.isDisabled}
                    options={warehouseOptions}
                    placeholder={t("pages.mainUsers.form.warehousePlaceholder")}
                  />
                )}
              />
            </div>

            <Controller
              name="phone_number"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <NumericField
                  placeholder="+998 -- --- -- --"
                  format="+998 ## ### ## ##"
                  errors={errors}
                  label={t("form.phoneNumber")}
                  className="w-full h-[44px]"
                  allowEmptyFormatting={true}
                  mask="-"
                  {...field}
                />
              )}
            />
            <Input
              label="Email"
              fieldName="email"
              type="email"
              register={register}
              errors={errors}
              className="w-full h-[44px]"
              placeholder={t("form.emailPlaceholder")}
            />
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <Checkbox
                  label={t("button.activeUser")}
                  {...field}
                  classNameExtra="mt-11"
                  labelClassName="text-[14px]"
                />
              )}
            />
          </div>
        </div>
        <div className="flex justify-between mt-[20px]">
          <BaseButton
            label={t("button.cancel")}
            onClick={clearAndClose}
            className="p-2 btn-secondary dark:btn-secondary-dark"
          />

          <BaseButton
            type="submit"
            className="p-2 btn-success"
            label={rowId ? t("button.update") : t("button.save")}
            icon={IconSave}
            // disabled={data?.has_user || searchResult?.length < 3}
            isLoading={create.isLoading || update.isLoading}
          />
        </div>
      </form>
    </div>
  );
});

export default ModalForm;
