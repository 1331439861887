import { forwardRef } from "react";
import Select from "react-select";
import cls from "classnames";

const CustomSelect = forwardRef((props, ref) => {
  const {
    name,
    label,
    errors,
    value,
    onChange,
    onInputChange,
    styles,
    placeholderStyles,
    options = [],
    handleChange,
    isClearable = true,
    isDisabled = false,
    isRequired = false,
    isMulti = false,
    height = "44px",
    overflow = "hidden",
    ...rest
  } = props;

  const customStyles = {
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      maxHeight: "calc(100% - 3px)", // Adjust the height of the container
      overflowY: overflow,
      padding: "0",
    }),
    option: (styles, state) => ({
      ...styles,
      cursor: "pointer",
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      padding: "0",
      // height: height || "100%",
    }),
    dropdownIndicator: (baseStyles) => ({
      ...baseStyles,
      padding: "0 8px",
      // height: height || "100%",
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      padding: "6px 5px;",
      color: "red",
      height: height || "100%",
      ":active": {
        color: "white",
      },
      ...styles,
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      padding: "0",
      width: "100%", // Set the desired width here
      // You can also customize other properties like maxHeight, boxShadow, etc.
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      ...placeholderStyles,
    }),

    // Styles for the multi-value container (the container that wraps the tags)
    multiValue: (baseStyles) => ({
      ...baseStyles,
      display: "flex",
      maxWidth: "calc(100% - 20px)", // Adjust the max-width as needed
      margin: "2px", // Adds a little space around each tag
      // Add any specific styles you need, like padding or background-color
    }),

    // Styles for each tag in the multi-select
    multiValueLabel: (baseStyles) => ({
      ...baseStyles,
      maxWidth: "150px", // Prevent the label from becoming too wide
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      // Customize the look of each tag, like font size or color
    }),
    clearIndicator: (baseStyles) => ({
      ...baseStyles,
      position: "relative",
      left: "15px",
      padding: "0 10px",
    }),
  };

  return (
    <div>
      {label && (
        <label
          className={cls("block text-stone-800 dark:text-white font-semibold text-[14px]", {
            "required-field": isRequired,
          })}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <Select
        {...rest}
        isClearable={isClearable}
        isMulti={isMulti}
        ref={ref}
        options={options}
        isDisabled={isDisabled}
        value={value || (isMulti ? [] : null)}
        height="44px"
        className="my-react-select-container"
        classNamePrefix="my-react-select"
        menuPosition="fixed"
        // menuPortalTarget={document.body}
        // menuPlacement="top"
        onChange={(val) => {
          onChange(val);
          handleChange && handleChange(val);
        }}
        onInputChange={onInputChange}
        styles={customStyles}
      />
      {errors[name] && <p className="error-message text-sm mb-4 mt-1 text-red-500">{label} ni tanlang</p>}
    </div>
  );
});

export default CustomSelect;
