const ControlledCheckbox = ({ checked, onChange, label, name }) => {
  return (
    <div className="flex gap-2 items-center">
      <input
        type="checkbox"
        id={name}
        className="w-4 h-4 text-purple-1 accent-purple-1 hover:accent-purple-1 bg-gray-100 border-gray-300 rounded dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        checked={checked}
        onChange={onChange}
      />
      {label && (
        <label htmlFor={name} className="cursor-pointer text-[#2D2D34] dark:text-textDark">
          {label}
        </label>
      )}
    </div>
  );
};

export default ControlledCheckbox;
