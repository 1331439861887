export const languages = [
  {
    title: "O’z",
    key: "lat",
  },
  {
    title: "Ру",
    key: "ru",
  },
];

export const ROLES = {
  Admin: "admin",
  User: "customer",
};

export const getCurrentVersion = () => {
  const packageInfo = require("../../package.json");
  return packageInfo.version;
};
