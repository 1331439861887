import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import shallow from "zustand/shallow";
import { useCoreStore } from "store/core";
// components
import NavigatorBase from "./NavigatorBase";
import NavigatorAdminInvoice from "./NavigatorAdminInvoice";
import NavigatorSale from "./NavigatorSale";

const Navigator = () => {
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  // clear search params

  useEffect(() => {
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }, []);

  const { isOpenSidebar, toggleSidebar, setOrgId } = useCoreStore(
    (state) => ({
      isOpenSidebar: state.isOpenSidebar,
      toggleSidebar: state.toggleSidebar,
      setOrgId: state.setOrgId,
    }),
    shallow,
  );

  const renderNavigator = () => {
    switch (location.pathname) {
      case "/admin-invoices":
        return <NavigatorAdminInvoice />;
      case "/sale":
        return <NavigatorSale />;
      default:
        return <NavigatorBase />;
    }
  };

  return <div className="h-screen bg-[#f2f2f2] dark:bg-mainGreyDark relative">{renderNavigator()}</div>;
};

export default Navigator;
