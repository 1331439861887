import { useCoreStore } from "store/core";

import IconTrash from "assets/images/svg/trash-red.svg";
import EditIconDark from "assets/images/svg/edit.svg";
import EditIconLight from "assets/images/svg/edit-light.svg";
import DeleteIcon from "assets/images/svg/delete.svg";
import IconMove from "assets/images/svg/icon-move.svg";

const themeIcon = {
  [true]: EditIconDark,
  [false]: EditIconLight,
};

function btnImage(theme, type) {
  switch (type) {
    case "delete":
      return DeleteIcon;
    case "delete-group":
      return IconTrash;
    case "add":
      return themeIcon[theme];
    case "move":
      return IconMove;

    default:
      return themeIcon[theme];
  }
}

function ActionButton({ type = "delete", className, handleClick = () => {} }) {
  const theme = useCoreStore((state) => state.theme);

  return (
    <div onClick={handleClick} className={`cursor-pointer py-3 px-0 flex justify-center items-center ${className}`}>
      <img src={btnImage(theme, type)} className="max-w-[90px]" alt={`${type} icon`} />
    </div>
  );
}
export default ActionButton;
