import { useState, useRef } from "react";
// helpers
import { useTranslatedTableConfig } from "../Helpers";
// components
import Tbody from "./Tbody";
import BaseTable from "components/Table/BaseTable";
import BaseThead from "components/Table/BaseThead";

const Table = ({ data = [] }) => {

  const { header2 } = useTranslatedTableConfig();
  const [isSelectedAllRows, setIsSelectedAllRows] = useState(false);
  const [checkboxes, setCheckboxes] = useState({});

  const closeModal = useRef();
  const deleteId = useRef();

  const closeDeleteModal = (value, id) => {
    deleteId.current = id;
    closeModal.current.openModal(value);
  };

  // h-[calc(100vh-745px)]
  return (
    <div className="px-2 h-[50%] overflow-y-hidden">
      <div className="w-full flex justify-between items-centers mt-[8px]"></div>
      <BaseTable
        extraClassNames="h-[100%]"
        thead={<BaseThead header={header2} extraClassTh="text-[12px]" />}
        tbody={
          <Tbody
            data={data}
            isChecked={isSelectedAllRows}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            closeDeleteModal={closeDeleteModal}
          />
        }
      />
    </div>
  );
};

export default Table;
