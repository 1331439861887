import { useEffect } from "react";
import { useCoreStore } from "store/core";
import { useLocation } from "react-router-dom";
// components
import NotificationBar from "components/NotificationBar";
import PersonBar from "components/PersonBar";
import ControlledInput from "components/Input/ControlledInput";
import BreadCrumbs from "components/Breadcumbs";
// i18n
import { useTranslation } from "react-i18next";
// icons
import KeycodesIcon from "assets/images/svg/icon-keycodes.svg";
import KeycodesIconDark from "assets/images/svg/icon-keycodes-dark.svg";
import Search from "assets/images/svg/search.svg";
import SearchWhite from "assets/images/svg/search-white.svg";
// hooks
import useMenuIcons from "hooks/useMenuIcons/useMenuIcons";

const Header = () => {
  const { t } = useTranslation();

  const { theme } = useCoreStore();

  const search = () => {
    // ...
  };

  const handleSearch = (e) => {
    // ...
  };

  const { pathname } = useLocation();
  const menus = useMenuIcons();
  const currentMenu = menus.find((menu) => menu.to === pathname);

  const crumbs = [];

  // Функция для генерации крошек
  const generateCrumbs = (menu) => {
    if (menu) {
      crumbs.unshift({
        title: menu.label,
        link: menu.to,
      });

      if (menu.subMenu) {
        const parentCrumb = {
          title: menu.label,
          link: menu.to,
        };
        crumbs.unshift(parentCrumb);

        menu.subMenu.forEach((subMenu) => {
          generateCrumbs(subMenu);
        });
      }
    }
  };
  useEffect(() => {
    generateCrumbs(currentMenu);
  }, [currentMenu]);

  return (
    <div className="w-full bg-white dark:bg-blue-3 pl-4 pt-4 pr-10">
      <div className="flex justify-between items-center ">
        <ControlledInput
          className="w-[356px] pr-14"
          onChange={handleSearch}
          placeholder={t("drawer.searchGlobal")}
          leftElement={<img src={theme ? SearchWhite : Search} alt="search" className="cursor-pointer" />}
          rightElement={
            <img
              src={theme ? KeycodesIconDark : KeycodesIcon}
              alt="KeycodesIcon"
              className="cursor-pointer"
              onClick={search}
            />
          }
        />
        <div className="h-full flex justify-end items-center gap-4">
          <div className="flex items-center">
            <NotificationBar />
            {/* <ThemeToggler /> */}
          </div>
          {/* <LangPicker /> */}
          <PersonBar />
        </div>
      </div>
      <div className="mt-4"></div>
      <BreadCrumbs path={pathname} />
    </div>
  );
};

export default Header;
