import React, { memo, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useCoreStore } from "store/core";
// hooks
import useOrganization from "../../hooks/useOrganization";
import useUsers from "../../hooks/useUsers";
// api
import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// constants
import { invoiceMainStates } from "constants";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";
import Datepicker from "react-tailwindcss-datepicker";
import ControlledSelect from "components/Select/ControlledSelect";
import Input from "components/Input/Input";
import TextArea from "components/Textarea/TextArea";
// icons
import IconSave from "assets/images/svg/icon-save.svg";

const ModalAddInvoice = memo(({ close, row }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const rowId = searchParams.get("row_id");
  const { orgId, refetchAdminInvoices } = useCoreStore((state) => state);

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur", // or "onChange"
  });

  const { warehouseOptions: warehouseList, subOrgOptions: subOrgList } = useOrganization(orgId, false);
  const { managerOptions: managerList, customerOptions: customerList } = useUsers(orgId);

  const [currentDate, setDate] = useState({
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
  });
  let prevDay = new Date();
  prevDay.setDate(prevDay.getDate() - 1);

  function removeId() {
    reset();
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (rowId) {
      setValue("tag_name", row.tag_name);
      setValue("tag_description", row.tag_description);
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: () => {
      refetchAdminInvoices();
      reset();
      clearAndClose();
      notifySuccess(t("notification.invoiceSuccessCreate"));
    },
  });

  const onSubmit = (values) => {
    const body = {
      manager_id: values.manager,
      customer_id: values.client,
      warehouse_id: values.warehouse,
      box_count: Number(values.places),
      dynamic_date: currentDate.startDate,
      close_date: currentDate.startDate,
      note: values.comment,
      send_bot: false,
      discount_sum: 0.0,
    };

    create.mutate({
      link: endpoints.invoices.create,
      params: {
        org_id: orgId,
        sub_org_id: values.branch,
      },
      method: "post",
      body: body,
    });
  };

  return (
    <div className="w-[600px]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <ControlledSelect
              label={t("pages.manInvoice.form.customer")}
              name="client"
              errors={errors}
              autoFocus={true}
              height="50px"
              isRequired={true}
              placeholder={t("pages.manInvoice.form.customerPlaceholder")}
              className="w-full  placeholder-darkGreyLight rounded-lg focus:border-purple-0 focus:bg-white focus:border-2 font-[16px] max-w-[100%]"
              options={customerList}
              onChange={(e) => {
                setValue("client", e.value);
              }}
            />
          </div>

          <div className="col-span-1">
            <label className="block text-stone-800 dark:text-white font-semibold text-[14px]">{t("info.date")}</label>
            <Datepicker
              asSingle
              useRange={false}
              placeholder={currentDate.startDate}
              displayFormat={"DD.MM.YYYY"}
              startFrom={currentDate}
              value={currentDate}
              onChange={setDate}
              primaryColor={"violet"}
              containerClassName="relative calendar-container"
              inputClassName="w-full py-3 px-4 rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey dark:text-white"
            />
          </div>
          <div className="col-span-1">
            <Input
              isRequired={true}
              fieldName="places"
              label={t("pages.admInvoice.form.places")}
              placeholder={t("pages.admInvoice.form.placesPlaceholder")}
              name="places"
              errors={errors}
              register={register}
              hasError={errors.places}
              className="w-full h-[50px] rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey"
            />
          </div>
          <div className="col-span-1">
            <ControlledSelect
              label={t("info.status")}
              name="status"
              errors={errors}
              register={register}
              height="50px"
              isRequired={true}
              placeholder={t("pages.admInvoice.form.statusPlaceholder")}
              className="w-full  placeholder-darkGreyLight rounded-lg focus:border-purple-0 focus:bg-white focus:border-2 font-[16px] max-w-[100%]"
              options={invoiceMainStates}
              onChange={(e) => {
                setValue("state", e.value);
              }}
            />
            {errors.status && <p className="error-message mt-1 text-red-500 text-[14px]">{t("info.requiredField")}</p>}
          </div>
          <div className="col-span-1">
            <ControlledSelect
              label={t("pages.customers.form.manager")}
              name="manager"
              errors={errors}
              height="50px"
              isRequired={true}
              placeholder={t("pages.customers.form.managerPlaceholder")}
              className="w-full  placeholder-darkGreyLight rounded-lg focus:border-purple-0 focus:bg-white focus:border-2 font-[16px] max-w-[100%]"
              options={managerList}
              onChange={(e) => {
                setValue("manager", e.value);
              }}
            />
          </div>
          <div className="col-span-1">
            <ControlledSelect
              label={t("pages.mainUsers.table.branch")}
              name="branch"
              errors={errors}
              height="50px"
              isRequired={true}
              placeholder={t("pages.mainUsers.selectBranch")}
              className="w-full  placeholder-darkGreyLight rounded-lg focus:border-purple-0 focus:bg-white focus:border-2 font-[16px] max-w-[100%]"
              options={subOrgList}
              onChange={(e) => {
                setValue("branch", e.value);
              }}
            />
          </div>
          <div className="col-span-1">
            <ControlledSelect
              label={t("pages.mainUsers.form.warehouse")}
              name="warehouse"
              errors={errors}
              height="50px"
              isRequired={true}
              placeholder={t("pages.mainUsers.form.warehousePlaceholder")}
              className="w-full  placeholder-darkGreyLight rounded-lg focus:border-purple-0 focus:bg-white focus:border-2 font-[16px] max-w-[100%]"
              options={warehouseList}
              onChange={(e) => {
                setValue("warehouse", e.value);
              }}
            />
          </div>
          <div className="col-span-1">
            <TextArea
              fieldName="comment"
              label={t("info.comment")}
              placeholder={t("pages.admInvoice.form.commentPlaceholder")}
              name="comment"
              errors={{}}
              register={register}
              className="w-full py-3 px-4 rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey h-[50px]"
            />
          </div>

          <div className="col-span-2 flex justify-between">
          <BaseButton
            label={t("button.cancel")}
            onClick={clearAndClose}
            className="p-2 btn-secondary dark:btn-secondary-dark"
          />

            <BaseButton
              icon={IconSave}
              label={t("button.save")}
              type="submit"
              className="col-span-1 bg-mainGreen text-white font-semibold"
              isLoading={create.isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
});

export default ModalAddInvoice;
