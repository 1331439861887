import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import shallow from "zustand/shallow";
// helpers
import { debounce } from "services/helpers";
// api
import { useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
// store
import { useCoreStore } from "store/core";
// i18n
import { useTranslation } from "react-i18next";
// components
import ControlledInput from "components/Input/ControlledInput";
import SaleDrawer from "./SaleDrawer";
// icons
import LogoutIcon from "assets/images/svg/logout-icon.svg";
import UsdIcon from "assets/images/svg/usd-icon.svg";
import UsdIconDark from "assets/images/svg/usd-icon-dark.svg";

const NavigatorSale = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const navigate = useNavigate();
  const { orgId, theme } = useCoreStore((state) => state);

  const [categories, setCategories] = useState([]);

  const { isOpenSidebar, toggleSidebar, setSaleFilters, saleFilters } = useCoreStore(
    (state) => ({
      saleFilters: state.saleFilters,
      isOpenSidebar: state.isOpenSidebar,
      toggleSidebar: state.toggleSidebar,
      setOrgId: state.setOrgId,
      setSaleFilters: state.setSaleFilters,
      theme: state.theme,
    }),
    shallow,
  );

  const { data: categoriesApi } = useGet({
    link: endpoints.category.get,
    params: { org_id: orgId, page: 1, size: 15 },
  });

  useEffect(() => {
    if (categoriesApi) {
      // map categoriesApi and set to categories as subMenu and change name property to label

      const categories = categoriesApi.data.map((category) => {
        return {
          label: category.name[lang],
          child_count: category.child_count,
          id: category.id,
          subMenu: [],
        };
      });

      setCategories((prev) => [...categories]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesApi]);

  useEffect(() => {
    console.log("categories", categories);
  }, [categories]);

  return (
    <div className={`w-full flex flex-col h-full`}>
      <div className={`p-[40px] flex justify-between items-center ${isOpenSidebar && "flex-col"}`}>
        <div className={`flex justify-start items-center hover:cursor-pointer  `} onClick={() => navigate("/home")}>
          <img src={LogoutIcon} alt="LogoutIcon" className="w-5" />
          {!isOpenSidebar && (
            <p className="text-[#535456] ml-3 text-[18px] dark:text-[#8B8999] hover:text-[#FF705C] group-hover:text-[#FF705C]">
              {t("button.logout")}
            </p>
          )}
        </div>

        <div className="flex flex-col gap-1 pl-1 -ml-[5px] items-end cursor-pointer" onClick={toggleSidebar}>
          <span className="w-5 h-[3px] rounded-sm bg-[#535456]"></span>
          <span className="w-4 h-[3px] rounded-sm bg-[#535456]"></span>
          <span className="w-5 h-[3px] rounded-sm bg-[#535456]"></span>
        </div>
      </div>

      {!isOpenSidebar && (
        <div className="mt-[10px] w-full h-full max-h-[100%] overflow-y-auto">
          <SaleDrawer categories={categories} setCategories={setCategories} isOpenSidebar={isOpenSidebar} />
        </div>
      )}

      <div className={`p-[40px] grid grid-cols-2 gap-4 mt-auto ${isOpenSidebar && "hidden"}`}>
        <div className="flex flex-row col-span-2 ">
          <img src={theme ? UsdIconDark : UsdIcon} alt="UsdIcon" className="w-5" />{" "}
          <p className="font-semibold text-[#535456] dark:text-[#8B8999] ml-[8px]">{t("pages.sale.price")}</p>
        </div>
        <div className="col-span-1">
          <ControlledInput
            label={t("pages.sale.min")}
            className="w-full h-[38px] outline-none border-1 border-[#9B9DA3] rounded-[8px] mt-[4px] focus:border-[#9B9DA3] focus:border-[1px]"
            placeholder="0"
            onChange={(e) => {
              debounce(() => {
                setSaleFilters({ ...saleFilters, min_price: e.target.value });
              }, 500)();
            }}
          />
        </div>
        <div className="col-span-1">
          <ControlledInput
            label={t("pages.sale.max")}
            className="w-full h-[38px] outline-none border-1 border-[#9B9DA3] rounded-[8px] mt-[4px] focus:border-[#9B9DA3] focus:border-[1px]"
            placeholder="0"
            onChange={(e) => {
              debounce(() => {
                setSaleFilters({ ...saleFilters, max_price: e.target.value });
              }, 500)();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NavigatorSale;
