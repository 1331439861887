import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import cls from "classnames";
import { useSearchParams } from "react-router-dom";

import { useCoreStore } from "store/core";

// assets
import Close from "assets/images/svg/close.svg";
import CloseDark from "assets/images/svg/close-dark.svg";

const Index = ({ children, title, close = () => {}, rowId = "row_id", classNameExtra }, ref) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useCoreStore((state) => state.theme);

  const themeIcon = {
    [true]: CloseDark,
    [false]: Close,
  };

  function removeId() {
    searchParams.delete(rowId);
    setSearchParams(searchParams);
  }

  useImperativeHandle(ref, () => ({
    openModal: (value) => setIsModalOpen(value),
    isModalOpen: () => isModalOpen,
  }));

  const handleCloser = () => {
    close();
    setIsModalOpen(false);
    removeId();
  };

  const closeModel = (e) => {
    if (e.key === "Escape") {
      handleCloser();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", closeModel);
    return () => window.removeEventListener("keydown", closeModel);
  }, []);

  if (!isModalOpen) return null;

  return (
    <div
      className={cls("modal", {
        opened: isModalOpen,
      })}
      onClick={handleCloser}
    >
      <div className="">
        <div
          className={cls(
            `${classNameExtra} modal__card bg-white dark:bg-blue-3 border-[1px] border-transparent dark:border-darkGrey`,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-between py-4 px-[24px]">
            <p className="text-textBlack font-semibold dark:text-textLight text-xl text-regular">
              {
                title?.start ? (
                  <span>
                    {title.start}
                    <span className={`text-[${title.color}]`}>{title.end}</span>
                  </span>
                ) : (
                  title
                )
              }
            </p>
            <button
              className="modal__close dark:text-darkGrey rounded-[4px]
                  bg-GreyInput dark:bg-inputBgDark flex items-center"
              onClick={handleCloser}
            >
              <img src={themeIcon[theme]} alt="modalclose" className="m-auto" />
            </button>
          </div>
          <div className="modal__content">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Index);
