import { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import cls from "classnames";
// store
import { useCoreStore } from "store/core";
// i18n
import { useTranslation } from "react-i18next";
// components
import BaseButton from "components/Button/BaseButton";
import TableAction from "components/Table/TableAction";
import BaseTd from "components/Table/BaseTd";
import ControlledCheckbox from "components/Checkbox/ControlledCheckbox";
import { checkHasAccessToAction } from "services/helpers";
// icons
import iconTrash from "assets/images/svg/trash-red.svg";
import iconSee from "assets/images/svg/icon-see.svg";
import iconSeeDark from "assets/images/svg/icon-see-dark.svg";
import IconDefault from "assets/images/svg/icon-default.svg";

const Tbody = ({ data, isChecked, checkboxes, setCheckboxes, closeDeleteModal }) => {
  const { t } = useTranslation();

  const { theme } = useCoreStore();

  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const hasAccessToEdit = checkHasAccessToAction("update-organization");
  const hasAccessToDelete = checkHasAccessToAction("delete-organization");

  const selectTableRow = (rowId) => {
    setCheckboxes({ ...checkboxes, [rowId]: !checkboxes[rowId] });
  };

  useEffect(() => {
    const objState = {};
    if (isChecked) {
      data.forEach((item) => {
        objState[item.id] = true;
      });
      setCheckboxes(objState);
    } else {
      const filterByTrue = Object.values(checkboxes).filter((el) => el);
      if (filterByTrue.length === data.length) setCheckboxes({});
    }
  }, [isChecked]);

  return (
    <>
      {data.map((item) => (
        <tr key={item.id} className="hover:bg-GreyLight dark:hover:bg-inputBgDark">
          <BaseTd>
            <div className="flex items-center gap-2 underline">
              <ControlledCheckbox checked={checkboxes[item.id] || false} onChange={() => selectTableRow(item.id)} />
              <img
                src={item.main_photo || IconDefault}
                alt="Avatar"
                className={cls("table--avatar", { "rounded-full": !item.main_photo })}
              />
              <Link to={`/sub-organizations/${item.id}?back=true`}>{item.name}</Link>
            </div>
          </BaseTd>
          <BaseTd>{item.main_phone}</BaseTd>
          <BaseTd>{item.tin}</BaseTd>
          <BaseTd extraClass="text-center">{item.user_cnt}</BaseTd>
          <BaseTd extraClass="text-center">{item.warehouse_cnt}</BaseTd>
          <BaseTd extraClass="text-center">{item.child_cnt}</BaseTd>
          <BaseTd>
            <div className="flex items-center gap-4 justify-end">
              <BaseButton
                icon={theme ? iconSeeDark : iconSee}
                onClick={() => navigate(`/warehouse/${item.id}`)}
                className="!p-1"
              />
              {hasAccessToEdit && (
                <BaseButton
                  label={t("button.edit")}
                  className="btn-gray hover:bg-GreyInput dark:text-white dark:bg-midBlack dark:border-midBlack py-[6px] h-[32px] text-[15px]"
                  onClick={() => setSearchParams({ row_id: item.id })}
                />
              )}
              {hasAccessToDelete && (
                <TableAction>
                  <div
                    className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                    onClick={() => closeDeleteModal(true, item.id)}
                  >
                    <img src={iconTrash} alt="delete" />
                    <p className="text-[#FF705D]">{t("button.delete")}</p>
                  </div>
                </TableAction>
              )}
            </div>
          </BaseTd>
        </tr>
      ))}
    </>
  );
};

export default Tbody;
