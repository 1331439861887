import { useLayoutEffect, useRef, useState } from "react";
import useSearch from "hooks/useSearch/useSearch";
import { useSearchParams } from "react-router-dom";
// api
import { useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
// store
import { useCoreStore } from "store/core";
// i18n
import { useTranslation } from "react-i18next";
// components
import Modal from "components/Modal/Modal";
import ModalForm from "./ModalForm";
import Table from "./Table/Table";

const Customer = () => {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  let [searchParams, _] = useSearchParams();
  let queryId = searchParams.get("row_id");
  const { searchResult, handleSearch } = useSearch();
  const orgId = useCoreStore((state) => state.orgId);

  const modal = useRef();

  const handleTemplatesModal = (value) => {
    modal.current.openModal(value);
  };

  const { data, isLoading, isError, refetch } = useGet({
    link: endpoints.customers.get,
    params: { org_id: orgId, size: 10, page: page + 1, q: searchResult || undefined },
  });

  const { data: singleData } = useGet({
    link: endpoints.customers.one,
    params: { id: queryId },
    enabled: !!queryId,
  });

  useLayoutEffect(() => {
    if (queryId && modal.current) {
      handleTemplatesModal(true);
    }
  }, [queryId]);

  const paginationConfig = {
    forcePage: page,
    total: data?.total,
    pageCount: Math.ceil((data?.total ?? 10) / 10),
    pageRangeDisplayed: 2,
    onPageChange: ({ selected }) => setPage(selected % data?.total),
  };

  if (isLoading) return <p>Loading</p>;
  if (isError) return <p>Errror</p>;

  return (
    <div className="flex flex-col h-full">
      <Table
        data={data.data}
        setPage={setPage}
        refetch={refetch}
        modalToggler={handleTemplatesModal}
        handleSearch={handleSearch}
        paginationConfig={paginationConfig}
      />
      <Modal ref={modal} title={queryId ? t("pages.customers.update") : t("pages.customers.add")}>
        <ModalForm close={handleTemplatesModal} refetch={refetch} row={queryId && singleData ? singleData : {}} />
      </Modal>
    </div>
  );
};

export default Customer;
