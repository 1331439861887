import { useState } from "react";
import { useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
import useSearch from "hooks/useSearch/useSearch";

import Table from "./Table/Table";

const Permissions = () => {
  const [page, setPage] = useState(0);

  //hooks
  const { searchResult, handleSearch } = useSearch();

  const { data, isLoading } = useGet({
    link: endpoints.permissions.get,
    params: { q: searchResult || undefined },
  });

  const paginationConfig = {
    forcePage: page + 1,
    total: data?.total,
    pageCount: Math.ceil((data?.total ?? 10) / 10),
    pageRangeDisplayed: 2,
    onPageChange: ({ selected }) => setPage(selected % data?.total),
  };

  if (isLoading) return <p>Loading</p>;
  // if (isError) return <p>Errror</p>;

  return (
    <>
      <Table data={data} handleSearch={handleSearch} paginationConfig={paginationConfig} />
    </>
  );
};

export default Permissions;
