// i18n
import { useTranslation } from "react-i18next";

export const useTranslatedTableConfig = () => {
  const { t } = useTranslation();

  const header = [
    {
      key: "name",
      title: t("pages.permissions.table.name"),
      width: "w-[30%]",
    },
    {
      key: "description",
      title: t("pages.permissions.table.description"),
      width: "w-[70%]",
    },
  ];
  return { header };
};
