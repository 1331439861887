import { useMemo } from "react";
import ReactPaginate from "react-paginate";
// store
import { useCoreStore } from "store/core";
// i18n
import { useTranslation } from "react-i18next";
// icons
import ArrowLeft from "assets/images/svg/icon-left-arrow.svg";
import ArrowLeftWhite from "assets/images/svg/icon-left-arrow-white.svg";

const themeIcon = {
  [false]: ArrowLeft,
  [true]: ArrowLeftWhite,
};

function displayRange(forcePage, pageCount, totalItems) {
  let start = forcePage * pageCount + 1;
  let end = start + pageCount - 1;
  end = end > totalItems ? totalItems : end;

  return { start, end, totalItems };
}

const Pagination = ({ paginationConfig = {} }) => {

  const { t } = useTranslation();

  const theme = useCoreStore((state) => state.theme);
  const { total, forcePage } = paginationConfig;

  const { start, end, totalItems } = useMemo(() => displayRange(forcePage, 10, total), [total, forcePage]);

  if (total <= 10) return null;

  return (
    <div className="flex justify-between items-center mt-7 px-4 bg-white dark:bg-[transparent]">
      <div className="flex justify-between items-center gap-1">
        <span className="dark:text-white">
          {start} - {end}
        </span>
        <span className="text-[#9C9DA3]">{t("info.from")}</span>
        <span className="dark:text-white">{totalItems}</span>
      </div>
      <ReactPaginate
        breakLabel={<span className="dark:text-white">...</span>}
        nextLabel={<img src={themeIcon[theme]} alt="arrow-left" className="rotate-180" />}
        previousLabel={<img src={themeIcon[theme]} alt="arrow-left" />}
        renderOnZeroPageCount={() => null}
        containerClassName="flex items-center gap-2"
        pageClassName="w-6 h-6 flex items-center justify-center rounded dark:text-white"
        activeClassName="bg-GreyInput dark:bg-inputBgDark"
        {...paginationConfig}
      />
    </div>
  );
};

export default Pagination;
