import LayerIcon from "assets/images/svg/auth-layer.svg";
// import LayerWave from "assets/images/svg/auth-layer-wave.svg";

const Layer = () => {
  return (
    <div className="auth_layer h-full flex justify-center items-center">
      <img src={LayerIcon} alt="LayerIcon" className="h-3/5" />
      {/* <img
        src={LayerWave}
        alt="LayerWave"
        className="auth_layer-wave absolute -z-10 bottom-0"
      /> */}
    </div>
  );
};

export default Layer;
