import React, { useState, useEffect, useRef, memo } from "react";
import { useSearchParams } from "react-router-dom";
// helpers
import cls from "classnames";
// services
import { useForm } from "react-hook-form";
// store
import { useCoreStore } from "store/core";
// api
import { useGet, useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
// import { Tooltip } from "components/Tooltip/Tooltip";
import { Tooltip } from "react-tooltip";
import { notifySuccess } from "components/Toast";
import Input from "components/Input/Input";
import ControlledInput from "components/Input/ControlledInput";
import BaseButton from "components/Button/BaseButton";
import ActionButton from "components/Button/ActionButton";
import CustomSelect from "components/Select/CustomSelect";
// icons
import WhitePlus from "assets/images/svg/white-plus.svg";
import IconSave from "assets/images/svg/icon-save.svg";
import IconPlus from "assets/images/svg/icon-add-white.svg";
import IconTick from "assets/images/svg/tick_in_square.svg";
import IconTrash from "assets/images/svg/trash-red.svg";
import Search from "assets/images/svg/search.svg";
import SearchWhite from "assets/images/svg/search-white.svg";

const PropertySelects = ({
  property,
  index,
  properties,
  propertyOptions,
  propertiesError,
  changeProperty = () => {},
  changePropertyChild = () => {},
  deleteProperty = () => {},
}) => {
  const [propertyChildOptions, setPropertyChildOptions] = useState([]);

  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const propertyChildOptionsApi = useGet({
    link: endpoints.parameterValue.list,
    params: { property_id: property.property_id },
    queryKey: `${property.property}-${property.property_child}`,
    enabled: !!property.property_id,
  });

  useEffect(() => {
    if (propertyChildOptionsApi?.data) {
      const propertyChildTransformation = propertyChildOptionsApi?.data?.map((el) => ({
        label: el.name[lang],
        value: el.id,
      }));
      setPropertyChildOptions(propertyChildTransformation);
    }
  }, [propertyChildOptionsApi.data, lang]);

  return (
    <div className="flex items-center justify-between gap-1 mt-[14px]" key={`${property.id}-${index}`}>
      <div className="w-full">
        <CustomSelect
          name="property"
          placeholder={t("pages.productGroups.form.parameter")}
          height="32px"
          padding="0 0 0 8px"
          value={property.property_id}
          options={propertyOptions}
          onChangeFunc={({ option }) => changeProperty(option, index)}
          hasError={propertiesError && properties.length === 1}
        />
        {/* {property.property?.error ? <p className="text-red-600 absolute">Select property</p> : null} */}
      </div>
      <div className="w-full">
        <CustomSelect
          name="property_child"
          placeholder={t("form.value")}
          height="32px"
          padding="0 0 0 8px"
          value={property?.property_value_id}
          options={propertyChildOptions || []}
          onChangeFunc={({ option }) => changePropertyChild(option, index)}
          hasError={propertiesError && properties.length === 1}
        />
        {/* {property.property_child?.error ? (
                      <p className="text-red-600 absolute">Select property child</p>
                    ) : null} */}
      </div>
      <div
        className={`flex gap-4 h-[32px] justify-end bg-[#F2F2F2] dark:bg-[#1F1E25] px-[8px] py-[6px] rounded-[6px] cursor-pointer ${
          properties.length === 1 && "opacity-[0.7]"
        }`}
        onClick={() => {
          if (properties.length > 1) {
            deleteProperty(index);
          }
        }}
      >
        <img src={IconTrash} className="max-w-[90px]" alt="Delete icon" />
      </div>
    </div>
  );
};

const PropertyItem = (props) => {
  const { item, editTagsList, setEditMode, setIsOpenRightForm, deleteTagsList } = props;

  const tagsRef = useRef(null);
  const moreRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkWidth = () => {
      if (tagsRef.current) {
        const parentWidth = tagsRef.current.parentElement.offsetWidth;
        const tagsWidth = tagsRef.current.offsetWidth;
        setIsOverflowing(tagsWidth > parentWidth * 0.7);
      }
    };

    checkWidth();

    // Optional: Add a resize event listener if the container size might change
    window.addEventListener("resize", checkWidth);
    return () => window.removeEventListener("resize", checkWidth);
  }, [item.tags]);

  return (
    <div
      className="group flex mt-2 justify-between gap-1 items-center px-2 w-full hover:bg-[#FAFAFA] dark:hover:bg-[#1F1E25] border-1 border-[transparent] hover:rounded-[8px]"
      onHover
      trigger
      moreRef
      hover
      onMouseEnter={() => {
        moreRef.current?.dispatchEvent(new MouseEvent("mouseenter"));
      }}
      onMouseLeave={() => {
        moreRef.current?.dispatchEvent(new MouseEvent("mouseleave"));
      }}
    >
      <div className={`pb-[1px] whitespace-nowrap ${isOverflowing && " max-w-[75%] overflow-hidden"}`} ref={tagsRef}>
        {item?.tags?.map((tag) => (
          <span
            key={tag}
            className="bg-[#FAFAFA] dark:bg-[#1F1E25] dark:text-[#fff] border border-[#D1D1D1] rounded-[4px] text-[#535456] text-[13px] font-normal py-[3px] px-2 mx-1 hover:opacity-90 "
          >
            {tag}
          </span>
        ))}
      </div>
      {isOverflowing && (
        <>
          <div
            className="hover:cursor-default dark:text-white"
            ref={moreRef}
            data-tooltip-id={`tags-tooltip-${item.id}`}
            data-tooltip-place="bottom"
          >
            ...
          </div>
          <Tooltip
            id={`tags-tooltip-${item.id}`}
            style={{
              maxWidth: "260px",
              width: "fit-content",
              textAlign: "center",
              zIndex: "500",
            }}
          >
            <div className="flex flex-row flex-wrap">
              {item?.tags?.map((tag) => (
                <span
                  key={tag}
                  className="border border-[#D1D1D1] rounded-[4px] text-[#D1D1D1] text-[13px] font-normal py-[3px] px-2 m-[2px]"
                >
                  {tag}
                </span>
              ))}
            </div>
          </Tooltip>
        </>
      )}
      <div className="h-full max-w-[20%] flex items-center gap-[5px] justify-end">
        <ActionButton
          type="edit"
          handleClick={() => {
            editTagsList(item);
            setEditMode(true);
            setIsOpenRightForm(true);
          }}
        />

        <div
          className={`flex gap-4 h-full justify-end bg-[#F2F2F2] dark:bg-[#1F1E25] px-[8px] py-[6px] rounded-[6px] cursor-pointer hover:opacity-80`}
          onClick={() => {
            deleteTagsList(item.id);
          }}
        >
          <img src={IconTrash} className="max-w-[90px]" alt="Delete icon" />
        </div>
      </div>
    </div>
  );
};

const ModalForm = memo(({ close, setProductGroups, row, orgId }) => {
  const { t } = useTranslation();
  const { orgId: userOrgId, theme } = useCoreStore((state) => state);
  let [searchParams, setSearchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  // states
  const [propertiesAndTags, setPropertiesAndTags] = useState([]);
  const [currentProperty, setCurrentProperty] = useState({});
  const [tagsOptions, setTagsOptions] = useState([]);
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [isOpenRightForm, setIsOpenRightForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  // error states
  const [propertiesError, setPropertiesError] = useState(false);
  const [tagsError, setTagsError] = useState(false);

  // api calls

  useGet({
    link: endpoints.tags.list,
    params: { org_id: userOrgId },
    onSuccess: (res) => {
      const tagTransformation = res.map((el) => ({
        label: el.tag_name,
        value: el.tag_name,
      }));
      setTagsOptions(tagTransformation);
    },
  });

  useGet({
    link: endpoints.parameter.list,
    params: { org_id: userOrgId },
    onSuccess: (res) => {
      const propertyTransformation = res?.map((el) => ({
        label: el.name?.uz,
        value: el.id,
      }));
      setPropertyOptions(propertyTransformation);
    },
  });

  // sets the propertiesAndTags state from row

  useEffect(() => {
    if (row) {
      setValue("uz", row.name?.uz);
      setValue("ru", row.name?.ru);
      setPropertiesAndTags(row.product_group_tags);
    } else {
      setPropertiesAndTags([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row]);

  // sets free property options when tags are selected
  useEffect(() => {
    if (isOpenRightForm) {
      let isAllFilled;
      const selectedProperties = currentProperty.properties;

      if (selectedProperties.length > 0) {
        isAllFilled = selectedProperties.every((el) => el.property_id && el.property_value_id);
        if (isAllFilled) {
          // add extra property
          addExtraProperty();
        }
      } else {
        // add extra property
        addExtraProperty();
      }
    }
  }, [currentProperty.properties, isOpenRightForm]);

  // functions

  function addPropertyAndTags() {
    // error handling
    // don't allow to add if tags.length === 0
    if (currentProperty.tags.length === 0) {
      setTagsError(true);
      return;
    } else {
      setTagsError(false);
    }
    // don't allow to add if properties.length <= 1
    if (currentProperty.properties.length <= 1) {
      setPropertiesError(true);
      return;
    } else {
      setPropertiesError(false);
    }

    const newPropertiesAndTags = [...(propertiesAndTags ?? [])];

    const isExist = newPropertiesAndTags?.some((el) => el.id === currentProperty.id);
    const filteredProperties = currentProperty.properties.slice(0, currentProperty.properties.length - 1);
    const transformedCurrentProperty = {
      ...currentProperty,
      // left just "property_id" and "property_value_id" in properties array
      id: Math.random(),
      properties: filteredProperties.map((el) => ({
        property_id: el.property_id,
        property_value_id: el.property_value_id,
      })),
    };
    if (isExist) {
      const index = newPropertiesAndTags?.findIndex((el) => el.id === currentProperty.id);
      newPropertiesAndTags[index] = transformedCurrentProperty;
    } else {
      newPropertiesAndTags.push(transformedCurrentProperty);
    }
    setPropertiesAndTags(newPropertiesAndTags);
    setCurrentProperty({
      id: Math.random(),
      tags: [],
      properties: [],
    });
    setIsOpenRightForm(false);
  }

  function addExtraProperty() {
    setCurrentProperty((prev) => ({
      ...prev,
      properties: [
        ...prev.properties,
        {
          property_id: null,
          property_value_id: null,
        },
      ],
    }));
  }

  function changeProperty(option, index) {
    setCurrentProperty((prev) => {
      const newProperties = [...prev.properties];
      newProperties[index].property_id = option.value;
      newProperties[index].property = option;
      return {
        ...prev,
        properties: newProperties,
      };
    });
  }

  function changePropertyChild(option, index) {
    setCurrentProperty((prev) => {
      const newProperties = [...prev.properties];
      newProperties[index].property_value_id = option.value;
      newProperties[index].property_child = option;
      return {
        ...prev,
        properties: newProperties,
      };
    });
  }

  function deleteProperty(index) {
    setCurrentProperty((prev) => {
      const newProperties = [...prev.properties];
      newProperties.splice(index, 1);
      return {
        ...prev,
        properties: newProperties,
      };
    });
  }

  function editTagsList(el) {
    setCurrentProperty(el);
  }

  function deleteTagsList(id) {
    setPropertiesAndTags((prev) => prev.filter((el) => el.id !== id));
  }

  function openRightForm() {
    setIsOpenRightForm(true);
  }

  function clearAndClose() {
    searchParams.delete("row_id1");
    setSearchParams(searchParams);
    close(false);
    setPropertiesAndTags([]);
    reset();
  }

  // api for create and update
  const create = useMutate({
    onSuccess: (res) => {
      setProductGroups((prev) => [...prev, res]);
      clearAndClose();
      notifySuccess(t("notification.groupSuccessCreate"));
    },
  });

  const update = useMutate({
    onSuccess: () => {
      clearAndClose();
      notifySuccess(t("notification.groupSuccessUpdate"));
    },
  });

  const onSubmit = (names) => {
    const filteredPropertiesAndTags = propertiesAndTags?.map((el) => ({
      tags: el.tags,
      properties: el.properties,
    }));
    const body = {
      name: {
        uz: names.uz,
        ru: names.ru,
      },
      ...(filteredPropertiesAndTags.length > 0 && { product_group_tags: filteredPropertiesAndTags }),
    };

    if (row.id) {
      update.mutate({
        link: `${endpoints.productGroup.update}?id=${row.id}`,
        method: "put",
        body,
      });
    } else {
      create.mutate({
        link: `${endpoints.productGroup.create}?org_id=${orgId}`,
        method: "post",
        body,
      });
    }
  };

  return (
    <div className="w-[850px]">
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4 w-full">
          <Input
            label={`${t("form.title")} RU`}
            placeholder={t("form.titlePlaceholder")}
            fieldName="ru"
            register={register}
            errors={errors}
            isRequired={true}
            className="w-full"
          />
          <Input
            label={`${t("form.title")} UZ`}
            placeholder={t("form.titlePlaceholder")}
            fieldName="uz"
            register={register}
            errors={errors}
            isRequired={true}
            className="w-full"
          />
        </div>

        <hr className="mt-3 dark:!border-[#2d2d2d]" />
        <div className="grid grid-cols-2 gap-4 w-full mt-4">
          <div className="">
            <h3 className="text-stone-800 dark:text-textDark">{t("pages.productGroups.form.tagList")}</h3>
            <div className="mt-2 max-w-[417px] max-h-[300px] min-h-[300px] overflow-y-auto  p-2 rounded border border-[#F2F2F2] dark:border-darkGrey">
              <ControlledInput className="w-full" placeholder={t("pages.productGroups.form.logic")} disabled />

              {propertiesAndTags?.map((el) => (
                <PropertyItem
                  key={el?.id}
                  item={el}
                  editTagsList={editTagsList}
                  setEditMode={setEditMode}
                  setIsOpenRightForm={setIsOpenRightForm}
                  deleteTagsList={deleteTagsList}
                />
              ))}
            </div>
            <div className="flex justify-between w-full">
              <div></div>
              <div
                className={cls("mt-4 cursor-pointer transition-all")}
                onClick={() => {
                  setCurrentProperty({
                    id: Math.random(),
                    tags: [],
                    properties: [],
                  });
                  setEditMode(false);
                  openRightForm();
                }}
              >
                <p className="w-6 h-6 rounded-full bg-blue-1 group-hover/add-btn:bg-blue-2">
                  <img src={WhitePlus} alt="icon" />
                </p>
              </div>
            </div>
          </div>

          {isOpenRightForm ? (
            <div>
              <h3 className="text-stone-800 mb-2 dark:text-textDark">{t("pages.productGroups.form.tagValues")}</h3>
              <div className="relative p-2 rounded border-[1px] border-[#F2F2F2] dark:border-darkGrey">
                <div className="max-h-[220px] min-h-[220px] overflow-y-auto">
                  <div className="w-full">
                    {/* <ControlledSelect
                      isMulti={true}
                      value={currentProperty?.tags.map((tag) => ({ label: tag, value: tag }))}
                      closeMenuOnSelect={false}
                      placeholder={t("pages.productGroups.form.tagList")}
                      width={"417px"}
                      className="relative !z-[100]"
                      hasError={tagsError && currentProperty.tags.length === 0}
                      options={tagsOptions}
                      onChange={(value) => {
                        setCurrentProperty((prev) => ({ ...prev, tags: value.map((v) => v.value) }));
                      }}
                    /> */}

                    <CustomSelect
                      // isMulti={true}
                      height="44px"
                      padding="0px 5px"
                      name="tags"
                      search={true}
                      closeMenuOnSelect={false}
                      width={"100%"}
                      placeholder={`${t("pages.products.selectTags")}...`}
                      leftElement={
                        <img src={theme ? SearchWhite : Search} alt="CloseIcon" className="cursor-pointer" />
                      }
                      options={tagsOptions ?? []}
                      // empty value always should be
                      // value={currentProperty.tags.map((tag) => ({ label: tag, value: tag + "1" }))}
                      isMulti={true}
                      value={currentProperty?.tags.map((tag) => ({ label: tag, value: tag + "1" }))}
                      checkbox={true}
                      hasError={tagsError && currentProperty.tags.length === 0}
                      selectedTags={currentProperty.tags.map((tag) => ({ label: tag, value: tag }))}
                      customChip={true}
                      onChangeFunc={(value) => {
                        // check if already selected
                        const isExist = currentProperty.tags.some((el) => el === value.value);
                        if (!isExist) {
                          setCurrentProperty((prev) => ({ ...prev, tags: [...prev.tags, value.value] }));
                        } else {
                          setCurrentProperty((prev) => ({
                            ...prev,
                            tags: prev.tags.filter((el) => el !== value.value),
                          }));
                        }

                        // clear select
                        return;
                      }}
                    />
                    {/* {isExistSelectedTag ? <p className="text-red-600">Please Select Tag</p> : null} */}
                  </div>
                  {currentProperty.properties.map((property, index) => (
                    <PropertySelects
                      key={property.id}
                      index={index}
                      property={property}
                      properties={currentProperty.properties}
                      propertyOptions={propertyOptions}
                      // setProperties={setProperties}
                      changeProperty={changeProperty}
                      changePropertyChild={changePropertyChild}
                      deleteProperty={deleteProperty}
                      propertiesError={propertiesError}
                    />
                  ))}
                </div>

                <BaseButton
                  label={editMode ? t("button.acceptChanges") : t("button.addSelected")}
                  className="btn-primary  w-full transform sticky z-[30] mt-4"
                  icon={editMode ? IconTick : IconPlus}
                  onClick={addPropertyAndTags}
                />
                {/* </div> */}
                {/* </div> */}
              </div>
            </div>
          ) : null}
        </div>

        <div className="flex justify-between gap-6 mt-8">
          <BaseButton
            label={t("button.cancel")}
            onClick={clearAndClose}
            className="p-2 btn-secondary dark:btn-secondary-dark"
          />
          <BaseButton
            type="submit"
            className="p-2 btn-success"
            icon={IconSave}
            label={row.id ? t("button.update") : t("button.save")}
          />
        </div>
      </form>
    </div>
  );
});

export default ModalForm;
