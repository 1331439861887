import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
// helpers
import cls from "classnames";
import { cutString } from "services/helpers";
// store
import { useCoreStore } from "store/core";
// i18n
import { useTranslation } from "react-i18next";
// components
import BaseButton from "components/Button/BaseButton";
import TableAction from "components/Table/TableAction";
import BaseTd from "components/Table/BaseTd";
import ControlledCheckbox from "components/Checkbox/ControlledCheckbox";
import { Tooltip } from "react-tooltip";
import { checkHasAccessToAction } from "services/helpers";
import Chip from "components/Chip/Chip";
//icons
import iconEdit from "assets/images/svg/edit-icon.svg";
import iconRestore from "assets/images/svg/restoreGreen-icon.svg";
import IconDefault from "assets/images/svg/icon-default.svg";
import LinkIcon from "assets/images/svg/icon-link.svg";
import LinkIconDark from "assets/images/svg/icon-link-dark.svg";
import SendIcon from "assets/images/svg/icon-send.svg";
import SendIconDark from "assets/images/svg/icon-send-dark.svg";
import iconTrash from "assets/images/svg/trash-red.svg";

const Tbody = ({
  data,
  isChecked,
  checkboxes,
  setCheckboxes,
  closeDeleteModal,
  tags,
  categories,
  changeState,
  modalToggler,
}) => {
  const { t } = useTranslation();
  let [_, setSearchParams] = useSearchParams();

  const { theme } = useCoreStore();

  const hasAccessToEdit = checkHasAccessToAction("update-organization");
  const hasAccessToDelete = checkHasAccessToAction("delete-organization");

  const selectTableRow = (rowId) => {
    setCheckboxes({ ...checkboxes, [rowId]: !checkboxes[rowId] });
  };

  useEffect(() => {
    const objState = {};
    if (isChecked) {
      data.forEach((item) => {
        objState[item.id] = true;
      });
      setCheckboxes(objState);
    } else {
      const filterByTrue = Object.values(checkboxes).filter((el) => el);
      if (filterByTrue.length === data.length) setCheckboxes({});
    }
  }, [isChecked]);

  return (
    <>
      {data.map((item) => (
        <tr key={item.id} className={`hover:bg-GreyLight dark:hover:bg-inputBgDark`}>
          <BaseTd>
            <div className="flex items-center gap-2 underline">
              <ControlledCheckbox checked={checkboxes[item.id] || false} onChange={() => selectTableRow(item.id)} />
              <img
                src={item.images[0] || IconDefault}
                alt="product"
                className={cls("table--avatar", { "rounded-full": !item.imageLink })}
              />
            </div>
          </BaseTd>
          <BaseTd extraClass={item.state.toLowerCase() === "inactive" ? "opacity-[0.2]" : ""}>{item.code}</BaseTd>
          <BaseTd extraClass={item.state.toLowerCase() === "inactive" ? "opacity-[0.2]" : ""}>
            {item.name.length > 20 ? (
              <>
                <a data-tooltip-id={`product-name-tooltip-${item.id}`} data-tooltip-place="top">
                  {cutString(item.name, 20)}
                </a>
                <Tooltip
                  id={`product-name-tooltip-${item.id}`}
                  style={{ maxWidth: "320px", width: "fit-content", textAlign: "center", zIndex: "500" }}
                >
                  <div class="flex flex-row flex-wrap text-white">{item.name}</div>
                </Tooltip>
              </>
            ) : (
              item.name
            )}
          </BaseTd>
          <BaseTd extraClass={item.state.toLowerCase() === "inactive" ? "opacity-[0.2]" : ""}>{item.as || "-"}</BaseTd>
          <BaseTd extraClass={item.state.toLowerCase() === "inactive" ? "opacity-[0.2]" : ""}>{item.in_price}</BaseTd>
          <BaseTd extraClass={item.state.toLowerCase() === "inactive" ? "opacity-[0.2]" : ""}>{item.b2b_price}</BaseTd>
          <BaseTd extraClass={item.state.toLowerCase() === "inactive" ? "opacity-[0.2]" : ""}>{item.b2c_price}</BaseTd>
          <BaseTd extraClass={item.state.toLowerCase() === "inactive" ? "opacity-[0.2]" : ""}>{item.rpr}</BaseTd>
          <BaseTd extraClass={item.state.toLowerCase() === "inactive" ? "opacity-[0.2]" : ""}>
            <div className="flex w-auto flex-wrap items-end">
              {item?.tag_names.slice(0, 3).map((tag) => (
                <Chip key={tag} text={tag} closeOption={false} />
              ))}
              {
                // add ... if tags more than 3
                item?.tag_names.length > 3 && (
                  <>
                    <a data-tooltip-id={`tags-tooltip-${item.id}`} data-tooltip-place="top">
                      ...
                    </a>
                    <Tooltip
                      id={`tags-tooltip-${item.id}`}
                      style={{ maxWidth: "320px", width: "fit-content", textAlign: "center", zIndex: "500" }}
                    >
                      <div className="flex flex-row flex-wrap">
                        {item?.tag_names.slice(3).map((tag) => (
                          <Chip key={tag} text={tag} className="text-[#D1D1D1]" closeOption={false} />
                        ))}
                      </div>
                    </Tooltip>
                  </>
                )
              }
            </div>
          </BaseTd>
          <BaseTd extraClass={item.state.toLowerCase() === "inactive" ? "opacity-[0.2]" : ""}>
            {item?.category_names.slice(0, 3).map((category) => (
              <p key={category.ru} className="">
                {category.ru}
              </p>
            ))}

            {
              // add ... if tags more than 3
              item?.category_names.length > 3 && (
                <>
                  <a data-tooltip-id={`categories-tooltip-${item.id}`} data-tooltip-place="top">
                    ...
                  </a>
                  <Tooltip
                    id={`categories-tooltip-${item.id}`}
                    style={{ maxWidth: "320px", width: "fit-content", textAlign: "center", zIndex: "500" }}
                  >
                    <div className="flex flex-row flex-wrap">
                      {item?.category_names.slice(3).map((category) => (
                        <Chip key={category.ru} text={category.ru} className="text-[#D1D1D1]" closeOption={false} />
                      ))}
                    </div>
                  </Tooltip>
                </>
              )
            }
          </BaseTd>
          <BaseTd extraClass={item.state.toLowerCase() === "inactive" ? "opacity-[0.2]" : ""}>
            <a href={item.productLink} className="button">
              <BaseButton
                type="submit"
                icon={theme ? LinkIconDark : LinkIcon}
                className={`bg-transparent w-auto px-[8px] py-[6px] rounded-[6px]  text-textBlack hover:opacity-[0.8] transition duration-200`}
                iconClassName="w-[24px]"
              />
            </a>
          </BaseTd>
          <BaseTd extraClass={item.state.toLowerCase() === "inactive" ? "opacity-[0.2]" : ""}>
            <BaseButton
              type="submit"
              icon={theme ? SendIconDark : SendIcon}
              className={`bg-transparent w-auto px-[8px] py-[6px] rounded-[6px]  text-textBlack hover:opacity-[0.8] transition duration-200`}
              iconClassName="w-[24px]"
            />
          </BaseTd>
          <BaseTd>
            <div className="flex items-center gap-4 justify-end">
              <TableAction>
                <div
                  className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                  onClick={() => setSearchParams({ row_id: item.id })}
                >
                  <img src={iconEdit} alt="delete" />
                  <p className="text-textBlack">{t("button.edit")}</p>
                </div>
                {item.state.toLowerCase() === "inactive" ? (
                  <div
                    className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                    onClick={() => changeState(item.id, "ACTIVE")}
                  >
                    <img src={iconRestore} alt="delete" />
                    <p className="text-textBlack">{t("button.restore")}</p>
                  </div>
                ) : null}

                {item.state.toLowerCase() === "active" && (
                  <div
                    className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                    onClick={() => changeState(item.id, "INACTIVE")}
                  >
                    <img src={iconTrash} alt="delete" className="ml-[3px]" />
                    <p className="text-[#FF705D]">{t("button.disable")}</p>
                  </div>
                )}

                {/* In Future */}

                {/* <div
                  className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                  onClick={() => closeDeleteModal(true, item.id)}
                >
                  <img src={iconAssignment} alt="delete" />
                  <p className="text-textBlack">Остаток</p>
                </div> */}
                {/* <div
                    className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                    onClick={() => closeDeleteModal(true, item.id)}
                  >
                    <img src={iconHistoryPlus} alt="delete" />
                    <p className="text-textBlack">История покупок</p>
                  </div> */}

                {/* <div
                    className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                    onClick={() => closeDeleteModal(true, item.id)}
                  >
                    <img src={iconHistoryPlus} alt="delete" />
                    <p className="text-textBlack">История покупок</p>
                  </div>
                  <div
                    className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                    onClick={() => closeDeleteModal(true, item.id)}
                  >
                    <img src={iconHistoryMinus} alt="delete" />
                    <p className="text-textBlack">История продаж</p>
                  </div> */}
              </TableAction>
            </div>
          </BaseTd>
        </tr>
      ))}
    </>
  );
};

export default Tbody;
