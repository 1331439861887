import { useEffect, useRef, useState } from "react";
import { useCoreStore } from "store/core";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
// helpers
import { usePaginateGoBack } from "hooks/usePaginateGoBack/usePaginateGoback";
import { checkHasAccessToAction } from "services/helpers";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import Tbody from "./Tbody";
import BaseTable from "components/Table/BaseTable";
import BaseThead from "components/Table/BaseThead";
import BaseButton from "components/Button/BaseButton";
import ControlledInput from "components/Input/ControlledInput";
import Pagination from "components/Pagination/Pagination";
import { notifySuccess } from "components/Toast";
import { useGet, useMutate } from "hooks/query";
import DeleteConfirmation from "container/DeleteConfirmation";
import { useTranslatedTableConfig } from "../Helpers";
// icons
import Logout from "assets/images/svg/log-out.svg";
import Search from "assets/images/svg/search.svg";
import AddIcon from "assets/images/svg/add-to-photos.svg";
import TrashIcon from "assets/images/svg/trash-white.svg";
import SearchWhite from "assets/images/svg/search-white.svg";

const Table = ({ refetch, data = [], modalToggler, handleSearch, paginationConfig, setPage }) => {
  const { t } = useTranslation();
  const { header } = useTranslatedTableConfig();
  const [isSelectedAllRows, setIsSelectedAllRows] = useState(false);
  const [checkboxes, setCheckboxes] = useState({});
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const theme = useCoreStore((state) => state.theme);

  const [searchParams, setSearchParams] = useSearchParams();

  const back = searchParams.get("back");

  const navigate = useNavigate();

  const { id } = useParams();

  const closeModal = useRef();
  const deleteId = useRef();
  const hasAccessToCreate = checkHasAccessToAction("create-sub-organization");
  const paginateGoBack = usePaginateGoBack({
    setPage,
    total: paginationConfig.total,
    pageCount: paginationConfig.pageCount,
  });

  const { data: singleData } = useGet({
    link: endpoints.organizations.one,
    params: { id },
    enabled: !!id,
  });

  const handleChangeCheckbox = (e) => {
    setIsSelectedAllRows(e.target.checked);
  };

  const closeDeleteModal = (value, id) => {
    deleteId.current = id;
    closeModal.current.openModal(value);
  };

  const deleteOrganization = useMutate({
    onSuccess: () => {
      refetch();
      closeDeleteModal(false);
      notifySuccess(t("notification.successDelete"));
      paginateGoBack();
    },
  });

  const confirmDelete = () => {
    deleteOrganization.mutate({
      link: `/org/delete?id=${deleteId.current}&parent_id=${id}`,
      method: "delete",
    });
  };

  const deleteMultiUser = useMutate({
    onSuccess: (res) => {
      refetch();
      setCheckboxes({});
      setSelectedRowIds([]);
      notifySuccess(t("notification.successDelete"));
      paginateGoBack(res.length);
    },
  });

  const deleteSelectedRows = () => {
    deleteMultiUser.mutate({
      link: `${endpoints.organizations.delete}`,
      method: "patch",
      body: { ids: selectedRowIds },
    });
  };

  useEffect(() => {
    const filterByTrue = Object.values(checkboxes).filter((el) => el);
    const filteredKeys = Object.keys(checkboxes).filter((key) => checkboxes[key]);
    setSelectedRowIds(filteredKeys);

    if (filterByTrue.length !== data.length) setIsSelectedAllRows(false);
  }, [checkboxes]);

  return (
    <div className="h-full px-2 flex flex-col">
      <h2 className="text-bold text-2xl flex text-black dark:text-textDark">{`${t("pages.subOrganizations.title")}: ${
        singleData?.name
      }`}</h2>
      <div className="w-full flex justify-between items-center my-4">
        <div className="flex items-center gap-2">
          {back && (
            <div
              className="cursor-pointer flex items-center gap-1 p-2 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
              onClick={() => {
                navigate(-1);
              }}
            >
              <img src={Logout} alt="CloseIcon" className="cursor-pointer" />
              <p>{t("button.back")}</p>
            </div>
          )}

          <ControlledInput
            className="w-[260px]"
            onChange={handleSearch}
            placeholder={`${t("pages.mainOrganizations.search")}...`}
            leftElement={<img src={theme ? SearchWhite : Search} alt="CloseIcon" className="cursor-pointer" />}
          />
        </div>
        <div className="flex gap-3">
          {selectedRowIds.length > 0 && (
            <BaseButton
              label={`${t("button.deleteSelected")} (${selectedRowIds.length})`}
              icon={TrashIcon}
              className="btn-danger"
              onClick={deleteSelectedRows}
            />
          )}
          {hasAccessToCreate && (
            <BaseButton
              label={t("button.addOrg")}
              icon={AddIcon}
              className="btn-primary"
              onClick={() => modalToggler(true)}
            />
          )}
        </div>
      </div>
      <BaseTable
        thead={
          <BaseThead
            header={header}
            withCheckbox={true}
            isSelectedAllRows={isSelectedAllRows}
            handleChangeCheckbox={handleChangeCheckbox}
          />
        }
        tbody={
          <Tbody
            data={data}
            isChecked={isSelectedAllRows}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            closeDeleteModal={closeDeleteModal}
          />
        }
      />
      <div className="absolute bottom-10 w-full">
        <Pagination paginationConfig={paginationConfig} />
      </div>
      <DeleteConfirmation ref={closeModal} close={closeDeleteModal} cb={confirmDelete} />
    </div>
  );
};

export default Table;
