import InvoiceItem from "./InvoiceItem";

const InvoiceList = ({ invoices, currentInvoice, setCurrentInvoice }) => {
  return (
    <div className={`flex flex-col w-full relative bg flex-1`} >
      <div className="absolute w-full overflow-y-auto h-full top-0">
      {invoices?.map((invoice) => (
        <InvoiceItem
          invoice={invoice}
          currentInvoice={currentInvoice}
          setCurrentInvoice={setCurrentInvoice}
          key={invoice.id}
        />
      ))}
      </div>
    
    </div>
  );
};

export default InvoiceList;
