import { QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "react-hot-toast";
import Route from "routes/route";
import cls from "classnames";
import { useCoreStore } from "store/core";
import { queryClient } from "./services/queryConfig";

//Assets
import "./assets/styles/main.scss";

function App() {
  const theme = useCoreStore((state) => state.theme);
  return (
    <div
      // className={cls(`transition-all app scrollbar-thin scrollbar-lightScrollbarThumb scrollbar-track-slate-400`, {
      className={cls(`transition-all app scrollbar-thin scrollbar-lightScrollbarThumb`, {
        dark: theme,
      })}
    >
      <QueryClientProvider client={queryClient}>
        <Route />
      </QueryClientProvider>
      <Toaster position="top-right" reverseOrder={false} className="scrollbar" />
    </div>
  );
}

export default App;
