import { useEffect, useRef, useState } from "react";
import BaseTable from "components/Table/BaseTable";
import BaseThead from "components/Table/BaseThead";
import BaseButton from "components/Button/BaseButton";
import { notifySuccess } from "components/Toast";
import { useMutate } from "hooks/query";
import DeleteConfirmation from "container/DeleteConfirmation";
import { useCoreStore } from "store/core";
import ControlledInput from "components/Input/ControlledInput";
import Pagination from "components/Pagination/Pagination";
// i18n
import { useTranslation } from "react-i18next";
import { usePaginateGoBack } from "hooks/usePaginateGoBack/usePaginateGoback";
import { checkHasAccessToAction } from "services/helpers";
import { endpoints } from "services/endpoints";

import { useTranslatedTableConfig } from "../helpers";
import Tbody from "./Tbody";

import Search from "assets/images/svg/search.svg";
import SearchWhite from "assets/images/svg/search-white.svg";
import AddIcon from "assets/images/svg/add-to-photos.svg";
import TrashIcon from "assets/images/svg/trash-white.svg";

const Table = ({ refetch, data = [], modalToggler, handleSearch, paginationConfig, setPage }) => {
  const { t } = useTranslation();

  const { header } = useTranslatedTableConfig();
  const [isSelectedAllRows, setIsSelectedAllRows] = useState(false);
  const [checkboxes, setCheckboxes] = useState({});
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const closeModal = useRef();
  const deleteId = useRef();
  const theme = useCoreStore((state) => state.theme);
  //   const hasAccessToCreate = checkHasAccessToAction("create-customer");
  const hasAccessToCreate = checkHasAccessToAction("create-customer");

  const paginateGoBack = usePaginateGoBack({
    setPage,
    total: paginationConfig.total,
    pageCount: paginationConfig.pageCount,
  });

  const handleChangeCheckbox = (e) => {
    setIsSelectedAllRows(e.target.checked);
  };

  const closeDeleteModal = (value, id) => {
    deleteId.current = id;
    closeModal.current.openModal(value);
  };

  const deleteCustomer = useMutate({
    onSuccess: () => {
      refetch();
      closeDeleteModal(false);
      notifySuccess(t("notification.successDelete"));
      paginateGoBack();
    },
  });

  const confirmDelete = () => {
    deleteCustomer.mutate({
      link: `/customer/delete?id=${deleteId.current}`,
      method: "delete",
    });
  };

  const deleteMultiCustomer = useMutate({
    onSuccess: (res) => {
      refetch();
      setCheckboxes({});
      setSelectedRowIds([]);
      notifySuccess(t("notification.successDelete"));
      paginateGoBack(res.length);
    },
  });

  const deleteSelectedRows = () => {
    deleteMultiCustomer.mutate({
      link: `${endpoints.customers.multiple_delete}`,
      method: "patch",
      body: { ids: selectedRowIds },
    });
  };

  const toggleCustomer = useMutate({
    onSuccess: () => {
      refetch();
    },
  });

  const customerToggleState = (id, state) => {
    toggleCustomer.mutate({
      link: `${endpoints.customers.update_state}?id=${id}`,
      method: "patch",
      body: { state: state === "ACTIVE" ? "INACTIVE" : "ACTIVE" },
    });
  };

  useEffect(() => {
    const filterByTrue = Object.values(checkboxes).filter((el) => el);
    const filteredKeys = Object.keys(checkboxes).filter((key) => checkboxes[key]);
    setSelectedRowIds(filteredKeys);

    if (filterByTrue.length !== data.length) setIsSelectedAllRows(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxes]);

  return (
    <div className="h-full px-2 flex flex-col relative">
      <h2 className="text-bold text-2xl flex text-black dark:text-textDark">{t("pages.customers.title")}</h2>
      <div className="w-full flex justify-between items-center my-4">
        <div className="w-[260px]">
          <ControlledInput
            className="w-full"
            onChange={handleSearch}
            placeholder={t("pages.customers.search")}
            leftElement={<img src={theme ? SearchWhite : Search} alt="CloseIcon" className="cursor-pointer" />}
          />
        </div>
        <div className="flex gap-3">
          {selectedRowIds.length > 0 && (
            <BaseButton
              label={`${t("button.deleteSelected")} (${selectedRowIds.length})`}
              icon={TrashIcon}
              className="btn-danger"
              onClick={deleteSelectedRows}
            />
          )}
          {hasAccessToCreate && (
            <BaseButton
              label={t("button.add")}
              icon={AddIcon}
              className="btn-primary"
              onClick={() => modalToggler(true)}
            />
          )}
        </div>
      </div>
      <BaseTable
        thead={
          <BaseThead
            header={header}
            withCheckbox={true}
            isSelectedAllRows={isSelectedAllRows}
            handleChangeCheckbox={handleChangeCheckbox}
          />
        }
        tbody={
          <Tbody
            data={data}
            isChecked={isSelectedAllRows}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            closeDeleteModal={closeDeleteModal}
            customerToggleState={customerToggleState}
          />
        }
      />
      <div className="w-full">
        <Pagination paginationConfig={paginationConfig} />
      </div>
      <DeleteConfirmation ref={closeModal} close={closeDeleteModal} cb={confirmDelete} />
    </div>
  );
};

export default Table;
