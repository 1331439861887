// i18n
import { useTranslation } from "react-i18next";

export const useTranslatedTableConfig = () => {
  const { t } = useTranslation();

  const header = [
    {
      key: "customer",
      title: t("pages.customers.table.customer"),
      width: "w-[40%]",
    },
    {
      key: "id",
      title: t("pages.customers.table.id"),
      width: "w-[15%]",
    },
    {
      key: "balance",
      title: t("pages.customers.table.balance"),
      width: "w-[15%]",
    },
    {
      key: "manager",
      title: t("pages.customers.table.manager"),
      width: "w-[15%]",
    },

    {
      key: "empty",
      title: "",
      width: "w-[15%]",
    },
  ];
  return { header };
};
