import { useState } from "react";
import { useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
import { userInfo } from "services/helpers";

const useRoles = () => {
  const [rolesOptions, setRolesOptions] = useState([]);
  const userPermissions = userInfo()?.resource_access?.[userInfo()?.azp]?.roles;

  useGet({
    link: endpoints.roles.get,
    onSuccess: (res) => {
      const optionTransformation = res?.map((el) => ({
        label: el.name,
        value: el.id,
      }));
      const filterByPermission = optionTransformation.filter(el => {
        if(userPermissions.includes("all-roles")) {
          return el
        } else if(userPermissions.includes("org-roles")) {
          return el.label === "org-manager"
        } else {
          return false
        }
      })
      setRolesOptions(filterByPermission);
    },
  });

  return { rolesOptions };
};

export default useRoles;
