import React, { memo, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// hooks
import useOrganization from "../../hooks/useOrganization";
import useUsers from "../../hooks/useUsers";
import useWarehouse from "../hooks/useWarehouse";
// store
import { useCoreStore } from "store/core";
// api
import { useMutate, useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";
import Datepicker from "react-tailwindcss-datepicker";
import ControlledSelect from "components/Select/ControlledSelect";
import TextArea from "components/Textarea/TextArea";
// icons
import IconSave from "assets/images/svg/icon-save.svg";

const ModalEditInvoice = memo(({ close, refetch }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const rowId = searchParams.get("row_id");
  const { orgId } = useCoreStore((state) => state);

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur", // or "onChange"
  });

  const { data: singleData } = useGet({
    link: endpoints.invoices.one,
    params: { id: rowId },
    // enabled: rowId,
  });

  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedManager, setSelectedManager] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const { warehouseOptions } = useWarehouse(orgId);
  const { subOrgOptions: subOrgList } = useOrganization(orgId, false);
  const { managerOptions: managerList, customerOptions: customerList } = useUsers(orgId);

  const [currentDate, setDate] = useState({
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
  });
  var prevDay = new Date();
  prevDay.setDate(prevDay.getDate() - 1);

  function removeId() {
    reset();
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }

  function clearAndClose() {
    close(false);
    removeId();
  }

  useEffect(() => {
    if (singleData) {
      if (customerList && customerList?.length > 0) {
        const client = {
          label: customerList?.find((item) => item?.value === singleData?.customer_id)?.label,
          value: singleData.customer_id,
        };

        setSelectedClient(client);
      }

      if (managerList && managerList?.length > 0) {
        const manager = {
          label: managerList?.find((item) => item?.value === singleData?.manager_id)?.label,
          value: singleData.manager_id,
        };

        setSelectedManager(manager);
      }

      if (subOrgList && subOrgList?.length > 0) {
        const branch = {
          label: subOrgList?.find((item) => item?.value === singleData?.sub_org_id)?.label,
          value: singleData.sub_org_id,
        };

        setSelectedBranch(branch);
      }

      if (warehouseOptions && warehouseOptions?.length > 0) {
        const warehouse = {
          label: warehouseOptions?.find((item) => item?.value === singleData?.warehouse_id)?.label,
          value: singleData.warehouse_id,
        };

        setSelectedWarehouse(warehouse);
      }

      setValue("comment", singleData.note);
      setDate({
        startDate: new Date(singleData.dynamic_date).toISOString().slice(0, 10),
        endDate: new Date(singleData.dynamic_date).toISOString().slice(0, 10),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleData, customerList, managerList]);

  const create = useMutate({
    onSuccess: () => {
      refetch();
      reset();
      clearAndClose();
      notifySuccess(t("notification.invoiceSuccessUpdate"));
    },
  });

  const onSubmit = (values) => {
    const body = {
      manager_id: selectedManager?.value,
      customer_id: selectedClient?.value,
      warehouse_id: selectedWarehouse?.value,
      sub_org_id: selectedBranch?.value,
      dynamic_date: currentDate.startDate,
      note: values.comment,
      send_bot: false,
      discount_sum: 0.0,
    };

    create.mutate({
      link: endpoints.invoices.update,
      params: {
        id: rowId,
      },
      method: "put",
      body: body,
    });
  };

  return (
    <div className="w-[600px]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <ControlledSelect
              label={t("pages.manInvoice.form.customer")}
              name="client"
              errors={errors}
              autoFocus={true}
              height="50px"
              isRequired={true}
              placeholder={t("pages.manInvoice.form.customerPlaceholder")}
              className="w-full  placeholder-darkGreyLight rounded-lg focus:border-purple-0 focus:bg-white focus:border-2 font-[16px] max-w-[100%]"
              options={customerList}
              value={selectedClient}
              onChange={(e) => {
                setSelectedClient(e);
              }}
            />
          </div>

          <div className="col-span-1">
            <label className="block text-stone-800 dark:text-white font-semibold text-[14px]">{t("info.date")}</label>
            <Datepicker
              asSingle
              useRange={false}
              // placeholder={"Выберите дату"}
              placeholder={currentDate.startDate}
              displayFormat={"DD.MM.YYYY"}
              startFrom={currentDate}
              value={currentDate}
              onChange={setDate}
              primaryColor={"violet"}
              containerClassName="relative calendar-container"
              inputClassName="w-full py-3 px-4 rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey w-full dark:text-white"
            />
          </div>

          <div className="col-span-1">
            <ControlledSelect
              label={t("pages.customers.form.manager")}
              name="manager"
              errors={errors}
              height="50px"
              isRequired={true}
              placeholder={t("pages.customers.form.managerPlaceholder")}
              className="w-full  placeholder-darkGreyLight rounded-lg focus:border-purple-0 focus:bg-white focus:border-2 font-[16px] max-w-[100%]"
              options={managerList}
              value={selectedManager}
              onChange={(e) => {
                setSelectedManager(e);
              }}
            />
          </div>

          <div className="col-span-1">
            <ControlledSelect
              label={t("pages.mainUsers.table.branch")}
              name="branch"
              errors={errors}
              height="50px"
              isRequired={true}
              placeholder={t("pages.mainUsers.selectBranch")}
              className="w-full  placeholder-darkGreyLight rounded-lg focus:border-purple-0 focus:bg-white focus:border-2 font-[16px] max-w-[100%]"
              options={subOrgList}
              value={selectedBranch}
              onChange={(e) => {
                setSelectedBranch(e);
              }}
            />
          </div>

          <div className="col-span-1">
            <ControlledSelect
              label={t("pages.mainUsers.table.warehouse")}
              name="branch"
              errors={errors}
              height="50px"
              isRequired={true}
              placeholder={t("pages.mainUsers.selectWarehouse")}
              className="w-full  placeholder-darkGreyLight rounded-lg focus:border-purple-0 focus:bg-white focus:border-2 font-[16px] max-w-[100%]"
              options={warehouseOptions}
              value={selectedWarehouse}
              onChange={(e) => {
                setSelectedWarehouse(e);
              }}
            />
          </div>

          <div className="col-span-1">
            <TextArea
              fieldName="comment"
              label={t("info.comment")}
              placeholder={t("pages.admInvoice.form.commentPlaceholder")}
              name="comment"
              errors={{}}
              register={register}
              className="w-full py-3 px-4 rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey h-[50px]"
            />
          </div>

          <div className="col-span-2 flex justify-between">
            <BaseButton
              label={t("button.cancel")}
              onClick={clearAndClose}
              className="p-2 btn-secondary dark:btn-secondary-dark"
            />

            <BaseButton
              icon={IconSave}
              label={t("button.save")}
              type="submit"
              className="col-span-1 bg-mainGreen text-white font-semibold"
              isLoading={create.isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
});

export default ModalEditInvoice;
