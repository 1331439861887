import { useState, useEffect, useCallback, useContext } from "react";
import { SaleContext } from "../Sale";
// store
import { useCoreStore } from "store/core";
// api
import { endpoints } from "services/endpoints";
import { useGet, useMutate } from "hooks/query";
// helpers
import { debounce } from "services/helpers";
// constants
import { exchangeOptions } from "constants";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import BaseTd from "components/Table/BaseTd";
import { NumericFormat } from "react-number-format";

// icons
import Close from "assets/images/svg/close.svg";
import CloseDark from "assets/images/svg/close-dark.svg";
import ColorBucketIcon from "assets/images/svg/color-bucket-icon.svg";

const PaymentItem = ({ item, courses }) => {
  const { t } = useTranslation();
  const getLabel = (value) => {
    const option = exchangeOptions.find((item) => item?.value === value);
    return option?.label;
  };

  const theme = useCoreStore((state) => state.theme);
  const { productSaleInfo, paymentSaleInfo, setPaymentSaleInfo } = useContext(SaleContext);

  const outgoPayments = paymentSaleInfo?.outgo_payments;

  const [outgoPayment, setOutgoPayment] = useState({});
  const [currentPayment, setCurrentPayment] = useState({});

  const [course, setCourse] = useState(item?.course || 1);
  const [outgoCourse, setOutgoCourse] = useState(outgoPayment?.course || 1);

  // sums
  // poducts
  const [total, setTotal] = useState(0);
  const [totalOutgo, setTotalOutgo] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  // payments
  const [paymentTotal, setPaymentTotal] = useState(0);
  const [paymentTotalOutgo, setPaymentTotalOutgo] = useState(0);
  const [paymentTotalIncome, setPaymentTotalIncome] = useState(0);

  useEffect(() => {
    setTotal(totalIncome - totalOutgo);
  }, [totalIncome, totalOutgo]);

  useEffect(() => {
    setPaymentTotal(paymentTotalIncome - paymentTotalOutgo);
  }, [paymentTotalIncome, paymentTotalOutgo]);

  useEffect(() => {
    if (paymentSaleInfo && item && item.id) {
      // Extract the payment ID from the item prop
      const paymentId = item.id;

      // Find the payment in income_payments or outgo_payments
      let foundPayment =
        paymentSaleInfo.income_payments.find((payment) => payment.id === paymentId) ||
        paymentSaleInfo.outgo_payments.find((payment) => payment.id === paymentId);

      if (foundPayment) {
        // Set the found payment to state
        setCurrentPayment(foundPayment);
      } else {
        // Handle case where payment is not found - possibly set to an empty object or a default value
        setCurrentPayment({});
      }
    }
  }, [paymentSaleInfo, item]);

  useEffect(() => {
    if (outgoPayments) {
      setOutgoPayment(outgoPayments.find((el) => el.currency === item?.currency));
    }
  }, [outgoPayments, item]);

  const edit = useMutate({
    onSuccess: (res) => {
      notifySuccess(t("notification.paymentSuccessUpdate"));
    },
  });

  const updatePaymentData = (course, baseAmount, currencyAmount, id) => {
    // Call the API with the new data
    edit.mutate({
      link: `${endpoints.invoices.updateInvoicePayment}?id=${id}`,
      method: "patch",
      body: {
        course: Number(course),
        base_amount: Number(baseAmount),
        currency_amount: Number(currencyAmount),
      },
    });
  };

  const debouncedUpdateProductData = useCallback(
    debounce((course, baseAmount, currencyAmount, id) => {
      updatePaymentData(course, baseAmount, currencyAmount, id);
    }, 300),
    [], // If updateProductData or any other dependencies don't change, this won't be recreated
  );

  useEffect(() => {
    if (item?.course) {
      setCourse(currentPayment?.course);
    } else {
      if (item?.currency) {
        let key = getLabel(item?.currency)?.toLowerCase();
        setCourse(Number(courses[key]?.amount));
      }
    }
  }, [item, courses, currentPayment]);

  useEffect(() => {
    if (outgoPayment?.course) {
      setOutgoCourse(outgoPayment?.course);
    } else {
      if (outgoPayment?.currency) {
        let key = getLabel(outgoPayment?.currency)?.toLowerCase();
        setOutgoCourse(Number(courses[key]?.amount));
      }
    }
  }, [outgoPayment, courses]);

  useEffect(() => {
    setTotalIncome(productSaleInfo?.income_products?.reduce((acc, item) => acc + (Number(item.paymentSumUsd) || 0), 0));

    setTotalOutgo(productSaleInfo?.outgo_products?.reduce((acc, item) => acc + (Number(item.paymentSumUsd) || 0), 0));
  }, [productSaleInfo]);

  useEffect(() => {
    setPaymentTotalIncome(
      paymentSaleInfo?.income_payments?.reduce((acc, item) => acc + (Number(item.currencyAmount) || 0), 0),
    );

    setPaymentTotalOutgo(
      paymentSaleInfo?.outgo_payments?.reduce((acc, item) => acc + (Number(item.currencyAmount) || 0), 0),
    );
  }, [paymentSaleInfo]);

  // handlers

  const handleCourseChange = (value) => {
    const formattedValue = value.replace(/\s/g, "");
    setCourse(Number(formattedValue));

    let baseAmount = currentPayment?.amount || null;
    let currencyAmount = null;
    if (baseAmount) {
      currencyAmount = baseAmount / formattedValue;
    }

    setPaymentSaleInfo((prev) => {
      // Assuming you have the paymentId variable with the ID you're looking for

      let paymentId = item.id;

      // Find the payment in income_payments
      const updatedIncomePayments = prev.income_payments.map((payment) => {
        if (payment.id === paymentId) {
          // Update the payment with the new value
          return {
            ...payment,
            course: Number(formattedValue),
            currencyAmount: payment.amount / Number(formattedValue),
          };
        }
        return payment;
      });

      // Find the payment in outgo_payments if not found in income_payments
      const updatedOutgoPayments = prev.outgo_payments.map((payment) => {
        if (payment.id === paymentId) {
          // Update the payment with the new value
          return {
            ...payment,
            course: Number(formattedValue),
            currencyAmount: payment.amount / Number(formattedValue),
          };
        }
        return payment;
      });

      // Return the updated paymentSaleInfo object
      return {
        ...prev,
        income_payments: updatedIncomePayments,
        outgo_payments: updatedOutgoPayments,
      };
    });

    debouncedUpdateProductData(formattedValue, currentPayment?.amount, currencyAmount, item.id);
  };

  const handleOutgoCourseChange = (value) => {
    const formattedValue = value.replace(/\s/g, "");
    setOutgoCourse(Number(formattedValue));

    let baseAmount = outgoPayment?.amount || null;
    let currencyAmount = null;
    if (baseAmount) {
      currencyAmount = baseAmount / formattedValue;
    }

    // edit paymentSaleInfo
    setPaymentSaleInfo((prev) => {
      // Assuming you have the paymentId variable with the ID you're looking for

      let paymentId = outgoPayment.id;

      // Find the payment in income_payments
      const updatedIncomePayments = prev.income_payments.map((payment) => {
        if (payment.id === paymentId) {
          // Update the payment with the new value
          return {
            ...payment,
            course: Number(formattedValue),
            currencyAmount: payment.amount / Number(formattedValue),
          };
        }
        return payment;
      });

      // Find the payment in outgo_payments if not found in income_payments
      const updatedOutgoPayments = prev.outgo_payments.map((payment) => {
        if (payment.id === paymentId) {
          // Update the payment with the new value
          return {
            ...payment,
            course: Number(formattedValue),
            currencyAmount: payment.amount / Number(formattedValue),
          };
        }
        return payment;
      });

      // Return the updated paymentSaleInfo object
      return {
        ...prev,
        income_payments: updatedIncomePayments,
        outgo_payments: updatedOutgoPayments,
      };
    });

    debouncedUpdateProductData(formattedValue, outgoPayment?.amount, currencyAmount, outgoPayment.id);
  };

  const handleBaseAmountChange = (value, type) => {
    const formattedValue = value.replace(/\s/g, "");

    let outgo;

    setPaymentSaleInfo((prev) => {
      // Assuming you have the paymentId variable with the ID you're looking for

      let paymentId;
      if (type === "income") {
        paymentId = item.id;
      } else {
        paymentId = outgoPayment.id;
      }

      // Find the payment in income_payments
      const updatedIncomePayments = prev.income_payments.map((payment) => {
        if (payment.id === paymentId) {
          outgo = false;
          // Update the payment with the new value
          return { ...payment, amount: Number(formattedValue), currencyAmount: Number(formattedValue) / course };
        }
        return payment;
      });

      // Find the payment in outgo_payments if not found in income_payments
      const updatedOutgoPayments = prev.outgo_payments.map((payment) => {
        if (payment.id === paymentId) {
          outgo = true;
          // Update the payment with the new value
          return {
            ...payment,
            amount: Number(formattedValue),
            currencyAmount: Number(formattedValue) / outgoCourse,
          };
        }
        return payment;
      });

      // Return the updated paymentSaleInfo object
      return {
        ...prev,
        income_payments: updatedIncomePayments,
        outgo_payments: updatedOutgoPayments,
      };
    });

    if (outgo) {
      debouncedUpdateProductData(outgoCourse, formattedValue, formattedValue / outgoCourse, outgoPayment.id);
    } else {
      debouncedUpdateProductData(course, formattedValue, formattedValue / course, item.id);
    }
  };

  const themeIcon = {
    [true]: CloseDark,
    [false]: Close,
  };

  const tdStyles = "!text-[12px] py-[10px]";

  return (
    <tr key={item.id} className="hover:bg-GreyLight dark:hover:bg-inputBgDark">
      <BaseTd extraClass={tdStyles}>{getLabel(item?.currency)}</BaseTd>
      <BaseTd extraClass={tdStyles}>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <p>{item?.income}</p>
            {/* <span>${currentPayment?.currencyAmount}</span> */}
            <NumericFormat
              value={currentPayment?.amount}
              placeholder={paymentTotal + total < 0 ? -((paymentTotal + total) * course) : 0}
              className="w-[60px] h-[20px] text-[14px] input p-[2px] mr-[8px] dark:bg-[#1F1E25] dark:border-[#1F1E25]"
              allowEmptyFormatting={true}
              decimalScale={2}
              thousandSeparator=" "
              fixedDecimalScale={false}
              allowNegative={false}
              onChange={(e) => {
                handleBaseAmountChange(e.target.value, "income");
              }}
            />
            <button
              className="dark:text-darkGrey rounded-[1px] cursor-pointer hover:bg-GreyLight dark:hover:bg-inputBgDark flex items-center
            bg-GreyInput dark:bg-inputBgDark flex items-center
            w-[10px] h-[10px] ml-[7px]
            "
              onClick={() => handleBaseAmountChange("0", "income")}
            >
              <img src={themeIcon[theme]} alt="modalclose" className="m-auto w-[6px] h-[6px]" />
            </button>
          </div>
          <div className="flex flex-row items-center">
            <button
              className="dark:text-darkGrey rounded-[1px] cursor-pointer hover:bg-GreyLight dark:hover:bg-inputBgDark flex items-center
            bg-GreyInput dark:bg-inputBgDark flex items-center
            w-[20px] h-[20px] mr-[7px]"
              onClick={() => {
                if (paymentTotal + total < 0) {
                  handleBaseAmountChange(-((paymentTotal + total) * course - currentPayment?.amount) + "", "income");
                }
              }}
            >
              <img src={ColorBucketIcon} alt="modalclose" className="m-auto w-[18px] h-[18px]" />
            </button>
            <NumericFormat
              value={currentPayment?.course || course}
              className="w-[60px] h-[20px] text-[14px] input p-[2px] mr-[8px] dark:bg-[#1F1E25] dark:border-[#1F1E25]"
              decimalScale={2}
              thousandSeparator=" "
              fixedDecimalScale={false}
              allowNegative={false}
              onChange={(e) => {
                handleCourseChange(e.target.value);
              }}
            />
            {/* <input defaultValue={1} className="w-[50px] h-[20px] text-[14px] input p-[2px] mr-[8px]" /> */}
          </div>
        </div>
      </BaseTd>
      <BaseTd extraClass={tdStyles}>
        <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row items-center">
            <p>{item?.income}</p>
            {/* <span>${outgoPayment?.currencyAmount}</span> */}
            <NumericFormat
              value={outgoPayment?.amount}
              placeholder={
                paymentTotal + total > 0 ? (paymentTotal + total) * outgoCourse + outgoPayment?.amount * outgoCourse : 0
              }
              className="w-[60px] h-[20px] text-[14px] input p-[2px] mr-[8px] dark:bg-[#1F1E25] dark:border-[#1F1E25]"
              decimalScale={2}
              thousandSeparator=" "
              fixedDecimalScale={false}
              allowNegative={false}
              onChange={(e) => {
                handleBaseAmountChange(e.target.value, "outgo");
              }}
            />
            <button
              className="dark:text-darkGrey rounded-[1px] cursor-pointer hover:bg-GreyLight dark:hover:bg-inputBgDark flex items-center
            bg-GreyInput dark:bg-inputBgDark flex items-center
            w-[10px] h-[10px] ml-[7px]
            "
              onClick={() => handleBaseAmountChange("0", "outgo")}
            >
              <img src={themeIcon[theme]} alt="modalclose" className="m-auto w-[6px] h-[6px]" />
            </button>
          </div>
          <div className="flex flex-row items-center">
            <button
              className="dark:text-darkGrey rounded-[1px] cursor-pointer hover:bg-GreyLight dark:hover:bg-inputBgDark flex items-center
            bg-GreyInput dark:bg-inputBgDark flex items-center
            w-[20px] h-[20px] mr-[7px]"
              onClick={() => {
                if (paymentTotal + total > 0) {
                  handleBaseAmountChange((paymentTotal + total) * outgoCourse + outgoPayment?.amount + "", "outgo");
                }
              }}
            >
              <img src={ColorBucketIcon} alt="modalclose" className="m-auto w-[18px] h-[18px]" />
            </button>
            <NumericFormat
              value={outgoPayment?.course || outgoCourse}
              className="w-[60px] h-[20px] text-[14px] input p-[2px] mr-[8px] dark:bg-[#1F1E25] dark:border-[#1F1E25]"
              decimalScale={2}
              thousandSeparator=" "
              fixedDecimalScale={false}
              allowNegative={false}
              onChange={(e) => {
                handleOutgoCourseChange(e.target.value);
              }}
            />
          </div>
        </div>
      </BaseTd>
    </tr>
  );
};

const Tbody = ({ data }) => {
  const orgId = useCoreStore((state) => state.orgId);

  const { data: courseUsdApi } = useGet({
    link: endpoints.exchangeRate.last,
    params: { org_id: orgId, base_currency: "USD", currency: "USD" },
  });

  const { data: courseUzsApi } = useGet({
    link: endpoints.exchangeRate.last,
    params: { org_id: orgId, base_currency: "USD", currency: "UZS" },
  });

  const { data: coursePerApi } = useGet({
    link: endpoints.exchangeRate.last,
    params: { org_id: orgId, base_currency: "USD", currency: "PER" },
  });

  const { data: courseTerApi } = useGet({
    link: endpoints.exchangeRate.last,
    params: { org_id: orgId, base_currency: "USD", currency: "TER" },
  });

  if (!data) return null;

  return (
    <>
      {data?.income_payments?.map((item) => (
        <PaymentItem
          key={item.id}
          item={item}
          outgoPayments={data?.outgo_payments}
          courses={{
            usd: courseUsdApi,
            uzs: courseUzsApi,
            per: coursePerApi,
            ter: courseTerApi,
          }}
        />
      ))}
    </>
  );
};

export default Tbody;
