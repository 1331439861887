import cls from "classnames";

const CircleLoader = ({ theme = "default" }) => {
  return (
    // <div className="w-full flex justify-center">
    <span className={cls("loader", theme)}></span>
    // </div>
  );
};

export default CircleLoader;
