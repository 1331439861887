import { useEffect, useRef, useState } from "react";

import Tbody from "./Tbody";
import BaseTable from "components/Table/BaseTable";
import BaseThead from "components/Table/BaseThead";
import BaseButton from "components/Button/BaseButton";
import ControlledInput from "components/Input/ControlledInput";
import { useCoreStore } from "store/core";
import { notifySuccess } from "components/Toast";
import { useMutate } from "hooks/query";
import DeleteConfirmation from "container/DeleteConfirmation";
// i18n
import { useTranslation } from "react-i18next";
import { useTranslatedTableConfig } from "../Helpers";
import { checkHasAccessToAction } from "services/helpers";
import { endpoints } from "services/endpoints";

import Search from "assets/images/svg/search.svg";
import AddIcon from "assets/images/svg/add-to-photos.svg";
import TrashIcon from "assets/images/svg/trash-white.svg";
import SearchWhite from "assets/images/svg/search-white.svg";

const Table = ({ refetch, data = [], modalToggler, handleSearch = () => {} }) => {
  const { t } = useTranslation();

  const [isSelectedAllRows, setIsSelectedAllRows] = useState(false);
  const [checkboxes, setCheckboxes] = useState({});
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const { header } = useTranslatedTableConfig();

  const closeModal = useRef();
  const deleteId = useRef();
  const theme = useCoreStore((state) => state.theme);
  const hasAccessToCreate = checkHasAccessToAction("create-role");

  const handleChangeCheckbox = (e) => {
    setIsSelectedAllRows(e.target.checked);
  };

  const closeDeleteModal = (value, id) => {
    deleteId.current = id;
    closeModal.current.openModal(value);
  };

  const deleteRole = useMutate({
    onSuccess: () => {
      refetch();
      closeDeleteModal(false);
      notifySuccess(t("notification.successDelete"));
    },
  });

  const confirmDelete = () => {
    deleteRole.mutate({
      link: `${endpoints.roles.delete}?id=${deleteId.current}`,
      method: "delete",
    });
  };

  useEffect(() => {
    const filterByTrue = Object.values(checkboxes).filter((el) => el);
    const filteredKeys = Object.keys(checkboxes).filter((key) => checkboxes[key]);
    setSelectedRowIds(filteredKeys);

    if (filterByTrue.length !== data.length) setIsSelectedAllRows(false);
  }, [checkboxes]);

  return (
    <div className="h-full px-2 flex flex-col">
      <h2 className="text-bold text-2xl flex text-black dark:text-textDark">{t("pages.roles.title")}</h2>
      <div className="w-full flex justify-between items-center my-4">
        <div className="w-[260px]">
          <ControlledInput
            className="w-full"
            placeholder={`${t("pages.roles.search")}...`}
            onChange={handleSearch}
            leftElement={<img src={theme ? SearchWhite : Search} alt="CloseIcon" className="cursor-pointer" />}
          />
        </div>
        <div className="flex gap-3">
          {selectedRowIds.length > 0 && (
            <BaseButton
              label={`${t("button.deleteSelected")} (${selectedRowIds.length})`}
              icon={TrashIcon}
              className="btn-danger"
            />
          )}
          {hasAccessToCreate && (
            <BaseButton
              label={t("button.addRole")}
              icon={AddIcon}
              className="btn-primary"
              onClick={() => modalToggler(true)}
            />
          )}
        </div>
      </div>
      <BaseTable
        thead={
          <BaseThead
            header={header}
            withCheckbox={true}
            isSelectedAllRows={isSelectedAllRows}
            handleChangeCheckbox={handleChangeCheckbox}
          />
        }
        tbody={
          <Tbody
            data={data}
            isChecked={isSelectedAllRows}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            closeDeleteModal={closeDeleteModal}
          />
        }
      />
      <DeleteConfirmation
        ref={closeModal}
        close={closeDeleteModal}
        cb={confirmDelete}
        description="Вы уверены, что хотите удалить выбранные элементы?"
        title="Удалить"
      />
    </div>
  );
};

export default Table;
