import React, { useState, memo, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// api
import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// heplers
import { uppercaseTwoLetters } from "services/helpers";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import Input from "components/Input/Input";
import NumericField from "components/NumericField/NumericField";
import BaseButton from "components/Button/BaseButton";
import ImageUploaderLarge from "components/ImageUploaderLarge";
// icons
import IconSave from "assets/images/svg/icon-save.svg";

const ModalForm = memo(({ close, refetch, row }) => {
  const { t } = useTranslation();

  let [searchParams, setSearchParams] = useSearchParams();
  const rowId = searchParams.get("row_id");

  const [imagesUrl, setImagesUrl] = useState([]);

  const {
    reset,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function removeId() {
    reset();
    setImagesUrl([]);
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }

  function clearAndClose() {
    close(false);
    removeId();
    setImagesUrl([]);
  }

  useEffect(() => {
    if (rowId) {
      setValue("tin", row.tin);
      setValue("name", row.name);
      setValue("main_email", row.main_email);
      setValue("main_phone", row.main_phone);
      setValue("customer_prefix", row.customer_prefix);
      setValue("customer_lpad", row.customer_lpad);
      setValue("product_prefix", row.product_prefix);
      setValue("product_lpad", row.product_lpad);
    } else {
      reset();
    }
  }, [row]);

  useEffect(() => {
    if (row.main_photo) {
      setImagesUrl([row.main_photo]);
    } else {
      setImagesUrl([]);
    }
  }, [row.main_photo]);

  const create = useMutate({
    onSuccess: () => {
      refetch();
      close(false);
      removeId();
      notifySuccess(t("notification.orgSuccessCreate"));
    },
  });

  const update = useMutate({
    onSuccess: () => {
      refetch();
      close(false);
      removeId();
      notifySuccess(t("notification.orgSuccessUpdate"));
    },
  });

  const onSubmit = (values) => {
    values.main_photo = imagesUrl[0];
    if (row.id) {
      update.mutate({
        link: `${endpoints.organizations.update}?id=${row.id}`,
        method: "put",
        body: {
          ...values,
          main_phone: "+998" + values.main_phone,
        },
      });
    } else {
      create.mutate({
        link: endpoints.organizations.create,
        method: "post",
        body: {
          ...values,
          main_phone: "+998" + values.main_phone,
        },
      });
    }
  };

  return (
    <div className="w-[320px]">
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-1">
          <ImageUploaderLarge
            url={imagesUrl}
            label={t("pages.mainOrganizations.orgImage")}
            setImagesUrl={setImagesUrl}
            classNameExtra="w-[200px]"
          />
          <Input
            autoFocus
            label={t("pages.mainOrganizations.form.name")}
            fieldName="name"
            register={register}
            errors={errors}
            isRequired={true}
            className="w-full"
            placeholder={t("pages.mainOrganizations.form.namePlaceholder")}
          />
          <Input
            label={t("pages.mainOrganizations.form.tin")}
            type="number"
            fieldName="tin"
            register={register}
            errors={errors}
            isRequired={true}
            className="w-full"
            placeholder={t("pages.mainOrganizations.form.tinPlaceholder")}
          />
          <Input
            label="Email"
            fieldName="main_email"
            register={register}
            errors={errors}
            isRequired={true}
            className="w-full"
            placeholder={t("pages.mainOrganizations.form.emailPlaceholder")}
          />
          {/* <Input
            label={t("pages.mainOrganizations.form.phone")}
            fieldName="main_phone"
            register={register}
            errors={errors}
            isRequired={true}
            className="w-full"
          /> */}

          <NumericField
            name="phone_number"
            placeholder="+998 -- --- -- --"
            format="+998 ## ### ## ##"
            errors={errors}
            label={t("form.phoneNumber")}
            allowEmptyFormatting={true}
            isRequired={true}
            mask="-"
            className="w-full h-[44px]"
            onChange={(e) => setValue("main_phone", e)}
            value={
              // cut the first 4 characters
              row.main_phone && row.main_phone.substring(4, row.main_phone.length)
            }
          />
          <div className="grid grid-cols-3 gap-x-2 gap-y-1">
            <div className="col-span-3">
              <p className="block text-stone-800 dark:text-white font-semibold text-[14px] required-field">
                {t("pages.mainOrganizations.form.customerPrefix")}
              </p>
            </div>
            <div className="col-span-1">
              <Input
                label=""
                fieldName="customer_prefix"
                placeholder="AB"
                inputType="letterPrefix"
                register={register}
                errors={errors}
                isRequired={true}
                className="w-full"
                setValue={setValue}
                formatFunction={uppercaseTwoLetters}
              />
            </div>
            <div className="col-span-2">
              <Input
                label=""
                placeholder="4"
                fieldName="customer_lpad"
                type="number"
                register={register}
                errors={errors}
                isRequired={true}
                className="w-full"
              />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-x-2 gap-y-1">
            <div className="col-span-3">
              <p className="block text-stone-800 dark:text-white font-semibold text-[14px] required-field">
                {t("pages.mainOrganizations.form.productPrefix")}
              </p>
            </div>
            <div className="col-span-1">
              <Input
                label=""
                placeholder="AB"
                inputType="letterPrefix"
                fieldName="product_prefix"
                register={register}
                errors={errors}
                isRequired={true}
                className="w-full"
                formatFunction={uppercaseTwoLetters}
              />
            </div>
            <div className="col-span-2">
              <Input
                label=""
                placeholder="4"
                fieldName="product_lpad"
                type="number"
                register={register}
                errors={errors}
                isRequired={true}
                className="w-full"
              />
            </div>
          </div>
        </div>
        <div className="flex gap-6 mt-8">
          <BaseButton
            label={t("button.cancel")}
            onClick={clearAndClose}
            className="flex-1 p-2 btn-secondary dark:btn-secondary-dark w-[50px]"
          />
          <BaseButton
            type="submit"
            className="flex-1 p-2 btn-success"
            icon={IconSave}
            label={row.id ? t("button.update") : t("button.save")}
            isLoading={create.isLoading || update.isLoading}
          />
        </div>
      </form>
    </div>
  );
});

export default ModalForm;
