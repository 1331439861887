import cls from "classnames/";
import CircleLoader from "../Loader/CircleLoader";

const BaseButton = ({
  label,
  labelClass,
  iconClassName,
  icon,
  className,
  onClick = () => {},
  type = "button",
  disabled = false,
  isLoading = false,
}) => {
  return (
    <button
      className={cls("btn", className, { "opacity-[0.6] select-none pointer-events-none": disabled || isLoading })}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <img src={icon} alt="icon" className={cls("button__icon", iconClassName)} />}
      {isLoading ? <CircleLoader /> : null}
      {label && <p className={cls(labelClass)}>{label}</p>}
    </button>
  );
};

export default BaseButton;
