import React, { useState, useEffect, memo } from "react";
import { useSearchParams } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { useForm, Controller } from "react-hook-form";
// constants
import { exchangeOptions } from "constants";
// store
import { useCoreStore } from "store/core";
// api
import { useGet, useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";
import ControlledSelect from "components/Select/ControlledSelect";
import ControlledInput from "components/Input/ControlledInput";
import TextArea from "components/Textarea/TextArea";
// icons
import IconSave from "assets/images/svg/icon-save.svg";

const ModalAddPayment = memo(({ close, refetch }) => {
  const { t } = useTranslation();
  const { orgId } = useCoreStore((state) => state);
  let [searchParams, setSearchParams] = useSearchParams();
  const invoiceId = searchParams.get("invoice_id");
  const type = searchParams.get("type");

  const {
    watch,
    reset,
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      return: false,
    },
  });

  function removeId() {
    reset();
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: () => {
      refetch();
      reset();
      clearAndClose();
      notifySuccess(t("notification.paymentSuccessCreate"));
    },
  });

  

  const watchType = watch("currencyType") || "";
  const watchSum = watch("sum");
  const watchPercent = watch("percent");
  const watchRate = watch("rate");
  const watchCurrency = watch("currency");

  const [inputKey, setInputKey] = useState(0);

  const { data: course } = useGet({
    link: endpoints.exchangeRate.last,
    params: { org_id: orgId, base_currency: "USD", currency: watchType.toUpperCase() },
    enabled: !!watchType,
  });

  useEffect(() => {
    if (course?.has_course) {
      setValue("rate", course.amount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course]);

  useEffect(() => {
    if (watchType === "usd") {
      setValue("currencyType", "usd");
      setValue("percent", 100);
    }
  }, [watchType, setValue]);
  const handlePercentChange = (values) => {
    const value = Number(values.value);
    if (value > 100) {
      setValue("percent", 100);
      setInputKey((prevKey) => prevKey + 1); // Increment key to force re-render
    } else {
      setValue("percent", value);
    }

    if (watchSum && watchRate) {
      const sum = Number(watchSum);
      const rate = Number(watchRate);
      const currency = (sum / rate / 100) * value;

      setValue("currency", currency);
    }
  };

  const handleSumChange = (value) => {
    const formattedValue = value.replace(/\s/g, "");

    if (watchPercent && watchRate) {
      const percent = Number(watchPercent);
      const rate = Number(watchRate);
      const currency = (Number(formattedValue) / rate / 100) * percent;

      setValue("currency", currency);
    }
    setValue("sum", formattedValue);
  };

  const handleRateChange = (value) => {
    if (watchPercent && watchSum) {
      const percent = Number(watchPercent);
      const sum = Number(watchSum);
      const currency = (Number(sum) / Number(value) / 100) * percent;

      setValue("currency", currency);
    }

    setValue("rate", value);
  };

  const handleCurrencyChange = (value) => {
    const formattedValue = value.replace(/\s/g, "");

    if (watchPercent && watchRate) {
      const percent = Number(watchPercent);
      const rate = Number(watchRate);
      const sum = Number(formattedValue) * rate * (percent / 100);

      setValue("sum", sum);
    }
    setValue("currency", formattedValue);
  };

  const onSubmit = (values) => {
    const body = {
      payment_type: type,
      num: 1,
      currency: values.currencyType,
      base_amount: Number(values.sum),
      course: Number(values.rate),
      currency_amount: Number(values.currency),
      percent: Number(values.percent),
      note: values.comment,
    };

    create.mutate({
      link: endpoints.invoices.invoicePaymentCreate,
      params: { invoice_id: invoiceId },
      method: "post",
      body,
    });
  };

  return (
    <div className="w-[600px]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <Controller
              name="currencyType"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <ControlledSelect
                  label={t("pages.manInvoice.form.type")}
                  name="currencyType"
                  errors={errors}
                  autoFocus={true}
                  height="50px"
                  placeholder={t("pages.manInvoice.form.typePlaceholder")}
                  className="w-full  placeholder-darkGreyLight rounded-lg focus:border-purple-0 focus:bg-white focus:border-2 font-[16px] max-w-[100%]"
                  options={exchangeOptions}
                  onChange={(e) => {
                    setValue("currencyType", e.value);
                  }}
                />
              )}
            />
          </div>

          <div className="col-span-1">
            <NumericFormat
              fieldName="sum"
              label={t("pages.manInvoice.table.amount")}
              placeholder={t("pages.manInvoice.table.amount")}
              name="sum"
              value={watchSum}
              errors={errors}
              register={register}
              hasError={errors.sum}
              className="w-full h-[50px] rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey"
              decimalScale={0}
              thousandSeparator=" "
              suffix=""
              fixedDecimalScale={false}
              allowNegative={false}
              onChange={(e) => {
                handleSumChange(e.target.value);
              }}
              customInput={ControlledInput}
            />
          </div>

          <div className="col-span-1">
            <NumericFormat
              key={inputKey} // Use the key to force re-render
              value={watchPercent}
              onValueChange={handlePercentChange}
              isRequired={true}
              fieldName="percent"
              label={t("pages.admInvoice.form.percent")}
              placeholder={t("pages.admInvoice.form.percentPlaceholder")}
              name="percent"
              errors={errors}
              register={register}
              hasError={errors.percent}
              disabled={watchType === "usd"}
              className="w-full h-[50px] rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey"
              decimalScale={0}
              thousandSeparator=" "
              suffix=""
              fixedDecimalScale={false}
              allowNegative={false}
              customInput={ControlledInput}
            />
          </div>
          <div className="col-span-1">
            <NumericFormat
              fieldName="rate"
              label={t("pages.kurs.form.rate")}
              placeholder={t("pages.kurs.form.ratePlaceholder")}
              name="rate"
              value={watchRate}
              errors={errors}
              register={register}
              hasError={errors.rate}
              disabled={watchType === "usd"}
              className="w-full h-[50px] rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey"
              decimalScale={0}
              thousandSeparator=" "
              suffix=""
              fixedDecimalScale={false}
              allowNegative={false}
              onValueChange={(values) => handleRateChange(values.value)}
              customInput={ControlledInput}
            />
          </div>
          <div className="col-span-1">
            <NumericFormat
              fieldName="currency"
              label="$"
              placeholder="10$"
              name="currency"
              errors={errors}
              value={watchCurrency}
              register={register}
              hasError={errors.currency}
              disabled={watchType === "usd"}
              className="w-full h-[50px] rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey"
              decimalScale={2}
              thousandSeparator=" "
              suffix=""
              fixedDecimalScale={false}
              allowNegative={false}
              onChange={(e) => {
                handleCurrencyChange(e.target.value);
              }}
              customInput={ControlledInput}
            />
          </div>
          <div className="col-span-1">
            <TextArea
              fieldName="comment"
              label={t("info.comment")}
              placeholder={t("pages.admInvoice.form.commentPlaceholder")}
              name="comment"
              errors={{}}
              register={register}
              className="w-full py-3 px-4 rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey h-[50px]"
            />
          </div>

          <div className="col-span-2 flex justify-between">
            <BaseButton
              label={t("button.cancel")}
              onClick={clearAndClose}
              className="p-2 btn-secondary dark:btn-secondary-dark"
            />

            <BaseButton
              icon={IconSave}
              label={t("button.save")}
              type="submit"
              className="col-span-1 bg-mainGreen text-white font-semibold"
              isLoading={create.isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
});

export default ModalAddPayment;
