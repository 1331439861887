import React, { useRef, useState, useEffect } from "react";
import { useCoreStore } from "store/core";
import { useSearchParams } from "react-router-dom";
// api
import { endpoints } from "services/endpoints";
import { useGet, useMutate } from "hooks/query";
// helpers
import { formatPrice } from "services/helpers";
// i18n
import { useTranslation } from "react-i18next";
// components
import Tbody from "./Tbody";
import Tbody2 from "./Tbody2";
import BaseTable from "components/Table/BaseTable";
import BaseThead from "components/Table/BaseThead";
import DeleteConfirmation from "container/DeleteConfirmation";
import { notifySuccess, notifyError } from "components/Toast";
import BaseButton from "components/Button/BaseButton";

import { useTranslatedTableConfig } from "../Helpers";
// icons
import Save from "assets/images/svg/icon-save.svg";
import Excel from "assets/images/svg/exel-icon.svg";
import Delete from "assets/images/svg/trash-white.svg";

const Table = ({
  archived,
  incomeProducts,
  outgoProducts,
  incomePayments,
  outgoPayments,
  singleInvoice,
  updateSingleInvoice,
  currentTable,
  refetch,
  singleInvoiceRefetch,
  handleTemplatesModalSaveInvoice,
  handleTemplatesModalComments,
}) => {
  const { header: tableHeader, header2: tableHeader2 } = useTranslatedTableConfig();

  const { t } = useTranslation();
  const { orgId } = useCoreStore((state) => state);

  const deleteId = useRef();
  const closeModal = useRef();

  const [header, setHeader] = useState(tableHeader);
  const [header2, setHeader2] = useState(tableHeader2);

  const { data: courseApi } = useGet({
    link: endpoints.exchangeRate.last,
    params: { org_id: orgId, base_currency: "USD", currency: "UZS" },
  });

  const course = courseApi?.amount || 1;
  // eslint-disable-next-line no-unused-vars
  let [searchParams, setSearchParams] = useSearchParams();

  const rowId = searchParams.get("id");

  const extraClassTr = `!rounded-lg`;

  const deleteInvoice = useMutate({
    onSuccess: () => {
      closeDeleteModal(false);
      notifySuccess(t("notification.successDelete"));
      refetch();
    },
  });

  const confirmDelete = () => {
    deleteInvoice.mutate({
      link: `${endpoints.invoices.delete}?id=${deleteId.value}`,
      method: "delete",
    });
  };

  const closeDeleteModal = (value) => {
    closeModal.current.openModal(value);
  };

  useEffect(() => {
    setHeader(tableHeader);
    setHeader2(tableHeader2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTable]);

  const checkInvoice = () => {
    let isFilled = true;

    if (
      incomeProducts.length === 0 &&
      outgoProducts.length === 0 &&
      incomePayments.length === 0 &&
      outgoPayments.length === 0
    ) {
      isFilled = false;
      notifyError(t("notification.addPaymentOrProduct"));
    }

    if (incomeProducts.length !== 0) {
      incomeProducts.map((item) => {
        if ((item.amount == null || item.price == null) && isFilled) {
          isFilled = false;
          notifyError(t("notification.incomeProductNotFilled"));
        }
      });
    }

    if (outgoProducts.length !== 0) {
      outgoProducts.map((item) => {
        if ((item.amount == null || item.price == null) && isFilled) {
          isFilled = false;
          notifyError(t("notification.expenseProductNotFilled"));
        }
      });
    }

    if (incomePayments.length !== 0) {
      incomePayments.map((item) => {
        if (
          (item.base_amount == null || item.percent == null || item.course == null || item.currency_amount == null) &&
          isFilled
        ) {
          isFilled = false;
          notifyError(t("notification.incomePaymentNotFilled"));
        }
      });
    }

    if (outgoPayments.length !== 0) {
      outgoPayments.map((item) => {
        if (
          (item.base_amount == null || item.percent == null || item.course == null || item.currency_amount == null) &&
          isFilled
        ) {
          isFilled = false;
          notifyError(t("notification.expensePaymentNotFilled"));
        }
      });
    }

    if (isFilled) {
      handleTemplatesModalSaveInvoice(true);
    } else {
      // notifyError(t("pages.manInvoice.notFilled"));
    }
  };
  return (
    <div className="h-full flex flex-col">
      {/* <div className="max-h-full h-full"> */}
      {currentTable === "payment" ? (
        <div className="flex flex-col h-full h-[calc(100vh-400px)]">
          {/* <div className="mt-[16px] flex flex-col flex-1 overflow-y-scroll"> */}
          <BaseTable
            thead={<BaseThead key={currentTable} header={header2} extraClassTr={extraClassTr} thColor="#FFC6BE" />}
            tbody={
              <Tbody2
                archived={archived}
                singleInvoice={singleInvoice}
                updateSingleInvoice={updateSingleInvoice}
                data={outgoPayments}
                currentTable="outgoPayments"
                handleTemplatesModalComments={handleTemplatesModalComments}
              />
            }
          />
          {/* </div> */}
          <div className="mt-[16px] flex flex-col flex-1 overflow-y-auto">
            <BaseTable
              thead={
                <BaseThead
                  key={currentTable}
                  header={header2}
                  extraClassTr={extraClassTr}
                  thColor="#A6D5FA"
                  className="rounded-[12px]"
                />
              }
              tbody={
                <Tbody2
                  archived={archived}
                  singleInvoice={singleInvoice}
                  updateSingleInvoice={updateSingleInvoice}
                  data={incomePayments}
                  currentTable="incomePayments"
                  handleTemplatesModalComments={handleTemplatesModalComments}
                />
              }
            />
          </div>
        </div>
      ) : (
        <div className="overflow-y-auto flex flex-col h-[calc(100vh-400px)]">
          {/* <div className="h-[1000px] bg-[lawngreen]">
            Content
          </div> */}
          <BaseTable
            thead={<BaseThead header={header} extraClassTr={extraClassTr} />}
            tbody={
              <Tbody
                archived={archived}
                singleInvoice={singleInvoice}
                updateSingleInvoice={updateSingleInvoice}
                data={currentTable === "arrival" ? incomeProducts : outgoProducts}
                currentTable={currentTable}
                handleTemplatesModalComments={handleTemplatesModalComments}
              />
            }
          />
        </div>
      )}
      {/* </div> */}
      <div className="flex flex-row justify-between mt-auto text-xs items-center">
        <div className="flex flex-row gap-[16px]">
          <div className="flex flex-row px-[6px] py-[4px] bg-[#FAFAFA] dark:bg-[#1F1E25] dark:border-[#2D2D2D] text-[12px] border-solid border-[1px] border-[#F2F2F2] rounded-[4px] text-[#FF705D]">
            <span className="mr-[4px]">{t("pages.manInvoice.totals.expense")}: </span>
            <span>
              {formatPrice(
                outgoProducts.reduce((acc, item) => {
                  return acc + item.price * item.amount;
                }, 0),
              )}{" "}
              $
            </span>
          </div>
          <div className="flex flex-row px-[6px] py-[4px] bg-[#FAFAFA] dark:bg-[#1F1E25] dark:border-[#2D2D2D] text-[12px] border-solid border-[1px] border-[#F2F2F2] rounded-[4px] text-[#2196F3]">
            <span className="mr-[4px]">{t("pages.manInvoice.totals.income")}: </span>
            <span>
              {formatPrice(
                incomeProducts.reduce((acc, item) => {
                  return acc + item.price * item.amount;
                }, 0),
              )}{" "}
              $
            </span>
          </div>
          <div className="flex flex-row px-[6px] py-[4px] bg-[#FAFAFA] dark:bg-[#1F1E25] dark:border-[#2D2D2D] text-[12px] border-solid border-[1px] border-[#F2F2F2] rounded-[4px] text-[#FFCD29]">
            <span className="mr-[4px]">{t("pages.manInvoice.totals.payment")}: </span>
            <span>
              {formatPrice(
                incomePayments.reduce((acc, item) => {
                  return item.course !== 0 ? acc + item.base_amount / item.course : acc;
                }, 0) -
                  outgoPayments.reduce((acc, item) => {
                    return item.course !== 0 ? acc + item.base_amount / item.course : acc;
                  }, 0),
              )}{" "}
              $
            </span>
          </div>
          <div className="flex flex-row px-[6px] py-[4px] bg-[#FAFAFA] dark:bg-[#1F1E25] dark:border-[#2D2D2D] text-[12px] border-solid border-[1px] border-[#F2F2F2] rounded-[4px] text-[#535456] dark:text-white">
            <span className="mr-[4px]">{t("pages.manInvoice.totals.invoice")}: </span>
            <span>
              {formatPrice(
                incomeProducts.reduce((acc, item) => {
                  return acc + item.price * item.amount;
                }, 0) -
                  outgoProducts.reduce((acc, item) => {
                    return acc + item.price * item.amount;
                  }, 0) +
                  incomePayments.reduce((acc, item) => {
                    return item.course !== 0 ? acc + item.base_amount / item.course : acc;
                  }, 0) -
                  outgoPayments.reduce((acc, item) => {
                    return item.course !== 0 ? acc + item.base_amount / item.course : acc;
                  }, 0),
              )}{" "}
              $
            </span>
          </div>
        </div>
        {!archived && (
          <div className="btns flex flex-row space-x-[8px]">
            <BaseButton
              icon={Delete}
              className="h-[32px] px-[16px] rounded-lg bg-[#FF705D] text-textBlack hover:opacity-[0.8] transition duration-200"
              iconClassName="w-[16px]"
              onClick={() => {
                deleteId.value = rowId;
                closeDeleteModal(true, rowId);
              }}
            />
            <BaseButton
              icon={Excel}
              className="h-[32px] p-[16px] rounded-lg bg-[#7B61FF] text-textBlack hover:opacity-[0.8] transition duration-200"
              iconClassName="w-[16px]"
            />
            <BaseButton
              type="submit"
              icon={Save}
              className="h-[32px] p-[16px] rounded-lg bg-[#87CB19] text-textBlack hover:opacity-[0.8] transition duration-200"
              iconClassName="w-[20px]"
              onClick={checkInvoice}
            />
          </div>
        )}
      </div>
      <DeleteConfirmation
        title={t("pages.manInvoice.deleteInvoice")}
        description={t("pages.manInvoice.askDeleteInvoice")}
        ref={closeModal}
        close={closeDeleteModal}
        cb={confirmDelete}
      />
    </div>
  );
};

export default Table;
