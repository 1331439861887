import { isOrgowner, userInfo } from "services/helpers";
import { useGet } from "../query";
import { endpoints } from "services/endpoints";
import { useCoreStore } from "store/core";

const UseUserInfoGetter = () => {
  const { sub } = userInfo();
  const orgOwnerState = isOrgowner();
  const { setOrgId, setSubOrgId, setUserData, setRefetchUserInfo } = useCoreStore((state) => state);

  const { refetch } = useGet({
    link: endpoints.users.one,
    params: { id: sub },
    // enabled: !!sub && !!orgOwnerState,
    enabled: !!sub,
    onSuccess: (res) => {
      setOrgId(res.org_id);
      setSubOrgId(res.sub_org_id);
      setUserData(res.username, res.full_name, res.photo, res.email, res.phone_number, res.roles, res.warehouse_id);
      setRefetchUserInfo(refetch);
    },
  });
};

export default UseUserInfoGetter;
