import React, { useState, useEffect } from "react";
// store
import { useCoreStore } from "store/core";
// api
import { useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// icons
import Widget from "assets/images/svg/icon-widgets.svg";
import WidgetDark from "assets/images/svg/icon-widgets-dark.svg";
import Minus from "assets/images/svg/icon-minus.svg";
import MinusDark from "assets/images/svg/icon-minus-dark.svg";
import Plus from "assets/images/svg/icon-plus.svg";
import PlusDark from "assets/images/svg/icon-plus-dark.svg";

const TreeRoot = ({ label, children, textClass, setCategories, item }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [fetch, setFetch] = useState(false);
  const { theme } = useCoreStore((state) => state);
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { orgId } = useCoreStore((state) => state);

  const { data: categories } = useGet({
    link: endpoints.category.list,
    params: { org_id: orgId, parent_id: item.id },
    enabled: fetch,
  });

  useEffect(() => {
    if (categories) {
      setCategories((prevCategories) => {
        // Find the index of the clicked category in the array
        const clickedCategoryIndex = prevCategories.findIndex((cat) => cat.id === item.id);

        // If the clicked category is found, update its subMenu
        if (clickedCategoryIndex !== -1) {
          const updatedCategories = [...prevCategories];
          const formattedCategories = categories.map((category) => {
            return {
              label: category.name[lang],
              child_count: category.child_count,
              id: category.id,
              subMenu: [],
            };
          });
          updatedCategories[clickedCategoryIndex].subMenu = formattedCategories;
          return updatedCategories;
        }

        return prevCategories;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setFetch(true);
  };

  return (
    <div
      className="text-[#535456] dark:text-[#8B8999] text-[18px] mb-[15px] relative before:content-['']
    before:absolute
    before:left-[0px]
    before:h-[calc(100%-50px)]
    before:bottom-[15px]
    before:w-[1px]
    before:bg-[#C9CBD2]
    dark:before:bg-[#47484e]
    before:z-[1]"
    >
      <div onClick={toggleExpand} className="font-semibold cursor-pointer flex justify-between items-center">
        <div className={`${!children && "opacity-[0.5]"} flex ${textClass}`}>
          <img src={theme ? WidgetDark : Widget} className="mr-[8px]" alt="Widget" />
          {label} {children && <span className="flex items-center opacity-[0.5] mx-[8px]">{item.child_count}</span>}
        </div>
        {children && item && item?.child_count !== 0 && (
          <div className="bg-white dark:bg-[#36343E] h-[18px] w-[18px] min-w-[18px] rounded-[4px] flex items-center justify-center">
            <img src={isExpanded ? (theme ? MinusDark : Minus) : theme ? PlusDark : Plus} alt="Expand" />
          </div>
        )}
      </div>
      {isExpanded && children}
    </div>
  );
};

const TreeNode = ({ setCategories, allCategories, item, parentIndex, children, last, textClass }) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { orgId } = useCoreStore((state) => state);

  const [isExpanded, setIsExpanded] = useState(false);
  const [fetch, setFetch] = useState(false);

  const { theme } = useCoreStore((state) => state);

  const { data: categories } = useGet({
    link: endpoints.category.list,
    params: { org_id: orgId, parent_id: item.id },
    enabled: fetch,
  });

  useEffect(() => {
    if (categories && parentIndex !== undefined) {
      const newCategories = allCategories.map((cat, index) => {
        if (index === parentIndex) {
          // one more map to find the index of the clicked category in the array
          const clickedCategoryIndex = cat.subMenu.findIndex((subCat) => subCat.id === item.id);

          // If the clicked category is found, update its subMenu
          if (clickedCategoryIndex !== -1) {
            const updatedCategories = [...cat.subMenu];
            const formattedCategories = categories.map((category) => {
              return {
                label: category.name[lang],
                child_count: category.child_count,
                id: category.id,
                subMenu: [],
              };
            });
            updatedCategories[clickedCategoryIndex].subMenu = formattedCategories;
            return { ...cat, subMenu: updatedCategories };
          }
        }
        return cat;
      });

      setCategories(newCategories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setFetch(true);
  };

  return (
    // if children the i should add grey line as ::before item

    <div>
      <div
        onClick={toggleExpand}
        className={`${
          last && "last-sale-node"
        } font-semibold cursor-pointer flex mt-[16px] items-center justify-between sale-node`}
      >
        <div
          className={`${!children && "opacity-[0.9]"} relative flex ml-[20px]
          after:content-['']
          after:absolute
          after:left-[-20px]
          after:bottom-[10px]
          after:w-[12px]
          after:h-[20px]
          after:rounded-bl-[5px]
          after:border-b-[1px]
          after:border-b-[#C9CBD2]
          dark:after:border-b-[#47484e]
          after:border-l-[1px]
          after:border-l-[#C9CBD2]
          dark:after:border-l-[#47484e]
          after:z-[1]

          ${textClass}
        `}
        >
          <img src={theme ? WidgetDark : Widget} className="mr-[8px]" alt="Widget" />
          {item.label}
          {children && <span className="flex items-center opacity-[0.5] mx-[8px]">{item.child_count}</span>}
        </div>
        {item.child_count > 0 && (
          <div className="bg-white dark:bg-[#36343E] h-[18px] w-[18px] min-w-[18px] rounded-[4px] flex items-center justify-center">
            <img src={isExpanded ? (theme ? MinusDark : Minus) : theme ? PlusDark : Plus} alt="Expand" />
          </div>
        )}
      </div>
      {isExpanded && (
        <div
          className="relative before:content-['']
      before:absolute
      before:left-[16px]
      before:top-[-10px]
      before:h-[calc(100%-10px)]
      before:bottom-[0px]
      before:w-[1px]
      before:bg-[#C9CBD2]
      dark:before:bg-[#47484e]
      before:z-[1]"
        >
          {children}
        </div>
      )}
    </div>
  );
};

const TreeItem = ({ item, children, setCurrentProduct, last, textClass }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { setSaleFilters, saleFilters } = useCoreStore((state) => state);

  const handleClick = () => {
    if (item.id !== saleFilters?.category_id) {
      setCurrentProduct(item.id);
      setSaleFilters({ ...saleFilters, category_id: item.id });
    } else {
      setCurrentProduct({});
      setSaleFilters({ ...saleFilters, category_id: null });
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div onClick={handleClick}>
      <div
        onClick={toggleExpand}
        className={`${item.id === saleFilters.category_id && "text-[#7B61FF]"} ${
          last && "last-sale-node"
        } flex cursor-pointer font-medium mt-[16px] ml-[22px] sale-node`}
      >
        <span
          className={`ml-[18px] relative flex ml-[20px]
          after:content-['']
          after:absolute
          after:left-[-24px]
          after:bottom-[10px]
          after:w-[12px]
          after:h-[20px]
          after:rounded-bl-[5px]
          after:border-b-[1px]
          after:border-b-[#C9CBD2]
          dark:after:border-b-[#47484E]
          after:border-l-[1px]
          after:border-l-[#C9CBD2]
          dark:after:border-l-[#47484e]
          after:z-[1] ${textClass}`}
        >
          {item.label}
        </span>
      </div>
      {isExpanded && children}
    </div>
  );
};

const TreeView = ({ categories, setCategories, textClass }) => {
  const [currentProduct, setCurrentProduct] = useState({});

  return (
    <>
      {categories?.map((cat, i1) => {
        return (
          <TreeRoot label={cat.label} key={i1} textClass={textClass} setCategories={setCategories} item={cat}>
            {cat.subMenu.map((subCat, i2) => {
              return (
                <TreeNode
                  allCategories={categories}
                  setCategories={setCategories}
                  parent={cat}
                  parentIndex={i1}
                  item={subCat}
                  key={i2}
                  last={i2 === cat.subMenu.length - 1}
                  textClass={textClass}
                >
                  {subCat.subMenu !== undefined &&
                    subCat.subMenu.map((subSubCat, i3) => {
                      return (
                        <TreeItem
                          last={i3 === subCat.subMenu.length - 1}
                          item={subSubCat}
                          currentProduct={currentProduct}
                          key={i3}
                          setCurrentProduct={setCurrentProduct}
                          textClass={textClass}
                        ></TreeItem>
                      );
                    })}
                </TreeNode>
              );
            })}
          </TreeRoot>
        );
      })}
    </>
  );
};

export default TreeView;
