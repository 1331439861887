import cls from "classnames";
import { useCoreStore } from "store/core";

function ControlledInput(props) {
  const { theme } = useCoreStore();
  const {
    type = "text",
    label,
    name,
    className,
    placeholder,
    defaultValue,
    leftElement,
    rightElement,
    autoFocus,
    value,
    ...rest
  } = props;

  return (
    <div className="">
      {label && (
        <label className={cls("block text-stone-800 dark:text-white font-semibold text-[14px]", {})} htmlFor={name}>
          {label}
        </label>
      )}
      <div className="relative">
        {leftElement && <div className="absolute left-3 top-1/2 -translate-y-1/2">{leftElement}</div>}
        <input
          type={type}
          autoComplete="off"
          name={name}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          autoFocus={autoFocus}
          className={cls(
            `input bg-GreyInput placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 
            focus:bg-white dark:focus:border-purple-2 focus:border-2 dark:text-midGrey dark:border-blue-3 dark:bg-inputBgDark`,
            className,
            theme ? "custominput-dark" : "custominput-light",
            {
              "pl-9": leftElement,
              "pr-9": rightElement,
            },
          )}
          {...rest}
        />
        {rightElement && <div className="absolute right-3 top-1/2 -translate-y-1/2">{rightElement}</div>}
      </div>
    </div>
  );
}

export default ControlledInput;
