import { useState, createContext } from "react";
// i18n
import { useTranslation } from "react-i18next";
// components
import Empty from "../Category/Empty";
import ParametrFirst from "./ParametrFirst/ParametrFirst";
import ParametrSecond from "./ParametrSecond/ParametrSecond";

const layoutWrapper = "flex flex-row overflow-y-hidden h-full rounded-lg h-full relative";

export const ParameterContext = createContext(null);

const ParametrLayout = () => {
  const { t } = useTranslation();
  const [parentIds, setParentIds] = useState({
    parentId: "",
  });

  return (
    <>
      <div className={layoutWrapper}>
        <ParameterContext.Provider value={{ parentIds, setParentIds }}>
          <div className="flex-1 flex flex-col mr-4">
            <h1 className="text-bold text-2xl flex text-black dark:text-textDark mb-4">
              {t("pages.parameters.title")}
            </h1>
            <div className="dark:bg-mainGreyDark border dark:border-[#2D2D2D] rounded-md p-3 flex flex-col flex-1">
              <ParametrFirst />
            </div>
          </div>

          <div className="flex-1 flex flex-col ml-4">
            <h1 className="text-bold text-2xl flex text-black dark:text-textDark mb-4">
              {t("pages.parameters.value")}
            </h1>
            <div className="dark:bg-mainGreyDark border dark:border-[#2D2D2D] rounded-md p-3 flex flex-col flex-1">
              {parentIds.parentId ? <ParametrSecond /> : <Empty title={t("pages.parameters.select")} />}
            </div>
          </div>
        </ParameterContext.Provider>
      </div>
    </>
  );
};

export default ParametrLayout;
