import * as React from "react";

import TreeView from "./TreeView";

const SaleDrawer = ({ categories, setCategories, isOpenSidebar }) => {

  const textClass = "text-[15px]";

  return (
    <>
      <div className={`px-10 overflow-x-hidden flex flex-col h-full text-[15px] ${isOpenSidebar && "p-[12px]"}`}>
        <TreeView categories={categories} setCategories={setCategories} isOpenSidebar={isOpenSidebar} textClass={textClass} />
      </div>
    </>
  );
};

export default SaleDrawer;
