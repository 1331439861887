// searchParams
import { useSearchParams } from "react-router-dom";
// icons
import { ReactComponent as MenuWarehouse } from "assets/images/svg/menu-warehouse.svg";
import ClockIcon from "assets/images/svg/clock-icon.svg";

const WareHouseItem = ({ item }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selected = searchParams.get("invoice_id") === item.id;

  // from timestamp to "12.05.2023 14:43

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${day < 10 ? `0${day}` : day}.${month < 10 ? `0${month}` : month}.${year} ${
      hours < 10 ? `0${hours}` : hours
    }:${minutes < 10 ? `0${minutes}` : minutes}`;
  };

  return (
    <div
      className={`item ${
        selected && "active bg-[#fafafa]"
      } bg-[#fafafa] dark:bg-[#1F1E25] text-[#000] dark:text-[#fff]`}
      onClick={() => {
        setSearchParams({ invoice_id: item.id });
      }}
    >
      <div className="title text-sm">
        <p>№{item.num}</p>
        <span className="ml-[8px] mr-[6px]">
          <MenuWarehouse fill="#535456" />
        </span>
        <p>
          {item.owner_name} - {item.client_name}
        </p>
      </div>

      <div className="time text-xs text-darkGreyLight">
        <img src={ClockIcon} alt="ClockIcon" />
        <p>{formatTime(item.created_at)}</p>
      </div>
    </div>
  );
};

export default WareHouseItem;
