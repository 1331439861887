import { useContext, useRef, useState } from "react";
import { SaleContext } from "../Sale";
// api
import { endpoints } from "services/endpoints";
import { useMutate } from "hooks/query";

// helpers
import { useTranslatedTableConfig } from "../Helpers";
// components
import Tbody from "./Tbody";
import BaseTable from "components/Table/BaseTable";
import BaseThead from "components/Table/BaseThead";
import { notifySuccess } from "components/Toast";
import DeleteConfirmation from "container/DeleteConfirmation";

const Table = () => {
  const { singleInvoice, setSingleInvoice, type, setProductSaleInfo, productSaleInfo } = useContext(SaleContext);

  const { header } = useTranslatedTableConfig();
  const closeModal = useRef();
  const deleteId = useRef();

  const closeDeleteModal = (value, id) => {
    deleteId.current = id;
    closeModal.current.openModal(value);
  };

  const deleteProduct = useMutate({
    onSuccess: (res) => {
      // delete item from single invoice
      if (type === "return") {
        // delete item from single invoice
        setSingleInvoice({
          ...singleInvoice,
          income_products: singleInvoice?.income_products.filter((el) => el.id !== res.id),
        });

        // delete item from product sale info
        setProductSaleInfo((prev) => {
          const newProductSaleInfo = prev?.income_products?.filter((payment) => payment.id !== res.id);
          return { ...prev, income_products: newProductSaleInfo };
        });
      }
      if (type === "out") {
        // delete item from single invoice
        setSingleInvoice({
          ...singleInvoice,
          outgo_products: singleInvoice?.outgo_products.filter((el) => el.id !== res.id),
        });

        // delete item from product sale info
        setProductSaleInfo((prev) => {
          const newProductSaleInfo = prev?.outgo_products?.filter((payment) => {
            return payment.id !== res.id;
          });
          return { ...prev, outgo_products: newProductSaleInfo };
        });
      }

      closeDeleteModal(false);
      notifySuccess("Успешно удалено");
    },
  });

  const confirmDelete = () => {
    deleteProduct.mutate({
      link:
        type === "return"
          ? `${endpoints.invoices.deleteIncomeProduct}?id=${deleteId.current}`
          : `${endpoints.invoices.deleteOutgoProduct}?id=${deleteId.current}`,
      method: "delete",
    });
  };

  return (
    <div className="px-2 mt-[8px] h-[60%] overflow-y-hidden">
      <BaseTable
        extraClassNames="h-[100%]"
        thead={<BaseThead header={header} extraClassTh="text-[12px]" />}
        tbody={<Tbody data={singleInvoice} closeDeleteModal={closeDeleteModal} />}
      />

      <DeleteConfirmation ref={closeModal} close={closeDeleteModal} cb={confirmDelete} />
    </div>
  );
};

export default Table;
