import { useState, createContext } from "react";
// i18n
import { useTranslation } from "react-i18next";
// components
import CategoryFirst from "./CategoryFirst/CategoryFirst";
import CategorySecond from "./CategorySecond/CategorySecond";
import CategoryThird from "./CategoryThird/CategoryThird";

import Empty from "./Empty";

const layoutWrapper = "grid grid-cols-3 gap-10 overflow-y-hidden h-full rounded-lg";

export const CategoryContext = createContext(null);

const CategoryLayout = () => {
  const { t } = useTranslation();
  const [parentIds, setParentIds] = useState({
    parentId: "",
    subParentId: "",
  });

  return (
    <>
      <div className={layoutWrapper}>
        <CategoryContext.Provider value={{ parentIds, setParentIds }}>
          <div>
            <h1 className="text-2xl text-bold text-textBlack dark:text-textDark mb-4">{t("pages.categories.title")}</h1>
            <div className="dark:bg-mainGreyDark border rounded-md p-3 dark:border-[#2D2D2D] h-[calc(100vh-262px)]">
              <CategoryFirst />
            </div>
          </div>
          <div>
            <h1 className="text-2xl text-bold text-textBlack dark:text-textDark mb-4"> </h1>
            <div className="dark:bg-mainGreyDark border rounded-md p-3 dark:border-[#2D2D2D] h-[calc(100vh-262px)] mt-12">
              {parentIds.parentId ? <CategorySecond /> : <Empty title={t("pages.categories.select")} />}
            </div>
          </div>
          <div>
            <h1 className="text-2xl text-bold text-textBlack dark:text-textDark mb-4"> </h1>
            <div className="dark:bg-mainGreyDark border rounded-md p-3 dark:border-[#2D2D2D] h-[calc(100vh-262px)] mt-12">
              {parentIds.subParentId ? <CategoryThird /> : <Empty title={t("pages.categories.select")} />}
            </div>
          </div>
        </CategoryContext.Provider>
      </div>
    </>
  );
};

export default CategoryLayout;
