import { useMemo } from "react";
import cls from "classnames";

const BaseData = ({ children, position = "left", className = "", tag = "td" }) => {
  const customClassName = useMemo(
    () => cls(className, `py-1 pr-3 text-${position} dark:text-textDark first:rounded-tl-[8px] first:rounded-bl-[8px] first:rounded-tr-none first:rounded-br-none last:rounded-tr-[8px] last:rounded-br-[8px] last:rounded-tl-none last:rounded-bl-none`),
    [position, className],
  );
  const DynamicTag = tag;
  return <DynamicTag className={customClassName}>{children}</DynamicTag>;
};

export default BaseData;
