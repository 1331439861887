import React, { memo, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
// api
import { useGet, useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// hooks
import { useCoreStore } from "store/core";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";
import Select from "components/Select/Select";
// icons
import IconSave from "assets/images/svg/icon-save.svg";

const ModalForm = memo(({ close, setWarehouseInvoiceList, warehouseInvoiceList }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();

  const [warehouseOptions, setWarehouseOptions] = useState([]);

  const rowId = searchParams.get("row_id");

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function removeId() {
    reset();
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }

  const { userData, subOrgId, orgId } = useCoreStore((state) => state);

  useGet({
    link: endpoints.warehouse.list,
    params: { org_id: orgId, parent_id: subOrgId },
    onSuccess: (res) => {
      const optionTransformation = res
        ?.map((el) => ({
          label: el.name,
          value: el.id,
        }))
        .filter((el) => {
          // Check if the current element's value matches the user's warehouse_id
          const isUserWarehouse = el.value === userData.warehouse_id;

          // Check if the current element's value matches any owner or client warehouse IDs
          const isInInvoiceList = warehouseInvoiceList.some((invoice) => {
            return invoice.owner_warehouse_id === el.value || invoice.client_warehouse_id === el.value;
          });

          // Return true if the element's value does not match user's warehouse_id and is not in the invoice list
          return !isUserWarehouse && !isInInvoiceList;
        });

      setWarehouseOptions(optionTransformation);
    },
  });

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: (e) => {
      setWarehouseInvoiceList((prev) => [...prev, e]);
      clearAndClose();
      notifySuccess(t("notification.betweenWarehouseSuccesCreate"));
    },
  });

  const onSubmit = (values) => {
    create.mutate({
      link: `${endpoints.warehouseInvoice.create}?org_id=${orgId}&client_warehouse_id=${values.warehouse.value}`,
      method: "post",
    });
  };

  return (
    <div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5">
          <Controller
            name="warehouse"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                fieldName="warehouse"
                name="warehouse"
                autoFocus={true}
                // styles={selectStyles}
                isMulti={false}
                closeMenuOnSelect={true}
                errors={errors}
                required
                label={t("pages.betweenWarehouse.warehouse")}
                placeholder={t("pages.betweenWarehouse.warehouse")}
                // onInputChange={(value) => {
                //   debounce(() => {
                //     setProductSearch(value);
                //   }, 500)();
                // }}
                // handleChange={}
                options={warehouseOptions}
              />
            )}
          />
        </div>
        <div className="flex gap-6 mt-8">
          <BaseButton
            label={t("button.cancel")}
            onClick={clearAndClose}
            className="flex-1 p-2 btn-secondary dark:btn-secondary-dark"
          />
          <BaseButton
            type="submit"
            className="flex-1 p-2 btn-success"
            icon={IconSave}
            label={t("button.save")}
            isLoading={create.isLoading}
          />
        </div>
      </form>
    </div>
  );
});

export default ModalForm;
