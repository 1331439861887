import React, { memo, useEffect, useState, useMemo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
// store
import { useCoreStore } from "store/core";
// hooks
import { useForm } from "react-hook-form";
import { useGet, useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
import useDebounce from "hooks/useDebounce/useDebounce";
import { Tooltip } from "react-tooltip";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import { FormCheckbox } from "components/Checkbox/FormCheckbox";
import Chip from "components/Chip/Chip";
import Input from "components/Input/Input";
import ControlledInput from "components/Input/ControlledInput";
import TextArea from "components/Textarea/TextArea";
import ControlledSelect from "components/Select/ControlledSelect";
import CustomSelect from "components/Select/CustomSelect";
import BaseButton from "components/Button/BaseButton";
import ImageUploaderMulti from "components/ImageUploaderLarge/MultiImage";
// icons
import IconSave from "assets/images/svg/icon-save.svg";
import CheckIcon from "assets/images/svg/check-icon.svg";
import Search from "assets/images/svg/search.svg";
import SearchWhite from "assets/images/svg/search-white.svg";

const ModalForm = memo(({ close, refetch, productGroup, tags, categories }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const theme = useCoreStore((state) => state.theme);
  let [searchParams, setSearchParams] = useSearchParams();

  let queryId = searchParams.get("row_id");

  const { orgId } = useCoreStore((state) => state);

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [images, setImages] = useState([]);

  const [shouldGenerateCode, setShouldGenerateCode] = useState(false);
  const [generationCounter, setGenerationCounter] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState(categories);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [propertyValues, setPropertyValues] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [searchResult, setSearchResult] = useState();
  const [firstCode, setFirstCode] = useState();

  const [imagesError, setImagesError] = useState(null);
  const [productGroupError, setProductGroupError] = useState(null);

  const [categoryFilter, setCategoryFilter] = useState("");

  const {
    reset,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (categoryFilter) {
      setFilteredCategories(
        categories.filter((category) => {
          let name = category.name[lang] || category.name[lang];
          return name.toLowerCase().includes(categoryFilter.toLowerCase());
        }),
      );
    } else {
      setFilteredCategories(categories);
    }
  }, [categoryFilter]);

  function removeId() {
    reset();
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }

  const { data: singleProduct } = useGet({
    link: endpoints.product.one,
    params: { id: queryId },
    enabled: !!queryId,
  });

  // TODO - check if this is needed
  useEffect(() => {
    if (singleProduct) {
      let label = productGroup?.find((group) => group.id === singleProduct.product_group_id)?.name[lang] || "";

      setSelectedGroup({
        value: singleProduct.product_group_id,
        label,
      });
    }
  }, [singleProduct]);

  const code = watch("code");

  useDebounce(() => setSearchResult(code), [code], 500);

  useEffect(() => {
    setProductGroupError(null);
  }, [selectedGroup, propertyValues]);

  const { data: generate, loading: isGeneratingCode } = useGet({
    link: endpoints.product.generateCode,
    params: { org_id: orgId },
    enabled: shouldGenerateCode,
  });

  useEffect(() => {
    if (generate?.code) {
      let name = getValues("name");

      generationCounter === 1 && !queryId && setValue("name", name + ` (${generate.code})`);
      setValue("code", generate.code);

      setShouldGenerateCode(false);
    }
    // Include generationCounter in the dependency array to ensure the effect runs every time it changes
  }, [generate, setValue, generationCounter]);

  // Handle the click to generate a new code
  const handleGenerateClick = () => {
    setShouldGenerateCode(true);
    setGenerationCounter((c) => c + 1); // Increment the counter to trigger the effect
  };
  const { data: checkCode } = useGet({
    link: endpoints.product.check,
    params: { code: searchResult, org_id: orgId },
    enabled: searchResult?.length >= 3 && firstCode !== searchResult,
  });
  const { data: productGroupProperties } = useGet({
    link: `${endpoints.productGroupProperty.list}?product_group_id=${selectedGroup?.value}`,
    enabled: !!selectedGroup,
  });

  // setting default values for form
  useEffect(() => {
    if (queryId && singleProduct) {
      const selectedCategories = categories.filter((category) => {
        return singleProduct.category_names.find((item) => item.ru === category.name[lang]);
      });

      const selectedTags = tags.filter((tag) => {
        return singleProduct.tag_names.find((item) => item === tag.tag_name);
      });

      const tagsNew = selectedTags.map((tag) => {
        return {
          value: tag.id,
          label: tag.tag_name,
        };
      });

      setValue("code", singleProduct.code);
      setValue("name", singleProduct.name);
      setValue("short_name", singleProduct.short_name);
      setValue("description", singleProduct.description);
      setValue("b2b_price", singleProduct.b2b_price);
      setValue("b2c_price", singleProduct.b2c_price);
      setValue("in_price", singleProduct.in_price);
      setValue("add_into_catalog", singleProduct.add_into_catalog);

      // get property_id and property_value_id from singleProduct.property_values, then find property_value_name from productGroupProperties

      setFirstCode(singleProduct.code);
      setSelectedTags(tagsNew);
      setSelectedCategories(selectedCategories);
      setImages(singleProduct.images);
    } else {
      reset();
    }
  }, [queryId, singleProduct, productGroupProperties, setValue, reset]);

  // setting propertyValues and productGroupProperties
  useEffect(() => {
    if (queryId && singleProduct) {
      if (selectedGroup?.value === singleProduct.product_group_id) {
        const oldPropertyValues = singleProduct?.property_values?.map((item) => {
          const property = productGroupProperties?.find((prop) => prop.property_id === item.property_id);

          const propertyValue = property?.values.find((value) => value.id === item.property_value_id);

          return {
            is_show: item.is_show,
            property_id: item.property_id,
            property_value_id: item.property_value_id,
            property_value_name: propertyValue?.name[lang],
          };
        });
        setPropertyValues(oldPropertyValues);
      } else {
        setPropertyValues([]);
      }
      if (!selectedGroup && productGroup) {
        setSelectedGroup({
          value: singleProduct.product_group_id,
          label: productGroup?.find((group) => group.id === singleProduct.product_group_id)?.name[lang] || "",
        });
      }
    }
  }, [singleProduct, productGroupProperties, productGroup, queryId]);

  useEffect(() => {
    // copy of productGroupProperties with 4 properties: is_show, property_id, property_value_id, property_value_name
    if (productGroupProperties && selectedGroup?.value !== singleProduct?.product_group_id) {
      const newPropertyValues = productGroupProperties?.map((item) => {
        return {
          is_show: item.property_separator ? true : item.is_show,
          property_separator: item.property_separator,
          property_id: item.property_id,
          property_value_id: item.property_separator ? item.default_value_id : null,
          property_value_name: item.property_separator ? item.default_value_name[lang] : null,
        };
      });

      setPropertyValues(newPropertyValues);
    }
  }, [productGroupProperties]);

  const tableData = useMemo(() => {
    if (productGroupProperties?.length === 0 || propertyValues?.length === 0) return [];
    return productGroupProperties
      ?.filter((el) => !el.property_separator) // Filter out the elements with property_separator set to true
      .map((el, index) => {
        const listPropertyValues = el.values.map((item) => {
          return {
            value: item.id,
            label: item.name[lang],
          };
        });

        return {
          index,
          id: el.id,
          row: [
            {
              title: (
                <div className="grid grid-cols-2 gap-4 w-full">
                  <div className="flex">
                    <FormCheckbox
                      label=""
                      errors={{}}
                      // checked={el.is_show}
                      checked={propertyValues?.find((item) => item.property_id === el.property_id)?.is_show}
                      // checked={true}
                      name="is_show"
                      onChange={(e) => {
                        // "is_show": true
                        setPropertyValues(() => {
                          const existingProperty = propertyValues.find((prop) => prop.property_id === el.property_id);
                          if (existingProperty) {
                            // If property exists, update its values
                            return propertyValues.map((prop) =>
                              prop.property_id === el.property_id
                                ? {
                                    ...prop,
                                    is_show: e.target.checked,
                                  }
                                : prop,
                            );
                          } else {
                            // If property doesn't exist, add a new object
                            return [
                              ...propertyValues,
                              {
                                property_id: el.property_id,
                                is_show: e.target.checked,
                              },
                            ];
                          }
                        });
                      }}
                    />

                    <div className="bg-[#F2F2F2] dark:bg-[#1F1E25] dark:text-[#9B9DA3] rounded-lg px-[16px] py-[12px] col-span-1 flex items-center flex-1">
                      {el.name[lang]}
                    </div>
                  </div>

                  <div className="bg-[#F2F2F2] dark:bg-[#1F1E25] rounded-lg px-[16px] py-[6px] flex flex-row items-center">
                    <ControlledSelect
                      // name="productGroup"
                      errors={errors}
                      isRequired={true}
                      placeholder={t("form.value")}
                      className="w-full relative dark:border-[#2d2d2d]"
                      options={listPropertyValues}
                      height="fit-content"
                      closeMenuOnScroll={true}
                      value={propertyValues?.map((item) => {
                        if (item.property_id === el.property_id) {
                          return {
                            value: item.property_value_id,
                            label: item.property_value_name,
                          };
                        }
                      })}
                      onChange={(e) => {
                        setPropertyValues((prevPropertyValues) => {
                          const existingProperty = prevPropertyValues.find(
                            (prop) => prop.property_id === el.property_id,
                          );
                          if (existingProperty) {
                            // If property exists, update its values
                            return prevPropertyValues.map((prop) =>
                              prop.property_id === el.property_id
                                ? {
                                    ...prop,
                                    property_value_id: e.value,
                                    property_value_name: e.label,
                                  }
                                : prop,
                            );
                          } else {
                            // If property doesn't exist, add a new object
                            return [
                              ...prevPropertyValues,
                              {
                                ...existingProperty,
                                property_id: el.property_id,
                                property_value_id: e.value,
                                property_value_name: e.label,
                              },
                            ];
                          }
                        });
                      }}
                    />
                  </div>
                </div>
              ),
            },
          ],
        };
      });
  }, [propertyValues, productGroupProperties, errors]);

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: () => {
      refetch();
      reset();
      clearAndClose();
      notifySuccess(t("notification.successProductCreate"));
    },
  });

  const update = useMutate({
    onSuccess: () => {
      refetch();
      reset();
      clearAndClose();
      notifySuccess(t("notification.successProductUpdate"));
    },
  });

  const onSubmit = (values) => {
    const validPropertyValues = propertyValues.reduce((acc, item) => {
      // add just not separators and is_show true
      if (!item.property_separator) {
        acc.push({
          property_id: item.property_id,
          property_value_id: item.property_value_id,
          is_show: item.is_show,
        });
      }
      return acc;
    }, []);

    const body = {
      ...values,
      in_price: Number(values.in_price),
      b2b_price: Number(values.b2b_price),
      b2c_price: Number(values.b2c_price),
      categories: selectedCategories.map((item) => item.id),
      tags: selectedTags?.map((item) => item.label),
      images,
      property_values: validPropertyValues,
      product_group_id: selectedGroup?.value,
    };

    if (queryId) {
      update.mutate({
        link: endpoints.product.update,
        params: { id: queryId },
        method: "put",
        body: body,
      });
    } else {
      create.mutate({
        link: endpoints.product.create,
        params: { product_group_id: selectedGroup?.value },
        method: "post",
        body: body,
      });
    }
  };

  // on change show form values from useForm

  const handleNextStep = () => {
    // check value filled in first step
    if (currentStep === 1) {
      if (!selectedGroup) {
        setProductGroupError("Please select a product group.");
        return;
      }

      // Check if at least one element is marked to be shown.
      const atLeastOneShown = propertyValues.some((prop) => prop.is_show && !prop.property_separator);
      if (!atLeastOneShown) {
        setProductGroupError("At least one property must be marked as shown.");
        return;
      }

      // Then, check if all shown properties have values.
      const allShownHaveValues = propertyValues.every(
        (prop) => !prop.is_show || (prop.is_show && prop.property_value_id),
      );
      if (!allShownHaveValues) {
        setProductGroupError("Please fill in all properties that are marked as shown.");
        return;
      }

      if (!queryId) setValue("name", getValueWithSeparators(propertyValues), { shouldValidate: true });
    }
    // check of required fileld in step 2 is filled else make it red
    if (currentStep === 2) {
      if (!getValues("code")) {
        setValue("code", "", { shouldValidate: true });
      } else {
        setValue("code", getValues("code"), { shouldValidate: true });
      }
      if (!getValues("name")) {
      } else {
        setValue("name", getValues("name"), { shouldValidate: true });
      }
      // images
      if (!images.length) {
        // send extra error message to image uploader
        setImagesError("Please upload at least one image.");
      } else {
        setImagesError(null);
      }

      if (
        !getValues("code") ||
        !getValues("name") ||
        !images.length ||
        checkCode?.contains ||
        getValues("code").length < 3
      ) {
        return;
      }
    }

    setCurrentStep((prevStep) => Math.min(prevStep + 1, 3));
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const getValueWithSeparators = useCallback(
    (propertyValues) => {
      // Check if there is at least one element with is_show true, a value, and not a separator
      const hasVisibleProperties = propertyValues.some(
        (item) => item.is_show && item.property_value_name && !item.property_separator,
      );

      // If no such elements exist, return an empty string or a default value
      if (!hasVisibleProperties) {
        return " ";
      }

      let showSeparator = false;
      return propertyValues.reduce((acc, item) => {
        if (item.property_separator) {
          // Only add separator if the previous value was shown
          if (showSeparator) {
            acc += item.property_value_name;
            showSeparator = false; // Reset for the next iteration
          }
        } else {
          if (item.is_show && item.property_value_name) {
            acc += item.property_value_name; // Add the property name if shown
            showSeparator = true; // Next item can show separator if this one is shown
          } else {
            showSeparator = false; // Don't show separator since this item isn't shown
          }
        }
        return acc;
      }, "");
    },
    [propertyValues],
  );

  return (
    <div className="w-[700px] max-h-[fit-content] overflow-y-auto overflow-x-hidden">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          {!queryId ? (
            <ol className="flex items-center w-full mb-4 sm:mb-5 relative">
              <li
                className={`absolute w-[50%] h-[1px]  left-0 top-[50%] z-0 transform translate-y-[-50%] ${
                  currentStep >= 2 ? "bg-mainGreen" : "bg-[#D1D1D1] dark:bg-[#4F4F4F]"
                }`}
              ></li>
              <li
                className={`absolute w-[50%] h-[1px] right-0 top-[50%] z-0 transform translate-y-[-50%] ${
                  currentStep >= 3 ? "bg-mainGreen" : "bg-[#D1D1D1] dark:bg-[#4F4F4F]"
                }`}
              ></li>
              <li className={`flex w-full items-center justify-start dark:text-blue-500 relative`}>
                <div
                  className={`flex items-center justify-center w-[20px] h-[20px] border-[1px] border-[#D1D1D1] bg-white rounded-full shrink-0 ${
                    currentStep >= 1 && "border-[0px]"
                  }`}
                >
                  {currentStep >= 1 && <img src={CheckIcon} alt="Completed" />}
                </div>
              </li>
              <li className={`flex w-full items-center justify-center dark:text-blue-500 relative`}>
                <div className="flex items-center justify-center w-[20px] h-[20px] border-[1px] border-[#D1D1D1] bg-white dark:bg-[#1F1E25] rounded-full shrink-0">
                  {currentStep >= 2 && <img src={CheckIcon} alt="Completed" />}
                </div>
              </li>
              <li className={`flex w-full items-center justify-end dark:text-blue-500 relative `}>
                <div className="flex items-center justify-center w-[20px] h-[20px] border-[1px] border-[#D1D1D1] bg-white dark:bg-[#1F1E25] rounded-full shrink-0">
                  {currentStep >= 3 && <img src={CheckIcon} alt="Completed" />}
                </div>
              </li>
            </ol>
          ) : (
            <div className="w-full bg-[#FAFAFA] dark:bg-[#1F1E25] p-1 border-solid border-2 border-[#F2F2F2] dark:border-[#2d2d2d] rounded-lg flex flex-row justify-between h-[44px] items-center mb-5">
              <div
                className={`${
                  currentStep === 1
                    ? "bg-white border-solid border-1 border-[#F2F2F2] dark:bg-[#191A22] dark:text-[#fff] rounded-lg text-black"
                    : "text-[#9B9DA3] cursor-pointer"
                } flex items-center px-[16px] py-[4px]`}
                onClick={() => setCurrentStep(1)}
              >
                <span>{t("pages.products.productGroup")}</span>
              </div>
              <div
                className={`${
                  currentStep === 2
                    ? "bg-white border-solid border-1 border-[#F2F2F2] dark:bg-[#191A22] dark:text-[#fff] rounded-lg"
                    : "text-[#9B9DA3] cursor-pointer"
                } flex items-center px-[16px] py-[4px]`}
                onClick={() => setCurrentStep(2)}
              >
                <span>{t("pages.products.imageData")}</span>
              </div>
              <div
                className={`${
                  currentStep === 3
                    ? "bg-white border-solid border-1 border-[#F2F2F2] dark:bg-[#191A22] dark:text-[#fff] rounded-lg"
                    : "text-[#9B9DA3] cursor-pointer"
                } flex items-center px-[16px] py-[4px]`}
                onClick={() => setCurrentStep(3)}
              >
                <span>{t("pages.products.tagsCategories")}</span>
              </div>
            </div>
          )}

          {currentStep === 1 && (
            <div>
              <div
                className={`h-[280px] border-1 border-GreyInput dark:border-[#2d2d2d] mb-[16px] rounded-[8px] p-[8px] flex flex-col ${
                  productGroupError && "border-[#FF705D]"
                }`}
              >
                {/* select and then options with checkboxes and ability to drag them */}
                <ControlledSelect
                  label=""
                  name="productGroup"
                  errors={errors}
                  isRequired={true}
                  height="38px"
                  autoFocus={true}
                  placeholder={t("pages.products.productGroup")}
                  className="w-full bg-GreyInput dark:bg-[transparent] dark:border-[#2d2d2d] placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 focus:bg-white focus:border-2 font-[16px] h-[42px] max-w-[100%] relative z-[910]"
                  options={
                    productGroup &&
                    productGroup.map((group) => ({
                      value: group.id,
                      label: group.name[lang],
                    }))
                  }
                  value={selectedGroup}
                  onChange={(e) => {
                    setSelectedGroup(e);
                  }}
                />

                <div className="flex gap-4 mt-[16px] overflow-y-auto max-h-[100%]" id="selectScroll">
                  {
                    // show selected group properties
                    productGroupProperties && (
                      <div className="w-full">
                        {tableData?.map((data) => (
                          <div key={data.id} className="mb-4">
                            {/* Render the title which includes your ControlledSelect */}
                            {data.row.map((cell, index) => (
                              <React.Fragment key={index}>{cell.title}</React.Fragment>
                            ))}
                          </div>
                        ))}
                      </div>
                    )
                  }
                </div>
              </div>
              <Input
                label={t("form.title")}
                type="text"
                fieldName="nameProperty"
                disabled={true}
                value={queryId ? singleProduct?.name : getValueWithSeparators(propertyValues)}
                register={register}
                errors={errors}
                placeholder={t("pages.products.form.titleGen")}
                className="w-full bg-GreyInput placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 focus:bg-white focus:border-2"
              />
              <div className="flex mt-[40px] justify-between">
                {/* width of buttons should be auto */}
                <div>
                  <BaseButton
                    label={t("button.cancel")}
                    onClick={clearAndClose}
                    className="flex-1 px-[16px] py-[11px] btn-secondary dark:btn-secondary-dark w-[auto]"
                  />
                </div>

                {queryId ? (
                  <div>
                    <BaseButton
                      type="submit"
                      className="flex-1 py-[11px] btn-success"
                      icon={IconSave}
                      label={t("button.save")}
                    />
                  </div>
                ) : (
                  <div>
                    <BaseButton
                      label={t("button.next")}
                      onClick={handleNextStep}
                      className="flex-1 px-[16px] py-[11px] w-[auto] text-white bg-[#6248E7] hover:bg-[#4F3DBF]"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {currentStep === 2 && (
            <div>
              {/* 2 divs 50% width */}

              <div className="flex gap-4">
                <div className="w-[50%] h-full">
                  <ImageUploaderMulti
                    label={t("button.addPhoto")}
                    labelClass="!h-[187px]"
                    name="image"
                    register={register}
                    errors={errors}
                    isRequired={true}
                    placeholder={t("info.picture")}
                    className="w-full bg-GreyInput placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 focus:bg-white focus:border-2"
                    url={images}
                    setImagesUrl={setImages}
                    errorMessages={imagesError}
                  />
                </div>
                <div className="w-[50%]">
                  {/* 3 inputs here */}
                  <div className="flex flex-row items-end space-x-2">
                    <div className="flex-1">
                      <Input
                        label={t("form.code")}
                        type="text"
                        fieldName="code"
                        name="code"
                        register={register}
                        errors={errors}
                        isRequired={true}
                        placeholder={t("form.codePlaceholder")}
                        className="w-full bg-GreyInput placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 focus:bg-white focus:border-2 text-[16px] h-[44px]"
                      />
                    </div>
                    <div>
                      <a data-tooltip-id="code" data-tooltip-place="top-left">
                        <BaseButton
                          className="h-[40px] w-[44px] bg-gray-400 dark:bg-[#1F1E25] text-white rounded-md hover:bg-gray-500 active:bg-gray-600 focus:outline-none mb-[10px]  hover:opacity-80 transition-opacity duration-300"
                          label="+"
                          onClick={handleGenerateClick}
                          disabled={isGeneratingCode} // Disable button while generating code
                        />
                      </a>
                    </div>

                    <Tooltip id="code" style={{ zIndex: "500" }}>
                      <div className="text-textLight text-[14px]">{t("pages.products.form.generateCode")}</div>
                    </Tooltip>
                  </div>

                  {
                    // check if code is already taken
                    checkCode?.contains && (
                      <div className="text-red-500 text-[13px] mt-[4px]">
                        {t("form.code")} {checkCode.code} {t("info.taken")}
                      </div>
                    )
                  }
                  <Input
                    label={t("form.title")}
                    type="text"
                    fieldName="name"
                    name="name"
                    register={register}
                    errors={errors}
                    isRequired={true}
                    placeholder={t("form.titlePlaceholder")}
                    className="w-full bg-GreyInput placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 focus:bg-white focus:border-2 h-[44px]"
                  />
                  <Input
                    label={t("form.shortTitle")}
                    type="text"
                    fieldName="short_name"
                    name="short_name"
                    register={register}
                    errors={errors}
                    placeholder={t("form.shortTitlePlaceholder")}
                    className="w-full bg-GreyInput placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 focus:bg-white focus:border-2 h-[44px]"
                  />
                </div>
              </div>

              <div className="flex justify-between gap-[12px]">
                <div className="w-[25%]">
                  <Input
                    label={t("pages.products.form.inPrice")}
                    type="number"
                    fieldName="in_price"
                    name="in_price"
                    register={register}
                    errors={errors}
                    isRequired={true}
                    placeholder={t("pages.products.form.inPricePlaceholder")}
                    className="w-full bg-GreyInput placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 focus:bg-white focus:border-2 h-[44px] px-[14px] font-normal text-[15px]"
                    step="any"
                  />
                </div>
                <div className="w-[25%]">
                  <Input
                    label={t("pages.products.table.b2b")}
                    type="number"
                    fieldName="b2b_price"
                    name="b2b_price"
                    register={register}
                    errors={errors}
                    isRequired={true}
                    placeholder={t("pages.products.form.b2bPlaceholder")}
                    className="w-full bg-GreyInput placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 focus:bg-white focus:border-2 h-[44px] px-[14px] font-normal text-[15px]"
                  />
                </div>
                <div className="w-[25%]">
                  <Input
                    label={t("pages.products.table.b2c")}
                    type="number"
                    fieldName="b2c_price"
                    name="b2c_price"
                    register={register}
                    errors={errors}
                    isRequired={true}
                    placeholder={t("pages.products.form.b2cPlaceholder")}
                    className="w-full bg-GreyInput placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 focus:bg-white focus:border-2 h-[44px] px-[14px] font-normal text-[15px]"
                  />
                </div>
                <div className="w-[25%] flex items-center">
                  <FormCheckbox
                    label={t("pages.products.form.addCatalog")}
                    errors={errors}
                    {...register("add_into_catalog")}
                  />
                </div>
              </div>
              <TextArea
                label={t("form.description")}
                type="text"
                fieldName="description"
                name="description"
                register={register}
                errors={errors}
                placeholder={t("form.descriptionPlaceholder")}
                className="w-full bg-GreyInput placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 focus:bg-white focus:border-2 h-[44px]"
              />

              <div className="flex mt-[40px] justify-between">
                {/* width of buttons should be auto */}
                <div>
                  {queryId ? (
                    <BaseButton
                      label={t("button.cancel")}
                      onClick={clearAndClose}
                      className="flex-1 px-[16px] py-[11px] btn-secondary dark:btn-secondary-dark w-[auto]"
                    />
                  ) : (
                    <BaseButton
                      label={t("button.back")}
                      onClick={handlePrevStep}
                      className="flex-1 px-[16px] py-[11px] btn-secondary dark:btn-secondary-dark w-[auto]"
                    />
                  )}
                </div>

                {
                  // if edit mode show save button
                  queryId ? (
                    <div>
                      <BaseButton
                        type="submit"
                        className="flex-1 py-[11px] btn-success"
                        icon={IconSave}
                        label={t("button.save")}
                      />
                    </div>
                  ) : (
                    <div>
                      <BaseButton
                        label={t("button.next")}
                        onClick={handleNextStep}
                        className="flex-1 px-[16px] py-[11px] w-[auto] text-white bg-[#6248E7] hover:bg-[#4F3DBF]"
                      />
                    </div>
                  )
                }
              </div>
            </div>
          )}
          {currentStep === 3 && (
            <div>
              {/* Step 3 form content */}
              <div className="flex gap-[40px]">
                <div className="w-[50%]">
                  <h3 className="text-[15px] font-semibold text-stone-800 dark:text-white">
                    {t("button.addCategories")}
                  </h3>
                  <div
                    className={`mt-[8px] border-1 border-GreyInput dark:border-[#2d2d2d] rounded-[8px] p-[8px] h-[320px] flex flex-col`}
                  >
                    {/* search input here */}

                    <ControlledInput
                      placeholder={`${t("form.search")}...`}
                      className="w-full bg-GreyInput placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 focus:bg-white focus:border-2 h-[44px]"
                      leftElement={
                        <img src={theme ? SearchWhite : Search} alt="CloseIcon" className="cursor-pointer" />
                      }
                      onChange={(e) => setCategoryFilter(e.target.value)}
                    />
                    <div className="mt-[18px] overflow-y-auto">
                      {/* list of categories here */}
                      {filteredCategories.map((category) => (
                        <div
                          className="flex items-center gap-2
                        py-[8px] hover:bg-GreyInput dark:hover:bg-midBlack
                        cursor-pointer rounded-[8px] px-[16px] py-[5px] w-full
                        "
                          key={category.id}
                          onClick={() => {
                            if (selectedCategories.find((item) => item.id === category.id)) {
                              setSelectedCategories(selectedCategories.filter((item) => item.id !== category.id));
                            } else {
                              setSelectedCategories([...selectedCategories, category]);
                            }
                          }}
                        >
                          <FormCheckbox
                            label={category.name[lang]}
                            errors={errors}
                            checked={selectedCategories.find((item) => item.id === category.id)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="w-[50%]">
                  <h3 className="text-[15px] font-semibold text-stone-800 dark:text-white">{t("button.addTags")}</h3>
                  <div
                    className={`mt-[8px] border-1 border-GreyInput dark:border-[#2d2d2d] rounded-[8px] p-[8px] h-[320px]`}
                  >
                    <CustomSelect
                      // isMulti={true}
                      height="44px"
                      padding="0px 5px"
                      name="tags"
                      search={true}
                      closeMenuOnSelect={false}
                      width={"100%"}
                      placeholder={`${t("pages.products.selectTags")}...`}
                      leftElement={
                        <img src={theme ? SearchWhite : Search} alt="CloseIcon" className="cursor-pointer" />
                      }
                      options={tags?.map((item) => ({ value: item.id, label: item.tag_name })) ?? []}
                      // empty value always should be
                      value={[]}
                      checkbox={true}
                      selectedTags={selectedTags}
                      onChangeFunc={(value) => {
                        // check if already selected
                        if (selectedTags?.find((tag) => tag.value === value.option.value)) {
                          // delete from selected
                          setSelectedTags(selectedTags.filter((tag) => tag.value !== value.option.value));
                        } else setSelectedTags([...selectedTags, value.option]);

                        // clear select
                        return;
                      }}
                    />

                    <div className="mt-[6px] flex w-full flex-wrap gap-[4px]">
                      {/* list of selected tags here */}
                      {selectedTags &&
                        selectedTags.map((tag) => (
                          <Chip
                            key={tag.value}
                            text={tag.label}
                            onDelete={() => {
                              setSelectedTags(selectedTags.filter((item) => item.value !== tag.value));
                            }}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex mt-[58px] justify-between">
                <div>
                  {queryId ? (
                    <BaseButton
                      label={t("button.cancel")}
                      onClick={clearAndClose}
                      className="flex-1 px-[16px] py-[11px] btn-secondary dark:btn-secondary-dark w-[auto]"
                    />
                  ) : (
                    <BaseButton
                      label={t("button.back")}
                      onClick={handlePrevStep}
                      className="flex-1 px-[16px] py-[11px] btn-secondary dark:btn-secondary-dark w-[auto]"
                    />
                  )}
                </div>
                <div>
                  <BaseButton
                    type="submit"
                    className="flex-1 py-[11px] btn-success"
                    icon={IconSave}
                    label={queryId ? t("button.save") : t("button.add")}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
});

export default ModalForm;
