import React, { createContext, useState, useEffect, useMemo, useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
// store
import { useCoreStore } from "store/core";
// api
import { useGet, useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// helpers
import { formatPrice } from "services/helpers";
// hooks
import useDebounce from "hooks/useDebounce/useDebounce";
// i18n
import { useTranslation } from "react-i18next";
// components
import BaseButton from "components/Button/BaseButton";
import InvoiceTabs from "./components/InvoiceTabs";
import Table from "./Table/Table";
import TableSecond from "./TableSecond/Table";
import ControlledSelect from "components/Select/ControlledSelect";
import Input from "components/Input/Input";
import ProductItem from "./components/ProductItem";
import DeleteConfirmation from "container/DeleteConfirmation";
import ModalSaveInvoice from "../Invoices/ClientInvoices/Modal/ModalSaveInvoice";
import Modal from "components/Modal/Modal";
import { notifyError, notifySuccess } from "components/Toast";
// icons
import SearchDark from "assets/images/svg/icon-search-dark.svg";
import SearchWhite from "assets/images/svg/search-white.svg";
import Grid from "assets/images/svg/icon-grid.svg";
import List from "assets/images/svg/icon-list.svg";
import IconSave from "assets/images/svg/icon-save.svg";
import TrashIcon from "assets/images/svg/trash-white.svg";
import PrintIcon from "assets/images/svg/icon-print-white.svg";

export const SaleContext = createContext(null);

const Sale = () => {
  const { t } = useTranslation();
  const { orgId, theme, saleFilters, setSaleFilters } = useCoreStore((state) => state);
  const [invoices, setInvoices] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedManager, setSelectedManager] = useState(null);
  const [singleInvoice, setSingleInvoice] = useState(null);
  const [type, setType] = useState("out");
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");
  const [currentLayout, setCurrentLayout] = useState("list");
  const [currentInvoice, setCurrentInvoice] = useState(2);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  // poducts
  const [total, setTotal] = useState(0);
  const [totalOutgo, setTotalOutgo] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  // payments
  const [paymentTotal, setPaymentTotal] = useState(0);
  const [paymentTotalOutgo, setPaymentTotalOutgo] = useState(0);
  const [paymentTotalIncome, setPaymentTotalIncome] = useState(0);

  const [productSaleInfo, setProductSaleInfo] = useState();
  const [paymentSaleInfo, setPaymentSaleInfo] = useState();

  const scrollRef = useRef(null);
  const closeModal = useRef();
  const deleteId = useRef();

  const rowId = searchParams.get("invoice_id") || "";

  const {
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      state: true,
      roles: {
        label: "org-admin",
        value: "org-admin",
      },
    },
  });

  const { data: courseApi } = useGet({
    link: endpoints.exchangeRate.last,
    params: { org_id: orgId, base_currency: "USD", currency: "UZS" },
  });

  const course = courseApi?.amount || 1;
  const searchIcon = useMemo(() => (theme ? SearchWhite : SearchDark), [theme]);

  const closeDeleteModal = (value, id) => {
    deleteId.current = id;
    closeModal.current.openModal(value);
  };

  const deleteInvoice = useMutate({
    onSuccess: () => {
      // delete item from single invoice
      setInvoices(invoices.filter((el) => el.id !== deleteId.current));

      closeDeleteModal(false);
      notifySuccess(t("notification.successDelete"));
    },
  });

  const confirmDelete = () => {
    deleteInvoice.mutate({
      link: `${endpoints.invoices.delete}?id=${deleteId.current}`,
      method: "delete",
    });
  };

  const searchProduct = watch("search");

  useDebounce(
    () => {
      if (searchProduct !== search) {
        setPage(1); // Reset page to 1 whenever the search term changes
        setSearch(searchProduct);
      }
    },
    [searchProduct],
    500,
  );

  const modalSaveInvoice = useRef();

  const handleTemplatesModalSaveInvoice = (value) => {
    modalSaveInvoice.current.openModal(value);
  };

  const handleClickSaveInvoice = () => {
    const isOutgoExist = productSaleInfo?.outgo_products?.length > 0;
    const isIncomeExist = productSaleInfo?.income_products?.length > 0;
    const isZero = total + paymentTotal === 0;
    const isOutgoZero = productSaleInfo?.outgo_products?.some((el) => +el.paymentSumUsd === 0);
    const isIncomeZero = productSaleInfo?.income_products?.some((el) => +el.paymentSumUsd === 0);

    if (!isZero) {
      notifyError(t("pages.manInvoice.zeroTotal"));
      return;
    }
    if (!isOutgoExist && !isIncomeExist) {
      notifyError(t("pages.manInvoice.notExist"));
      return;
    }
    if (isOutgoExist && isOutgoZero) {
      notifyError(t("pages.manInvoice.zeroOutgo"));
      return;
    }
    if (isIncomeExist && isIncomeZero) {
      notifyError(t("pages.manInvoice.zeroIncome"));
      return;
    }
    handleTemplatesModalSaveInvoice(true);
  };

  const { data: singleInvoiceApi } = useGet({
    link: endpoints.invoices.oneDetailed,
    params: { id: rowId },
    enabled: !!rowId && rowId !== "null" && rowId !== "undefined",
  });

  const { data: invoicesApi } = useGet({
    link: endpoints.invoices.list,
    params: { org_id: orgId, state: "CREATED_DONE", manager_id: selectedManager?.value },
  });

  const { data: productsApi, isLoading: isLoadingProductsApi } = useGet({
    link: endpoints.product.get,
    params: {
      org_id: orgId,
      size: 14,
      name: search,
      page,
      ...(saleFilters?.category_id ? { category_id: saleFilters.category_id } : {}),
      ...(saleFilters?.min_price ? { min_price: saleFilters.min_price } : {}),
      ...(saleFilters?.max_price ? { max_price: saleFilters.max_price } : {}),
    },
  });

  useEffect(() => {
    // Assuming the div has a ref called scrollRef
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
    setSaleFilters({});
  }, []);

  useEffect(() => {
    if (singleInvoiceApi) {
      setSingleInvoice(singleInvoiceApi);

      // When products change, update the payments state
    }
  }, [singleInvoiceApi]);

  useEffect(() => {
    // check if length of invoices changed
    if (invoices.length > 0) {
      setSearchParams({ invoice_id: invoices[0]?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices.length]);

  useEffect(() => {
    if (invoicesApi) {
      setInvoices(invoicesApi);
      // TODO check if need to set searchParams
      // searchParams.set("invoice_id", invoicesApi[0]?.id);
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesApi]);

  useEffect(() => {
    // If there's a search term and we're on the first page, reset the products list
    if (searchProduct && page === 1) {
      setProducts([]);
    }
  }, [searchProduct, page]);

  useEffect(() => {
    setTotalIncome(productSaleInfo?.income_products?.reduce((acc, item) => acc + (Number(item.paymentSumUsd) || 0), 0));

    setTotalOutgo(productSaleInfo?.outgo_products?.reduce((acc, item) => acc + (Number(item.paymentSumUsd) || 0), 0));
  }, [productSaleInfo]);

  useEffect(() => {
    setPaymentTotalIncome(
      paymentSaleInfo?.income_payments?.reduce((acc, item) => acc + (Number(item.currencyAmount) || 0), 0),
    );

    setPaymentTotalOutgo(
      paymentSaleInfo?.outgo_payments?.reduce((acc, item) => acc + (Number(item.currencyAmount) || 0), 0),
    );
  }, [paymentSaleInfo]);

  useEffect(() => {
    setTotal(totalIncome - totalOutgo);
  }, [totalIncome, totalOutgo]);

  useEffect(() => {
    setPaymentTotal(paymentTotalIncome - paymentTotalOutgo);
  }, [paymentTotalIncome, paymentTotalOutgo]);

  useEffect(() => {
    setProductSaleInfo({
      outgo_products: singleInvoiceApi?.outgo_products?.map((product) => ({
        id: product.id,
        paymentType: "uzs",
        paymentUzs: product.price,
        paymentUsd: product.price / course,
        paymentSumUzs: product.price * product.amount,
        paymentSumUsd: (product.price * product.amount) / course,
        amount: product.amount,
      })),
      income_products: singleInvoiceApi?.income_products?.map((product) => ({
        id: product.id,
        paymentType: "uzs",
        paymentUzs: product.price,
        paymentUsd: product.price / course,
        paymentSumUzs: product.price * product.amount,
        paymentSumUsd: (product.price * product.amount) / course,
        amount: product.amount,
      })),
    });

    setPaymentSaleInfo({
      outgo_payments: singleInvoiceApi?.outgo_payments?.map((payment) => ({
        id: payment.id,
        currency: payment.currency,
        course: payment.course,
        amount: payment.base_amount,
        currencyAmount: payment.currency_amount,
      })),

      income_payments: singleInvoiceApi?.income_payments?.map((payment) => ({
        id: payment.id,
        currency: payment.currency,
        course: payment.course,
        amount: payment.base_amount,
        currencyAmount: payment.currency_amount,
      })),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleInvoiceApi]);

  useEffect(() => {
    if (productsApi) {
      if (page === 1) {
        // For the first page, we replace the products with new data
        setProducts(productsApi.data);
      } else {
        // For subsequent pages, we append the new data
        setProducts((prevProducts) => [...prevProducts, ...productsApi.data]);
      }
      setHasMore(productsApi.data.length > 0);
    }
  }, [productsApi, page]);

  const isScrollAtBottom = (element) => {
    return element.scrollHeight - element.scrollTop === element.clientHeight;
  };

  // Scroll event handler
  const handleScroll = (event) => {
    const { currentTarget: target } = event;

    if (isScrollAtBottom(target) && !isLoadingProductsApi && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  const handleSelectChange = (newValue) => {
    setSelectedManager(newValue);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSingleInvoiceMemoized = useCallback(setSingleInvoice, []);

  const providerValue = useMemo(
    () => ({
      invoices,
      setInvoices,
      singleInvoice,
      setSingleInvoice: setSingleInvoiceMemoized,
      products,
      setProducts,
      setSelectedManager,
      type,
      productSaleInfo,
      paymentSaleInfo,
      setProductSaleInfo,
      setPaymentSaleInfo,
    }),
    [invoices, singleInvoice, products, type, setSingleInvoiceMemoized, productSaleInfo, paymentSaleInfo],
  );
  return (
    <div className="h-full flex flex-col max-h-full">
      <SaleContext.Provider value={providerValue}>
        <div className="flex flex-row items-center">
          <InvoiceTabs invoices={invoices} currentInvoice={currentInvoice} setCurrentInvoice={setCurrentInvoice} />
          <ControlledSelect
            placeholder={t("pages.sale.sold")}
            onChange={handleSelectChange}
            value={{
              label: singleInvoice?.customer_name,
              value: singleInvoice?.customer_id,
            }}
            disabled={true}
            className="ml-[16px] self-center"
            height="54px"
            width="304px"
          />
        </div>

        <div className="mt-[20px] grid grid-cols-2 gap-[28px] overflow-hidden flex-1">
          {/* child 1 */}
          <div className="border-2 border-[#F2F2F2] dark:border-[#2D2D2D] border-solid rounded-lg flex flex-col flex-1 h-[calc(100vh-288px)]">
            {rowId && rowId !== "null" && rowId !== "undefined" ? (
              <div className="flex flex-col flex-1 overflow-hidden">
                <div className="flex flex-row justify-between w-full p-[16px] pb-0">
                  <div className="w-[85%] mr-[10px]">
                    <Input
                      leftElement={<img src={searchIcon} alt="search icon" className="top-3" />}
                      fieldName="search"
                      register={register}
                      errors={errors}
                      className="mr-2 flex-1 h-[44px] w-full mr-[10px] z-100"
                      placeholder={`${t("pages.products.search")}...`}
                    />
                  </div>
                  <div className="h-[44px] flex flex-row bg-GreyInput dark:bg-[#1F1E25] rounded-lg cursor-pointer">
                    <img
                      src={Grid}
                      className={currentLayout === "list" ? "opacity-[0.3]" : ""}
                      onClick={() => setCurrentLayout("grid")}
                      alt="Grid"
                    ></img>
                    <img
                      src={List}
                      className={currentLayout === "grid" ? "opacity-[0.3]" : ""}
                      onClick={() => setCurrentLayout("list")}
                      alt="List"
                    ></img>
                  </div>
                </div>
                <div
                  onScroll={handleScroll}
                  ref={scrollRef}
                  className={`mt-[18px] overflow-y-auto flex flex-col px-[16px] mb-[16px] ${
                    currentLayout !== "list" && "grid grid-cols-4 gap-4"
                  }`}
                >
                  {products.map((item, i) => {
                    return (
                      <ProductItem
                        key={item.id + i}
                        item={item}
                        currentLayout={currentLayout}
                        setProductSaleInfo={setProductSaleInfo}
                        course={course}
                      />
                    );
                  })}
                  {products.length === 0 && (
                    <div className="flex flex-col h-full justify-center items-center">
                      <p className="text-[#9B9DA3] text-[16px] font-medium text-center">{t("pages.sale.noProducts")}</p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="flex flex-col h-full justify-center items-center">
                <p className="text-[#9B9DA3] text-[16px] font-medium text-center">{t("pages.sale.selectOrCreate")}</p>
              </div>
            )}
          </div>

          {/* child 2 */}
          <div className="h-full border-2 border-[#F2F2F2] dark:border-[#2D2D2D] border-solid rounded-lg p-[16px] flex flex-col  h-[calc(100vh-288px)]">
            {rowId && rowId !== "null" && rowId !== "undefined" ? (
              <>
                <div className="bg-[#FAFAFA] border-solid border-[2px] border-[#F2F2F2] dark:bg-[#1F1E25] dark:border-[#36343E] rounded-lg w-full flex flex-row p-[2px]">
                  <div
                    className={`${
                      type === "out" &&
                      "bg-white dark:bg-[#191A22] dark:border-[#2D2D2D] border-solid border-[2px] border-[#F2F2F2] py-[8px]"
                    } rounded-lg w-[50%] flex items-center justify-center text-[16px] cursor-pointer`}
                    onClick={() => setType("out")}
                  >
                    <span className={`${type === "out" ? "text-[#FF705D]" : "text-[#9B9DA3]"}`}>
                      {t("pages.sale.expense")} ({singleInvoice?.outgo_products?.length})
                    </span>
                  </div>
                  <div
                    // className=""
                    className={`${
                      type === "return" &&
                      "bg-white dark:bg-[#191A22] dark:border-[#2D2D2D] border-solid border-[2px] border-[#F2F2F2] py-[8px]"
                    } rounded-lg w-[50%] flex items-center justify-center text-[16px] cursor-pointer`}
                    onClick={() => setType("return")}
                  >
                    <span className={`${type === "return" ? "text-[#2196F3]" : "text-[#9B9DA3]"}`}>
                      {t("pages.sale.return")} ({singleInvoice?.income_products?.length})
                    </span>
                  </div>
                </div>
                <div className="flex flex-col h-full h-[calc(100vh-510px)] relative">
                  <Table data={singleInvoice} type={type} />

                  <div className="px-[16px] flex justify-between border-t-[1px] border-[#F2F2F2] dark:border-[#2D2D2D] pt-[8px] mt-[6px]">
                    <span className="text-[12px] font-semibold dark:text-[#fff]">{t("pages.sale.summary")}: </span>
                    <span className="text-[12px] font-semibold dark:text-[#fff]">
                      {formatPrice(
                        productSaleInfo?.income_products?.reduce(
                          (acc, item) => acc + (Number(item.paymentSumUzs) || 0),
                          0,
                        ) -
                          productSaleInfo?.outgo_products?.reduce(
                            (acc, item) => acc + (Number(item.paymentSumUzs) || 0),
                            0,
                          ),
                      ) || 0}{" "}
                      {t("info.sum")} (
                      {formatPrice(
                        productSaleInfo?.income_products?.reduce(
                          (acc, item) => acc + (Number(item.paymentSumUsd) || 0),
                          0,
                        ) -
                          productSaleInfo?.outgo_products?.reduce(
                            (acc, item) => acc + (Number(item.paymentSumUsd) || 0),
                            0,
                          ),
                      ) || 0}
                      $)
                    </span>
                  </div>

                  <TableSecond data={singleInvoice} />
                </div>

                <div className="flex flex-col !text-[12px]">
                  <div
                    className="w-full font-semibold flex flex-row justify-between pt-[10px] mt-[10px] px-[16px] 
    border-t-[1px] border-[#E5E5E5] dark:border-[#2D2D2D]"
                  >
                    <span className="dark:text-[#fff]">{t("pages.sale.discount")}:</span>
                    <span className="dark:text-[#fff]">80 000 {t("info.sum")} (7.50$)</span>
                  </div>
                  <div
                    className="w-full font-semibold flex flex-row justify-between pt-[10px] mt-[10px] px-[16px] 
    border-t-[1px] border-[#E5E5E5] dark:border-[#2D2D2D]"
                  >
                    <span className="dark:text-[#fff]">{t("pages.sale.topay")}:</span>
                    <span className={`${total + paymentTotal < 0 ? "text-[#FF705D]" : "text-[#87CB19]"}`}>
                      {formatPrice((total + paymentTotal) * course)} {t("info.sum")} (
                      {formatPrice(total + paymentTotal)}$)
                    </span>
                  </div>

                  <div className="flex flex-row justify-between">
                    <div className="flex gap-6 mt-2">
                      <BaseButton
                        label={t("button.delete")}
                        className="whitespace-nowrap h-[38px] px-[10px] border ml-2 btn-danger text-[15px]"
                        icon={TrashIcon}
                        onClick={() => closeDeleteModal(true, singleInvoice?.id)}
                      />
                    </div>
                    <div className="flex gap-6 mt-2">
                      <BaseButton
                        type="submit"
                        className="flex-1 h-[38px] px-[10px] bg-[#7B61FF] text-[#fff]  text-[15px]"
                        icon={PrintIcon}
                        label={t("button.print")}
                      />
                      <BaseButton
                        type="submit"
                        className="flex-1 h-[38px] px-[10px] btn-success text-[15px]"
                        onClick={handleClickSaveInvoice}
                        icon={IconSave}
                        label={t("button.save")}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <DeleteConfirmation ref={closeModal} close={closeDeleteModal} cb={confirmDelete} />
        <Modal classNameExtra="!overflow-visible" ref={modalSaveInvoice} title={t("pages.manInvoice.saveInvoice")}>
          <ModalSaveInvoice
            close={handleTemplatesModalSaveInvoice}
            refetch={() => {
              setInvoices((prev) => {
                const newInvoices = [...prev];
                // delete current invocie from list by id
                newInvoices.splice(
                  newInvoices.findIndex((el) => el.id === singleInvoice.id),
                  1,
                );
                return newInvoices;
              });
            }}
            // archivedInvoicesRefetch={archivedInvoicesRefetch}
          />
        </Modal>
      </SaleContext.Provider>
    </div>
  );
};

export default Sale;
