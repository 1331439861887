import React, { useLayoutEffect, useRef } from "react";

import { useSearchParams } from "react-router-dom";
// api
import { useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
// hooks
import useSearch from "hooks/useSearch/useSearch";
// i18n
import { useTranslation } from "react-i18next";
// components
import CircleLoader from "components/Loader/CircleLoader";
import Modal from "components/Modal/Modal";
import ModalForm from "./ModalForm";
import Table from "./Table/Table";

const Roles = () => {
  const { t } = useTranslation();

  let [searchParams, _] = useSearchParams();
  let queryId = searchParams.get("row_id");

  //hooks
  const { searchResult, handleSearch } = useSearch();

  //refs
  const modal = useRef();

  const handleTemplatesModal = (value) => {
    modal.current.openModal(value);
  };

  const { data, isLoading, refetch } = useGet({
    link: endpoints.roles.get,
    params: {
      q: searchResult || undefined,
    },
  });

  const { data: singleData, isFetching } = useGet({
    link: endpoints.roles.one,
    params: { id: queryId },
    enabled: !!queryId,
  });

  useLayoutEffect(() => {
    if (queryId && modal.current) {
      handleTemplatesModal(true);
    }
  }, [queryId]);

  if (isLoading) return <p>Loading</p>;
  // if (isError) return <p>Errror</p>;

  return (
    <>
      <Table data={data} refetch={refetch} modalToggler={handleTemplatesModal} handleSearch={handleSearch} />
      <Modal ref={modal} title={queryId ? t("button.updateRole") : t("button.addRole")}>
        {isFetching ? (
          <div className="w-full flex justify-center">
            <CircleLoader />
          </div>
        ) : (
          <ModalForm close={handleTemplatesModal} refetch={refetch} row={queryId && singleData ? singleData : {}} />
        )}
      </Modal>
    </>
  );
};

export default Roles;
