import React, { useMemo, useRef, useContext } from "react";
import { useSearchParams } from "react-router-dom";
// hooks
import { checkHasAccessToAction } from "services/helpers";
// store
import { useCoreStore } from "store/core";
// api
import { endpoints } from "services/endpoints";
import { useMutate } from "hooks/query";
// helpers
import { header } from "../Helpers";
// i18n
import { useTranslation } from "react-i18next";
// components
import { CategoryContext } from "../CategoryLayout";
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";
import DndTable from "components/Table/DndTable";
import ActionButton from "components/Button/ActionButton";
import DeleteConfirmation from "container/DeleteConfirmation";
import TableAction from "../../../components/Table/TableAction";
// icons
import iconTrash from "../../../assets/images/svg/trash-red.svg";
import Plus from "assets/images/svg/icon-add.svg";
import PlusWhite from "assets/images/svg/icon-add-theme.svg";

const Table = ({ refetch, data = [], modalToggler }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const closeModal = useRef();
  const deleteId = useRef();
  // eslint-disable-next-line no-unused-vars
  let [_, setSearchParams] = useSearchParams();

  const hasAccessToCreate = checkHasAccessToAction("create-category");
  const hasAccessToEdit = checkHasAccessToAction("update-category");
  const hasAccessToDelete = checkHasAccessToAction("delete-category");

  const { parentIds, setParentIds } = useContext(CategoryContext);

  const { theme } = useCoreStore();

  const closeDeleteModal = (value) => {
    closeModal.current.openModal(value);
  };

  const sortCategory = useMutate({
    onSuccess: () => {
      notifySuccess(t("notification.categorySuccessSort"));
    },
  });

  const onListChange = (data) => {
    sortCategory.mutate({
      link: `${endpoints.category.sort}`,
      method: "patch",
      body: data,
    });
  };

  const deleteCategory = useMutate({
    onSuccess: () => {
      refetch();
      closeDeleteModal(false);
      notifySuccess(t("notification.successDelete"));
    },
  });

  const confirmDelete = () => {
    deleteCategory.mutate({
      link: `${endpoints.category.delete}?id=${deleteId.value}`,
      method: "delete",
    });
  };

  const tableData = useMemo(() => {
    return data.map((el, index) => {
      return {
        index,
        id: el.id,
        row: [
          {
            title: (
              <div
                className="hover:text-blue-400 cursor-pointer flex flex-col"
                onClick={() => setParentIds((prev) => ({ ...prev, subParentId: el.id }))}
              >
                <div>
                  {el.name[lang]}
                  <span className="text-[12px] text-[#9B9DA3] ml-2 text-[14px] p-[2px] bg-[#f2f2f2] rounded-[4px]">
                    {el.product_count || 0}
                  </span>
                </div>
                <span className="text-[12px] text-[#9B9DA3]">{el.child_count || 0} категорий</span>
              </div>
            ),
          },
          {
            title: (
              <div className="flex gap-4 items-center justify-end">
                {hasAccessToEdit && (
                  <ActionButton type="edit" handleClick={() => setSearchParams({ row_id2: el.id })} />
                )}
                {hasAccessToDelete && (
                  <TableAction hasBackground={true} parentClass="opacity-[0.8] !h-[32px]">
                    <div
                      className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput"
                      onClick={() => {
                        deleteId.value = el.id;
                        closeDeleteModal(true, el.id);
                      }}
                    >
                      <img src={iconTrash} alt="delete" />
                      <p className="text-[#FF705D]">{t("button.delete")}</p>
                    </div>
                  </TableAction>
                )}
              </div>
            ),
          },
        ],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div className="h-full relative">
        <div className="h-[calc(100vh-355px)]">
          <DndTable
            header={header}
            body={tableData}
            add={modalToggler}
            addable={true}
            isSearchable={true}
            orderChange={onListChange}
            activeRowId={parentIds.subParentId}
          />
        </div>
        <div className="w-full px-2 absolute bottom-2 left-1/2 -translate-x-1/2">
          {hasAccessToCreate && (
            <BaseButton
              label={t("pages.categories.new")}
              onClick={modalToggler}
              icon={theme ? PlusWhite : Plus}
              className="w-full whitespace-nowrap btn-transparent dark:btn-transparent-dark dark:text-white"
            />
          )}
        </div>
      </div>
      <DeleteConfirmation ref={closeModal} close={closeDeleteModal} cb={confirmDelete} />
    </>
  );
};

export default Table;
