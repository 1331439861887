import React, { memo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// api
import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";
import TextArea from "components/Textarea/TextArea";

import IconSave from "assets/images/svg/icon-save.svg";

const ModalFormComments = memo(({ reverse, close, comment, commentType, oneInvoice, setOneInvoice }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const itemId = searchParams.get("item_id") || searchParams.get("invoice_id");

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  function removeId() {
    reset();
    searchParams.delete("row_id");
    searchParams.delete("comment");
    searchParams.delete("item_id");
    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (comment) {
      setValue("note", comment);
    } else {
      reset();
    }
  }, [comment]);

  function clearAndClose() {
    close(false);
    removeId();
  }

  const update = useMutate({
    onSuccess: () => {
      const newSingleInvoice = { ...oneInvoice };

      switch (commentType) {
        case "invoice":
          newSingleInvoice.note = getValues("note");
          break;
        case "out":
          // Use "outgo_products" if not reversed, "income_products" if reversed
          const outProductsKey = reverse ? "income_products" : "outgo_products";
          newSingleInvoice[outProductsKey] = newSingleInvoice[outProductsKey].map((item) => {
            if (item.id === itemId) {
              item.note = getValues("note");
            }
            return item;
          });
          break;
        case "in":
          // Use "income_products" if not reversed, "outgo_products" if reversed
          const inProductsKey = reverse ? "outgo_products" : "income_products";
          newSingleInvoice[inProductsKey] = newSingleInvoice[inProductsKey].map((item) => {
            if (item.id === itemId) {
              item.note = getValues("note");
            }
            return item;
          });
          break;
        default:
          break;
      }

      reset();
      clearAndClose();
      notifySuccess(t("notification.commentSuccessUpdate"));
      setOneInvoice(newSingleInvoice);
    },
  });

  const onSubmit = (values) => {
    let link;

    switch (commentType) {
      case "invoice":
        link = `${endpoints.warehouseInvoice.update}?id=${itemId}`;
        break;
      case "in":
        // Use income product endpoint if not reversed, outgo product endpoint if reversed
        link = reverse
          ? `${endpoints.warehouseInvoice.outgoProductUpdate}?id=${itemId}`
          : `${endpoints.warehouseInvoice.incomeProductUpdate}?id=${itemId}`;
        break;
      case "out":
        // Use outgo product endpoint if not reversed, income product endpoint if reversed
        link = reverse
          ? `${endpoints.warehouseInvoice.incomeProductUpdate}?id=${itemId}`
          : `${endpoints.warehouseInvoice.outgoProductUpdate}?id=${itemId}`;
        break;
      default:
        break;
    }

    update.mutate({
      link,
      method: "patch",
      body: values,
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextArea
          type="text"
          fieldName="note"
          name="note"
          autoFocus={true}
          register={register}
          errors={errors}
          placeholder={t("form.typeText")}
          className="h-[88px] w-full bg-GreyInput placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 focus:bg-white focus:border-2 h-[44px]"
        />
        <div className="flex gap-6 mt-8">
          <BaseButton
            label={t("button.cancel")}
            onClick={clearAndClose}
            className="flex-1 p-2 btn-secondary dark:btn-secondary-dark"
          />
          <BaseButton type="submit" className="flex-1 p-2 btn-success" icon={IconSave} label={t("button.save")} />
        </div>
      </form>
    </div>
  );
});

export default ModalFormComments;
