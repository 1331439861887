import React, { memo } from "react";
import ReactSelect from "react-select";
// store
import { useCoreStore } from "store/core";
// components
import { FormCheckbox } from "components/Checkbox/FormCheckbox";
// icons
import CloseIcon from "assets/images/svg/close.svg";

const changeHandler = (e, props) => {
  let value = null;
  if (e) {
    value = props.isMulti ? e[e.length - 1]?.value : e?.value;
  }
  props.onChangeFunc({ value, name: props.name, option: props.isMulti ? e[e.length - 1] : e });
};

const CheckBoxOption = (props) => {
  const { data, innerProps, selectedTags } = props;

  const checked = selectedTags.find((tag) => tag.value === data.value);

  return (
    <div {...innerProps} className="flex items-center p-[8px] cursor-pointer">
      <FormCheckbox
        name={data.value}
        checked={checked}
        errors={{}}
        className="mr-2 cursor-pointer"
        onChange={() => props.selectOption(data)}
      />

      <div className="dark:text-white">{data.label}</div>
    </div>
  );
};

const CustomSelect = (props) => {
  const value =
    typeof props.value !== "object" ? props.options.find((option) => option.value === props.value) : props.value;
  const {
    selectedTags,
    checkbox = false,
    onInputChange,
    onInput,
    autofocus,
    customChip = false,
    isMulti,
    showChip,
  } = props;

  const inputProps = {
    ...props,
    value,
    name: props.name,
    // placeholder: props.placeholder || `Select ${props.title}`,
    className: "my-react-select-container",
    isClearable: props.isClearable,
    options: props.options,
  };

  const { theme } = useCoreStore();
  const height = props.height || "100%";
  const width = props.width || "100%";
  const borderColor = props.borderColor || "transparent !important";
  const hasError = props.hasError || false;

  const handleCustomChipDelete = (e, chipProps) => {
    e.stopPropagation();
    e.preventDefault();
    if (customChip) {
      const chip = chipProps.data;
      chip.value = chip.value.slice(0, -1);
      props.onChangeFunc(chip);
    }
  };

  const customComponents = {
    Option: (optionProps) => <CheckBoxOption {...optionProps} tabIndex={0} selectedTags={selectedTags} />,
  };

  if (customChip) {
    customComponents.MultiValue = (props) => (
      <div className="flex items-center">
        <div className="flex items-center px-[6px] py-[4px] border-1 border-[#D1D1D1] mr-[6px] rounded-[4px]">
          <span className="text-[13px] leading-3 dark:text-white">{props.data.label}</span>
          <div
            className="w-[12px] h-[12px] p-[2px] ml-[4px] cursor-pointer"
            onClick={(e) => handleCustomChipDelete(e, props)}
          >
            <img src={CloseIcon} alt="CloseIcon" className=" w-full h-full" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <label className="block text-stone-800 dark:text-white font-semibold text-[14px]">{props.title}</label>
      <ReactSelect
        {...inputProps}
        components={checkbox ? customComponents : null}
        tabIndex="0"
        autoFocus={autofocus}
        onChange={(e) => changeHandler(e, props)}
        classNamePrefix="my-react-select"
        menuPosition="fixed"
        placeholder={inputProps.placeholder}
        onInputChange={onInputChange}
        onInput={onInput}
        // content inside select
        styles={{
          // option: (styles, state) => ({
          //   ...styles,
          //   cursor: "pointer",
          // }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: width,
            height: height || "100%",
            minHeight: height || "100%",
            maxWidth: "100%",
            cursor: "pointer",
            borderColor: state.isFocused ? "#7B61FF !important" : hasError ? "#FF705D !important" : borderColor,
          }),
          // height of select
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            height: height || "100%",
            minHeight: height || "100%",
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            padding: "0",
            // height: height || "100%",
          }),
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            padding: "0 8px",
            // height: height || "100%",
          }),

          menuPortal: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: "320!important",
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            overflowY: "auto",
            // if theme true set background to dark
            background: theme ? "#1E1F26!important" : "#fff!important",
          }),
          menuList: (baseStyles) => ({
            ...baseStyles,
            color: theme ? "#fff!important" : "#1E1F26!important",
          }),
          // hover on menu item
          option: (baseStyles, state) => ({
            ...baseStyles,
            cursor: "pointer",
            // backgroundColor: "#7B61FF!important",
            backgroundColor: state.isFocused
              ? theme
                ? "#7B61FF!important"
                : "#f2f2f2!important"
              : "transparent!important",
            // backgroundColor: state.isFocused ? "#7B61FF" : "transparent",
            color: theme ? "#fff" : "#1E1F26",
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: theme ? "#fff" : "#1E1F26",
            opacity: 0.5,
          }),
        }}
      />
    </div>
  );
};

export default memo(CustomSelect);
