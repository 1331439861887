// i18n
import { useTranslation } from "react-i18next";
import TableActionIcon from "assets/images/svg/icon-table-actions.svg";

export const useTranslatedTableConfig = () => {
  const { t } = useTranslation();

  const header = [
    {
      key: "username",
      title: t("pages.mainUsers.table.username"),
      width: "w-[15%]",
    },
    {
      key: "name",
      title: t("pages.mainUsers.table.name"),
      width: "w-[15%]",
    },
    {
      key: "phone_number",
      title: t("pages.mainUsers.table.phoneNumber"),
      width: "w-[15%]",
    },
    {
      key: "state",
      title: t("pages.mainUsers.table.branch"),
      width: "w-[15%]",
    },
    {
      key: "role",
      title: t("pages.mainUsers.table.role"),
      width: "w-[15%]",
    },
    {
      key: "empty",
      // image: TableActionIcon,
      width: "w-[10%]",
    },
  ];

  return { header };
};
