import React, { memo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// api
import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import Input from "components/Input/Input";
import { notifySuccess } from "components/Toast";
import BaseButton from "../../components/Button/BaseButton";
import { useCoreStore } from "../../store/core";
// icons
import IconSave from "assets/images/svg/icon-save.svg";

const ModalForm = memo(({ close, refetch, row }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const rowId = searchParams.get("row_id");
  const { orgId } = useCoreStore((state) => state);

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  function removeId() {
    reset();
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (rowId) {
      setValue("tag_name", row.tag_name);
      setValue("tag_description", row.tag_description);
    } else {
      reset();
    }
  }, [row]);

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: () => {
      refetch();
      reset();
      clearAndClose();
      notifySuccess(t("notification.tagSuccessCreate"));
    },
  });

  const update = useMutate({
    onSuccess: () => {
      refetch();
      reset();
      clearAndClose();
      notifySuccess(t("notification.tagSuccessUpdate"));
    },
  });

  const onSubmit = (values) => {
    if (row.id) {
      update.mutate({
        link: `${endpoints.tags.update}?id=${row.id}`,
        method: "put",
        body: values,
      });
    } else {
      create.mutate({
        link: `${endpoints.tags.create}?org_id=${orgId}`,
        method: "post",
        body: values,
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          autoFocus
          label={t("form.title")}
          fieldName="tag_name"
          register={register}
          errors={errors}
          isRequired={true}
          className="w-full"
          placeholder={t("form.titlePlaceholder")}
        />
        <div className="flex gap-6 mt-8">
          <BaseButton
            label={t("button.cancel")}
            onClick={clearAndClose}
            className="flex-1 p-2 btn-secondary dark:btn-secondary-dark"
          />
          <BaseButton
            type="submit"
            className="flex-1 p-2 btn-success"
            icon={IconSave}
            label={row.id ? t("button.update") : t("button.save")}
            isLoading={create.isLoading || update.isLoading}
          />
        </div>
      </form>
    </div>
  );
});

export default ModalForm;
