import { useSearchParams } from "react-router-dom";

const InvoiceTab = ({ invoice }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentInvoiceId = searchParams.get("invoice_id");

  const setCurrentInvoice = () => {
    setSearchParams({ invoice_id: invoice.id });
  };

  const hour = new Date(invoice.created_at).getHours();
  const minute = new Date(invoice.created_at).getMinutes();
  
  const formattedHour = hour < 10 ? `0${hour}` : hour;
  const formattedMinute = minute < 10 ? `0${minute}` : minute;
  
  const formattedTime = `${formattedHour}:${formattedMinute}`;

  return (
    <>
      <div
        onClick={() => setCurrentInvoice(invoice?.id)}
        className={`
       ${
         currentInvoiceId === invoice.id
           ? "bg-[#FFF]  border-[#F2F2F2] dark:bg-[#27262C] dark:border-[transparent] dark:text-[#fff]"
           : "border-transparent  dark:text-[#9B9DA3]"
       }
       mr-[10px] px-[16px] py-[8px] border-solid border-2 
       flex items-center rounded-lg cursor-pointer 
       width-[max-content] whitespace-nowrap
     `}
      >
        <span>№{invoice?.num} ({formattedTime})</span>
      </div>
    </>
  );
};

export default InvoiceTab;
