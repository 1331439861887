import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        // Retries 2 times for unknown errors or 5xx errors.
        // Fail immediately for 4xx error
        if((!error || !error.statusCode || error.statusCode >= 500) && failureCount < 2) {
          return true;
        }
        return false;
      },
      retryDelay: (attemptIndex) => 500 * (attemptIndex + 1),
      refetchOnWindowFocus: false,
    },
  },
});