import { useContext, useEffect } from "react";

import { endpoints } from "services/endpoints";
import { useGet } from "hooks/query";
import useSearch from "hooks/useSearch/useSearch";

import Table from "./Table";
import { ProductGroupsContext } from "../ProductGroupsLayout";

const ProductGroupsSecond = () => {
  const { parentIds, setParentIds } = useContext(ProductGroupsContext);
  const { searchResult, handleSearch } = useSearch();

  const { data, isLoading, isError, refetch } = useGet({
    link: endpoints.productGroupProperty.list,
    params: { product_group_id: parentIds.parentId, q: searchResult || undefined },
    onSuccess: (data) => {
      const propertyIds = data.map((item) => item.property_id);
      setParentIds((prevState) => ({ ...prevState, propertiesIdList: propertyIds }));
    },
  });

  useEffect(() => {
    refetch();
  }, [parentIds.propertyId]);

  if (isLoading) return <p>Loading</p>;
  if (isError) return <p>Errror</p>;

  return (
    <>
      <Table data={data} refetch={refetch} handleSearch={handleSearch}/>
    </>
  );
};

export default ProductGroupsSecond;
