import React, { memo, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useCoreStore } from "store/core";
import { useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
// api
import { useMutate, useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
// heplers
import { debounce } from "services/helpers";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";
import ControlledSelect from "components/Select/ControlledSelect";
import TextArea from "components/Textarea/TextArea";
import ControlledInput from "components/Input/ControlledInput";
// icons
import IconSave from "assets/images/svg/icon-save.svg";

const ModalEditProductOutgo = memo(({ close, refetch }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const row_id = searchParams.get("row_id");
  const { orgId } = useCoreStore((state) => state);

  const { setSelectedObj, selectedObj } = useCoreStore((state) => state);
  const [productName, setProductName] = useState("");
  const [productOptions, setProductOptions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const {
    watch,
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      return: false,
    },
  });

  useGet({
    link: endpoints.product.get,
    params: {
      org_id: orgId,
      size: 100,
      page: 1,
      ...(productName && { name: productName }),
    },
    enabled: productName?.length >= 3,
    onSuccess: (res) => {
      const optionTransformation = res.data.map((el) => ({
        label: el.name,
        value: el.id,
      }));
      setProductOptions(optionTransformation);
    },
  });

  const debouncedHandleInputChange = debounce((value) => {
    setProductName(value);
  }, 500);

  function removeId() {
    reset();
    setSelectedObj({});
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }

  function clearAndClose() {
    close(false);
    removeId();
  }

  const create = useMutate({
    onSuccess: () => {
      refetch();
      reset();
      clearAndClose();
      notifySuccess(t("notification.successProductUpdate"));
    },
  });

  useEffect(() => {
    if (selectedObj?.id) {
      setSelectedProduct({
        label: selectedObj?.product_name,
        value: selectedObj?.id,
      });

      setValue("quantity", selectedObj?.amount);
      setValue("price", selectedObj?.price);
      setValue("sum", selectedObj?.total);
      setValue("comment", selectedObj?.note);
      setValue("real_price", selectedObj?.real_price);
      setValue("risk", selectedObj?.risk);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedObj]);

  const quantity = watch("quantity");
  const price = watch("price");
  const sum = watch("sum");

  const handleQuantityChange = (value) => {
    setValue("quantity", value);

    if (value && price) {
      setValue("sum", value * price);
    }
  };

  const handlePriceChange = (value) => {
    setValue("price", value);

    if (value && quantity) {
      setValue("sum", value * quantity);
    }
  };

  const handleSumChange = (value) => {
    setValue("sum", value);

    if (value && quantity) {
      setValue("price", value / quantity);
    }
  };

  const onSubmit = (values) => {
    const body = {
      product_id: selectedProduct?.value,
      num: Number(values.quantity),
      amount: Number(values.quantity),
      price: Number(values.price),
      total: Number(values.sum),
      note: values.comment,
      real_price: Number(values.real_price),
      risk: Number(values.risk),
      refund: false,
    };

    create.mutate({
      link: `${endpoints.invoices.updateOutgoProduct}?id=${row_id}`,
      method: "put",
      body,
    });
  };

  return (
    <div className="w-[600px]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <ControlledSelect
              label={t("pages.betweenWarehouse.product")}
              name="product"
              errors={errors}
              autoFocus={true}
              height="50px"
              isRequired={true}
              placeholder={t("pages.admInvoice.form.productPlaceholder")}
              className="w-full  placeholder-darkGreyLight rounded-lg focus:border-purple-0 focus:bg-white focus:border-2 font-[16px] max-w-[100%]"
              options={productOptions}
              value={selectedProduct}
              onInputChange={(value) => debouncedHandleInputChange(value)}
              onChange={(e) => {
                setSelectedProduct(e);
              }}
            />
          </div>

          <div className="col-span-1">
            <NumericFormat
              value={quantity}
              isRequired={true}
              fieldName="quantity"
              label={t("pages.admInvoice.table.quantity")}
              placeholder={t("pages.admInvoice.form.quantityPlaceholder")}
              name="quantity"
              errors={errors}
              register={register}
              hasError={errors.quantity}
              className="w-full h-[50px] rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey"
              //
              decimalScale={0} // Number of decimal places
              thousandSeparator=""
              fixedDecimalScale={false}
              allowNegative={false} // Set to true if negative values should be allowed
              onValueChange={(values) => {
                handleQuantityChange(values.value);
              }}
              customInput={ControlledInput}
            />
          </div>
          <div className="col-span-1">
            <NumericFormat
              value={price}
              fieldName="price"
              label={t("pages.sale.price")}
              placeholder={t("pages.admInvoice.form.pricePlaceholder")}
              name="price"
              errors={errors}
              register={register}
              hasError={errors.price}
              rules={{ required: true }}
              className="w-full h-[50px] rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey"
              //
              decimalScale={2} // Number of decimal places
              thousandSeparator=" "
              fixedDecimalScale={false}
              allowNegative={false} // Set to true if negative values should be allowed
              onValueChange={(values) => {
                handlePriceChange(values.value);
              }}
              customInput={ControlledInput}
            />
          </div>
          <div className="col-span-1">
            <NumericFormat
              value={sum}
              fieldName="sum"
              label={t("pages.admInvoice.table.sum")}
              placeholder={t("pages.admInvoice.form.sumPlaceholder")}
              name="sum"
              errors={errors}
              register={register}
              hasError={errors.sum}
              className="w-full h-[50px] rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey"
              //
              decimalScale={2} // Number of decimal places
              thousandSeparator=" "
              fixedDecimalScale={false}
              allowNegative={false} // Set to true if negative values should be allowed
              onValueChange={(values) => {
                handleSumChange(values.value);
              }}
              customInput={ControlledInput}
            />
          </div>

          <div className="col-span-2">
            <TextArea
              fieldName="comment"
              label={t("info.comment")}
              placeholder={t("pages.admInvoice.form.commentPlaceholder")}
              name="comment"
              errors={{}}
              register={register}
              className="w-full py-3 px-4 rounded-md focus:ring-0 font-normal bg-GreyInput dark:bg-inputBgDark dark:placeholder:text-midGrey h-[50px]"
            />
          </div>

          <div className="col-span-2 flex justify-between">
            <BaseButton
              label={t("button.cancel")}
              onClick={clearAndClose}
              className="p-2 btn-secondary dark:btn-secondary-dark"
            />

            <BaseButton
              icon={IconSave}
              label={t("button.save")}
              type="submit"
              className="col-span-1 bg-mainGreen text-white font-semibold"
              isLoading={create.isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
});

export default ModalEditProductOutgo;
