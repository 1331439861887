import React, { useLayoutEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useSearch from "hooks/useSearch/useSearch";
// api
import { useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import Modal from "components/Modal/Modal";
import CircleLoader from "components/Loader/CircleLoader";
import ModalForm from "./ModalForm";
import Table from "./Table/Table";

const SubUsers = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const modal = useRef();
  let [searchParams, _] = useSearchParams();
  let queryId = searchParams.get("row_id");
  const { id } = useParams();
  const { searchResult, handleSearch } = useSearch();

  const handleTemplatesModal = (value) => {
    modal.current.openModal(value);
  };

  const { data, isLoading, isError, refetch } = useGet({
    link: endpoints.users.get,
    params: { org_id: id, size: 10, page: page + 1, q: searchResult || undefined },
  });

  const { data: singleData, isFetching } = useGet({
    link: endpoints.users.one,
    params: { id: queryId },
    enabled: !!queryId,
  });

  useLayoutEffect(() => {
    if (queryId && modal.current) {
      handleTemplatesModal(true);
    }
  }, [queryId]);

  const paginationConfig = {
    forcePage: page,
    total: data?.total,
    pageCount: Math.ceil((data?.total ?? 10) / 10),
    pageRangeDisplayed: 2,
    onPageChange: ({ selected }) => setPage(selected % data?.total),
  };

  if (isLoading) return <p>Loading</p>;
  // if (isError) return <p>Errror</p>;

  return (
    <div className="h-full flex flex-col justify-between">
      <Table
        data={data.data}
        setPage={setPage}
        refetch={refetch}
        modalToggler={handleTemplatesModal}
        handleSearch={handleSearch}
        paginationConfig={paginationConfig}
      />
      <Modal ref={modal} title={queryId ? t("button.editUser") : t("button.addUser")}>
        {isFetching ? (
          <div className="w-full flex justify-center">
            <CircleLoader />
          </div>
        ) : (
          <ModalForm close={handleTemplatesModal} refetch={refetch} row={queryId && singleData ? singleData : {}} />
        )}
      </Modal>
    </div>
  );
};

export default SubUsers;
