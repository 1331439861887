// i18n
import { useTranslation } from "react-i18next";

export const useTranslatedTableConfig = () => {
  const { t } = useTranslation();

  const header = [
    {
      key: "Дата",
      title: t("pages.kurs.table.date"),
      width: "w-[25%]",
    },
    {
      key: "Валюта",
      title: t("pages.kurs.table.currency"),
      width: "w-[25%]",
    },
    {
      key: "Курс",
      title: t("pages.kurs.table.rate"),
      width: "w-[25%]",
    },
    {
      key: "empty",
      title: "",
      width: "w-[25%]",
    },
  ];

  return { header };
};
