// i18n
import { useTranslation } from "react-i18next";

export const useTranslatedTableConfig = () => {
  const { t } = useTranslation();

  const header = [
    {
      key: "name",
      title: t("pages.mainOrganizations.table.name"),
    },
    {
      key: "phone",
      title: t("pages.mainOrganizations.table.phone"),
    },
    {
      key: "tin",
      title: t("pages.mainOrganizations.table.tin"),
    },
    {
      key: "user_cnt",
      title: t("pages.mainOrganizations.table.userCnt"),
      className: "text-center",
    },
    {
      key: "warehouse_cnt",
      title: t("pages.mainOrganizations.table.warehouseCnt"),
      className: "text-center",
    },
    {
      key: "child_cnt",
      title: t("pages.mainOrganizations.table.childCnt"),
      className: "text-center",
    },
    {
      key: "empty",
      title: "",
    },
  ];
  return { header };
};
