import React from "react";
// i18n
import { useTranslation } from "react-i18next";
const Home = () => {

  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="">
        <p className="text-[#2D2D34] text-[24px] font-bold dark:text-[#fff]">
          {t("pages.home.title")}
        </p>
      </div>
    </div>
  );
};

export default Home;
