import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";
// helpers
import { formatPrice } from "services/helpers";
// store
import { useCoreStore } from "store/core";
// api
import { useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import ControlledCheckbox from "components/Checkbox/ControlledCheckbox";
import Invoice from "./components/Invoice";

const AdminInvoices = () => {
  const { t } = useTranslation();
  const { orgId, adminInvoiceFilters } = useCoreStore((state) => state);
  const { setRefetchAdminInvoices } = useCoreStore((state) => state);
  const [invoices, setInvoices] = useState([]);
  const [allOpen, setAllOpen] = useState(true);
  const [pfShow, setPfShow] = useState(false);
  const [pfsShow, setPfsShow] = useState(false);
  //   const { data, isLoading, isError, refetch } = useGet({
  //     link: endpoints.tags.get,
  //     params: { org_id: orgId, size: 10, page: 1, q: searchResult || undefined },
  //   });

  const {
    data: invoicesApi,
    isLoading: invoicesLoading,
    isError: invoicesError,
    refetch,
  } = useGet({
    link: endpoints.invoices.pageDetailed,
    params: { org_id: orgId, ...adminInvoiceFilters, },
  });

  useEffect(() => {
    if (invoicesApi) {
      setInvoices(invoicesApi.data);
    }
  }, [invoicesApi]);

  useEffect(() => {
    if (refetch) setRefetchAdminInvoices(refetch);
  }, [refetch, setRefetchAdminInvoices]);

  if (invoicesLoading && invoices?.length === 0) return <div>loading...</div>;
  if (invoicesError) return <div>Error...</div>;

  return (
    <div
      className="w-full border-1 border-[#F2F2F2] dark:border-[#2d2d2d]
        rounded-lg h-full p-[16px] overflow-y-auto relative"
    >
      <div className="px-[16px] py-[6px] flex items-center border-1 border-[#F2F2F2] dark:border-[#2d2d2d] bg-[#fff] dark:bg-[#1F1E25] rounded-lg justify-between sticky top-0 z-[70]">
        <p className="text-[#1C1C1C] dark:text-white text-[15px] font-[500]">
          {t("pages.admInvoice.invoiceCount")}:{" "}
          <span className="text-[#7B61FF]">
            {formatPrice(invoicesApi?.totals?.count) || "0"} {t("info.piece")}
          </span>
          , <span className="lowercase">{t("pages.admInvoice.recieved")}</span>:{" "}
          <span className="text-[#2196F3]">${formatPrice(invoicesApi?.totals?.income_pay_total) || "0"}</span>,{" "}
          <span className="lowercase">{t("pages.admInvoice.issued")}</span>:{" "}
          <span className="text-[#FF705D]">${formatPrice(invoicesApi?.totals?.outgo_pay_total) || "0"}</span>,{" "}
          <span className="lowercase">{t("pages.admInvoice.totalAmount")}</span>:{" "}
          <span className="text-[#87CB19]">${formatPrice(invoicesApi?.totals?.income_outgo_pay_diff) || "0"}</span>,{" "}
          <span className="lowercase">{t("pages.admInvoice.recieved")}</span>:{" "}
          <span className="text-[#87CB19]">
            {formatPrice(invoicesApi?.totals?.income_amount_total) || "0"} {t("info.piece")}
          </span>
          , <span className="lowercase">{t("pages.admInvoice.issued")}</span>:{" "}
          <span className="text-[#FF705D]">
            {formatPrice(invoicesApi?.totals?.outgo_amount_total) || "0"} {t("info.piece")}
          </span>
          , <span className="lowercase">{t("pages.admInvoice.productCount")}</span>:{" "}
          <span className="text-[#FF705D]">
            {formatPrice(invoicesApi?.totals?.income_outgo_amount_diff) || "0"} {t("info.piece")}
          </span>
        </p>
        <div className="flex flex-row items-center">
          <div className="flex gap-1 mr-[8px]">
            <a data-tooltip-id="show-pf-tooltip" data-tooltip-place="top">
              <ControlledCheckbox onChange={() => setPfShow(!pfShow)} />
            </a>
            <Tooltip id="show-pf-tooltip" style={{ maxWidth: "320px", textAlign: "center", zIndex: "500" }}>
              <p className="text-[#fff] text-[12px]">{t("pages.admInvoice.showPf")}</p>
            </Tooltip>

            <a data-tooltip-id="show-pfs-tooltip" data-tooltip-place="top">
              <ControlledCheckbox onChange={() => setPfsShow(!pfsShow)} />
            </a>
            <Tooltip id="show-pfs-tooltip" style={{ maxWidth: "320px", textAlign: "center", zIndex: "500" }}>
              <p className="text-[#fff] text-[12px]">{t("pages.admInvoice.showPfs")}</p>
            </Tooltip>

            <a data-tooltip-id="show-all-tooltip" data-tooltip-place="top">
              <ControlledCheckbox onChange={() => setAllOpen(!allOpen)} />
            </a>
            <Tooltip id="show-all-tooltip" style={{ maxWidth: "320px", textAlign: "center", zIndex: "500" }}>
              <p className="text-[#fff] text-[12px]">{t("pages.admInvoice.showHide")}</p>
            </Tooltip>
          </div>
        </div>
      </div>
      <div>
        {invoices?.map((invoice, i) => (
          <Invoice
            invoice={invoice}
            key={invoice.id}
            refetch={refetch}
            setInvoices={setInvoices}
            open={allOpen}
            pfShow={pfShow}
            pfsShow={pfsShow}
          />
        ))}
      </div>
    </div>
  );
};

export default AdminInvoices;
