import { useState } from "react";
import Select from "react-select";
import cls from "classnames";
// store
import { useCoreStore } from "store/core";
// icons
import closeIcon from "assets/images/svg/icon-close.svg";

const ControlledSelect = (props) => {
  const {
    name,
    label,
    value,
    width,
    height,
    borderColor = "transparent !important",
    className,
    placeholder,
    leftElement,
    register,
    isRequired = false,
    autofocus,
    onChange = () => {},
    options = [],
    hasError = false,
    clearable = false,
    ...rest
  } = props;

  const { theme } = useCoreStore();

  const handleReset = () => {
    onChange(null, name); // Pass null or an empty array [] for isMulti
  };

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  // useEffect(() => {
  //   const closeMenuOnScroll = () => {
  //     setMenuIsOpen(false);
  //   };

  //   // Set up the global scroll event listener
  //   window.addEventListener("scroll", closeMenuOnScroll, true);

  //   // Clean up the event listener
  //   return () => {
  //     window.removeEventListener("scroll", closeMenuOnScroll, true);
  //   };
  // }, []);

  // close menu on scroll

  return (
    <div className={className}>
      {label && (
        <label className={cls(`block text-stone-800 dark:text-white font-semibold text-[14px]`)} htmlFor={name}>
          {label} {isRequired && <span className="text-red-500">*</span>}
        </label>
      )}
      <div className="relative">
        {leftElement && <div className="absolute left-3 top-1/2 -translate-y-1/2">{leftElement}</div>}
        <Select
          options={options}
          value={value}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          menuShouldBlockScroll={true}
          autoFocus={autofocus}
          // ref={register}
          menuIsOpen={menuIsOpen}
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
          name={name}
          placeholder={<span className="text-[#9A9DA2]">{placeholder}</span>}
          className="my-react-select-container"
          classNamePrefix="my-react-select"
          // menuPosition="fixed"
          onChange={(value, { name }) => onChange(value, name)}
          styles={{
            // option: (styles, state) => ({
            //   ...styles,
            //   cursor: "pointer",
            // }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              width: width,
              height: height || "100%",
              minHeight: height || "100%",
              maxWidth: "100%",
              cursor: "pointer",
              borderColor: state.isFocused ? "#7B61FF !important" : hasError ? "#FF705D !important" : borderColor,
            }),
            // height of select
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              height: height || "100%",
              minHeight: height || "100%",
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              padding: "0",
              // height: height || "100%",
            }),
            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              padding: "0 8px",
              // height: height || "100%",
            }),

            menuPortal: (baseStyles, state) => ({
              ...baseStyles,
              zIndex: "320!important",
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              overflowY: "auto",
              // if theme true set background to dark
              background: theme ? "#1E1F26" : "#fff",
            }),
            menuList: (baseStyles) => ({
              ...baseStyles,
              color: theme ? "#fff" : "#1E1F26",
            }),
            // hover on menu item
            option: (baseStyles, state) => ({
              ...baseStyles,
              cursor: "pointer",
              backgroundColor: state.isFocused && theme ? "#7B61FF" : "transparent",
              color: theme ? "#fff" : "#1E1F26",
            }),
          }}
          {...rest}
        />
        {clearable && value && (
          <button
            type="button"
            onClick={handleReset}
            className="absolute right-[30px] top-1/2 transform -translate-y-1/2 text-sm p-1"
          >
            <img src={closeIcon} alt="close" className="w-[20px] h-[20px]" />
          </button>
        )}
      </div>
    </div>
  );
};

export default ControlledSelect;
