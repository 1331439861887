import { useEffect, useState } from "react";

const UseGetSingleData = (queryId, data = []) => {
  const [singleData, setSingleData] = useState({});

  useEffect(() => {
    if (queryId) {
      const findSelectedData = data.find((el) => el.id === queryId);
      setSingleData(findSelectedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryId]);

  return { singleData, setSingleData };
};

export default UseGetSingleData;
