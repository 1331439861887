import { getCurrentVersion } from "services/const";
// i18n
import { useTranslation } from "react-i18next";

export const Footer = () => {

  const { t } = useTranslation();

  const year = new Date().getFullYear();

  return (
    // <footer className="relative bottom-[30px]">
    <footer className={`bg-white dark:bg-mainGreyDark pb-[25px] px-[25px] pt-[12px]`}>
      <div className="flex justify-between items-center">
        <p className="text-textBlack text-[15px] dark:text-darkGreyLight">© {year} — NT Systems LTD. {t("footer.copyright")}. </p>
        <p className="text-darkGreyLight text-[15px]">{t("footer.version")} {getCurrentVersion()}</p>
      </div>
    </footer>
  );
};
