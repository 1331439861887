import React, { memo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// api
import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import BaseButton from "components/Button/BaseButton";
import TextArea from "components/Textarea/TextArea";
// icons
import IconSave from "assets/images/svg/icon-save.svg";

const ModalFormComments = memo(({ archived, close, row, singleInvoice, updateSingleInvoice }) => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const comment = searchParams.get("comment");
  const commentType = searchParams.get("comment_type");
  const itemId = searchParams.get("item_id") || searchParams.get("id");

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  function removeId() {
    reset();
    searchParams.delete("row_id");
    searchParams.delete("comment");
    searchParams.delete("item_id");
    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (comment) {
      setValue("note", comment);
    } else {
      reset();
    }
  }, [row]);

  function clearAndClose() {
    close(false);
    removeId();
  }

  const update = useMutate({
    onSuccess: () => {
      const newSingleInvoice = { ...singleInvoice };

      switch (commentType) {
        case "invoice":
          newSingleInvoice.note = getValues("note");
          break;
        case "productOutgo":
          newSingleInvoice.outgo_products = newSingleInvoice.outgo_products.map((item) => {
            if (item.id === itemId) {
              item.note = getValues("note");
            }
            return item;
          });
          break;
        case "productIncome":
          newSingleInvoice.income_products = newSingleInvoice.income_products.map((item) => {
            if (item.id === itemId) {
              item.note = getValues("note");
            }
            return item;
          });
          break;
        case "outgoPayments":
          newSingleInvoice.outgo_payments = newSingleInvoice.outgo_payments.map((item) => {
            if (item.id === itemId) {
              item.note = getValues("note");
            }
            return item;
          });
          break;
        case "incomePayments":
          newSingleInvoice.income_payments = newSingleInvoice.income_payments.map((item) => {
            if (item.id === itemId) {
              item.note = getValues("note");
            }
            return item;
          });
          break;
        default:
          break;
      }

      reset();
      clearAndClose();
      notifySuccess(t("notification.commentSuccesUpdate"));
      updateSingleInvoice(newSingleInvoice);
    },
  });

  const onSubmit = (values) => {
    let link = "";

    switch (commentType) {
      case "invoice":
        link = `${endpoints.invoices.update}?id=${itemId}`;
        break;
      case "productOutgo":
        link = `${endpoints.invoices.updateOutgoProduct}?id=${itemId}`;
        break;
      case "productIncome":
        link = `${endpoints.invoices.updateIncomeProduct}?id=${itemId}`;
        break;
      case "outgoPayments":
      case "incomePayments":
        link = `${endpoints.invoices.updateInvoicePayment}?id=${itemId}`;
        break;
      default:
        break;
    }

    update.mutate({
      link,
      method: "patch",
      body: values,
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextArea
          type="text"
          // if archived === true, user can't edit comment
          autoFocus
          disabled={archived}
          fieldName="note"
          name="note"
          register={register}
          errors={errors}
          placeholder={t("form.typeText")}
          className="h-[88px] w-full bg-GreyInput placeholder-darkGreyLight rounded-lg border-2 border-GreyInput focus:border-purple-0 focus:bg-white focus:border-2 h-[44px]"
        />
        <div className={`${archived && "justify-end"} flex gap-6 mt-8`}>
          <BaseButton
            label={archived ? t("button.close") : t("button.cancel")}
            onClick={clearAndClose}
            className={`${archived ? "" : "flex-1"} p-2 btn-secondary dark:btn-secondary-dark`}
          />
          {!archived && (
            <BaseButton type="submit" className="flex-1 p-2 btn-success" icon={IconSave} label={t("button.save")} />
          )}
        </div>
      </form>
    </div>
  );
});

export default ModalFormComments;
