// i18n
import { useTranslation } from "react-i18next";

export const useTranslatedTableConfig = () => {
  const { t } = useTranslation();

  const header = [
    {
      key: "name",
      title: t("pages.sale.table.name"),
      width: "w-[35%]",
    },
    {
      key: "quantity",
      title: t("pages.sale.table.quantity"),
      width: "w-[12%]",
    },
    {
      key: "amount",
      title: t("pages.sale.table.amount"),
      width: "w-[25%]",
    },
    {
      key: "total",
      title: t("pages.sale.table.total"),
      width: "w-[20%]",
    },
    {
      key: "empty",
      title: "",
      width: "w-[8%]",
    },
  ];

  const header2 = [
    {
      key: "type",
      title: t("pages.sale.table.type"),
      width: "w-[20%]",
    },
    {
      key: "received",
      title: t("pages.sale.table.recieved"),
      width: "w-[40%]",
    },
    {
      key: "issued",
      title: t("pages.sale.table.issued"),
      width: "w-[40%]",
    },
  ];

  return { header, header2 };
};
