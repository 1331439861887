// i18n
import { useTranslation } from "react-i18next";

export const useTranslatedTableConfig = () => {
  const { t } = useTranslation();

  const header = [
    {
      key: "name",
      title: t("form.title"),
      width: "w-[90%]",
    },
    {
      key: "empty",
      width: "w-[10%]",
      title: "",
    },
  ];
  return { header };
};
