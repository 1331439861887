import { clientRoutes, adminRoutes } from "./seperatedRoutes";

export const Roles = {
  client: ["client"],
  admin: ["admin"],
};

export const roleBasedRoute = {
  client: {
    path: "/",
    roles: Roles.client,
    routes: clientRoutes,
  },
  admin: {
    path: "/",
    roles: Roles.admin,
    routes: adminRoutes,
  },
};
