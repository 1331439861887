import { useRef, useState, useLayoutEffect, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useSearch from "hooks/useSearch/useSearch";
// api
import { useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
// store
import { useCoreStore } from "store/core";
// i18n
import { useTranslation } from "react-i18next";
// components
import Modal from "components/Modal/Modal";
import ModalForm from "./Modal/ModalForm";
import ModalFormTags from "./Modal/ModalFormTags";
import CircleLoader from "components/Loader/CircleLoader";
import Table from "./Table/Table";

const Products = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);

  const tableRef = useRef();
  const modal = useRef();
  const modalTags = useRef();

  const { orgId } = useCoreStore((state) => state);
  const theme = useCoreStore((state) => state.theme);
  const store = useCoreStore();
  let [searchParams, _] = useSearchParams();
  const { searchResult, handleSearch } = useSearch();

  let queryId = searchParams.get("row_id");
  // get all products

  const handleTemplatesModal = (value) => {
    modal.current.openModal(value);
  };

  const handleTemplatesModalTags = (value) => {
    modalTags.current.openModal(value);
  };

  const updateTags = (categories, tags) => {
    tableRef.current.updateTags(categories, tags);
  };

  useLayoutEffect(() => {
    if (queryId && modal.current) {
      handleTemplatesModal(true);
    }
  }, [queryId]);

  useEffect(() => {
    setPage(0);
  }, [searchResult, selectedTags]);

  const { data, isLoading, isError, refetch } = useGet({
    link: endpoints.product.get,
    params: {
      org_id: orgId,
      page: page + 1,
      size: 10,
      name: searchResult || undefined,
      tag_names: selectedTags.length > 0 ? selectedTags.map((el) => el.label).join(",") : undefined,
    },
  });

  const { data: productGroup, isLoading: isLoadingProductGroup } = useGet({
    link: endpoints.productGroup.list,
    params: {
      org_id: orgId,
    },
  });

  const { data: tags, isLoading: isLoadingTags } = useGet({
    link: endpoints.tags.list,
    params: {
      org_id: orgId,
    },
  });

  // get categories

  const { data: categories, isLoading: isLoadingCategories } = useGet({
    link: endpoints.category.list,
    params: {
      org_id: orgId,
      child_empty: true,
    },
  });

  const paginationConfig = {
    forcePage: page,
    total: data?.total ?? 10,
    pageCount: Math.ceil((data?.total ?? 10) / 10),
    pageRangeDisplayed: 2,
    onPageChange: ({ selected }) => setPage(selected % data?.total),
  };

  // create product

  if (isLoading || isLoadingTags || isLoadingCategories) return <p>Loading</p>;
  if (isError) return <p>Errror</p>;

  return (
    <>
      <Table
        ref={tableRef}
        data={data?.data}
        tags={tags}
        categories={categories}
        paginationConfig={paginationConfig}
        setPage={setPage}
        tagModalToggler={handleTemplatesModalTags}
        refetch={refetch}
        modalToggler={handleTemplatesModal}
        handleSearch={handleSearch}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />

      <Modal ref={modal} title={queryId ? t("pages.products.edit") : t("pages.products.new")}>
        {isLoading ? (
          <div className="w-full flex justify-center">
            <CircleLoader />
          </div>
        ) : (
          <ModalForm
            close={handleTemplatesModal}
            refetch={refetch}
            productGroup={productGroup}
            tags={tags}
            categories={categories}
          />
        )}
      </Modal>
      <Modal ref={modalTags} title={t("pages.products.manageTags")}>
        {isLoading ? (
          <div className="w-full flex justify-center">
            <CircleLoader />
          </div>
        ) : (
          <ModalFormTags
            close={handleTemplatesModalTags}
            refetch={refetch}
            tags={tags}
            categories={categories}
            updateTags={updateTags}
          />
        )}
      </Modal>
    </>
  );
};

export default Products;
