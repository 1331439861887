
import React from "react";
import cls from "classnames";
import ControlledCheckbox from "../Checkbox/ControlledCheckbox";

const BaseThead = ({
  header,
  withCheckbox = false,
  isSelectedAllRows = false,
  handleChangeCheckbox = () => {},
  extraClassTh,
  extraClassTr,
  thColor,
}) => {
  return (
    <tr
      className={`sticky top-[0px] z-50 bg-[#F2F2F2] dark:bg-inputBgDark rounded-[0] ${extraClassTr}`}
      style={ thColor && { backgroundColor: `${thColor}` }}
    >
      {header.map((head, ind) => (
        <th
          key={head.key}
          className={cls(
            "p-4 sticky top-0 text-left first:rounded-tl-[8px] first:rounded-bl-[8px] last:rounded-tr-[8px] last:rounded-br-[8px] " +
            head?.className,
            head.width,
            extraClassTh,
          )}
        >
          {withCheckbox && ind === 0 ? (
            <div className="flex items-center gap-2">
              <ControlledCheckbox checked={isSelectedAllRows} onChange={handleChangeCheckbox} />
              <span className="opacity-70 text-[#2D2D34] text-medium font-light dark:text-textDark">{head.title}</span>
            </div>
          ) : head.image ? (
            <div className="w-full flex justify-end pr-2">
              <img src={head.image} />
            </div>
          ) : (
            <span className={`opacity-70 text-[#2D2D34] text-medium font-light ${thColor ? "dark:text-[#000]" : "dark:text-[#FFFFFF]"}`}>{head.title}</span>
          )}
        </th>
      ))}
    </tr>
  );
};

export default BaseThead;




// import React from "react";
// import cls from "classnames";
// import ControlledCheckbox from "../Checkbox/ControlledCheckbox";

// const BaseThead = ({
//   header,
//   withCheckbox = false,
//   isSelectedAllRows = false,
//   handleChangeCheckbox = () => {},
//   extraClassTh,
//   extraClassTr,
//   thColor,
// }) => {
//   return (
//     <tr
//       className={`sticky top-[0px] z-50 bg-GreyExtraLight dark:bg-inputBgDark rounded-[0] ${extraClassTr}`}
//       style={ thColor && { backgroundColor: `${thColor}` }}
//     >
//       {header.map((head, ind) => (
//         <th
//           key={head.key}
//           className={cls(
//             "p-4 sticky top-0 text-left first:rounded-tl-[8px] first:rounded-bl-[8px] " +
//               "first:rounded-tr-none first:rounded-br-none last:rounded-tr-[8px] last:rounded-br-[8px] last:rounded-tl-none last:rounded-bl-none",
//             head.width,
//             extraClassTh,
//           )}
//         >
//           {withCheckbox && ind === 0 ? (
//             <div className="flex items-center gap-2">
//               <ControlledCheckbox checked={isSelectedAllRows} onChange={handleChangeCheckbox} />
//               <span className="opacity-70 text-[#2D2D34] text-medium font-light dark:text-textDark">{head.title}</span>
//             </div>
//           ) : head.image ? (
//             <div className="w-full flex justify-end pr-2">
//               <img src={head.image} />
//             </div>
//           ) : (
//             <span className="opacity-70 text-[#2D2D34] text-medium font-light dark:text-[#FFFFFF]">{head.title}</span>
//           )}
//         </th>
//       ))}
//     </tr>
//   );
// };

// export default BaseThead;
