import create from "zustand";
import { devtools, persist } from "zustand/middleware";

persist(
  (set, get) => ({
    fishes: 0,
    addAFish: () => set({ fishes: get().fishes + 1 }),
  }),
  {
    name: "food-storage", // name of item in the storage (must be unique)
    getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
  },
);

export const useCoreStore = create(
  devtools(
    persist(
      (set, get) => ({
        theme: false,
        isOpenSidebar: false,
        orgId: "",
        subOrgId: "",
        userData: {
          photo: null,
          name: "",
          email: "",
          phone_number: "",
          roles: [],
          warehouse_id: "",
        },
        adminInvoiceFilters: {},
        saleFilters: {},
        selectedObj: {},
        navigatorExtra: {},
        // function to set the myFunction property
        setRefetchAdminInvoices: (func) => set({ refetchAdminInvoicesState: func }),
        setRefetchUserInfo: (func) => set({ refetchUserInfoState: func }),

        refetchUserInfo: (...args) => {
          const func = get().refetchUserInfoState;
          if (typeof func === "function") {
            func(...args);
          }
        },
        // function to call the myFunction property with arguments
        refetchAdminInvoices: (...args) => {
          const func = get().refetchAdminInvoicesState;
          if (typeof func === "function") {
            func(...args);
          }
        },

        toggleTheme: () => {
          set({ theme: !get().theme });
        },
        toggleSidebar: () => set({ isOpenSidebar: !get().isOpenSidebar }),
        setOrgId: (id) => set({ orgId: id }),
        setSubOrgId: (id) => set({ subOrgId: id }),
        setUserData: (name, full_name, image, email, phone_number, roles, warehouse_id) => {
          set({
            userData: {
              photo: image,
              name: name,
              full_name: full_name,
              email: email,
              phone_number: phone_number,
              roles: roles,
              warehouse_id: warehouse_id,
            },
          });
          let newUserInfo;
          const userData = JSON.parse(localStorage.getItem("user_info"));

          let newRoles = roles[0].composites;

          newRoles = newRoles.map((role) => {
            return role.name;
          });

          newUserInfo = {
            ...userData,
            resource_access: {
              "customer-api": {
                roles: newRoles,
              },
            },
          };

          localStorage.setItem("user_info", JSON.stringify(newUserInfo));
        },

        setAdminInvoiceFilters: (data) => set({ adminInvoiceFilters: data }),
        setSaleFilters: (data) => set({ saleFilters: data }),
        setSelectedObj: (data) => set({ selectedObj: data }),
        setNavigatorExtra: (data) => set({ navigatorExtra: data }),
      }),
      {
        name: "project-settings",
      },
    ),
  ),
);
