import { forwardRef } from "react";
// helpers
import { useTranslatedTableConfig } from "../Helpers";
import { formatPrice } from "services/helpers";
// i18n
import { useTranslation } from "react-i18next";
// components
import Tbody from "./PaymentsTbody";
import BaseTable from "components/Table/BaseTable";
import BaseThead from "components/Table/BaseThead";

const Table = forwardRef(
  ({ payments = [], badge, invoiceId, setInvoices, colors, handleTemplatesEditPayment, type }, ref) => {
    const { t } = useTranslation();

    const { paymentsHeaders } = useTranslatedTableConfig();

    const { darkColor, lightColor, badgeColor } = colors;

    const extraClassTr = `!bg-[${darkColor}] overflow-y-hidden`;
    const extraClassTh = "!py-[6px] px-[16px] first:rounded-tl-[0] first:rounded-bl-[0]";

    return (
      <>
        <div className="flex flex-row justify-between mt-[16px]"></div>
        <div className="h-[90%] px-2 relative">
          <div
            className="flex flex-row items-stretch"
            style={{
              borderRadius: "8px",
              overflow: "hidden",
              border: `1px solid ${badgeColor}`,
            }}
          >
            <div
              className={`w-[22px] relative flex-auto`}
              style={{
                backgroundColor: `${badgeColor}`,
              }}
            >
              {badge && (
                <span
                  style={{ transform: "rotate(-90deg) translate(-65%, 0%)", transformOrigin: "left" }}
                  className="block text-[#2D2D34] absolute bottom-1/2 left-1/2 text-[12px] font-semibold w-inherit"
                >
                  {badge}
                </span>
              )}
            </div>
            <div className="w-full h-full flex-auto">
              <BaseTable
                thead={
                  <BaseThead
                    header={paymentsHeaders}
                    extraClassTh={extraClassTh}
                    extraClassTr={extraClassTr}
                    thColor={darkColor}
                  />
                }
                tbody={
                  <Tbody
                    data={payments}
                    invoiceId={invoiceId}
                    setInvoices={setInvoices}
                    darkColor={darkColor}
                    lightColor={lightColor}
                    handleTemplatesEditPayment={handleTemplatesEditPayment}
                    type={type}
                  />
                }
              />
            </div>
          </div>
          <div className="pr-[16px] mt-[8px] flex justify-end items-center space-x-[30px]">
            <p className="text-[#535456] dark:text-white text-[15px]">
            {t("pages.admInvoice.table.sum")}:{" "}
              <span className="text-bold">
                {formatPrice(
                  payments.reduce((acc, payment) => {
                    return acc + payment.currency_amount;
                  }, 0),
                )}{" "}
                $
              </span>
            </p>
          </div>
        </div>
      </>
    );
  },
);

export default Table;
