import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
// helpers
import { checkHasAccessToAction } from "services/helpers";
// i18n
import { useTranslation } from "react-i18next";
// components
import BaseButton from "components/Button/BaseButton";
import TableAction from "components/Table/TableAction";
import BaseTd from "components/Table/BaseTd";
import ControlledCheckbox from "components/Checkbox/ControlledCheckbox";
// icons
import iconTrash from "assets/images/svg/trash-red.svg";
import IconEdit from "assets/images/svg/edit-icon.svg";

const Tbody = ({ data, isChecked, checkboxes, setCheckboxes, closeDeleteModal }) => {
  const { t } = useTranslation();
  let [_, setSearchParams] = useSearchParams();

  const hasAccessToEdit = checkHasAccessToAction("update-tag");
  const hasAccessToDelete = checkHasAccessToAction("delete-tag");

  const selectTableRow = (rowId) => {
    setCheckboxes({ ...checkboxes, [rowId]: !checkboxes[rowId] });
  };

  useEffect(() => {
    const objState = {};
    if (isChecked) {
      data.forEach((item) => {
        objState[item.id] = true;
      });
      setCheckboxes(objState);
    } else {
      const filterByTrue = Object.values(checkboxes).filter((el) => el);
      if (filterByTrue.length === data.length) setCheckboxes({});
    }
  }, [isChecked]);

  return (
    <>
      {data.map((item) => (
        <tr key={item.id} className="hover:bg-GreyLight dark:hover:bg-inputBgDark">
          <BaseTd>
            <div className="flex items-center gap-2">
              <ControlledCheckbox checked={checkboxes[item.id] || false} onChange={() => selectTableRow(item.id)} />
              <span>{item.tag_name}</span>
            </div>
          </BaseTd>
          <BaseTd>
            <div className="flex items-center gap-4 justify-end">
              {hasAccessToEdit && (
                <BaseButton
                  icon={IconEdit}
                  className="p-[6px] h-[32px]"
                  onClick={() => setSearchParams({ row_id: item.id })}
                />
              )}
              {hasAccessToDelete && (
                <TableAction hasBackground parentClass="!h-[32px]">
                  <div
                    className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                    onClick={() => closeDeleteModal(true, item.id)}
                  >
                    <img src={iconTrash} alt="delete" />
                    <p className="text-[#FF705D]">{t("button.delete")}</p>
                  </div>
                </TableAction>
              )}
            </div>
          </BaseTd>
        </tr>
      ))}
    </>
  );
};

export default Tbody;
