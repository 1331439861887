import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
// i18n
import { useTranslation } from "react-i18next";
import BaseButton from "components/Button/BaseButton";
import TableAction from "components/Table/TableAction";
import BaseTd from "components/Table/BaseTd";
import ControlledCheckbox from "components/Checkbox/ControlledCheckbox";
import { checkHasAccessToAction } from "services/helpers";
import { useCoreStore } from "store/core";
import useOrganization from "../../hooks/useOrganization";
import cls from "classnames";

import iconTrash from "assets/images/svg/trash-red.svg";
import IconDefault from "assets/images/svg/icon-default.svg";

const Tbody = ({ data, isChecked, checkboxes, setCheckboxes, closeDeleteModal }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  let [_, setSearchParams] = useSearchParams();

  const hasAccessToEdit = checkHasAccessToAction("update-sub-user");
  const hasAccessToDelete = checkHasAccessToAction("delete-sub-user");

  const selectTableRow = (rowId) => {
    setCheckboxes({ ...checkboxes, [rowId]: !checkboxes[rowId] });
  };

  const { orgId } = useCoreStore((state) => state);

  const { subOrgOptions: subOrgList } = useOrganization(orgId, false);

  useEffect(() => {
    const objState = {};
    if (isChecked) {
      data.forEach((item) => {
        objState[item.id] = true;
      });
      setCheckboxes(objState);
    } else {
      const filterByTrue = Object.values(checkboxes).filter((el) => el);
      if (filterByTrue.length === data.length) setCheckboxes({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  return (
    <>
      {data.map((item) => (
        <tr key={item.id} className="hover:bg-GreyLight dark:hover:bg-inputBgDark">
          <BaseTd>
            <div className="flex items-center gap-2">
              <ControlledCheckbox checked={checkboxes[item.id] || false} onChange={() => selectTableRow(item.id)} />
              <img
                src={item.photo || IconDefault}
                alt="avatar"
                className={cls("table--avatar", { "rounded-full": !item.photo })}
              />
              <span>{item.username}</span>
            </div>
          </BaseTd>
          <BaseTd>{item.full_name}</BaseTd>
          <BaseTd>{item.phone_number}</BaseTd>
          <BaseTd>{item.sub_org_id && subOrgList.find((el) => el.value === item.sub_org_id)?.label}</BaseTd>
          <BaseTd>{item.roles.map((role) => role.name).join(", ")}</BaseTd>
          <BaseTd>
            <div className="flex items-center gap-4 justify-end">
              {hasAccessToEdit && (
                <BaseButton
                  label={t("button.edit")}
                  className="btn-gray hover:bg-GreyInput dark:text-white dark:bg-midBlack dark:border-midBlack py-[6px] text-md h-[32px] text-[15px]"
                  onClick={() => setSearchParams({ row_id: item.id })}
                />
              )}
              {hasAccessToDelete && (
                <TableAction hasBackground parentClass="!h-[32px]">
                  <div
                    className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack"
                    onClick={() => closeDeleteModal(true, item.id)}
                  >
                    <img src={iconTrash} alt="delete" />
                    <p className="text-[#FF705D]">{t("button.delete")}</p>
                  </div>
                </TableAction>
              )}
            </div>
          </BaseTd>
        </tr>
      ))}
    </>
  );
};

export default Tbody;
