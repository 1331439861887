import { useState, useEffect, useMemo } from "react";
import { userInfo } from "services/helpers";
// store
import { useCoreStore } from "store/core";
// i18n
import { useTranslation } from "react-i18next";
import i18n from "i18next";
// light Icons
import { ReactComponent as HomeIcon } from "assets/images/svg/menu-home.svg";
import { ReactComponent as MenuUsersIcon } from "assets/images/svg/menu-users.svg";
import { ReactComponent as MenuOrganizationsIcon } from "assets/images/svg/menu-organizations.svg";
import { ReactComponent as MenuSecurityIcon } from "assets/images/svg/menu-Security.svg";
import { ReactComponent as MenuSaleIcon } from "assets/images/svg/menu-sale.svg";
import { ReactComponent as MenuKursIcon } from "assets/images/svg/menu-kurs.svg";
import { ReactComponent as MenuBetweenWarehouseIcon } from "assets/images/svg/menu-between_warehouse.svg";
import { ReactComponent as MenuInvoiceIcon } from "assets/images/svg/menu-invoice.svg";
import { ReactComponent as MenuProductsIcon } from "assets/images/svg/menu-products.svg";
import { ReactComponent as MenuWarehouseIcon } from "assets/images/svg/menu-warehouse.svg";
import { ReactComponent as MenuClientsIcon } from "assets/images/svg/menu-clients.svg";
// import MenuRoles from "assets/images/svg/menu-roles.svg";
// import MenuPermissions from "assets/images/svg/menu-permissions.svg";
// import MenuAllGoods from "assets/images/svg/menu-all_goods.svg";
// import MenuOverlay from "assets/images/svg/menu-overlay.svg";
// import MenuWareHouse from "assets/images/svg/menu-warehouse.svg";
// import MenuGoods from "assets/images/svg/menu-goods.svg";
// import MenuSale from "assets/images/svg/menu-sale.svg";
// import MenuWell from "assets/images/svg/menu-well.svg";
// import MenuBetweenWareHouse from "assets/images/svg/menu-between_warehouse.svg";

const UseMenuIcons = (orgId) => {
  const { theme } = useCoreStore();

  const userPermissions = userInfo()?.resource_access?.[userInfo()?.azp]?.roles;

  const { t } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    const handleLanguageChange = (lng) => {
      setLanguage(lng);
    };

    i18n.on("languageChanged", handleLanguageChange);
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []);

  // set userPermissions to see all menu
  // const userPermissions = ["all","home-menu", "user-menu", "permission-menu", "roles-menu", "organization-menu", "warehouse-menu"];

  const colors = {
    default: theme ? "#8B8999" : "#535456",
    active: "#7B61FF",
  };

  const menus = useMemo(() => {
    return [
      {
        label: t("drawer.home"),
        icon: <HomeIcon fill={colors.default} />,
        iconActive: <HomeIcon fill={colors.active} />,
        to: "/home",
        permissions: ["home-menu"],
        status: "done",
      },
      {
        label: t("drawer.security"),
        icon: <MenuSecurityIcon fill={colors.default} />,
        iconActive: <MenuSecurityIcon fill={colors.active} />,
        subMenu: [
          {
            label: t("drawer.users"),
            to: "/users",
            permissions: ["user-menu"],
            status: "done",
          },
          {
            label: t("drawer.permissions"),
            to: "/permissions",
            permissions: ["permission-menu"],
            status: "done",
          },
          {
            label: t("drawer.roles"),
            to: "/roles",
            permissions: ["roles-menu"],
            status: "done",
          },
        ],
      },
      {
        label: t("drawer.organizations"),
        icon: <MenuOrganizationsIcon fill={colors.default} />,
        iconActive: <MenuOrganizationsIcon fill={colors.active} />,
        to: "/organizations",
        permissions: ["organization-menu"],
        status: "done",
      },
      {
        label: t("drawer.products"),
        icon: <MenuProductsIcon fill={colors.default} />,
        iconActive: <MenuProductsIcon fill={colors.active} />,
        subMenu: [
          {
            label: t("drawer.allProducts"),
            to: "/products",
            permissions: ["product-menu"],
            status: "done",
          },
          {
            label: t("drawer.parameters"),
            to: "/parameters",
            permissions: ["property-menu"],
            status: "done",
          },
          {
            label: t("drawer.productGroups"),
            to: "/product-groups",
            permissions: ["product-group-menu"],
            status: "done",
          },
          {
            label: t("drawer.category"),
            to: "/category",
            permissions: ["category-menu"],
            status: "done",
          },
          {
            label: t("drawer.tags"),
            to: "/tags",
            permissions: ["tag-menu"],
            status: "done",
          },
        ],
      },
      {
        label: t("drawer.users"),
        icon: <MenuUsersIcon fill={colors.default} />,
        iconActive: <MenuUsersIcon fill={colors.active} />,
        to: `/sub-users/${orgId}`,
        permissions: ["sub-user-menu"],
        status: "done",
      },
      {
        label: t("drawer.subOrganizations"),
        icon: <MenuOrganizationsIcon fill={colors.default} />,
        iconActive: <MenuOrganizationsIcon fill={colors.active} />,
        to: `/sub-organizations/${orgId}`,
        permissions: ["sub-organization-menu"],
        status: "done",
      },
      {
        label: t("drawer.invoices"),
        icon: <MenuInvoiceIcon fill={colors.default} />,
        iconActive: <MenuInvoiceIcon fill={colors.active} />,
        to: "/invoices",
        permissions: ["invoice-menu"],
        status: "done",
      },
      {
        label: t("drawer.invoices"),
        icon: <MenuInvoiceIcon fill={colors.default} />,
        iconActive: <MenuInvoiceIcon fill={colors.active} />,
        to: "/admin-invoices",
        permissions: ["admin-invoice-menu"],
        status: "done",
      },
      {
        label: t("drawer.warehouse"),
        icon: <MenuWarehouseIcon fill={colors.default} />,
        iconActive: <MenuWarehouseIcon fill={colors.active} />,
        to: `/warehouse/${orgId}`,
        permissions: ["warehouse-menu"],
        status: "done",
      },
      {
        label: t("drawer.customers"),
        icon: <MenuClientsIcon fill={colors.default} />,
        iconActive: <MenuClientsIcon fill={colors.active} />,
        to: "/customers",
        permissions: ["customer-menu"],
        status: "done",
      },

      {
        label: t("drawer.sale"),
        icon: <MenuSaleIcon fill={colors.default} />,
        iconActive: <MenuSaleIcon fill={colors.active} />,
        to: "/sale",
        permissions: ["sale-menu"],
        status: "done",
      },
      {
        label: t("drawer.kurs"),
        icon: <MenuKursIcon fill={colors.default} />,
        iconActive: <MenuKursIcon fill={colors.active} />,
        to: "/kurs",
        permissions: ["course-menu"],
        status: "done",
      },
      {
        label: t("drawer.betweenWarehouse"),
        icon: <MenuBetweenWarehouseIcon fill={colors.default} />,
        iconActive: <MenuBetweenWarehouseIcon fill={colors.active} />,
        to: "/between-warehouse",
        permissions: ["warehouse-invoice-menu"],
        status: "done",
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, language]);

  const hasPermission = (permission) => userPermissions?.includes(permission) || permission === "all";

  const filteredMenu = menus.filter((menu) => {
    if (menu.subMenu) {
      menu.subMenu = menu.subMenu.filter((subMenu) => subMenu.permissions.some(hasPermission));
      return menu.subMenu.length > 0;
    }
    return menu.permissions.some(hasPermission);
  });

  return filteredMenu;
};

export default UseMenuIcons;
