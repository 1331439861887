import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
// api
import { useGet } from "hooks/query";
import { endpoints } from "services/endpoints";
// hooks
import useSearch from "hooks/useSearch/useSearch";
// store
import { useCoreStore } from "store/core";
// i18n
import { useTranslation } from "react-i18next";
// components
import Modal from "components/Modal/Modal";
import CircleLoader from "components/Loader/CircleLoader";
import ModalForm from "./ModalForm";
import Table from "./Table/Table";
// useOrganization
import useOrganization from "../hooks/useOrganization";


const Users = () => {

  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({
    is_active: false,
  });
  const [users, setUsers] = useState([]);
  const modal = useRef();
  const { orgId } = useCoreStore();
  const { orgOptions: orgList } = useOrganization(orgId, false);

  let [searchParams, _] = useSearchParams();
  let queryId = searchParams.get("row_id");

  const orgType = orgId ? "sub_org_id" : "org_id";

  const { searchResult, handleSearch } = useSearch();

  const handleTemplatesModal = (value) => {
    modal.current.openModal(value);
  };

  const { data, isLoading, isError, refetch } = useGet({
    link: endpoints.users.get,
    params: {
      size: 10,
      page: page + 1,
      q: searchResult || undefined,
      is_active: filters.is_active,
      // [orgType]: filters[orgType]?.value,
      org_id: filters?.org_id?.value,
      sub_org_id: filters?.sub_org_id?.value,

      // permission: filters["permission"],
    },
  });

  const { data: singleData, isFetching } = useGet({
    link: endpoints.users.one,
    params: { id: queryId },
    queryKey: queryId,
    enabled: !!queryId,
  });

  useEffect(() => {
    if (!data || !orgList) return;
    const newArr = [...data.data];
    newArr.forEach((item) => {
      const org = orgList.find((org) => org.value === item.org_id);

      if (org) {
        item.org_name = org.label;
      }
    });
    setUsers(newArr);
  }, [data, orgList]);

  useLayoutEffect(() => {
    if (queryId && modal.current) {
      handleTemplatesModal(true);
    }
  }, [queryId]);

  const paginationConfig = {
    forcePage: page,
    total: data?.total,
    pageCount: Math.ceil((data?.total ?? 10) / 10),
    pageRangeDisplayed: 2,
    onPageChange: ({ selected }) => setPage(selected % data?.total),
  };

  if (isLoading) return <p>Loading</p>;
  // if (isError) return <p>Errror</p>;

  return (
    <>
      <Table
        data={users}
        setPage={setPage}
        refetch={refetch}
        filters={filters}
        setFilters={setFilters}
        modalToggler={handleTemplatesModal}
        handleSearch={handleSearch}
        paginationConfig={paginationConfig}
      />
      <Modal ref={modal} title={queryId ? t("button.editUser") : t("button.addUser")}>
        {isFetching ? (
          <div className="w-full flex justify-center">
            <CircleLoader />
          </div>
        ) : (
          <ModalForm close={handleTemplatesModal} refetch={refetch} row={queryId && singleData ? singleData : {}} />
        )}
      </Modal>
    </>
  );
};

export default Users;
