import axios from "axios";
import { logOut } from "services/helpers";

export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  timeout: 500000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  let token = localStorage.getItem("user_token");

  if (token) {
    return {
      ...config,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  } else {
    return {
      ...config,
    };
  }
});

// Response interceptor for API calls
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const refresh_token = localStorage.getItem("refresh_token");
    const originalRequest = error.config;

    if (error.response.status !== 401) return Promise.reject(error);
    if (originalRequest.url === "/api/refresh_token")
      return Promise.reject(error);

    if (originalRequest._retry && originalRequest._retry > 1) {
      logOut();
      window.location.replace("login");
      return Promise.reject(error);
    }

    try {
      const { data } = await axios.post("/api/refresh_token", null, {
        params: {
          token: refresh_token,
        },
      });

      localStorage.setItem("user_token", data.access_token);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + data.access_token;
      return instance(originalRequest);
    } catch (error) {
      if (error.response.data.status === 401) {
        logOut();
        window.location.replace("login");
      }

      originalRequest._retry = (originalRequest._retry || 0) + 1;
      return Promise.reject(error.response.data);
    }
  },
);

const responseBody = (response) => response.data;

const requests = {
  get: (url, headers) => instance.get(url, headers).then(responseBody),

  post: (url, body, headers) =>
    instance.post(url, body, headers).then(responseBody),

  put: (url, body, headers) =>
    instance.put(url, body, headers).then(responseBody),

  patch: (url, body) => instance.patch(url, body).then(responseBody),

  delete: (url) => instance.delete(url).then(responseBody),
};

export default requests;
