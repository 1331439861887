// store
import { useCoreStore } from "store/core";
// i18n
import { useTranslation } from "react-i18next";
// components
import WareHouseItem from "./WareHouseItem";
import BaseButton from "components/Button/BaseButton";
// icons
import Plus from "assets/images/svg/icon-add.svg";
import PlusWhite from "assets/images/svg/icon-add-theme.svg";

const WareHouseList = ({ warehouseInvoiceList, handleTemplatesModal }) => {
  const { t } = useTranslation();
  const { theme } = useCoreStore();
  return (
    <div className="betweenWareHouseList flex flex-col justify-between dark:border-[#2D2D2D]">
      <div className="overflow-y-auto px-[8px] mb-[auto]">
        {warehouseInvoiceList?.map((item) => (
          <WareHouseItem key={item.id} item={item} />
        ))}
      </div>

      <BaseButton
        label={t("button.create")}
        type="submit"
        className="w-full whitespace-nowrap btn-transparent dark:btn-transparent-dark dark:text-white"
        onClick={() => handleTemplatesModal(true)}
        icon={theme ? PlusWhite : Plus}
      />
    </div>
  );
};

export default WareHouseList;
