import React, { useRef, useState } from "react";
import { usePopper } from "react-popper";
import { useOutsideAlerter } from "hooks/outSideClick";
// helpers
import cls from "classnames";
// components
import Portal from "components/Portal/Portal";
// icons
import MoreAlt from "assets/images/svg/icon-more-alt.svg";

const TableAction = ({
  children,
  hasBackground = false,
  boxClass,
  className,
  parentClass,
  customStyle,
  dark = false,
  icon = false,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const wrapperRef = useRef(null);

  const closeTableAction = () => {
    setIsActive(false);
  };

  useOutsideAlerter(wrapperRef, closeTableAction);

  let { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [{ name: "offset", options: { offset: [0, 10] } }],
  });

  // update position when rerender

  return (
    <div className={className ? className : "relative"} ref={wrapperRef}>
      <div className="relative" ref={setReferenceElement}>
        <div
          className={cls(
            "flex items-center gap-1 cursor-pointer h-[24px] py-[1px] px-2",
            {
              "bg-GreyInput rounded-[6px] h-[32px] dark:bg-midBlack": hasBackground,
            },
            {
              "!bg-white dark:bg-inputBgDark": icon,
            },
            parentClass,
          )}
          onClick={() => {
            update && update();
            setIsActive((prev) => !prev);
          }}
        >
          {icon ? (
            <img src={MoreAlt} alt="More" />
          ) : (
            <>
              <span className={`w-[4px] h-[4px] rounded-full bg-black ${!dark && "dark:bg-white"}`}></span>
              <span className={`w-[4px] h-[4px] rounded-full bg-black ${!dark && "dark:bg-white"}`}></span>
              <span className={`w-[4px] h-[4px] rounded-full bg-black ${!dark && "dark:bg-white"}`}></span>
            </>
          )}
        </div>
        <Portal>
          <div
            className={cls(
              // "block w-36 py-1 px-1 absolute top-4 right-0 bg-white shadow-[0px_2px_4px_-2px_rgba(0,0,0,0.1)] cursor-pointer z-10 border-[#B0B8C9] border dark:border-0 rounded-[6px] dark:bg-inputBgDark",
              // "block min-w-[9rem] min-w py-1 px-1 absolute top-4 right-0 bg-white shadow-dots cursor-pointer z-10 dark:border-0 rounded-[6px] dark:bg-inputBgDark",
              "min-w-[9rem] py-1 px-1 absolute bg-white shadow-dots cursor-pointer z-10 dark:border-0 rounded-[6px] dark:bg-inputBgDark",
              boxClass,
              { invisible: !isActive },
            )}
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            {children}
          </div>
        </Portal>
      </div>
    </div>
  );
};

export default TableAction;

// import React, { useRef, useState, useEffect } from "react";
// import cls from "classnames";
// import MoreAlt from "assets/images/svg/icon-more-alt.svg";
// import { useOutsideAlerter } from "hooks/outSideClick";
// import Portal from "components/Portal/Portal";

// const TableAction = ({
//   children,
//   hasBackground = false,
//   boxClass,
//   className,
//   parentClass,
//   customStyle,
//   icon = false,
// }) => {
//   const [isActive, setIsActive] = useState(false);
//   const [position, setPosition] = useState({ x: 0, y: 0 });
//   const wrapperRef = useRef(null);

//   const closeTableAction = () => {
//     setIsActive(false);
//   };

//   useOutsideAlerter(wrapperRef, closeTableAction);

//   useEffect(() => {
//     const updatePosition = () => {
//       if (wrapperRef.current) {
//         const { x, y, width, height } = wrapperRef.current.getBoundingClientRect();
//         setPosition({ x: x + width / 2, y: y + height });
//       }
//     };

//     updatePosition();

//     window.addEventListener("resize", updatePosition);

//     return () => {
//       window.removeEventListener("resize", updatePosition);
//     };
//   }, []);

//   return (
//     <div className={className ? className : "relative"} ref={wrapperRef}>
//       <div className="relative">
//         <div
//           className={cls(
//             "flex items-center gap-1 cursor-pointer",
//             {
//               "bg-GreyInput h-[32px] py-[1px] px-2 rounded-[6px] dark:bg-midBlack": hasBackground,
//             },
//             {
//               "!bg-white dark:bg-inputBgDark": icon,
//             },
//             parentClass,
//           )}
//           onClick={() => setIsActive((prev) => !prev)}
//         >
//           {icon ? (
//             <img src={MoreAlt} alt="More" />
//           ) : (
//             <>
//               <span className="w-[4px] h-[4px] rounded-full bg-black dark:bg-white"></span>
//               <span className="w-[4px] h-[4px] rounded-full bg-black dark:bg-white"></span>
//               <span className="w-[4px] h-[4px] rounded-full bg-black dark:bg-white"></span>
//             </>
//           )}
//         </div>
//         <Portal>
//           <div
//             className={cls(
//               "min-w-[9rem] py-1 px-1 absolute bg-white shadow-dots cursor-pointer z-10 dark:border-0 rounded-[6px] dark:bg-inputBgDark",
//               boxClass,
//               { invisible: !isActive },
//             )}
//             style={{ left: position.x, top: position.y }}
//           >
//             {children}
//           </div>
//         </Portal>
//       </div>
//     </div>
//   );
// };

// export default TableAction;
