import { useEffect, useState } from "react";
import useDebounce from "hooks/useDebounce/useDebounce";

const useSearch = (delay = 500) => {
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState();

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const { cancel } = useDebounce(() => setSearchResult(search), [search], delay);

  useEffect(() => {
    return () => cancel();
  }, []);

  return { searchResult, handleSearch };
};

export default useSearch;
