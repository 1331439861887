import { useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { endpoints } from "services/endpoints";
import { useCoreStore } from "store/core";
import { useGet } from "hooks/query";
import useSearch from "hooks/useSearch/useSearch";
// i18n
import { useTranslation } from "react-i18next";
import Modal from "components/Modal/Modal";
import useGetSingleData from "hooks/useGetSingleData/useGetSingleData";
import ModalForm from "./ModalForm";
import Table from "./Table";

const ParametrFirst = () => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  let queryId = searchParams.get("row_id1");
  const modal1 = useRef();
  const { orgId } = useCoreStore((state) => state);
  const { searchResult, handleSearch } = useSearch();

  const { data, isLoading, isError, refetch } = useGet({
    link: endpoints.parameter.list,
    params: { org_id: orgId, q: searchResult || undefined },
  });
  const { singleData } = useGetSingleData(queryId, data);

  const handleTemplatesModal = (value) => {
    modal1.current.openModal(value);
  };

  useEffect(() => {
    if (queryId && modal1.current) {
      handleTemplatesModal(true);
    }
  }, [queryId]);

  if (isLoading) return <p>Loading</p>;
  if (isError) return <p>Errror</p>;

  return (
    <>
      <Table data={data} refetch={refetch} modalToggler={handleTemplatesModal} handleSearch={handleSearch} />
      <Modal ref={modal1} title={queryId ? t("pages.parameters.edit") : t("pages.parameters.add")} rowId="row_id1">
        <ModalForm
          close={handleTemplatesModal}
          refetch={refetch}
          row={queryId && singleData ? singleData : {}}
          orgId={queryId ?? orgId}
        />
      </Modal>
    </>
  );
};

export default ParametrFirst;
