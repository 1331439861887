import { useMemo } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Login from "pages/Authentication/Login";
import PrivateRoute from "routes/privateRoute";
import { roleBasedRoute } from "./config";
import { userInfo } from "services/helpers";

const MainRoutes = () => {
  const role = "admin";
  const location = useLocation();

  const filterRoutes = useMemo(() => {
    const userPermissions = userInfo()?.resource_access?.[userInfo()?.azp]?.roles;
    const hasPermission = permission => userPermissions?.includes(permission) || permission === "all";
    return roleBasedRoute[role].routes.filter((route) => route.permissions.some(hasPermission));
  }, [location]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<p>Forget password</p>} />
      <Route
        path={roleBasedRoute[role].path}
        element={<PrivateRoute allowedRoles={roleBasedRoute["admin"].roles} />}
      >
        {filterRoutes.map((route, i) => {
          return route.component ? (
            <Route key={i} path={`${route.path}`} element={route.component} />
          ) : null;
        })}
      </Route>
      <Route path="/*" element={<p>Not found page</p>} />
    </Routes>
  );
};

export default MainRoutes;
