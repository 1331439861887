import React, { useState, memo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
// api
import { useGet, useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
// hooks
import { useCoreStore } from "store/core";
import { useGetManager } from "./hooks/useGetManager";
import useDebounce from "hooks/useDebounce/useDebounce";
// i18n
import { useTranslation } from "react-i18next";
// components
import Input from "components/Input/Input";
import BaseButton from "components/Button/BaseButton";
import ImageUploaderLarge from "components/ImageUploaderLarge";
import CustomSelect from "components/Select/Select";
import { notifySuccess } from "components/Toast";
import { FormCheckbox } from "components/Checkbox/FormCheckbox";

import IconSave from "assets/images/svg/icon-save.svg";

const ModalForm = memo(({ close, refetch, row }) => {
  const { t } = useTranslation();
  const [imagesUrl, setImagesUrl] = useState([]);
  const [searchResult, setSearchResult] = useState();
  const [codeState, setCodeState] = useState();
  const [firstCode, setFirstCode] = useState();
  const [shouldGenerateCode, setShouldGenerateCode] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  const rowId = searchParams.get("row_id");

  const orgId = useCoreStore((state) => state.orgId);
  const orgManagerList = useGetManager();

  const {
    reset,
    setValue,
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();

  function removeId() {
    // Clear out all form-related states first
    setImagesUrl([]);
    setFirstCode(null);
    setShouldGenerateCode(false);
    setCodeState(null);

    // Then, reset the form to clear inputs
    reset();

    // Lastly, clean up the URL search parameters
    searchParams.delete("row_id");
    setSearchParams(searchParams, { replace: true }); // Pass replace: true to not leave a history entry
  }

  function clearAndClose() {
    // Just call removeId as it handles everything
    removeId();
    close(false); // Assuming 'close' is a function to close the modal
  }

  useEffect(() => {
    if (rowId) {
      setValue("code", row.code);
      setValue("name", row.name);
      setValue("is_active", row.state === "INACTIVE" ? false : true);
      setFirstCode(row.code);
    } else {
      reset();
      setValue("is_active", true);
    }
  }, [row]);

  useEffect(() => {
    if (row.image) {
      setImagesUrl([row.image]);
    } else {
      setImagesUrl([]);
    }
  }, [row.image_id]);

  useEffect(() => {
    if (row.manager_id) {
      const findInitVal = orgManagerList.find((el) => el.value === row.manager_id);
      setValue("manager_id", findInitVal);
    }
  }, [row.manager_id, orgManagerList]);

  const code = watch("code");

  useDebounce(() => setSearchResult(code), [code], 500);

  const { data: checkCode } = useGet({
    link: endpoints.customers.check,
    params: { code: searchResult, org_id: orgId },
    enabled: searchResult?.length >= 3 && firstCode !== searchResult,
  });

  const { data: generate } = useGet({
    link: endpoints.customers.generateCode,
    params: { org_id: orgId },
    enabled: shouldGenerateCode,
  });

  useEffect(() => {
    if (generate?.code) {
      setCodeState(generate.code);
    }
  }, [generate?.code]);

  // useEffect(() => {
  //   if (generate?.code) {
  //     setValue("code", codeState);
  //     setShouldGenerateCode(false); // Reset the flag
  //   }
  // }, [codeState, setValue, shouldGenerateCode]);

  useEffect(() => {
    // Only set the code value if `shouldGenerateCode` is true or the modal is open
    if (generate?.code && shouldGenerateCode) {
      setValue("code", generate.code);
      setShouldGenerateCode(false); // Reset the flag
      // Resetting the code generation flag should be enough
      // setCodeState(null) here is redundant if you're not using it elsewhere
    }
  }, [generate?.code, setValue, shouldGenerateCode]);

  const create = useMutate({
    onSuccess: () => {
      refetch();
      close(false);
      removeId();
      notifySuccess(t("notification.customerSuccessCreate"));
    },
  });

  const update = useMutate({
    onSuccess: () => {
      refetch();
      close(false);
      removeId();
      notifySuccess(t("notification.customerSuccessUpdate"));
    },
  });

  const onSubmit = (values) => {
    const { is_active, ...filteredValues } = values;

    const body = {
      ...filteredValues,
      image: imagesUrl[0],
      manager_id: values.manager_id?.value,
      state: is_active ? "ACTIVE" : "INACTIVE",
    };

    if (row.id) {
      update.mutate({
        link: `${endpoints.customers.update}?id=${row.id}`,
        method: "put",
        body: body,
      });
    } else {
      create.mutate({
        link: `${endpoints.customers.create}?org_id=${orgId}`,
        method: "post",
        body: body,
      });
    }
  };

  return (
    <div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-2">
          <ImageUploaderLarge url={imagesUrl} setImagesUrl={setImagesUrl} />
          <div className="">
            <div className="flex items-end gap-x-2">
              <div className="flex-grow">
                <Input
                  label={t("pages.customers.form.code")}
                  fieldName="code"
                  register={register}
                  errors={errors}
                  isRequired={true}
                  autoFocus={true}
                  placeholder={t("pages.customers.form.codePlaceholder")}
                  className="w-full h-12"
                />
              </div>
              <BaseButton
                className="h-[44px] bg-gray-400 dark:bg-[#1F1E25] text-white px-4 rounded-md hover:bg-gray-500 active:bg-gray-600 focus:outline-none mb-[10px] hover:opacity-80 transition-opacity duration-300"
                label={t("button.generate")}
                onClick={() => setShouldGenerateCode(true)}
              />
            </div>
            {
              // check if code is already taken
              checkCode?.contains && (
                <div className="text-red-500 text-[13px] mt-[4px]">
                  Код {checkCode.code} {t("info.taken")}.
                </div>
              )
            }
          </div>

          <Input
            label={t("pages.customers.form.name")}
            fieldName="name"
            register={register}
            errors={errors}
            placeholder={t("pages.customers.form.namePlaceholder")}
            isRequired={true}
            className="w-full"
          />
          <Controller
            name="manager_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomSelect
                {...field}
                isMulti={false}
                closeMenuOnSelect={true}
                errors={errors}
                required
                label={t("pages.customers.form.manager")}
                placeholder={t("pages.customers.form.managerPlaceholder")}
                handleChange={() => {}}
                options={orgManagerList}
              />
            )}
          />
          <div className="mt-3">
            <FormCheckbox label={t("info.activeCustomer")} errors={errors} {...register("is_active")} />
          </div>
        </div>
        <div className="flex gap-6 mt-8">
          <BaseButton
            label={t("button.cancel")}
            onClick={clearAndClose}
            className="flex-1 p-2 btn-secondary dark:btn-secondary-dark"
          />
          <BaseButton
            type="submit"
            className="flex-1 p-2 btn-success"
            icon={IconSave}
            label={row.id ? t("button.update") : t("button.save")}
            isLoading={create.isLoading || update.isLoading}
          />
        </div>
      </form>
    </div>
  );
});

export default ModalForm;
