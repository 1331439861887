import { useState, useEffect } from "react";
// store
import { useCoreStore } from "store/core";
// icons
import BellIcon from "assets/images/svg/bell-icon.svg";
import BellIconDark from "assets/images/svg/bell-icon-dark.svg";

const NotificationBar = () => {
  const [notification, setNotification] = useState(false);

  const { theme } = useCoreStore();

  useEffect(() => {
    setNotification(true);
  }, []);

  return (
    <div className="w-[32px] h-[32px] bg-GreyInput flex justify-center items-center rounded-[4px] cursor-pointer hover:bg-[#e5e5e5] dark:bg-[#27262C] transition-all duration-200 relative">
      {notification && (
        <div className="w-[10px] h-[10px] bg-[#FF705D] border-2 rounded-full absolute top-[5px] right-[4px] border-[#e5e5e5] dark:border-[#27262C]"></div>
      )}
      <img src={theme ? BellIconDark : BellIcon} alt="bell-icon" className="w-[20px] h-[20px] stroke-[#000]" />
    </div>
  );
};

export default NotificationBar;
