export const userInfo = () => {
  let userInfo = {};

  if (!Object.keys(userInfo).length) {
    userInfo = JSON.parse(localStorage.getItem("user_info") || "{}");
  }

  return userInfo;
};

export const checkAmount = (item, length = "10") => {
  switch (length) {
    case "10":
      return item > 9 ? "9+" : item;
    case "100":
      return item > 99 ? "99+" : item;

    default:
      return item;
  }
};

export const languageCombination = (lang) => {
  switch (lang) {
    case "ru":
      return "ru_ru";
    case "uz":
      return "uz_uz";

    default:
      return "uz_lat";
  }
};

export const isAdmin = () => {
  return userInfo().user_type === "admin";
};

export function msToTime(duration) {
  var minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return hours + ":" + minutes;
}

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date) {
  return (
    [padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate()), date.getFullYear()].join("/") +
    " " +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(":")
  );
}

export const findErrorField = (data, fieldName) => {
  if (!!data?.errors?.length && data?.errors[0] !== null) {
    return data.errors.find((item) => item.field_name === fieldName)?.description;
  }
};

export function getOptionValue(data) {
  return data && data.reduce((acc, curr) => [...acc, curr.value ? curr.value : curr], []);
}

export function dataToOptions(data) {
  return (
    data &&
    data.map((el) => ({
      label: el.name,
      value: el.id,
    }))
  );
}

export function dataToValues(data) {
  return data && data.map((el) => el.id);
}

export function sortByAlphabetically(data) {
  return data.sort((a, b) => a.label.localeCompare(b.label));
}

export const isOrgowner = () => {
  if (userInfo()?.resource_access) {
    return userInfo().resource_access?.[userInfo()?.azp]?.roles.includes("org-owner");
  }
  return {};
};

export const checkHasAccessToAction = (accessVal) => {
  const userPermissions = userInfo()?.resource_access?.[userInfo()?.azp]?.roles;
  return userPermissions?.includes(accessVal) || accessVal === "all";
};

export const formatTimestamp = (timestamp, hour = true) => {
  const date = new Date(timestamp);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  if (hour) {
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  } else {
    return `${day}.${month}.${year}`;
  }
};

export function logOut() {
  localStorage.removeItem("user_info");
  localStorage.removeItem("user_detail");
  localStorage.removeItem("user_token");
  localStorage.removeItem("refresh_token");
}

export const cutString = (str, max) => {
  if (str.length <= max) return str;
  return str.slice(0, max) + "...";
};

export function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}

export function uppercaseTwoLetters(value) {
  return value.slice(0, 2).toUpperCase();
}

export const fourNumbersOnly = (value) => {
  return value.replace(/[^\d]/g, "").slice(0, 4);
};

export const formatPrice = (value) => {
  // Convert value to a number and then to a locale-specific string format
  const numberValue = Number(value);

  // Check if the number conversion is valid
  if (isNaN(numberValue)) return "";

  // Return the formatted number as a string with a dot as the decimal separator
  return numberValue
    .toLocaleString("ru-RU", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
    .replace(",", ".");
};

// export const formatPrice = (value) => {
//   // Convert value to a number
//   const numberValue = Number(value);

//   // Check if the number conversion is valid
//   if (isNaN(numberValue)) return "";

//   // Use toFixed to format the number with a maximum of 2 decimal places
//   let formattedValue = numberValue.toFixed(2);

//   // Remove trailing zeros
//   formattedValue = formattedValue.replace(/\.?0+$/, '');

//   // Use toLocaleString to add thousand separators
//   formattedValue = formattedValue.toLocaleString("ru-RU");

//   return formattedValue;
// };

export const unformatPrice = (value) => {
  return value.replace(/[^\d]/g, "");
};
