import { useEffect, useCallback, useRef } from "react";

const useDebounce = (cb, deps, interval) => {
  const callback = useCallback(cb, deps);
  const debounceRef = useRef(null);

  const cancel = () => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
      debounceRef.current = null;
    }
  };

  useEffect(() => {
    debounceRef.current = setTimeout(callback, interval);
    return () => cancel();
  }, [cb, interval]);

  return { cancel };
};

export default useDebounce;
