import { useMemo, useRef, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { ParameterContext } from "../ParametrLayout";
// hooks
import { checkHasAccessToAction } from "services/helpers";
// store
import { useCoreStore } from "store/core";
// api
import { endpoints } from "services/endpoints";
import { useMutate } from "hooks/query";
// i18n
import { useTranslation } from "react-i18next";
import TableAction from "components/Table/TableAction";
import BaseButton from "components/Button/BaseButton";
import { notifySuccess } from "components/Toast";
import DndTable from "components/Table/DndTable";
import ActionButton from "components/Button/ActionButton";
import DeleteConfirmation from "container/DeleteConfirmation";
import { header } from "../Helpers";
// icons
import Plus from "assets/images/svg/icon-add.svg";
import PlusWhite from "assets/images/svg/icon-add-theme.svg";
import iconTrash from "assets/images/svg/trash-red.svg";

const Table = ({ refetch, data = [], modalToggler, handleSearch }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const closeModal = useRef();
  const deleteId = useRef();
  // eslint-disable-next-line no-unused-vars
  let [_, setSearchParams] = useSearchParams();

  const hasAccessToCreate = checkHasAccessToAction("create-property");
  const hasAccessToEdit = checkHasAccessToAction("update-property");
  const hasAccessToDelete = checkHasAccessToAction("delete-property");

  const { parentIds, setParentIds } = useContext(ParameterContext);

  const { theme } = useCoreStore();

  const closeDeleteModal = (value) => {
    closeModal.current.openModal(value);
  };

  const sortCategory = useMutate({
    onSuccess: () => {
      notifySuccess(t("notification.parameterSuccessReorder"));
    },
  });

  const onListChange = (data) => {
    sortCategory.mutate({
      link: `${endpoints.parameter.sort}`,
      method: "patch",
      body: data,
    });
  };

  const deleteCategory = useMutate({
    onSuccess: () => {
      refetch();
      closeDeleteModal(false);
      notifySuccess(t("notification.successDelete"));
    },
  });

  const confirmDelete = () => {
    deleteCategory.mutate({
      link: `${endpoints.parameter.delete}?id=${deleteId.value}`,
      method: "delete",
    });
  };

  const tableData = useMemo(() => {
    return data.map((el, index) => {
      return {
        index,
        id: el.id,
        row: [
          {
            title: (
              <div className="hover:text-blue-400 cursor-pointer" onClick={() => setParentIds({ parentId: el.id })}>
                {el.name[lang]}{" "}
                <span className="ml-[5px] bg-[#F2F2F2] text-[#9B9DA3] rounded-[4px] py-[1px] px-[3px] text-[14px]">
                  {el?.value_count || 0}
                </span>
                {el?.is_separator && (
                  <span className="ml-[5px] bg-[#F2F2F2] text-[#9B9DA3] rounded-[4px] py-[1px] px-[3px] text-[14px]">
                    {t("pages.parameters.separator")}
                  </span>
                )}
              </div>
            ),
          },
          {
            title: (
              <div className="flex gap-4 items-center justify-end">
                {hasAccessToEdit && (
                  <ActionButton type="edit" handleClick={() => setSearchParams({ row_id1: el.id })} />
                )}
                {hasAccessToDelete && (
                  <TableAction hasBackground={true}>
                    <div
                      className="flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput"
                      onClick={() => {
                        deleteId.value = el.id;
                        closeDeleteModal(true, el.id);
                      }}
                    >
                      <img src={iconTrash} alt="delete" />
                      <p className="text-[#FF705D]">{t("button.delete")}</p>
                    </div>
                  </TableAction>
                )}
              </div>
            ),
          },
        ],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div className="relative flex flex-col flex-1 h-full">
        <div className="flex-1 relative">
          <div className="w-full h-[calc(100vh-335px)] pb-[15px]">
            <DndTable
              header={header}
              body={tableData}
              add={modalToggler}
              addable={true}
              handleSearch={handleSearch}
              isSearchable={true}
              orderChange={onListChange}
              activeRowId={parentIds.parentId}
            />
          </div>
        </div>

        <div className="w-full px-2">
          {hasAccessToCreate && (
            <BaseButton
              label={t("pages.parameters.new")}
              onClick={modalToggler}
              icon={theme ? PlusWhite : Plus}
              className="w-full whitespace-nowrap btn-transparent dark:btn-transparent-dark dark:text-white"
            />
          )}
        </div>
      </div>
      <DeleteConfirmation ref={closeModal} close={closeDeleteModal} cb={confirmDelete} />
    </>
  );
};

export default Table;
