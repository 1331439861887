import { useMemo, useRef, useContext } from "react";
// api
import { endpoints } from "services/endpoints";
import { useMutate } from "hooks/query";
// context
import { ProductGroupsContext } from "../ProductGroupsLayout";
// helpers
import { useTranslatedTableConfig } from "../Helpers";
// i18n
import { useTranslation } from "react-i18next";
// components
import { notifySuccess } from "components/Toast";
import DndTable from "components/Table/DndTable";
import ActionButton from "components/Button/ActionButton";

const Table = ({ refetch, data = [], modalToggler, handleSearch }) => {
  const { t } = useTranslation();
  const { header } = useTranslatedTableConfig();
  const deleteId = useRef();
  const deletePropertyId = useRef();

  const { parentIds } = useContext(ProductGroupsContext);

  const changeShow = useMutate({
    onSuccess: () => {
      refetch();
      notifySuccess(t("notification.successUpdate"));
    },
  });

  const sortSelectedProperty = useMutate({
    onSuccess: () => {
      // refetch();
      notifySuccess(t("notification.groupSuccessReorder"));
    },
  });

  const handleShow = (id, propertyId, value) => {
    changeShow.mutate({
      link: value
        ? `${endpoints.productGroup.showProperty}?id=${id}`
        : `${endpoints.productGroup.unshowProperty}?id=${id}`,
      method: "patch",
      // body: { property_id: propertyId },
    });
  };

  const onListChange = (data) => {
    sortSelectedProperty.mutate({
      link: `${endpoints.productGroupProperty.sort}`,
      method: "patch",
      body: data,
    });
  };

  const deleteSelectedProperty = useMutate({
    onSuccess: () => {
      refetch();
      notifySuccess(t("notification.successDelete"));
    },
  });

  const confirmDelete = () => {
    deleteSelectedProperty.mutate({
      link: `${endpoints.productGroup.unbindProperty}?id=${deleteId.value}`,
      method: "patch",
      body: { product_group_property_id: deletePropertyId.value },
    });
  };

  const tableData = useMemo(() => {
    return data.map((el, index) => {
      return {
        index,
        id: el.id,
        product_group_id: el.product_group_id,
        property_id: el.property_id,
        is_show: el.is_show,
        property_separator: el.property_separator,
        row: [
          {
            title: el.property_separator ? el?.default_value_name?.ru : el.name.uz,
          },
          {
            title: (
              <div className="flex gap-4 justify-end">
                  <ActionButton
                    type="delete-group"
                    handleClick={() => {
                      deleteId.value = el.product_group_id;
                      deletePropertyId.value = el.id;
                      confirmDelete();
                    }}
                  />
              </div>
            ),
          },
        ],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const generateSampleName = (properties) => {
    let sampleName = "";
    properties.forEach((el) => {
      if (el.is_show || el.property_separator) sampleName += `${el?.default_value_name?.ru}`;
    });
    return sampleName;
  };

  return (
    <>
      <div className="h-full relative flex flex-col">
        <div className="h-[calc(100vh-350px)]">
          <DndTable
            header={header}
            body={tableData}
            add={modalToggler}
            addable={false}
            isSearchable={true}
            searchPlaceholder={`${t("pages.productGroups.searchParam")}...`}
            hasBackground={false}
            handleSearch={handleSearch}
            orderChange={onListChange}
            activeRowId={parentIds.parentId}
            showSelected={true}
            handleShow={handleShow}
          />
        </div>
        <div
          className="w-full px-2 z-10 mt-[10px] h-[48px]
        bg-[#F8F8F8] dark:bg-[#1F1E25] rounded-[5px] word-wrap break-words overflow-hidden text-sm text-gray-500 flex flex-col justify-center"
        >
          <p>{generateSampleName(data)}</p>
        </div>
      </div>
    </>
  );
};

export default Table;
