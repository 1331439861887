import { useRef, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
// store
import { useCoreStore } from "store/core";
// api
import { endpoints } from "services/endpoints";
import { useGet } from "hooks/query";
// i18n
import { useTranslation } from "react-i18next";
// components
import { CategoryContext } from "../CategoryLayout";
import Modal from "components/Modal/Modal";
import useGetSingleData from "hooks/useGetSingleData/useGetSingleData";
import ModalForm from "./ModalForm";
import Table from "./Table";

const CategoryThird = () => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  let queryId = searchParams.get("row_id3");
  const modal3 = useRef();
  const form3 = useRef();
  const { orgId } = useCoreStore((state) => state);
  const { parentIds } = useContext(CategoryContext);

  const handleTemplatesModal = (value) => {
    modal3.current.openModal(value);
  };

  const { data, isLoading, isError, refetch } = useGet({
    link: endpoints.category.list,
    params: { org_id: orgId, parent_id: parentIds.subParentId },
  });
  const { singleData } = useGetSingleData(queryId, data);

  useEffect(() => {
    if (queryId && modal3.current) {
      handleTemplatesModal(true);
    }
  }, [queryId]);

  const removeImages = () => {
    form3.current.removeImages();
  };

  if (isLoading) return <p>Loading</p>;
  if (isError) return <p>Errror</p>;

  return (
    <>
      <Table data={data} refetch={refetch} modalToggler={handleTemplatesModal} />
      <Modal ref={modal3} title={t("pages.categories.new")} close={removeImages} rowId="row_id3">
        <ModalForm
          ref={form3}
          close={handleTemplatesModal}
          refetch={refetch}
          row={queryId && singleData ? singleData : {}}
          orgId={queryId ?? orgId}
        />
      </Modal>
    </>
  );
};

export default CategoryThird;
