import React, { memo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// store
import { useCoreStore } from "store/core";
// i18n
import { useTranslation } from "react-i18next";
// components
import { FormCheckbox } from "components/Checkbox/FormCheckbox";
import BaseButton from "components/Button/BaseButton";
import CustomSelect from "components/Select/CustomSelect";
import ControlledInput from "components/Input/ControlledInput";
import Chip from "components/Chip/Chip";
// icons
import IconSave from "assets/images/svg/icon-save.svg";
import Search from "assets/images/svg/search.svg";
import SearchWhite from "assets/images/svg/search-white.svg";

const ModalForm = memo(({ close, tags, categories, updateTags }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  let [searchParams, setSearchParams] = useSearchParams();
  const theme = useCoreStore((state) => state.theme);

  const { reset, handleSubmit } = useForm();

  const [selectedCats, setSelectedCats] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("");

  function removeId() {
    reset();
    searchParams.delete("row_id");
    setSearchParams(searchParams);
  }

  function clearAndClose() {
    close(false);
    removeId();
  }

  function saveAndClose() {
    close(false);
    removeId();

    const tags = selectedTags.map((item) => item.label);

    updateTags(selectedCats, tags);
  }

  return (
    <div className="w-[700px]">
      <form onSubmit={handleSubmit()}>
        <div className="flex flex-row justify-between gap-[40px]">
          <div className="border p-[8px] rounded-lg h-[320px] w-[50%]">
            <ControlledInput
              placeholder={`${t("pages.products.form.searchCat")}...`}
              className="mr-2 w-[100%] h-[38px]"
              leftElement={<img src={theme ? SearchWhite : Search} alt="CloseIcon" className="cursor-pointer" />}
              onChange={(e) => {
                setCategoryFilter(e.target.value);
              }}
            />
            <div className="mt-[18px] overflow-y-auto overflow-x-hidden max-h-[230px]">
              {categories?.map((c) => {
                return (
                  <>
                    {c.name[lang].toLowerCase().includes(categoryFilter.toLowerCase()) && (
                      <div key={c.id} className="flex flex-row w-full max-w-full items-center">
                        <div
                          className="flex items-center w-full rounded-[8px] px-[16px] py-[5px] h-[38px] hover:bg-[#F5F5F5] dark:hover:bg-[#2b2b2b] cursor-pointer"
                          onClick={() => {
                            if (selectedCats.includes(c.id)) {
                              setSelectedCats(selectedCats.filter((cat) => cat !== c.id));
                            } else {
                              setSelectedCats([...selectedCats, c.id]);
                            }
                          }}
                        >
                          <FormCheckbox
                            name={c.id}
                            checked={selectedCats.includes(c.id)}
                            id={c.id}
                            errors={{}}
                            onChange={() => {}}
                          />
                          <label htmlFor={c.id} className="ml-[8px] text-[#4f4f4f] text-[13px] font-normal text-[15px]">
                            {c.name[lang]}
                          </label>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
          <div className="border p-[8px] rounded-lg w-[50%]">
            <CustomSelect
              // isMulti={true}
              height="40px"
              padding="0px 5px"
              name="tags"
              search={true}
              closeMenuOnSelect={false}
              width={"100%"}
              placeholder={`${t("pages.products.form.selectTags")}...`}
              leftElement={<img src={theme ? SearchWhite : Search} alt="CloseIcon" className="cursor-pointer" />}
              options={tags?.map((item) => ({ value: item.id, label: item.tag_name })) ?? []}
              // empty value always should be
              value={[]}
              checkbox={true}
              selectedTags={selectedTags}
              onChangeFunc={(value) => {
                // check if already selected
                if (selectedTags?.find((tag) => tag.value === value.option.value)) {
                  // delete from selected
                  setSelectedTags(selectedTags.filter((tag) => tag.value !== value.option.value));
                } else {
                  // add tag_name to selected
                  setSelectedTags([...selectedTags, { value: value.option.value, label: value.option.label }]);
                }

                // clear select
                return;
              }}
            />

            <div className="flex flex-wrap gap-[4px]">
              {selectedTags?.map((item, i) => (
                <Chip
                  text={item.label}
                  closeOption={true}
                  key={i}
                  onDelete={() => {
                    setSelectedTags(selectedTags.filter((tag) => tag.value !== item.value));
                  }}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="flex mt-[16px] justify-between">
          <div>
            <BaseButton
              label={t("button.cancel")}
              onClick={clearAndClose}
              className="flex-1 px-[16px] py-[11px] btn-secondary dark:btn-secondary-dark w-[auto]"
            />
          </div>
          <div>
            <BaseButton
              onClick={saveAndClose}
              type="submit"
              className="flex-1 py-[11px] btn-success"
              icon={IconSave}
              label={t("button.save")}
            />
          </div>
        </div>
      </form>
    </div>
  );
});

export default ModalForm;
