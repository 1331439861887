import React, { useRef, useState, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { NumericFormat } from "react-number-format";
// helpers
import { debounce, formatPrice } from "services/helpers";
// api
import { endpoints } from "services/endpoints";
import { useMutate } from "hooks/query";
// i18n
import { useTranslation } from "react-i18next";
// components
import BaseTd from "components/Table/BaseTd";
import BaseButton from "components/Button/BaseButton";
import DeleteConfirmation from "container/DeleteConfirmation";
import { notifySuccess } from "components/Toast";
// icons
import CommentIcon from "assets/images/svg/comment-icon.svg";
import CommentIconDark from "assets/images/svg/comment-icon-dark.svg";
import iconTrash from "assets/images/svg/trash-red.svg";

const ProductItem = React.memo(
  ({
    item,
    archived,
    currentTable,
    index,
    arrLength,
    setDeleteId,
    singleInvoice,
    updateSingleInvoice,
    handleTemplatesModalComments,
    closeDeleteModal,
  }) => {
    const { t } = useTranslation();
    const tdStyles = "px-[6px] py-[6px] text-[15px]";

    const inputRef = useRef(null);

    const [amount, setAmount] = useState(item.amount);
    const [price, setPrice] = useState(item.price);

    const [searchParams, setSearchParams] = useSearchParams();
    const [isHover, setIsHover] = useState(false);

    // const edit = (data) => {
    //   const body = {
    //     ...data,
    //   };
    // };

    // create

    useEffect(() => {
      if (index === arrLength - 1 && inputRef.current) {
        inputRef.current.focus();
      }
    }, [index, arrLength]);

    const edit = useMutate({
      onSuccess: (res) => {
        notifySuccess(t("notification.successProductUpdate"));
        // update amount and price of product in singleInvoice
        if (currentTable === "arrival") {
          const newSingleInvoice = { ...singleInvoice };
          newSingleInvoice.income_products = newSingleInvoice.income_products.map((item) => {
            if (item.id === res.id) {
              return {
                ...item,
                amount: res.amount,
                price: res.price,
              };
            }
            return item;
          });
          updateSingleInvoice(newSingleInvoice);
        } else {
          const newSingleInvoice = { ...singleInvoice };
          newSingleInvoice.outgo_products = newSingleInvoice.outgo_products.map((item) => {
            if (item.id === res.id) {
              return {
                ...item,
                amount: res.amount,
                price: res.price,
              };
            }
            return item;
          });
          updateSingleInvoice(newSingleInvoice);
        }
      },
    });

    const updateProductData = (key, value) => {
      // Determine the correct endpoint based on the table
      const endpoint =
        currentTable === "arrival"
          ? `${endpoints.invoices.updateIncomeProduct}?id=${item.id}`
          : `${endpoints.invoices.updateOutgoProduct}?id=${item.id}`;

      // Call the API with the new data
      edit.mutate({
        link: endpoint,
        method: "patch",
        body: { [key]: value !== "" ? Number(value) : null },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedUpdateProductData = useCallback(
      debounce((key, value) => {
        updateProductData(key, value);
      }, 300),
      [], // If updateProductData or any other dependencies don't change, this won't be recreated
    );

    // Event handlers use the debounced function
    const handleAmountChange = (value) => {
      const formattedValue = value !== "" ? value.replace(/\s/g, "") : null;
      setAmount(formattedValue);
      // Use the debounced function here
      debouncedUpdateProductData("amount", formattedValue);
    };

    const handlePriceChange = (value) => {
      const formattedValue = value !== "" ? value.replace(/\s/g, "") : null;
      setPrice(formattedValue);
      // Use the debounced function here
      debouncedUpdateProductData("price", formattedValue);
    };
    // edit.mutate({
    //   link: `${endpoints.invoices.updateOutgoProduct}?id=${"id"}`,
    //   method: "put",
    //   body: "new data",
    // });

    return (
      <tr
        key={item.id}
        className="hover:bg-GreyLight dark:hover:bg-inputBgDark"
        onMouseEnter={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
      >
        <BaseTd extraClass={tdStyles + " pl-[16px]"}>{index + 1}</BaseTd>
        <BaseTd extraClass={tdStyles}>{item.product_name}</BaseTd>
        <BaseTd extraClass={tdStyles}>
          {archived ? (
            <span>{item.amount}</span>
          ) : (
            <NumericFormat
              getInputRef={(el) => {
                inputRef.current = el;
              }}
              value={amount}
              decimalScale={0}
              thousandSeparator=" "
              suffix=""
              fixedDecimalScale={false}
              allowNegative={false}
              allowEmptyFormatting={true}
              onChange={(e) => {
                handleAmountChange(e.target.value);
              }}
              className="w-[84px] h-[20px] text-[14px] input p-[2px] mr-[8px] dark:bg-[#1F1E25] dark:border-[#2d2d2d]"
            />
          )}
        </BaseTd>
        <BaseTd extraClass={tdStyles}>
          {archived ? (
            <span>{formatPrice(item.price)}</span>
          ) : (
            <NumericFormat
              value={price}
              decimalScale={2}
              thousandSeparator=" "
              suffix=""
              fixedDecimalScale={false}
              allowNegative={false}
              allowEmptyFormatting={true}
              onChange={(e) => {
                handlePriceChange(e.target.value);
              }}
              className="w-[84px] h-[20px] text-[14px] input p-[2px] mr-[8px] dark:bg-[#1F1E25] dark:border-[#2d2d2d]"
            />
          )}
        </BaseTd>
        <BaseTd extraClass={tdStyles}>{formatPrice(amount * price)}</BaseTd>
        <BaseTd extraClass={tdStyles}>
          <BaseButton
            type="submit"
            icon={item.note ? CommentIcon : CommentIconDark}
            className={`w-auto px-[8px] py-[6px] rounded-[6px]  text-textBlack hover:opacity-[0.8] transition duration-200
                ${item.note ? "bg-[#FFCD29]" : "bg-[#F2f2f2]"}
                `}
            iconClassName="w-[20px]"
            onClick={() => {
              searchParams.set("item_id", item.id);
              searchParams.set("comment_type", currentTable === "arrival" ? "productIncome" : "productOutgo");
              item.note ? searchParams.set("comment", item.note) : searchParams.set("comment", "");
              setSearchParams(searchParams);
              handleTemplatesModalComments(true);
            }}
          />
        </BaseTd>
        <BaseTd extraClass={tdStyles}>{formatPrice(item.rp)}</BaseTd>
        <BaseTd extraClass={tdStyles}>{formatPrice(item.b2b_price)}</BaseTd>
        <BaseTd extraClass={tdStyles}>{formatPrice(item.b2c_price)}</BaseTd>
        <BaseTd extraClass={tdStyles}>
          <div className="text-center flex justify-center">
            {archived ? (
              <span></span>
            ) : (
              <img
                src={iconTrash}
                alt="delete"
                className={`${
                  isHover ? "opacity-1" : "opacity-0"
                } cursor-pointer width-[20px] height-[20px] text-center`}
                onClick={() => {
                  setDeleteId(item.id); // Set the id to deleteId ref in Tbody component
                  closeDeleteModal(true);
                }}
              />
            )}
          </div>
        </BaseTd>
      </tr>
    );
  },
);

const Tbody = ({ data, archived, currentTable, singleInvoice, updateSingleInvoice, handleTemplatesModalComments }) => {
  const closeModal = useRef();
  const deleteId = useRef();

  const closeDeleteModal = (value) => {
    closeModal.current.openModal(value);
  };

  const deleteProduct = useMutate({
    onSuccess: () => {
      // singleInvoiceRefetch();
      const newSingleInvoice = { ...singleInvoice };
      if (currentTable !== "arrival") {
        newSingleInvoice.outgo_products = newSingleInvoice.outgo_products.filter(
          (item) => item.id !== deleteId.current,
        );
      } else {
        newSingleInvoice.income_products = newSingleInvoice.income_products.filter(
          (item) => item.id !== deleteId.current,
        );
      }
      updateSingleInvoice(newSingleInvoice);

      closeDeleteModal(false);
      notifySuccess("Успешно удалено");
    },
  });

  const confirmDelete = () => {
    deleteProduct.mutate({
      // Assuming deleteId.current holds the actual ID after being set by setDeleteId
      link:
        currentTable === "arrival"
          ? `${endpoints.invoices.deleteIncomeProduct}?id=${deleteId.current}`
          : `${endpoints.invoices.deleteOutgoProduct}?id=${deleteId.current}`,
      method: "delete",
    });
  };

  return (
    <>
      {data.map((item, index) => (
        <ProductItem
          archived={archived}
          currentTable={currentTable}
          key={item.id}
          item={item}
          setDeleteId={(id) => (deleteId.current = id)}
          index={index}
          arrLength={data.length}
          handleTemplatesModalComments={handleTemplatesModalComments}
          singleInvoice={singleInvoice}
          updateSingleInvoice={updateSingleInvoice}
          closeDeleteModal={closeDeleteModal}
        />
      ))}
      <DeleteConfirmation ref={closeModal} close={closeDeleteModal} cb={confirmDelete} />
    </>
  );
};

export default Tbody;
