import { useContext, useMemo } from "react";
import { endpoints } from "services/endpoints";
import { useCoreStore } from "store/core";
import { useGet } from "hooks/query";
import useSearch from "hooks/useSearch/useSearch";

import Table from "./Table";
import { ProductGroupsContext } from "../ProductGroupsLayout";

const ProductGroupsThird = () => {
  const { orgId } = useCoreStore((state) => state);
  const { parentIds } = useContext(ProductGroupsContext);
  const { searchResult, handleSearch } = useSearch();

  const { data, isLoading, isError } = useGet({
    link: endpoints.parameter.list,
    params: { org_id: orgId, q: searchResult || undefined },
  });

  const filterData = (data || []).filter(item => 
    !item.is_separator && !parentIds.propertiesIdList?.includes(item.id)
  );

  // get object with is)seperator: true from data

  const seperator = useMemo(() => {
    return (data || []).find((item) => item.is_separator);
  }, [data]);



  if (isLoading) return <p>Loading</p>;
  if (isError) return <p>Errror</p>;

  return (
    <>
      <Table data={filterData} handleSearch={handleSearch} seperator={seperator}/>
    </>
  );
};

export default ProductGroupsThird;
