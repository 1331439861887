// i18n
import { useTranslation } from "react-i18next";

export const useTranslatedTableConfig = () => {
  const { t } = useTranslation();

  const header = [
    {
      key: "image",
      title: t("pages.products.table.image"),
      width: "w-[5%]",
    },
    {
      key: "code",
      title: t("pages.products.table.code"),
      width: "w-[5%]",
    },
    {
      key: "name",
      title: t("pages.products.table.name"),
      width: "w-[15%]",
    },
    {
      key: "leftCount",
      title: t("pages.products.table.leftCount"),
      width: "w-[5%]",
    },
    {
      key: "inputPrice",
      title: t("pages.products.table.inputPrice"),
      width: "w-[10%]",
    },
    {
      key: "b2b",
      title: t("pages.products.table.b2b"),
      width: "w-[5%]",
    },
    {
      key: "b2c",
      title: t("pages.products.table.b2c"),
      width: "w-[5%]",
    },
    {
      key: "rpr",
      title: t("pages.products.table.rpr"),
      width: "w-[5%]",
    },
    {
      key: "tags",
      title: t("pages.products.table.tags"),
      width: "w-[15%]",
    },
    {
      key: "categories",
      title: t("pages.products.table.categories"),
      width: "w-[15%]",
    },
    {
      key: "link",
      title: t("pages.products.table.link"),
      width: "w-[5%]",
    },
    {
      key: "channel",
      title: t("pages.products.table.channel"),
      width: "w-[5%]",
    },
    {
      key: "actions",
      title: t("pages.products.table.actions"),
      width: "w-[5%]",
    },
  ];

  return { header };
};
