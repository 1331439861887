import React, { useRef, useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import cls from "classnames";
import DraggableList from "react-draggable-list";
import DndTableItem from "./DndTableItem";
import { useCoreStore } from "store/core";
// i18n
import { useTranslation } from "react-i18next";
//components
import GoBackButton from "components/Button/GoBackButton";
import ControlledInput from "components/Input/ControlledInput";
// assets
import SearchDark from "assets/images/svg/icon-search-dark.svg";
import CloseIcon from "assets/images/svg/icon-input-close.svg";
import SearchWhite from "assets/images/svg/search-white.svg";

const DndTable = ({
  title,
  redirect,
  activeRowId,
  className = "",
  fullWidth = false,
  seperator = {},
  body = [],
  header = [],
  add = () => {},
  searchValue = "",
  searchPlaceholder,
  isSearchable = false,
  showSelected = false,
  handleSearch = () => {},
  orderChange = () => {},
  handleBindPropertySeperator = () => {},
  handleScroll = () => {},
  handleShow = () => {},
}) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const [list, setList] = useState([]);
  const theme = useCoreStore((state) => state.theme);

  useEffect(() => {
    setList(body);
  }, [body]);

  const containerRef = useRef();

  const searchIcon = useMemo(() => (theme ? SearchWhite : SearchDark), [theme]);

  const checkPosition = (oldIndex, newIndex) => {
    return oldIndex > newIndex ? "up" : "down";
  };

  const changedOrderTransform = (movedItem, oldIndex, newIndex) => {
    const direction = checkPosition(oldIndex, newIndex);
    const destination = list[newIndex].id;
    const source = movedItem.id;

    return { direction, destination, source };
  };

  const _onListChange = (newList, movedItem, oldIndex, newIndex) => {
    // TODO check if needed
    // const orderedList = (newList || []).reduce((acc, curr, index) => {
    //   acc[index] = curr;
    //   acc[index].index = index;

    //   return acc;
    // }, []);
    setList(newList);
    orderChange(changedOrderTransform(movedItem, oldIndex, newIndex));
  };

  // const _onListChange = (newList) => {
  //   // Update the state or perform actions based on the new order
  //   setList(newList);
  // };

  return (
    <div className={cls("h-full rounded-lg flex flex-col", {})}>
      <h2 className="text-bold text-lg flex text-black dark:text-textDark">
        {redirect && (
          <Link to={redirect} className="w-8 h-8 mr-4">
            <GoBackButton />
          </Link>
        )}
        <span>{title}</span>
      </h2>
      <div className="w-full mt-1 pb-2 relative">
        {isSearchable && (
          <>
            <ControlledInput
              onChange={handleSearch}
              placeholder={searchPlaceholder || t("form.search")}
              className="w-full dark:border-[#2D2D2D]"
              leftElement={<img src={searchIcon} alt="search icon" className="top-3" />}
              rightElement={
                searchValue && (
                  <img src={CloseIcon} alt="CloseIcon" className="cursor-pointer" onClick={() => handleSearch("")} />
                )
              }
            />
          </>
        )}
      </div>
      <div className="min-h-[200px] overflow-y-auto" onScroll={handleScroll}>
        {Object.keys(seperator).length !== 0 && seperator?.separator_values.length !== 0 && (
          <div className="px-5 flex flex-row flex-wrap justify-center gap-[5px] mb-[10px]">
            {seperator?.separator_values.map((el, index) => (
              <div
                key={el.id}
                className="flex justify-center items-center border-1 border-[#BDBDBD] w-[40px] h-[40px] rounded-full cursor-pointer"
                onClick={() => handleBindPropertySeperator(seperator.id, el.id)}
              >
                <span className="text-[#000] dark:text-darkGrey">{el.name[lang]}</span>
              </div>
            ))}
          </div>
        )}
        <table className="w-full">
          <thead>
            <tr className="flex gap-3 pl-4"></tr>
          </thead>
          <tbody ref={containerRef} style={{ touchAction: "pan-y" }} className="w-full">
            {body.length > 0 && (
              <DraggableList
                itemKey="index"
                template={(props) => (
                  <DndTableItem {...props} handleShow={handleShow} showSelected={showSelected} className={className} />
                )}
                padding={0}
                list={list}
                commonProps={{ activeRowId }}
                onMoveEnd={_onListChange}
                container={() => containerRef.current}
              />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DndTable;
