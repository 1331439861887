import { useDropzone } from "react-dropzone";
// utils
import uploader from "services/uploader";
// components
import ThemeToggler from "components/ThemeToggler";
// icons
import iconAddphoto from "assets/images/svg/icon-addphoto.svg";
import iconDeletephoto from "assets/images/svg/delete.svg";

const ProfileImageUploader = ({ username, roles, url = [], setImagesUrl, label, name = "", descElement, imgIcon }) => {
  const requestImageUpload = (event) => {
    uploader(event).then((resp) => {
      let img = resp();
      if (img.includes("localhost")) {
        img = process.env.REACT_APP_API_PROXY_URL + img.slice(21, img.length);
      }

      if (name) {
        setImagesUrl((prev) => {
          return {
            ...prev,
            [name]: [img],
          };
        });
      } else {
        setImagesUrl((prev) => [...prev, img]);
      }
    });
  };

  const requestImageUploadDrag = (file) => {
    uploader(false, false, file).then((resp) => {
      let img = resp();
      if (img.includes("localhost")) {
        img = process.env.REACT_APP_API_PROXY_URL + img.slice(21, img.length);
      }

      if (name) {
        setImagesUrl((prev) => {
          return {
            ...prev,
            [name]: [img],
          };
        });
      } else {
        setImagesUrl((prev) => [...prev, img]);
      }
    });
  };

  const deleteImg = (img, name) =>
    setImagesUrl((prevImg) => {
      if (Array.isArray(prevImg)) {
        return prevImg.filter((pht) => pht !== img);
      } else {
        return prevImg[name].filter((pht) => pht !== img);
      }
    });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (file) => {
      requestImageUploadDrag(file[0]);
    },
  });

  return (
    <div>
      {label && <label className="block text-stone-800 dark:text-white font-semibold text-[14px] mb-1">{label}</label>}
      <div className="profile_image__uploader-imgs !w-full justify-between dark:!border-[#2d2d2d]">
        <div className="flex items-center space-x-4">
          {url &&
            url.map((image) => (
              <div className="profile_image__uploader-content" key={image}>
                <div className="profile_image__uploader-container">
                  <img src={image} alt="document" className="profile_image__uploader-img" />
                </div>
                <img
                  src={iconDeletephoto}
                  alt="delete-icon"
                  className="profile_image__uploader-delete"
                  onClick={() => deleteImg(image, name)}
                />
              </div>
            ))}

          {url.length === 0 && (
            <label
              {...getRootProps()}
              htmlFor={name || "requestPhoto"}
              className="profile_image__uploader-img create relative"
            >
              <input
                type="file"
                id={name || "requestPhoto"}
                onChange={requestImageUpload}
                className="w-[120px]"
                {...getInputProps()}
              />
              <div className="profile_image__uploader-container">
                <img src={imgIcon || iconAddphoto} alt="addphoto" />
              </div>
              {isDragActive && (
                <div className="absolute w-full h-full bg-[#7B61FF0D] border-2 border-[#7B61FF] border-dashed rounded-[4px] flex flex-col items-center justify-center"></div>
              )}
            </label>
          )}

          {descElement ? (
            descElement
          ) : (
            <div>
              <h2 className="textBlack font-semibold text-[15px] dark:text-[#fff]">{username}</h2>
              <p className="text-[#9B9DA3] font-normal text-[13px]">
                {roles.map((role) => {
                  return role.description;
                })}
              </p>
            </div>
          )}
        </div>

        <ThemeToggler />
      </div>
    </div>
  );
};

export default ProfileImageUploader;
