import { useSearchParams } from "react-router-dom";
import { useMutate } from "hooks/query";
import { endpoints } from "services/endpoints";
import { useCoreStore } from "store/core";
// helpers
import { formatPrice } from "services/helpers";
// i18n
import { useTranslation } from "react-i18next";
// components
import BaseButton from "components/Button/BaseButton";
import TableAction from "components/Table/TableAction";
import BaseTd from "components/Table/BaseTd";
import { notifySuccess } from "components/Toast";
//icons
import iconEdit from "assets/images/svg/edit-icon.svg";
import iconTrash from "assets/images/svg/trash-red.svg";
import NoteIcon from "assets/images/svg/note-icon.svg";
import NoteIconBlack from "assets/images/svg/note-icon-black.svg";
import InfoIcon from "assets/images/svg/icon-info.svg";
import ScissorsIcon from "assets/images/svg/icon-scissors.svg";
import HistoryPlusIcon from "assets/images/svg/historyPlus-icon.svg";
import HistoryMinusIcon from "assets/images/svg/historyMinus-icon.svg";
import AssignmentIcon from "assets/images/svg/assignment-icon.svg";

const Tbody = ({
  data,
  type,
  pfShow,
  pfsShow,
  invoiceId,
  warehouse,
  setInvoices,
  darkColor,
  lightColor,
  handleTemplatesEditProductExpence,
  handleTemplatesEditProductIncome,
}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line
  let [_, setSearchParams] = useSearchParams();

  const { setSelectedObj } = useCoreStore((state) => state);

  const remove = useMutate({
    onSuccess: (res) => {
      // refetch();
      // reset();
      // clearAndClose();
      setInvoices((prev) =>
        prev.map((item) =>
          item.id === invoiceId
            ? {
                ...item,
                ...(type === "expense"
                  ? { outgo_products: item.outgo_products.filter((product) => product.id !== res.id) }
                  : { income_products: item.income_products.filter((product) => product.id !== res.id) }),
              }
            : item,
        ),
      );

      notifySuccess(t("notification.successDelete"));
    },
  });

  const handleDelete = (id) => {
    remove.mutate({
      link:
        type === "expense"
          ? `${endpoints.invoices.deleteOutgoProduct}?id=${id}`
          : `${endpoints.invoices.deleteIncomeProduct}?id=${id}`,
      method: "delete",
    });
  };

  const extraClass =
    "!py-[6px] px-[16px] first:rounded-tl-[0] first:rounded-bl-[0] first:rounded-tr-[0] first:rounded-br-[0] last:rounded-tr-[0] last:rounded-br-[0] last:rounded-tl-[0] last:rounded-bl-[0] dark:!text-[#000]";

  const tabClass = "flex gap-2 p-1 rounded-[4px] hover:bg-GreyInput dark:hover:bg-midBlack";

  return (
    <>
      {data.map((item, i) => (
        <tr
          key={item.id}
          className={`hover:bg-GreyLight dark:hover:bg-inputBgDark text-left`}
          style={i % 2 !== 0 ? { backgroundColor: `${darkColor}` } : { backgroundColor: `${lightColor}` }}
        >
          <BaseTd extraClass={extraClass}>{i + 1}</BaseTd>
          <BaseTd extraClass={extraClass}>{item.product_name}</BaseTd>
          <BaseTd extraClass={extraClass}>{formatPrice(item.amount) || "-"}</BaseTd>
          <BaseTd extraClass={extraClass}>{formatPrice(item.price) || "-"}</BaseTd>
          {pfShow && <BaseTd extraClass={extraClass}>{formatPrice(item?.price - item?.rp) || "-"}</BaseTd>}
          <BaseTd extraClass={extraClass}>{formatPrice(item.total) || "-"}</BaseTd>
          {pfsShow && (
            <BaseTd extraClass={extraClass}>{formatPrice(item?.total - item?.rp * item?.amount) || "-"}</BaseTd>
          )}
          <BaseTd extraClass={extraClass}>{warehouse}</BaseTd>
          <BaseTd extraClass={extraClass}>
            {/* commentary button  */}
            <BaseButton
              type="submit"
              icon={item.note !== "" ? NoteIcon : NoteIconBlack}
              className={`w-auto px-[8px] py-[6px] rounded-[6px]  text-textBlack hover:opacity-[0.8] transition duration-200
                ${item.note !== "" ? "bg-[#FFCD29]" : "bg-[#F2f2f2] cursor-default"}
                `}
              iconClassName="w-[20px]"
            />
          </BaseTd>
          <BaseTd extraClass={extraClass}>{item.rp}</BaseTd>
          <BaseTd extraClass={extraClass}>{formatPrice(item.b2b_price) || "-"}</BaseTd>
          <BaseTd extraClass={extraClass}>{formatPrice(item.b2c_price) || "-"}</BaseTd>

          <BaseTd extraClass={extraClass}>
            <div className="flex items-center gap-4 justify-end">
              <TableAction boxClass="z-[500]" dark={true}>
                <div
                  className={tabClass}
                  onClick={() => {
                    setSearchParams({ row_id: item.id });
                    setSelectedObj(item);
                    handleTemplatesEditProductIncome
                      ? handleTemplatesEditProductIncome(true)
                      : handleTemplatesEditProductExpence && handleTemplatesEditProductExpence(true);
                  }}
                >
                  <img src={iconEdit} alt="delete" />
                  <p className="text-textBlack">{t("button.edit")}</p>
                </div>
                <div className={tabClass} onClick={() => console.log("INACTIVE")}>
                  <img src={InfoIcon} alt="delete" />
                  <p>{t("pages.admInvoice.actions.properties")}</p>
                </div>
                <div className={tabClass} onClick={() => console.log("INACTIVE")}>
                  <img src={ScissorsIcon} alt="delete" />
                  <p>{t("pages.admInvoice.actions.split")}</p>
                </div>
                <div className={tabClass} onClick={() => console.log("INACTIVE")}>
                  <img src={HistoryPlusIcon} alt="delete" />
                  <p>{t("drawer.purchHis")}</p>
                </div>
                <div className={tabClass} onClick={() => console.log("INACTIVE")}>
                  <img src={HistoryMinusIcon} alt="delete" />
                  <p>{t("drawer.saleHis")}</p>
                </div>
                <div className={tabClass} onClick={() => console.log("INACTIVE")}>
                  <img src={AssignmentIcon} alt="delete" />
                  <p className="text-textBlack">Остаток</p>
                </div>

                <div className={tabClass} onClick={() => handleDelete(item.id)}>
                  <img src={iconTrash} alt="delete" className="ml-[3px]" />
                  <p className="text-[#FF705D]">{t("button.delete")}</p>
                </div>
              </TableAction>
            </div>
          </BaseTd>
        </tr>
      ))}
    </>
  );
};

export default Tbody;
