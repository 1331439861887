import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import uz from "assets/translations/uz.json";
import ru from "assets/translations/ru.json";

const resources = {
  uz: {
    translation: uz,
  },
  ru: {
    translation: ru,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("_lang") || "ru",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
