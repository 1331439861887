import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { checkHasAccessToAction } from "services/helpers";
// i18n
import { useTranslation } from "react-i18next";
// components
import BaseButton from "components/Button/BaseButton";
import BaseTd from "components/Table/BaseTd";
import ControlledCheckbox from "components/Checkbox/ControlledCheckbox";
// icons
import iconTrash from "assets/images/svg/trash-red.svg";
import IconEdit from "assets/images/svg/edit-icon.svg";

const Tbody = ({ data, isChecked, checkboxes, setCheckboxes, closeDeleteModal }) => {
  const { t } = useTranslation();
  let [_, setSearchParams] = useSearchParams();

  const hasAccessToEdit = checkHasAccessToAction("update-warehouse");
  const hasAccessToDelete = checkHasAccessToAction("delete-warehouse");

  const selectTableRow = (rowId) => {
    setCheckboxes({ ...checkboxes, [rowId]: !checkboxes[rowId] });
  };

  useEffect(() => {
    const objState = {};
    if (isChecked) {
      data.forEach((item) => {
        objState[item.id] = true;
      });
      setCheckboxes(objState);
    } else {
      const filterByTrue = Object.values(checkboxes).filter((el) => el);
      if (filterByTrue.length === data.length) setCheckboxes({});
    }
  }, [isChecked]);

  return (
    <>
      {data.map((item) => (
        <tr key={item.id} className="hover:bg-GreyLight dark:hover:bg-inputBgDark">
          <BaseTd>
            <div className="flex items-center gap-2">
              <ControlledCheckbox checked={checkboxes[item.id] || false} onChange={() => selectTableRow(item.id)} />
              <p className="flex items-center gap-3">
                {item.name} <span className="bg-[#F2F2F2] text-[#9B9DA3] px-1 rounded-sm text-sm"> {item.code} </span>
              </p>
            </div>
          </BaseTd>
          <BaseTd>{item.address}</BaseTd>
          <BaseTd>

            <div className="flex items-center gap-4 justify-end">
              {hasAccessToEdit && (
                <BaseButton icon={IconEdit} className="p-[6px]" onClick={() => setSearchParams({ row_id: item.id })} />
              )}
              {hasAccessToDelete && (
                <div
                  className="flex gap-2 p-2 cursor-pointer rounded-[4px] bg-GreyInput dark:bg-midBlack hover:bg-GreyInput dark:hover:bg-midBlack"
                  onClick={() => closeDeleteModal(true, item.id)}
                >
                  <img src={iconTrash} alt="delete" />
                </div>
              )}
            </div>
          </BaseTd>
        </tr>
      ))}
    </>
  );
};

export default Tbody;
