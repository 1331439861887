import React from "react";

const Empty = ({ title }) => {
  return (
    <div className="h-[95%]">
      <h2 className="text-bold text-lg flex text-darkGreyLight dark:text-textDark"></h2>
      <div className="w-full mt-3 pb-2 px-4"></div>
      <div className="flex items-center justify-center h-full">
        <p className="text-center text-darkGreyLight flex justify-center items-center px-6 py-8">{title}</p>
      </div>
    </div>
  );
};

export default Empty;
