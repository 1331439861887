// i18n
import { useTranslation } from "react-i18next";

export const useTranslatedTableConfig = () => {
  const { t } = useTranslation();

  const header = [
    {
      key: "name",
      title: t("pages.warehouses.table.name"),
      width: "w-[30%]",
    },
    {
      key: "address",
      title: t("pages.warehouses.table.address"),
      width: "w-[50%]",
    },
    {
      key: "empty",
      title: "",
      width: "w-[20%]",
    },
  ];
  return { header };
};
